import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table } from '@arcflight/tf-component-library';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';

interface FlightHistoryProps {
  data: any;
}

const TableWrapper = styled.div`
  padding: 0 1px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const FlightHistoryTable: React.FC<FlightHistoryProps> = ({ data }) => {
  const { formatMessage } = useIntl();

  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const columns = useMemo(
    () => [
      {
        Header: formatMessage({ id: 'title.event' }),
        accessor: 'event',
        width: 300,
        Cell: ({ value }): string => value.charAt(0).toUpperCase() + value.slice(1) || '-',
      },
      {
        Header: formatMessage({ id: 'title.timestamp' }),
        accessor: 'timestamp',
        Cell: ({ value }): string => moment(value).format(`${dateFormat} HH:mmz`) || '-',
      },
      {
        Header: formatMessage({ id: 'title.user' }),
        accessor: 'changed_by_name',
        Cell: ({ value }): string => value || '-',
      },
    ],
    [dateFormat, formatMessage],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );
  return (
    <TableWrapper>
      <Table tableInstance={tableInstance} />
    </TableWrapper>
  );
};

export default FlightHistoryTable;
