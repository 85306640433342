import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';

interface FuelSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const FuelSection: React.FC<FuelSectionProps> = ({ flight, updateFlightData }) => {
  const { formatMessage } = useIntl();
  const isMobile = window.innerWidth < 451;

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const currentAircraft = aircraftMap.get(id);
  let fuelunit;
  if (currentAircraft) fuelunit = currentAircraft?.fuel_unit;

  const [initial, setInitial] = useState(null);
  const [departure, setDeparture] = useState(null);
  const [adjustment, setAdjustment] = useState(null);
  const [arrival, setArrival] = useState(null);
  const [fuelUsed, setFuelUsed] = useState(null);

  const handleInputChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (type === 'initial') {
      setInitial(newValue);
      updateFlightData([{ value: newValue, key: 'departure_fuel_pre_uplifts' }]);
    }
    if (type === 'departure') {
      setDeparture(newValue);
      // updateFlightData(newValue, 'departure_fuel_pre_uplifts');
    }
    if (type === 'adjustment') {
      setAdjustment(newValue);
      updateFlightData([{ value: newValue, key: 'departure_fuel_adjustment' }]);
    }
    if (type === 'arrival') {
      setArrival(newValue);
      updateFlightData([{ value: newValue, key: 'arrival_fuel' }]);
    }
    if (type === 'fuel') {
      setFuelUsed(newValue);
    }
  };

  useEffect(() => {
    if (flight) {
      if (flight?.departure_fuel_pre_uplifts) {
        setInitial(flight.departure_fuel_pre_uplifts);
      } else {
        setInitial('');
      }
      if (flight?.departure_fuel_log?.aircraft_value_post_event) {
        setDeparture(flight?.departure_fuel_log?.aircraft_value_post_event);
      } else {
        setDeparture('');
      }
      if (flight?.departure_fuel_adjustment) {
        setAdjustment(flight.departure_fuel_adjustment);
      } else {
        setAdjustment('');
      }
      if (flight?.arrival_fuel) {
        setArrival(flight?.arrival_fuel);
      } else {
        setArrival('');
      }
      if (flight?.arrival_fuel && flight?.departure_fuel_log?.aircraft_value_post_event) {
        setFuelUsed(flight?.departure_fuel_log?.aircraft_value_post_event - flight?.arrival_fuel);
      } else {
        setFuelUsed('');
      }
    }
  }, [flight]);

  return (
    <SectionWrapper>
      <SectionHeader data-testid="FuelSection-Header">Fuel</SectionHeader>
      <DetailsWrapper edit={mode !== 'view'} isMobile={isMobile}>
        <div data-testid="FuelSection-EstimatedUplift">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.initialSmall' })}</Header>
          <UnitWrapper>
            {mode === 'view' ? (
              <DisplayText cap>{initial || '-'}</DisplayText>
            ) : (
              <StyledInput
                type="number"
                placeholder="-"
                value={initial}
                onChange={(e): void => handleInputChange(e.target.value, 'initial')}
              />
            )}
            <StyledUnit edit={mode !== 'view'}>{initial || mode !== 'view' ? fuelunit : ''}</StyledUnit>
          </UnitWrapper>
        </div>
        <div data-testid="FuelSection-ActualUplift">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.departure' })}</Header>
          <UnitWrapper>
            {mode === 'view' ? (
              <DisplayText cap>{departure || '-'}</DisplayText>
            ) : (
              <StyledInput
                type="number"
                placeholder="-"
                value={departure}
                onChange={(e): void => handleInputChange(e.target.value, 'departure')}
              />
            )}
            <StyledUnit edit={mode !== 'view'}>{departure || mode !== 'view' ? fuelunit : ''}</StyledUnit>
          </UnitWrapper>
        </div>
        <div data-testid="FuelSection-Departure">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.adjustment' })}</Header>
          <UnitWrapper>
            {mode === 'view' ? (
              <DisplayText cap>{adjustment || '-'}</DisplayText>
            ) : (
              <StyledInput
                type="number"
                placeholder="-"
                value={adjustment}
                onChange={(e): void => handleInputChange(e.target.value, 'adjustment')}
              />
            )}
            <StyledUnit edit={mode !== 'view'}>{adjustment || mode !== 'view' ? fuelunit : ''}</StyledUnit>
          </UnitWrapper>
        </div>
        <div data-testid="FuelSection-Arrival">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.arrival' })}</Header>
          <UnitWrapper>
            {mode === 'view' ? (
              <DisplayText cap>{arrival || '-'}</DisplayText>
            ) : (
              <StyledInput
                type="number"
                placeholder="-"
                value={arrival}
                onChange={(e): void => handleInputChange(e.target.value, 'arrival')}
              />
            )}
            <StyledUnit edit={mode !== 'view'}>{arrival || mode !== 'view' ? fuelunit : ''}</StyledUnit>
          </UnitWrapper>
        </div>
        <div data-testid="FuelSection-UsedFuel">
          {mode === 'view' ? (
            <div>
              <Header>{formatMessage({ id: 'text.fuelUsed' })}</Header>
              <UnitWrapper>
                <DisplayText cap>{fuelUsed || '-'}</DisplayText>
                <StyledUnit edit={mode !== 'view'}>{fuelUsed || mode !== 'view' ? fuelunit : ''}</StyledUnit>
              </UnitWrapper>
            </div>
          ) : null}
        </div>
      </DetailsWrapper>
    </SectionWrapper>
  );
};

export default FuelSection;
