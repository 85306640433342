import { Person } from '../people';

export const initialState: UserSettingsState = {
  details: undefined,
  id: null,
  avatar: 'init', // for cache busting
  dateFormat: 'YYYY-MM-DD',
  selectedView: 'table',
  tableAnimationClass: 'defaultTable',
  listAnimationClass: 'defaultList',
  lastFetched: Date.now(),
  logbook_columns: [],
  ui: {
    colorWeak: false,
    navTheme: 'dark',
    primaryColor: '#126fd6',
    layout: 'sidemenu',
    contentWidth: 'Fluid',
    fixedHeader: false,
    autoHideHeader: false,
    fixSiderbar: false,
  },
  displayName: '',
  currentOrg: undefined,
};

export enum UserSettingsActionTypes {
  SAVE = 'userSettings/save',
  SET_SELECTED_VIEW = 'userSettings/setSelectedView',
  UPDATE_DETAILS = 'userSettings/updateDetails',
  SAVE_SINGLE = 'userSettings/saveSingle',
}

interface SaveUserSettingsActionType {
  type: UserSettingsActionTypes.SAVE;
  payload: UserSettings;
  details: User;
}

interface SaveSingleUserSettingsActionType {
  type: UserSettingsActionTypes.SAVE_SINGLE;
  payload: UserSettings;
  details: User;
}

interface SetSelectedViewUserSettingsActionType {
  type: UserSettingsActionTypes.SET_SELECTED_VIEW;
  payload: UserSettings;
}

interface UpdateDetailsUserSettingsActionType {
  type: UserSettingsActionTypes.UPDATE_DETAILS;
  payload: UserSettings;
  details: User;
}

type UserSettingsAction =
  | SaveUserSettingsActionType
  | SetSelectedViewUserSettingsActionType
  | UpdateDetailsUserSettingsActionType
  | SaveSingleUserSettingsActionType;

export default function(state = initialState, action: UserSettingsAction): typeof initialState {
  switch (action.type) {
    case UserSettingsActionTypes.SAVE: {
      return {
        ...state,
        details: action.payload,
        id: action.payload.id,
        avatar: action.payload.avatar || state.avatar,
        dateFormat: action.payload.date_format,
        logbook_columns: action.payload?.logbook_columns,
        lastFetched: Date.now(),
        displayName: `${action.payload.first_name} ${action.payload.last_name}`,
      };
    }
    // case UserSettingsActionTypes.SAVE_SINGLE: {
    //   return {
    //     currentOrg: {
    //       id: action.payload.id,
    //       avatar_url: action.payload.avatar || state.avatar,
    //       displayName: `${action.payload.first_name} ${action.payload.last_name}`,
    //     },
    //   };
    // }
    case UserSettingsActionTypes.SET_SELECTED_VIEW: {
      return {
        ...state,
        selectedView: action.payload.selectedView,
        tableAnimationClass: 'defaultTable',
        listAnimationClass: 'defaultList',
      };
    }
    case UserSettingsActionTypes.UPDATE_DETAILS:
      return {
        ...state,
        details: action.details,
        dateFormat: action.details.date_format,
        logbook_columns: action.details?.logbook_columns,
      };
    default:
      return state;
  }
}

export interface Operator {
  id: string;
  name: string;
  address: string;
  aoc_number: string;
  date_format: string;
  ddl_template: null;
  logo_url: null;
  dark_logo_url: null;
  aircraft_count: string;
  feature_flags: any;
  operator_setting: OperatorSetting;
}

export interface OperatorSetting {
  advisory_on_ddl: boolean;
  defect_type_on_ddl: string[];
  intermittent_fault_name_plural: string;
  intermittent_fault_name_singular: string;
  only_deferred_on_ddl: boolean;
  requires_camp_mx_approval: boolean;
}

export enum UserRoles {
  ADMIN = 'admin',
  HELPDESK = 'helpdesk',
  PUBLIC = 'public',
}

export enum DashboardResource {
  AIRCRAFT = 'Aircraft',
  DASHBOARD = 'Dashboard',
  DEFECT = 'Defect',
  DOCUMENT = 'Document',
  INTEGRATION = 'Integration',
  MEL = 'Mel',
  PEOPLE = 'People',
  RECEIPT = 'Receipt',
  MX_ITEM = 'ScheduledMxItem',
  TRIP = 'Trip',
}

export enum UserPermission {
  CREATE = 'to_create',
  READ = 'to_read',
  UPDATE = 'to_update',
  DELETE = 'to_delete',
}

export enum FeatureFlag {
  SRPEDIT = 'srp-pdf-api-generation',
  SRPVIEW = 'srp-pdf-api-editing',
  METRICS = 'metrics',
  INTERMITTENT = 'intermittent-faults',
  SAF = 'saf_percentage',
  FUELTYPE = 'fuel-type-selection',
  MAINTENANCE = 'maintenance-checks-with-release',
  STANDALONERELEASE = 'add-standalone-release',
  NOMXTASKSIGNOFF = 'no-mx-task-signoff',
  ACCESSSRP = 'access-srp-for-crs',
  DENTANDBUCKLE = 'damage-reports',
  CYCLESWIDGET = 'cycles-widget',
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  date_format: string;
  theme: string;
  menu_position: string;
  taskcards_theme: string;
  taskcards_role: string;
  avatar_url: string;
  dashboard_permissions: UserRoles[];
  people: PeoplePermissions[];
  operators: Operator[];
  logbook_columns?: string[];
}

export interface ResourcePermissions {
  id?: string;
  permission_group_id?: string;
  resource_type: string;
  to_create: boolean;
  to_read: boolean;
  to_update: boolean;
  to_delete: boolean;
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;
}
export interface AircraftPermission {
  id: string;
  permission_group_id: string;
  aircraft_id: string;
  aircraft_registration: string;
}

export interface PermissionGroup {
  id: string;
  global: boolean;
  permissions: ResourcePermissions[];
  aircraft: string[];
  aircraft_permission_groups: AircraftPermission[];
}
export interface PeoplePermissions extends Person {
  permission_groups: PermissionGroup[];
}

export interface UserSettingsState {
  details?: User;
  id: string;
  avatar: string;
  dateFormat: string;
  selectedView: string;
  tableAnimationClass: string;
  listAnimationClass: string;
  lastFetched: number;
  ui: AntUISettings;
  displayName: string;
  currentOrg?: User;
  logbook_columns: string[];
}

export interface UserSettings extends User {
  avatar: string;
  selectedView: string;
}

export interface AntUISettings {
  colorWeak: boolean;
  /**
   * Theme for the nav menu
   * @type {string}
   * @memberof IAntUI
   */
  navTheme: string;
  /**
   * Primary color of ant design
   * @type {string}
   * @memberof IAntUI
   */
  primaryColor: string;
  /**
   * Nav menu position: sidemenu or topmenu
   * @type {string}
   * @memberof IAntUI
   */
  layout: string;
  /**
   * Layout of content: Fluid or Fixed, only works when layout is topmenu
   * @type {('Fluid' | 'Fixed')}
   * @memberof IAntUI
   */
  contentWidth: 'Fluid' | 'Fixed';
  /**
   * Sticky header
   * @type {boolean}
   * @memberof IAntUI
   */
  fixedHeader: boolean;
  /**
   * Auto hide header
   * @type {boolean}
   * @memberof IAntUI
   */
  autoHideHeader: boolean;
  /**
   * Sticky siderbar
   * @type {boolean}
   * @memberof IAntUI
   */
  fixSiderbar: boolean;
}
