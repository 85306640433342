/* eslint-disable react/jsx-props-no-spreading */
import { Layout } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { pathToRegexp } from 'path-to-regexp';
import React from 'react';
import { ContainerQuery } from 'react-container-query';
import { Button, Drawer, Modal } from '@arcflight/tf-component-library';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import disableAutoCorrect from '../utils/disableAutoCorrect';
import logo from '../assets/logo.svg';
import logoText from '../assets/logoText.svg';
import SiderMenu from '../components/SiderMenu';
import { changeDrawerMode, changeDrawerVisibility, changeModalVisibility, revertChanges } from '../models/drawer';
import menuDataGenerator from '../utils/menuData';
import { ButtonSize } from '../components/PaginatedDefectsTable/DefectTableHeader';
import { fetch } from '../models/userSettings/actions';
import { changeLayoutCollapsed } from '../models/menu';
import Footer from './Footer';
import MyHeader from './Header';
import styles from './BasicLayout.module.less';

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
};

const mediaQueryList = window.matchMedia('(max-width: 767px)');

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
`;

const Text = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .cancelButton {
    height: 30px;
    border-radius: 15px;
    margin-left: 20px;
  }
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 20px;
`;

class BasicLayout extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    collapsed: PropTypes.bool.isRequired,
    bannerVisible: PropTypes.bool.isRequired,
    drawer: PropTypes.bool.isRequired,
    menu: PropTypes.object,
    fixSiderbar: PropTypes.bool.isRequired,
    fixedHeader: PropTypes.bool.isRequired,
    layout: PropTypes.string.isRequired,
    navTheme: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    menu: undefined,
  };

  state = {
    isMobile: false,
    menuData: menuDataGenerator(
      this.props.intl.formatMessage,
      this.props.userSettings?.details?.dashboard_permissions,
      this.props.userSettings?.details?.people,
      this.props.userSettings?.details?.operators?.reduce((flags, op) => {
        if (op.feature_flags) {
          op.feature_flags.forEach((flag) => {
            if (!flags.includes(flag.feature_name)) {
              flags.push(flag.feature_name);
            }
          });
        }
        return flags;
      }, []),
    ),
  };

  constructor(props) {
    super(props);
    this.getPageTitle = memoizeOne(this.getPageTitle);
    this.matchParamsPath = memoizeOne(this.matchParamsPath, isEqual);
  }

  componentDidMount() {
    const { dispatch, userSettings } = this.props;
    const { isMobile } = this.state;
    if (!userSettings || !userSettings.details) {
      dispatch(fetch());
    }
    // Dispatching resize event to prevent DASH-1096 bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    window.addEventListener(
      'orientationchange',
      () => {
        if (isMobile) this.handleMenuCollapse(true);
      },
      false,
    );
    disableAutoCorrect();
  }

  componentDidUpdate(prevProps, prevState) {
    // After changing to phone mode,
    // if collapsed is true, you need to click twice to display
    const { isMobile } = this.state;
    const { collapsed, menu, userSettings } = this.props;
    if (isMobile && !prevState.isMobile && !collapsed) {
      this.handleMenuCollapse(false);
    }
    if (
      menu !== prevProps.menu ||
      !_.isEqual(prevProps.userSettings && prevProps.userSettings.details, userSettings.details)
    ) {
      this.setMenuData();
    }
    disableAutoCorrect();
  }

  setMenuData() {
    const {
      userSettings,
      intl: { formatMessage },
    } = this.props;
    this.setState({
      menuData: menuDataGenerator(
        formatMessage,
        userSettings?.details?.dashboard_permissions,
        userSettings?.details?.people,
      ),
    });
  }

  updateMediaScreenSize = () => {
    this.setState({ isMobile: mediaQueryList.matches });
  };

  getPageTitle = (pathname) => {
    const {
      intl: { messages },
      match: {
        params: { id },
      },
    } = this.props;
    const intlPath = pathname
      .split('/')
      .filter((path) => path !== id && path.length > 0)
      .map((path) => path.charAt(0).toUpperCase() + path.slice(1))
      .join('.');
    const message = messages[`menu.${intlPath}`];
    return message ? `${message} - TrustFlight` : 'TrustFlight';
  };

  getLayoutStyle = () => {
    const { isMobile } = this.state;
    const { fixSiderbar, collapsed, layout } = this.props;
    if (fixSiderbar && layout !== 'topmenu' && !isMobile) {
      return {
        paddingLeft: collapsed ? '80px' : '200px',
      };
    }
    return null;
  };

  getContentStyle = () => {
    const { fixedHeader } = this.props;
    return {
      margin: '24px 24px 0',
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  matchParamsPath = (pathname) => {
    const pathKey = Object.keys(this.breadcrumbNameMap).find((key) => pathToRegexp(key).test(pathname));
    return this.breadcrumbNameMap[pathKey];
  };

  handleMenuCollapse = (collapsed, fromButton) => {
    if (fromButton !== 'responsive' || window.innerWidth < 1100) {
      const { dispatch } = this.props;
      dispatch(
        changeLayoutCollapsed({
          payload: collapsed,
        }),
      );
    }
  };

  render() {
    const {
      navTheme,
      children,
      location: { pathname },
      drawer,
      dispatch,
    } = this.props;
    const { menuData } = this.state;
    const isMobile = mediaQueryList.matches;

    document.addEventListener('wheel', () => {
      if (document.activeElement instanceof HTMLInputElement && document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    });

    return (
      <>
        <DocumentTitle title={this.getPageTitle(pathname)}>
          <ContainerQuery query={query}>
            {(params) => (
              <div id="layoutWrapper" className={classNames(params)}>
                <Drawer
                  visible={drawer?.visible}
                  data={drawer?.drawerContent ? drawer?.drawerContent : undefined}
                  handleClose={() => {
                    if (drawer?.drawerChanges && (drawer?.mode === 'edit' || drawer?.mode === 'add')) {
                      dispatch(changeModalVisibility({ payload: true }));
                    } else {
                      dispatch(changeDrawerVisibility({ payload: false }));
                      dispatch(changeDrawerMode({ payload: 'view' }));
                    }
                  }}
                >
                  {drawer?.drawerContent}
                </Drawer>
                <Modal
                  width={420}
                  isOpen={drawer?.changeModalVisible && drawer?.drawerChanges}
                  handleClose={() => dispatch(changeModalVisibility(false))}
                >
                  <ModalWrapper>
                    <Title>Are you sure you want to cancel?</Title>
                    <Text>
                      Are you sure you want to leave this page without saving your changes? If you leave before saving,
                      your changes will be lost.
                    </Text>
                    <ButtonWrapper>
                      <SubmitButtonWrapper>
                        <Button
                          padding="0 28px"
                          size={ButtonSize.MEDIUM}
                          onClick={() => {
                            dispatch(changeModalVisibility(false));
                            dispatch(revertChanges({ payload: true }));
                            dispatch(changeDrawerVisibility({ payload: false }));
                            dispatch(changeDrawerMode({ payload: 'view' }));
                          }}
                        >
                          {isMobile ? 'Continue' : 'Discard changes'}
                        </Button>
                      </SubmitButtonWrapper>
                      <Button
                        padding="0 28px"
                        size={ButtonSize.MEDIUM}
                        primary={false}
                        onClick={() => dispatch(changeModalVisibility(false))}
                      >
                        Cancel
                      </Button>
                    </ButtonWrapper>
                  </ModalWrapper>
                </Modal>
                <Layout>
                  {isMobile ? null : (
                    <SiderMenu
                      logo={logo}
                      logoText={logoText}
                      theme={navTheme}
                      onCollapse={this.handleMenuCollapse}
                      menuData={menuData}
                      isMobile={isMobile}
                      {...this.props}
                    />
                  )}
                  <Layout
                    style={{
                      ...this.getLayoutStyle(),
                    }}
                    className={styles.contentLayout}
                  >
                    {isMobile ? (
                      <MyHeader
                        menuData={menuData}
                        handleMenuCollapse={this.handleMenuCollapse}
                        logo={logo}
                        isMobile={isMobile}
                        {...this.props}
                      />
                    ) : null}

                    {children}
                    <Footer />
                  </Layout>
                </Layout>
              </div>
            )}
          </ContainerQuery>
        </DocumentTitle>
      </>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(({ menu, userSettings, drawer }) => ({
      collapsed: menu.collapsed,
      bannerVisible: menu.bannerVisible,
      userSettings,
      drawer,
      ...userSettings.ui,
    }))(BasicLayout),
  ),
);
