/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AircraftResource } from '../../../models/aircraft';
import AuthDropdownMenu from '../../AuthDropdownMenu/AuthDropdownMenu';
import StatusBadge from '../../Status/StatusBadge';
import TFToggle from '../../TFToggle/TFToggle';
import { DashboardState } from '../../../models';
import TFModal from '../../TFModal/TFModal';
import styles from '../workpackDrawer.module.less';
import UnsavedConfirmationButton from './UnsavedConfirmationButton';

const EditTitleInfo = ({
  editStagedPayload,
  description = '',
  po = '',
  date,
  status,
  loading,
  numberOfDraftItems,
  hasAnyDraftItems,
}): JSX.Element => {
  const [workpackDate, setWorkpackDate] = useState(null);
  const [localStatus, setLocalStatus] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { formatMessage } = useIntl();

  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const handleDateChange = (dateMoment, dateString: string): void => {
    setWorkpackDate(dateMoment);
    editStagedPayload({ date: dateString });
  };

  const handleToggle = (value: boolean): void => {
    if (value && hasAnyDraftItems) {
      setLocalStatus(true);
      setModalVisible(true);
    } else {
      editStagedPayload({ status: value ? 'active' : 'draft' });
      setLocalStatus(value);
    }
  };

  const handleCancelClick = () => {
    setLocalStatus(false);
    setModalVisible(false);
  };

  const handleContinueClick = () => {
    setModalVisible(false);

    editStagedPayload({ status: 'active' });
  };

  useEffect(() => {
    if (status === 'active') setLocalStatus(true);
  }, [status]);

  useEffect(() => {
    if (date) setWorkpackDate(date);
  }, [date]);

  return (
    <>
      <div className={styles.rowGridSix}>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>{formatMessage({ id: 'title.poNumber' })}</span>
          <input
            type="text"
            defaultValue={po}
            onChange={(e): void => editStagedPayload({ po_number: e.target.value })}
            className={styles.formInput}
            disabled={loading}
          />
        </div>
        <div className={styles.rowItemDescription}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.description' })}</span>
          <input
            type="text"
            defaultValue={description}
            className={styles.formInput}
            onChange={(e): void => editStagedPayload({ description: e.target.value })}
            disabled={loading}
          />
        </div>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.dateCreated' })}</span>
          <DatePicker
            suffixIcon={<span />}
            value={workpackDate ? moment(workpackDate) : moment()}
            format={dateFormat}
            onChange={handleDateChange}
            disabled={loading}
          />
        </div>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.statusToggle' })}</span>
          <div className={styles.toggleWrapper}>
            {status === 'completed' ? (
              <span className={styles.statusInfo}>
                <StatusBadge status={status} /> {status}
              </span>
            ) : (
              <TFToggle active={localStatus} handleToggle={(value: boolean): void => handleToggle(value)} />
            )}
          </div>
        </div>
      </div>

      {modalVisible && (
        <TFModal
          handleCancelClick={handleCancelClick}
          handleContinueClick={handleContinueClick}
          title="Accept all draft items?"
          // eslint-disable-next-line max-len
          text={`This workpack contains ${numberOfDraftItems} draft item${
            numberOfDraftItems === 1 ? '' : 's'
          }. Continuing will cause all of these items to be automatically accepted. Do you wish to proceed?`}
          cancelButtonText="No, cancel"
          confirmButtonText="Yes, proceed"
        />
      )}
    </>
  );
};

const AddTitleInfo = ({ editStagedPayload, loading }): JSX.Element => {
  const [status, setStatus] = useState(false);
  const [date, setDate] = useState(null);
  const [po, setPo] = useState('');
  const [description, setDescription] = useState('');

  const { formatMessage } = useIntl();

  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const handleInputChange = (target, value): void => {
    editStagedPayload({ [`${target}`]: value });
  };

  const handleDateChange = (dateMoment, dateString: string): void => {
    setDate(dateMoment);
    handleInputChange('date', dateString);
  };

  useEffect(() => {
    if (date === null) {
      setDate(moment().format('YYYY-MM-DD'));
      const setInitialDate = (): void => {
        editStagedPayload({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
      };
      setInitialDate();
    }
  }, [date, editStagedPayload]);

  return (
    <div className={styles.rowGridSix}>
      <div className={styles.rowItem}>
        <span className={styles.formLabel}>{formatMessage({ id: 'title.poNumber' })}</span>
        <input
          type="text"
          value={po}
          onChange={(e): void => {
            setPo(e.target.value);
            handleInputChange('po_number', e.target.value);
          }}
          className={styles.formInput}
        />
      </div>
      <div className={styles.rowItemDescription}>
        <span className={styles.formLabel}>{formatMessage({ id: 'text.description' })}</span>
        <input
          type="text"
          value={description}
          className={styles.formInput}
          onChange={(e): void => {
            setDescription(e.target.value);
            handleInputChange('description', e.target.value);
          }}
        />
      </div>
      <div className={styles.rowItem}>
        <span className={styles.formLabel}>{formatMessage({ id: 'text.date' })}</span>
        <DatePicker
          suffixIcon={<span />}
          value={date ? moment(date) : moment()}
          format={dateFormat}
          onChange={handleDateChange}
        />
      </div>
      <div className={styles.rowItem}>
        <span className={styles.formLabel}>{formatMessage({ id: 'text.statusToggle' })}</span>
        <div className={styles.toggleWrapper}>
          <TFToggle
            active={status}
            handleToggle={(value: boolean): void => {
              handleInputChange('status', value ? 'active' : 'draft');
              setStatus(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const WorkpackTitle = ({
  mode,
  description,
  changeMode,
  editStagedPayload,
  po,
  date,
  status,
  handleDeleteWorkpack,
  wpId,
  unsavedChanges,
  handleUnsavedItems,
  aircraftId,
  CRSUrl,
  loading,
  numberOfDraftItems,
  hasAnyDraftItems,
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const handleCloseClick = (): void => {
    handleUnsavedItems();
  };

  const editButtonChild = (
    <button type="button" className={styles.editButton} onClick={(): void => changeMode('edit')}>
      <span>{formatMessage({ id: 'form.button.edit' })}</span>
    </button>
  );

  let customButtonChild = null;

  if (status === 'active' || status === 'complete')
    customButtonChild = (
      <a href={CRSUrl} target="_blank" rel="noopener noreferrer" className={styles.addCRSLink}>
        <button className={styles.dropdownItem} onClick={(): void => null} type="button">
          {status === 'complete' ? 'View CRS' : 'Add CRS'}
        </button>
      </a>
    );

  let allowUpdate = true;
  if (status === 'complete') allowUpdate = false;

  return (
    <>
      <div className={styles.workpackTitle}>
        {mode === 'edit' ? (
          <h3>Editing: {po}</h3>
        ) : (
          <>
            <h3>
              Workpack:{' '}
              <span>
                {po} {description && `(${description})`}
              </span>
            </h3>
            <AuthDropdownMenu
              editText={editButtonChild}
              customText={customButtonChild}
              handleDelete={(): void => handleDeleteWorkpack(wpId)}
              resource={AircraftResource.WORKPACK}
              aircraftId={aircraftId}
              options={{ read: false, update: allowUpdate, delete: true }}
            />
          </>
        )}
        <UnsavedConfirmationButton handleClick={handleCloseClick} unsavedChanges={unsavedChanges} />
      </div>
      <div>
        {mode === 'edit' && (
          <EditTitleInfo
            editStagedPayload={editStagedPayload}
            description={description}
            po={po}
            date={date}
            status={status}
            loading={loading}
            numberOfDraftItems={numberOfDraftItems}
            hasAnyDraftItems={hasAnyDraftItems}
          />
        )}
      </div>
      <div>{mode === 'new' && <AddTitleInfo editStagedPayload={editStagedPayload} loading={loading} />}</div>
    </>
  );
};

export default WorkpackTitle;
