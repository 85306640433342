import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Loading } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { Header } from '../../components/FlightDrawer/FlightDrawer';
import { IntermittentFault } from '../../models/intermittentFaults';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import TFSearchableSelect from '../../components/TFSearchableSelect/TFSearchableSelect';
import { getFlightsForAircraft } from '../../models/flights/actions';
import createFlightOptions from '../../components/_utils/FlightOptionsBuilder';
import FlexWrapper from '../../components/DefectDrawer/components/FlexWrapper';

interface SectorSectionProps {
  fault: IntermittentFault;
  editable: boolean;
  updateFaultData: (key: string, value: any, secondKey?: string, secondValue?: any) => void;
  aircraftId: string;
  attachments?: any[];
  setAttachments?: (input: any) => void;
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, mode }): string => {
    if (isMobile) return '1fr';
    return '1fr 1fr';
  }};
  grid-template-rows: auto;
  gap: 1rem;
`;

const SectorSection: React.FC<SectorSectionProps> = ({ fault, updateFaultData, aircraftId }) => {
  const {
    flights: { aircraftFlightsMaps },
    drawer: { mode },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const [date, setDate] = useState('');
  const [flight, setFlight] = useState<{ title: string; value: string }>();
  const [flightOptions, setFlightOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const aircraft = aircraftFlightsMaps.get(aircraftId);
    if (!aircraft) {
      dispatch(getFlightsForAircraft({ payload: { id: aircraftId } }));
    } else {
      const parsedOptions = createFlightOptions(Array.from(aircraft.flightsMap.values()));
      setFlightOptions(parsedOptions);
    }
  }, [aircraftFlightsMaps, dispatch, aircraftId]);

  useEffect(() => {
    if (fault) {
      if (fault?.reported_at) setDate(fault?.reported_at);
      if (fault.flight_id) {
        const test = flightOptions.find((f) => f.value === fault.flight_id);
        setFlight(test);
      }
    } else {
      const now = moment()
        .utc()
        .format();
      setDate(now);
    }
  }, [fault, flightOptions]);

  const handleDateChange = (dateMoment): void => {
    setDate(dateMoment);
    updateFaultData('reported_at', dateMoment);
  };

  const handleFlightChange = (selectedFlight: { title: string; value: string }): void => {
    setFlight(selectedFlight);
    updateFaultData('flight_id', selectedFlight?.value);
  };

  const isMobile = window.innerWidth < 451;
  return (
    <>
      <ContentWrapper isMobile={isMobile} mode={mode}>
        <div>
          <Header editable={mode !== 'view'}>Sector</Header>
          {mode === 'edit' || mode === 'add' ? (
            <>
              <Loading loading={false} contain />
              <TFSearchableSelect initial={flight} options={flightOptions} handleSelectChange={handleFlightChange} />
            </>
          ) : (
            <FlexWrapper>{flight?.title || '-'}</FlexWrapper>
          )}
        </div>
        <div>
          {mode === 'view' ? (
            <>
              <Header>Date Recorded</Header>
              <span>
                {moment(date)
                  .utc()
                  .format(`${userSettings.dateFormat} HH:mm`) || '-'}
              </span>
            </>
          ) : (
            <DateTimePicker
              headerDate="Date Recorded"
              headerTime="Time Recorded"
              handleDateTimeChange={handleDateChange}
              dateTime={date}
            />
          )}
        </div>
      </ContentWrapper>
    </>
  );
};

export default SectorSection;
