import React from 'react';
import styled from 'styled-components';
import FlexWrapper from './FlexWrapper';

interface StyledRadioButtonProps {
  id: string;
  group: string;
  value: string;
  checked?: boolean;
  marginRight?: number;
  identifier?: string;
  onClick?: () => void;
  disabled?: boolean;
  width?: number;
}

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
`;

const StyledSpan = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled.label`
  width: ${({ width }): string => (width ? `${width}px` : '50px')};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }): string => (disabled ? '0.3' : '1')};
  ${StyledInput} + ${StyledSpan} {
    border: 0.5px solid rgba(36, 45, 65, 0.2);
    border-radius: 2px;
    color: rgba(36, 45, 65, 0.7);
    background-color: rgba(36, 45, 65, 0.02);
    margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
    width: ${({ width }): string => (width ? `${width}px` : '50px')};
  }
  ${StyledInput}:checked + ${StyledSpan} {
    border: 0.5px solid rgba(18, 111, 214, 0.3);
    border-radius: 2px;
    color: #3f98ff;
    background-color: rgba(18, 111, 214, 0.1);
    margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
    width: ${({ width }): string => (width ? `${width}px` : '50px')};
  }
`;

const StyledRadioButton: React.FC<StyledRadioButtonProps> = ({
  id,
  group,
  value,
  checked,
  marginRight,
  identifier,
  onClick,
  disabled,
  width,
}) => {
  return (
    <FlexWrapper marginRight={marginRight}>
      <StyledLabel htmlFor={id} disabled={disabled} width={width} data-testid={`StyledRadioButton--${identifier}`}>
        <StyledInput
          id={id}
          type="radio"
          name={group}
          value={value}
          onClick={onClick}
          onChange={onClick}
          checked={checked}
          disabled={disabled}
          width={width}
        />
        <StyledSpan>{value}</StyledSpan>
      </StyledLabel>
    </FlexWrapper>
  );
};

export default StyledRadioButton;
