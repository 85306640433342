import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';

interface ViewDeferralOptionsProps {
  defect: Defect | null;
}

const ViewDeferralOptions: React.FC<ViewDeferralOptionsProps> = ({ defect }) => {
  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const { formatMessage } = useIntl();
  let defect_type;
  let deferred;
  let ata;
  let ataSection;
  let reference;
  let interval;
  if (defect) {
    defect_type = defect?.defect_type;
    deferred = defect?.deferred;
    ata = defect?.display_data?.ata;
    interval = defect?.display_data?.category;
    ataSection = defect?.display_data?.ata_section;
    reference = defect?.reference;
  }

  let defectDate = 'N/A';
  if (deferred)
    defectDate =
      moment(defect?.date_due).format(dateFormat) !== 'Invalid date'
        ? `${moment(defect?.date_due).format(dateFormat)} @ 23:59 UTC`
        : '-';
  const isMobile = window.innerWidth < 451;
  return (
    <FlexWrapper marginBottom={30} marginTop={20} column>
      <Label marginBottom={20}>{formatMessage({ id: 'text.deferralOptions' })}</Label>
      <GridWrapper columns={isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr'} rows="auto" rowGap={20}>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'text.defectTypeCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{deferred ? 'Deferred' : 'Not Deferred'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'text.deferralOptionsCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{defect_type || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.categoryCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{interval || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.ataChapterCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{ata || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.ataSectionCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{ataSection || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.referenceCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{reference || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.deferredUntil' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{defectDate}</Label>
        </FlexWrapper>
      </GridWrapper>
      {interval === 'A' && (
        <GridWrapper columns="1fr 1fr 1fr 1fr" rows="1fr 1fr" marginTop={20} rowGap={20}>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightHourLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flight_seconds_limit / 3600 || '-'}</Label>
          </FlexWrapper>
          {defect?.aircraft?.apu_installed ? (
            <FlexWrapper column>
              <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
                {formatMessage({ id: 'form.labels.apuHoursLimit' })}
              </Label>
              <Label color="rgba(36, 45, 65, 0.7)">{defect?.apu_seconds_limit / 3600 || '-'}</Label>
            </FlexWrapper>
          ) : null}
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flight_days_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.calendarHoursLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.seconds_limit / 3600 || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.calendarDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.calendar_days_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.cyclesLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.cycles_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flights_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.otherLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.other_limit || '-'}</Label>
          </FlexWrapper>
        </GridWrapper>
      )}
    </FlexWrapper>
  );
};

export default ViewDeferralOptions;
