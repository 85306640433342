/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { message } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import { DashboardState } from '../../models';
import { Trip } from '../../models/trips';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  updateDrawerContent,
  revertChanges as reduxRevertChanges,
} from '../../models/drawer';
import completeIcon from '../../assets/icon-status-complete.svg';
import { updateTripFromDrawer } from '../../models/trips/actions';
import { addTrip, deleteAttachment } from '../../services/api';
import incompleteIcon from '../../assets/icon-status-incomplete.svg';
import FlightDrawer from '../FlightDrawer/FlightDrawer';
import backArrow from '../../assets/icon-back.svg';
import draftIcon from '../../assets/icon-status-unset.svg';
import TripAttachments from './TripAttachments';
import TripCrewDutyLogs from './TripCrewDutyLogs';
import TripDetailsSection from './TripDetailsSection';
import TripSectors from './TripSectors';
import TripTrendingSection from './TripTrendingSection';
import SRPSection from './SRPSection';
import ButtonSection from './ButtonSection';
import DrawerBanner from './DrawerBanner';

interface TripDrawerProps {
  trip: Trip;
  handleDelete: (id: string) => void;
  scroll?: string;
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: ${({ bannerVisible }): string => (bannerVisible ? '16px' : '24px')};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
  }
`;

const HeavyTitle = styled.span`
  font-size: 18px;
  color: #242d41;
  font-weight: 500;
  margin: 0 4px;
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 20px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const TripDrawer: React.FC<TripDrawerProps> = ({ trip, handleDelete, scroll }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode, drawerHistory, drawerId, revertChanges },
    trips: { tripsMap },
  } = useSelector((state: DashboardState) => state);

  const [tripData, setTripData] = useState(null);
  const [originaltrip, setOriginalTrip] = useState(null);
  const [resetAttachments, setResetAttachments] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tripStatus, setTripStatus] = useState(null);
  const [bannerVisible, setBannerVisible] = useState(false);

  const validateTripData = (): boolean => {
    if (tripData && !tripData.date) {
      message.error('Date must be entered');
      return false;
    }
    if (tripData && !tripData.callsign) {
      message.error('Callsign must be entered');
      return false;
    }
    if (tripData && !tripData.captain_id) {
      message.error('Pilot flying must be selected');
      return false;
    }
    return true;
  };

  const submitTrip = async (): Promise<void> => {
    if (mode === 'add' && !tripData?.id) {
      if (validateTripData()) {
        setLoading(true);
        if (tripData?.attachments)
          tripData.attachments.forEach((item) => {
            if (Object.keys(item)?.includes('_destroy')) {
              deleteAttachment(item.id);
            }
          });
        const newAttachments = tripData?.attachments?.filter(
          (item) => !Object.keys(item)?.includes('_destroy') && !originaltrip?.attachments?.includes(item),
        );
        const addTripData = {
          ...tripData,
          duty_logs_attributes: tripData?.duty_logs,
          attachments_attributes: newAttachments,
        };
        const newTripData = await addTrip({ id, trip: addTripData });
        setLoading(false);
        setTripData(newTripData);
        setOriginalTrip(newTripData);
        dispatch(updateTripFromDrawer({ trip: newTripData, id: newTripData.id }));
        dispatch(changeDrawerContent({ payload: { content: <FlightDrawer trip={newTripData} />, backButton: true } }));
      }
    } else if (validateTripData()) {
      tripData.attachments.forEach((item) => {
        if (Object.keys(item).includes('_destroy')) {
          deleteAttachment(item.id);
        }
      });
      const newAttachments = tripData.attachments.filter(
        (item) => !Object.keys(item).includes('_destroy') && !originaltrip?.attachments?.includes(item),
      );
      const newTripData = {
        ...tripData,
        duty_logs_attributes: tripData?.duty_logs,
        attachments_attributes: newAttachments,
      };
      setLoading(true);
      const promise = dispatch(updateTripFromDrawer({ trip: newTripData, id: tripData?.id }));
      Promise.all([promise]).then((res) => {
        const newTrip = res[0];
        setTripData(newTrip);
        setOriginalTrip(newTrip);
        setLoading(false);
      });
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleCancelClick = (): void => {
    if (mode === 'add') {
      dispatch(changeDrawerVisibility({ payload: false }));
    } else {
      setTripData(originaltrip);
      dispatch(changeDrawerMode({ payload: 'view' }));
      setResetAttachments(true);
    }
  };

  const handleSaveClick = (): void => {
    submitTrip();
  };

  const handleBackClick = (): void => {
    dispatch(handleBackButtonClick());
  };

  const updateTripData = (key: string, value: any, secondKey?: string, secondValue?: any): void => {
    if (key === 'date' && value !== originaltrip?.date && mode !== 'add') {
      setBannerVisible(true);
    } else {
      setBannerVisible(false);
    }
    let newTripData = {
      ...tripData,
      [key]: value,
    };
    if (secondKey) {
      newTripData = {
        ...newTripData,
        [secondKey]: secondValue,
      };
    }
    if (key === 'first_officer_id' && (value === undefined || value === null)) {
      newTripData = {
        ...newTripData,
        first_officer: {},
        first_officer_id: null,
      };
    }
    setTripData(newTripData);
  };

  useEffect(() => {
    const updatedTrip = tripsMap.get(drawerId);
    if (updatedTrip) {
      setTripData(updatedTrip);
      setSectors([...updatedTrip.flights]);
      dispatch(updateDrawerContent({ payload: false }));
    }
  }, [tripsMap]);

  useEffect(() => {
    if (drawerId && !trip) {
      const newTrip = tripsMap.get(drawerId);
      setTripData(newTrip);
      setOriginalTrip(newTrip);
      if (newTrip?.flights) setSectors(newTrip?.flights);
    }
  }, [drawerId, tripsMap]);

  useEffect(() => {
    if (!tripData) {
      setTripData({ date: moment().format('YYYY-MM-DD'), trip_category: 'private' });
      setOriginalTrip({ date: moment().format('YYYY-MM-DD'), trip_category: 'private' });
    }
  }, []);

  useEffect(() => {
    if (trip && trip?.id) {
      const foundTrip = tripsMap.get(trip?.id);
      const newTripData = foundTrip;
      if (foundTrip?.captain?.id) {
        newTripData.captain_id = foundTrip?.captain?.id;
      }
      if (foundTrip?.first_officer?.id) {
        newTripData.first_officer_id = foundTrip?.first_officer?.id;
      }
      setTripData(newTripData);
      setSectors(foundTrip?.flights);
      setOriginalTrip(foundTrip);
      if (foundTrip?.status) setTripStatus(foundTrip.status);
    }
  }, [trip]);

  useEffect(() => {
    if (!_.isEqual(tripData, originaltrip) && mode !== 'view') {
      dispatch(setDrawerChanges({ payload: true }));
    }
  }, [tripData, originaltrip]);

  useEffect(() => {
    if (revertChanges) {
      setTripData(originaltrip);
      setBannerVisible(false);
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
  }, [revertChanges]);

  useEffect(() => {
    if (scroll && mode === 'view') {
      const element = document.getElementById('attachments');
      if (element) element.scrollIntoView();
    }
  }, [scroll, tripData]);

  let statusIcon = completeIcon;
  if (tripStatus === 'partial') statusIcon = incompleteIcon;
  if (tripStatus === 'planned') statusIcon = draftIcon;
  if (tripStatus === 'in_progress') statusIcon = incompleteIcon;
  if (mode === 'add') statusIcon = draftIcon;

  let statusText = tripData?.status;
  if (tripData?.status === 'in_progress') statusText = 'in progress';

  let displayTrendSection = false;
  if (
    tripData?.custom_data?.trend_monitoring?.justifications ||
    tripData?.custom_data?.trend_monitoring?.press_alt ||
    tripData?.custom_data?.trend_monitoring?.ioat ||
    tripData?.custom_data?.trend_monitoring?.ias
  )
    displayTrendSection = true;
  if (tripData?.custom_data?.trend_monitoring?.engine_1) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (tripData?.custom_data?.trend_monitoring?.engine_2) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (tripData?.custom_data?.trend_monitoring?.engine_3) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  if (tripData?.custom_data?.trend_monitoring?.engine_4) {
    const vals = Object.values(tripData?.custom_data?.trend_monitoring?.engine_1);
    if (vals?.length) displayTrendSection = true;
  }
  return (
    <DrawerWrapper data-testid="TripDrawer-DrawerWrapper">
      {!loading && (tripData || mode === 'add') ? (
        <>
          <Title data-testid="TripDrawer-Title" bannerVisible={bannerVisible}>
            {drawerHistory.length ? (
              <StyledButton onClick={handleBackClick}>
                <img src={backArrow} alt="back arrow" />
              </StyledButton>
            ) : null}
            {formatMessage({ id: 'text.trip' })}:{' '}
            <HeavyTitle>{`${tripData?.number ? tripData?.number : ''}`}</HeavyTitle> -{' '}
            <img src={statusIcon} alt="status icon" /> {statusText}
            {mode === 'view' ? (
              <div>
                <AuthDropdownMenu
                  options={{
                    read: false,
                    update: true,
                    delete: !!tripData?.id,
                  }}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
                  resource={AircraftResource.TRIP}
                  aircraftId={tripData?.aircraft_id}
                  editCallback={(): void => handleEditClick()}
                  handleDelete={(): void => handleDelete(trip?.id)}
                />
              </div>
            ) : null}
          </Title>
          {bannerVisible ? <DrawerBanner message={formatMessage({ id: 'text.tripDateChanged' })} /> : null}
          {mode !== 'add' ? (
            <div data-testid="TripDrawer-SRPSection">
              <SRPSection trip={tripData} editable={mode !== 'view'} updateTripData={updateTripData} />
            </div>
          ) : null}
          <InfoDivWrapper data-testid="TripDrawer-TripDetailsSection">
            <TripDetailsSection
              trip={tripData}
              editable={mode !== 'view'}
              updateTripData={updateTripData}
              aircraftId={id}
            />
          </InfoDivWrapper>
          {(tripData?.duty_logs && tripData?.duty_logs.length) || mode !== 'view' ? (
            <TripCrewDutyLogs trip={tripData} editable={mode !== 'view'} updateTripData={updateTripData} />
          ) : null}
          {tripData?.id ? <TripSectors trip={tripData} sectors={sectors} /> : null}
          {displayTrendSection || mode !== 'view' ? (
            <TripTrendingSection trip={tripData} editable={mode !== 'view'} updateTripData={updateTripData} />
          ) : null}
          <InfoDivWrapper id="attachments" data-testid="TripDrawer-TripAttachments">
            <TripAttachments
              trip={tripData}
              editable={mode !== 'view'}
              updateTripData={updateTripData}
              resetAttachments={resetAttachments}
              setResetAttachments={setResetAttachments}
            />
          </InfoDivWrapper>
          {mode !== 'view' ? (
            <ButtonSection handleCancelClick={handleCancelClick} handleSaveClick={handleSaveClick} loading={loading} />
          ) : null}
        </>
      ) : (
        <Loading loading contain />
      )}
    </DrawerWrapper>
  );
};

export default TripDrawer;
