import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ThreeDotMenu } from '@arcflight/tf-component-library';
import Portal from '../TFPortal/TFPortal';
import { AircraftPermission } from '../../models/aircraft';
import { UserPermission } from '../../models/userSettings';
import AuthMenuItem from './AuthMenuItem';
import styles from './AuthDropdownMenu.module.less';

const AuthDropdownMenu = ({
  setDrawerVisible,
  setEditingRecord,
  setEditable,
  handleDelete,
  viewText,
  viewCallback,
  editText,
  editCallback,
  customText,
  customCallback,
  duplicateCallback,
  deleteButtonChild,
  aircraftId,
  hasDrawer = false,
  resource,
  options,
  forTable,
  noOffset = false,
}: AuthDropdownMenuProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [menuStyle, setMenuStyle] = useState({});
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const menuWrapperRef = useRef<HTMLDivElement>();

  const handleMenuClick = (): void => {
    setShowMenu(true);
    const rect = menuRef.current.getBoundingClientRect();
    let offset = 62;
    if (menuWrapperRef.current) {
      const wrapper = menuWrapperRef.current.getBoundingClientRect();
      offset = wrapper.width * -1 + 20;
      if (noOffset) offset = 0;
      const { top, left } = rect;
      setMenuStyle({
        position: 'absolute',
        top: top + 24 + window.scrollY,
        left: left + offset + window.scrollX,
      });
    }
  };

  useEffect(() => {
    if (showMenu) {
      const rect = menuRef.current.getBoundingClientRect();
      let offset = 62;
      if (menuWrapperRef.current) {
        const wrapper = menuWrapperRef.current.getBoundingClientRect();
        offset = wrapper.width * -1 + 20;
        const { top, left } = rect;
        if (noOffset) offset = 0;
        setMenuStyle({
          position: 'absolute',
          top: top + 24 + window.scrollY,
          left: left + offset + window.scrollX,
        });
      }
    }
  }, [noOffset, showMenu]);

  return (
    <div
      onClick={(): void => handleMenuClick()}
      onKeyDown={(): void => handleMenuClick()}
      onFocus={(): void => handleMenuClick()}
      className={forTable ? styles.menuButtonTable : styles.menuButton}
      role="button"
      tabIndex={0}
    >
      <div ref={menuRef} className={styles.iconWrapper}>
        <ThreeDotMenu menuItems={[]} />
      </div>
      {showMenu && (
        <Portal>
          <button
            type="button"
            onClick={(e): void => {
              e.stopPropagation();
              setShowMenu(false);
            }}
            className={styles.invisibleBackground}
          >
            <div ref={menuWrapperRef} className={styles.menuWrapper} style={menuStyle}>
              {options.read && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                      setEditingRecord();
                    }
                    if (viewCallback) {
                      viewCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={viewText || <span>{formatMessage({ id: 'text.view' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.READ : UserPermission.READ}
                />
              )}
              {options.update && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                      setEditingRecord();
                      setEditable(true);
                    }
                    if (editCallback) {
                      editCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={editText || <span>{formatMessage({ id: 'text.edit' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.duplicate && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                      setEditingRecord();
                      setEditable(true);
                    }
                    if (duplicateCallback) {
                      duplicateCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={<span>{formatMessage({ id: 'text.duplicate' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.custom && customText && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                      setEditingRecord();
                      setEditable(true);
                    }
                    if (customCallback) {
                      customCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={<span>{customText}</span> || <span>{formatMessage({ id: 'text.edit' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.delete && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    handleDelete();
                    setShowMenu(false);
                  }}
                  action={deleteButtonChild || <span>{formatMessage({ id: 'text.delete' })}</span>}
                  optionalClass={styles.delete}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.DELETE : UserPermission.DELETE}
                />
              )}
            </div>
          </button>
        </Portal>
      )}
    </div>
  );
};

interface MenuOptions {
  read: boolean;
  update: boolean;
  delete: boolean;
  duplicate?: boolean;
  custom?: boolean;
}

type AuthDropdownMenuProps = {
  record?: Record<string, unknown>;
  setDrawerVisible?: (arg0: boolean) => void;
  setEditingRecord?: () => void;
  setEditable?: (arg0: boolean) => void;
  handleDelete?: () => void;
  viewText?: JSX.Element | string;
  viewCallback?: () => void;
  editText?: JSX.Element | string;
  editCallback?: () => void;
  deleteButtonChild?: JSX.Element | string;
  customText?: JSX.Element | string;
  customCallback?: () => void;
  duplicateCallback?: () => void;
  aircraftId?: string;
  resource?: string;
  menuStyle?: object;
  hasDrawer?: boolean;
  options: MenuOptions;
  forTable?: boolean;
  noOffset?: boolean;
};

export default AuthDropdownMenu;
