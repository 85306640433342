import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import moment from 'moment';
import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import servers from '../../utils/servers';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import { Trip } from '../../models/trips';
import { deleteFlightEntry, getFlightDetails } from '../../models/flights/actions';
import { getAircraftTripEntries } from '../../models/trips/actions';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  setDrawerId,
  updateDrawerContent,
  revertChanges as reduxRevertChanges,
} from '../../models/drawer';
import { addFlight, updateFlight } from '../../services/api';
import { DashboardState } from '../../models';
import completeIcon from '../../assets/icon-status-complete.svg';
import incompleteIcon from '../../assets/icon-status-incomplete.svg';
import draftIcon from '../../assets/icon-status-unset.svg';
import backArrow from '../../assets/icon-back.svg';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import FlightDetailsSection from './FlightDetailsSection';
import FuelSection from './FuelSection';
import DefectsSection from './DefectsSection';
import HistorySection from './HistorySection';
import FlightAttachements from './FlightAttachments';
import PreFlightCustomDataSection from './PreFlightCustomDataSection';
import PostFlightCustomDataSection from './PostFlightCustomDataSection';
import FuelUplifts from './FuelUplifts';
import UpliftsSection from './UpliftsSection';

interface FlightDrawerProps {
  flight?: string;
  trip?: Trip;
  scroll?: string;
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  height: 32px;
  align-items: center;
  img {
    margin: 0 4px 0 8px;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    height: auto;
    max-width: 96%;
  }
`;

const HeavyTitle = styled.span`
  font-size: 18px;
  color: #242d41;
  font-weight: 500;
  margin: 0 4px;
`;

const StatusDivWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  span {
    font-size: 12px;
    color: #126fd6;
    letter-spacing: -0.08px;
    font-weight: 500;
  }
  img {
    margin-left: 4px;
    height: 16px;
    width: 16px;
  }
`;

export const SectionHeader = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: ${({ reducedBottomMargin }): string => (reducedBottomMargin ? '10px' : '20px')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}` : '0')};
`;

export const Header = styled.div`
  color: ${({ editable }): string => (editable ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  letter-spacing: ${({ editable }): string => (editable ? 'normal' : '0.4px')};
  font-size: ${({ editable }): string => (editable ? '14px' : '12px')};
  text-transform: ${({ editable }): string => (editable ? 'capitalize' : 'uppercase')};
  font-weight: ${({ editable }): string => (editable ? '500' : 'normal')};
  margin-bottom: 4px;
`;

export const DisplayText = styled.div`
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.4px;
  font-size: 14px;
  text-transform: ${({ cap }): string => (cap ? 'capitalize' : 'none')};
`;

export const StyledInput = styled.input`
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  height: 34px;
  border-radius: 2px;
  border: 1px solid rgba(36, 45, 65, 0.2);
  padding: 0 8px;
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 20px;
`;

const NoShadowWrapper = styled.div`
  margin-bottom: 32px;
`;

const LoadingWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 24px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const ModalContentWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: #242d41;
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
  max-width: 350px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubminButtonWraper = styled.div`
  margin-right: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CancelWrapper = styled.div`
  margin-left: 16px;
`;

const FlightDrawer: React.FC<FlightDrawerProps> = ({ flight, trip, scroll }) => {
  const [flightData, setFlightData] = useState<any>({});
  const [originalFlightData, setOriginalFlightData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [flightDate, setFlightDate] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [flightId, setFlightId] = useState(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const {
    flights: { flightsMap },
    userSettings: { dateFormat },
    drawer: { drawerHistory, mode, revertChanges },
  } = useSelector((state: DashboardState) => state);

  let statusIcon = completeIcon;
  if (flightData?.status === 'partial') statusIcon = incompleteIcon;
  if (flightData?.status === 'planned') statusIcon = draftIcon;
  if (flightData?.status === 'in_progress') statusIcon = incompleteIcon;

  let statusText = flightData?.status;
  if (flightData?.status === 'in_progress') statusText = 'in progress';

  const handleBackClick = (): void => {
    dispatch(updateDrawerContent({ payload: true }));
    dispatch(handleBackButtonClick());
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleCancelClick = (): void => {
    if (drawerHistory.length && mode === 'add') {
      handleBackClick();
    } else {
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const deleteSector = (): void => {
    dispatch(
      deleteFlightEntry({ payload: { id: flightData.id, aircraftId: id } }, () =>
        dispatch(getAircraftTripEntries({ payload: { id } })),
      ),
    );
    if (drawerHistory.length) {
      handleBackClick();
    } else {
      dispatch(changeDrawerVisibility({ payload: false }));
    }
  };

  const validateFlightData = (): boolean => {
    if (flightData && !flightData.pilot_flying?.id) {
      message.error('Pilot flying must be completed');
      return false;
    }
    if (flightData && !flightData.departure_airport_id) {
      message.error('Departure airport must be completed');
      return false;
    }
    if (flightData && !flightData.arrival_airport_id) {
      message.error('Arrival airport must be completed');
      return false;
    }
    if (flightData && !flightData.time_offblocks) {
      message.error('Time off blocks must be completed');
      return false;
    }
    if (flightData && !flightData.time_onblocks) {
      message.error('Time on blocks must be completed');
      return false;
    }
    if (flightData && !flightData.time_landing) {
      message.error('Time landing must be completed');
      return false;
    }
    if (flightData && !flightData.time_takeoff) {
      message.error('Time takeoff must be completed');
      return false;
    }
    if (flightData && !flightData.landings_count) {
      message.error('Landings must be completed');
      return false;
    }
    if (flightData && (flightData?.sector_attachments?.length === 0 || !flightData?.sector_attachments)) {
      message.error('Sector must have an attachment');
      return false;
    }
    if (
      flightData &&
      flightData?.sector_attachments?.length &&
      // eslint-disable-next-line no-underscore-dangle
      flightData?.sector_attachments.filter((item) => !item._destroy).length === 0
    ) {
      message.error('Sector must have an attachment');
      return false;
    }
    if (flightData && flightData?.fuel_uplifts_attributes && flightData?.fuel_uplifts_attributes.length) {
      let allChecked = true;
      // eslint-disable-next-line no-unused-expressions
      flightData?.fuel_uplifts_attributes.forEach((uplift) => {
        if (!uplift?.truck_actual_uplift && !uplift?._destroy) {
          message.error('Bowser uplift must be completed');
          allChecked = false;
        }
        return allChecked;
      });
      return allChecked;
    }
    return true;
  };

  const handleSaveClick = async (): Promise<void> => {
    if (validateFlightData()) {
      flightData.pilot_flying_id = flightData?.pilot_flying?.id;
      flightData.pilot_monitoring_id = flightData?.pilot_monitoring?.id;
      flightData.attachments_attributes = flightData?.sector_attachments;
      flightData.oil_uplifts_attributes = flightData?.oil_uplifts;
      delete flightData.oil_uplift;
      delete flightData.oil_uplifts;
      setLoading(true);
      if (flight) {
        await updateFlight({ id: flight, flight: flightData });
        dispatch(getFlightDetails({ payload: flight }));
        dispatch(changeDrawerMode({ payload: 'view' }));
        dispatch(getAircraftTripEntries({ payload: { id } }));
        setLoading(false);
        const drawer = document.getElementById('drawerTitle');
        if (drawer) drawer.scrollIntoView();
      } else if (trip) {
        const newFlight = await addFlight({ id: trip?.id, flight: flightData });
        dispatch(getFlightDetails({ payload: newFlight?.id }));
        setFlightId(newFlight?.id);
        dispatch(getAircraftTripEntries({ payload: { id } }));
        if (drawerHistory.length) {
          dispatch(setDrawerId({ payload: trip?.id }));
          dispatch(changeDrawerMode({ payload: 'view' }));
          handleBackClick();
        } else {
          dispatch(changeDrawerMode({ payload: 'view' }));
        }
        setLoading(false);
        const drawer = document.getElementById('drawerTitle');
        if (drawer) drawer.scrollIntoView();
      }
      dispatch(updateDrawerContent({ payload: true }));
    }
  };

  const updateFlightData = (changes: { value: any; key: string }[]): void => {
    const newData = JSON.parse(JSON.stringify(flightData));
    changes.forEach(({ value, key }) => {
      if (key === 'pilot_flying_id') {
        if (newData?.pilot_flying) {
          newData.pilot_flying.id = value;
        } else {
          newData.pilot_flying = {};
          newData.pilot_flying.id = value;
        }
      } else if (key === 'pilot_monitoring_id') {
        if (newData?.pilot_monitoring) {
          newData.pilot_monitoring.id = value;
        } else {
          newData.pilot_monitoring = {};
          newData.pilot_monitoring.id = value;
        }
      } else if (key) {
        newData[`${key}`] = value;
      }
    });
    setFlightData(newData);
  };

  useEffect(() => {
    if (!Object.keys(flightData).length && trip && trip?.flights && trip?.flights[trip?.flights.length - 1]) {
      const lastFlight = trip.flights[trip.flights.length - 1];
      const departureAirportId = lastFlight.arrival_airport_id;
      setFlightData({ departure_airport_id: departureAirportId });
      setOriginalFlightData({ departure_airport_id: departureAirportId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flight) {
      setLoading(true);
      dispatch(getFlightDetails({ payload: flight }));
    }
  }, [dispatch, flight]);

  useEffect(() => {
    if (flightId) {
      if (flightsMap.get(flightId)) {
        setLoading(false);
        setFlightData({ ...flightsMap.get(flightId) });
        setOriginalFlightData({ ...flightsMap.get(flightId) });
      }
    }
  }, [flightId, flightsMap]);

  useEffect(() => {
    if (flight) setFlightId(flight);
  }, [flight]);

  useEffect(() => {
    if (flightData?.trip?.date) {
      setFlightDate(flightData?.trip?.date);
    } else if (trip && trip?.flights && trip?.flights[trip?.flights.length - 1]) {
      setFlightDate(trip?.flights[trip?.flights.length - 1].time_onblocks);
      if (!flightData) {
        setFlightData({ departure_airport_id: trip?.flights[trip?.flights.length - 1].arrival_airport_id });
        setOriginalFlightData({ departure_airport_id: trip?.flights[trip?.flights.length - 1].arrival_airport_id });
      }
    } else if (trip && trip?.date) {
      setFlightDate(trip.date);
    }
  }, [flightData, trip]);

  useEffect(() => {
    if (!_.isEqual(flightData, originalFlightData)) {
      dispatch(setDrawerChanges({ payload: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightData]);

  useEffect(() => {
    if (revertChanges) {
      setFlightData(originalFlightData);
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revertChanges]);

  useEffect(() => {
    if (scroll) {
      const element = document.getElementById('attachments');
      if (element) element.scrollIntoView();
    }
  }, [scroll, loading]);

  let sectorNumber = null;
  if (flight && flightData) sectorNumber = flightData?.sector_number;
  let tripNumber = '';
  if (flightData?.trip?.number) {
    tripNumber = flightData?.trip?.number;
  }
  if (trip?.number) {
    tripNumber = trip?.number.toString();
  }

  const displayUplifts = (): boolean => {
    let show = false;
    if (flightData?.oil_uplifts?.length > 0) {
      for (let i = 0; i < flightData.oil_uplifts.length; i += 1) {
        if (flightData.oil_uplifts[i].uplift) {
          show = true;
        }
      }
    } else if (!flightData?.oil_uplifts?.length) {
      return false;
    }
    return show;
  };

  return (
    <DrawerWrapper id="drawerWrapper" data-testid="FlightDrawer-DrawerWrapper">
      {loading ? (
        <LoadingWrapper>
          <Loading loading={loading} contain />
        </LoadingWrapper>
      ) : (
        <>
          <Title id="drawerTitle" data-testid="FlightDrawer-Title">
            {drawerHistory.length && flightData?.trip?.flights?.length ? (
              <StyledButton onClick={handleBackClick}>
                <img src={backArrow} alt="back arrow" />
              </StyledButton>
            ) : null}
            Sector:{' '}
            <HeavyTitle data-testid="FlightDrawer-FlightNumber">
              {`${sectorNumber || '-'} (Trip #${tripNumber} - ${moment(flightDate).format(dateFormat)})`}
            </HeavyTitle>{' '}
            {mode === 'view' ? (
              <>
                -
                <img src={statusIcon} alt="status icon" /> {statusText}
              </>
            ) : null}
            <div>
              {mode === 'view' ? (
                <AuthDropdownMenu
                  options={{
                    read: false,
                    update: true,
                    delete: true,
                  }}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
                  resource={AircraftResource.TRIP}
                  aircraftId={flightData?.aircraft?.id}
                  editCallback={(): void => handleEditClick()}
                  handleDelete={(): void => deleteSector()}
                />
              ) : null}
            </div>
          </Title>
          <StatusDivWrapper data-testid="FlightDrawer-OpenButton">
            {mode !== 'add' ? (
              <div>
                <Header>SRP</Header>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${servers.api}/srp/${flightData?.trip?.id}.pdf`}
                  download={formatMessage({ id: 'text.srp' })}
                >
                  <span>SRP</span>
                  <img src={pdfIcon} alt="download icon" />
                </a>
              </div>
            ) : null}
          </StatusDivWrapper>
          <InfoDivWrapper data-testid="FlightDrawer-DetailsSection">
            <FlightDetailsSection
              flight={flightData}
              updateFlightData={updateFlightData}
              flightDate={flightDate}
              trip={trip}
            />
          </InfoDivWrapper>
          <InfoDivWrapper data-testid="FlightDrawer-PreFlightSection">
            <PreFlightCustomDataSection flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          <InfoDivWrapper data-testid="FlightDrawer-PostFlightSection">
            <PostFlightCustomDataSection flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          <InfoDivWrapper data-testid="FlightDrawer-FuelSection">
            <FuelSection flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          <InfoDivWrapper>
            <FuelUplifts flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          <NoShadowWrapper>
            {flightData?.deice_events?.length || displayUplifts() || mode !== 'view' ? (
              <UpliftsSection flight={flightData} updateFlightData={updateFlightData} displayUplifts={displayUplifts} />
            ) : null}
          </NoShadowWrapper>
          <NoShadowWrapper>
            <DefectsSection flight={flightData} />
          </NoShadowWrapper>
          {mode === 'view' ? (
            <NoShadowWrapper>
              <HistorySection flight={flightData} />
            </NoShadowWrapper>
          ) : null}
          <InfoDivWrapper id="attachments" data-testid="FlightDrawer-AttachmentsSection">
            <FlightAttachements flight={flightData} updateFlightData={updateFlightData} />
          </InfoDivWrapper>
          {mode !== 'view' ? (
            <ButtonWrapper>
              <Button size={ButtonSize.MEDIUM} onClick={handleSaveClick}>
                Save
              </Button>
              <CancelWrapper>
                <Button primary={false} size={ButtonSize.MEDIUM} onClick={handleCancelClick}>
                  Cancel
                </Button>
              </CancelWrapper>
            </ButtonWrapper>
          ) : null}
        </>
      )}
      <Modal width={420} isOpen={modalVisible} handleClose={(): void => setModalVisible(false)}>
        <ModalContentWrapper>
          <ModalTitle>{formatMessage({ id: 'title.deleteItem' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureDeleteSector',
            })} ${formatMessage({
              id: 'form.labels.cannotBeUndone',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubminButtonWraper>
              <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={(): void => deleteSector()}>
                Delete
              </Button>
            </SubminButtonWraper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setModalVisible(false)}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalContentWrapper>
      </Modal>
    </DrawerWrapper>
  );
};

export default FlightDrawer;
