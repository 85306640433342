/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDefectUrl } from '../../../services/api';
import { getFlightsForAircraft } from '../../../models/flights/actions';
import { getAllAircraft } from '../../../models/aircraft/actions';
import { DashboardState } from '../../../models';
import Loading from '../../TFLoading/index';
import TFSearchableSelect from '../../TFSearchableSelect/TFSearchableSelect';
import TFPortal from '../../TFPortal/TFPortal';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  handleBackButtonClick,
} from '../../../models/drawer';
import FlightDrawer from '../../FlightDrawer/FlightDrawer';
import { Defect } from '../../../models/defects';
import pdfIcon from '../../../assets/icon-filetype-pdf.svg';
import greenTick from '../../../assets/complete-icon.svg';
import backArrow from '../../../assets/icon-back.svg';
import shareIcon from '../../../assets/link-share.svg';
import servers from '../../../utils/servers';
import StatusBadge from '../../Status/StatusBadge';
import { AircraftAuthenticationWrapper, hasAircraftPermission } from '../../_utils/AuthenticationWrapper';
import { FeatureFlag } from '../../../models/userSettings';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import AuthDropdownMenu from '../../AuthDropdownMenu/AuthDropdownMenu';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import DrawerBanner from '../../TripDrawer/DrawerBanner';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import styles from './DefectHeader.module.less';

interface DefectHeaderProps {
  defect: Defect | null;
  flightId: string;
  acId: string;
  setAircraftId: (value: string) => void;
  updateDefectData?: (changes: any[]) => void;
  setDefectDeferred: (value: boolean) => void;
}

const Header = styled.div``;

const DefectTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile, width }): string => (isMobile ? '150px' : `${width}` || 'auto')};
  column-span: ${({ colSpan, editDefect }): string => (colSpan && editDefect ? `${colSpan}` : 'none')};
  span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
  margin-right: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '20px')};
`;

const StyledLinkWrapper = styled.div`
  border-left: ${({ tripId }): string => (tripId ? 'solid 1px rgba(36, 45, 65, 0.2)' : '')};
  padding-left: 6px;
  margin-left: 5px;
  position: relative;
  min-width: ${({ res }): string => (res ? '125px' : '25px')};
  a {
    color: #126fd6;
    flex-shrink: 1;
  }
`;

const LinkBackground = styled.button`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  z-index: 201;
  background-color: transparent;
  border: none;
`;

const PopoverWrapper = styled.div`
  width: 440px;
  background-color: #fafafa;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: 2px solid #fff;
  text-align: left;
  padding: 15px;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: ${({ flex }): string => (flex ? 'flex' : 'block')};
  flex-direction: ${({ column }): string => (column ? 'column' : 'row')};
`;

const DateWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: -25px;
  @media (max-width: 450px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border: solid 0.8px #75bd9a;
  background-image: linear-gradient(to bottom, #4fb184, #35b96d);
  border-radius: 50%;
  text-align: center;
  display: inline-block;
`;

const ClipboardText = styled.div`
  font-weight: 600;
  letter-spacing: 0.06px;
  color: #181818;
  display: inline-block;
  margin-left: 10px;
`;

const LinkText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05px;
  color: #9f9f9f;
  margin-top: 10px;
  margin-bottom: 10px;
  word-break: break-all;
`;

const PopoverText = styled.div`
  opacity: 0.7;
  line-height: 1.57;
  letter-spacing: 0.06px;
  color: #181818;
`;

const StyledButton = styled.button`
  color: #126fd6;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  padding: 0;
`;

const StyledBackButton = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const DefectHeader: React.FC<DefectHeaderProps> = ({
  defect,
  flightId,
  acId,
  updateDefectData,
  setDefectDeferred,
  setAircraftId,
}) => {
  const [flights, setFlights] = useState([]);
  const [chosenFlight, setChosenFlight] = useState(null);
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [chosenAircraft, setChosenAircraft] = useState(null);
  const [tripId, setTripId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const [publicLink, setPublicLink] = useState('');
  const [localDefectLink, setLocalDefectLink] = useState('');
  const [aircraftLoading, setAircraftLoading] = useState(false);
  const [flightLoading, setFlightLoading] = useState(false);
  const [publicLinkLoading, setPublicLinkLoading] = useState(false);
  const [sector, setSector] = useState('');
  const [tripNumber, setTripNumber] = useState('');
  const [localFlightTitles, setLocalFlightTitles] = useState([]);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap, lastFetched: aircraftLastFetched = 0 },
    flights: { aircraftFlightsMaps },
    drawer: { mode, drawerHistory },
    userSettings,
  } = useSelector((state: DashboardState) => state);
  const { flightsMap = new Map() } =
    aircraftFlightsMaps.get(chosenAircraft?.value) || aircraftFlightsMaps.get(defect?.aircraft?.id) || {};
  const featureFlags = aircraftMap.get(id)?.feature_flags
    ? aircraftMap.get(id)?.feature_flags.map((flag) => flag.feature_name)
    : [];

  const handlePopoverOpen = (): void => {
    const button = document.getElementById('publicButton');
    if (button) {
      const rect = button.getBoundingClientRect();
      const { top } = rect;
      setMenuStyle({ position: 'absolute', top: top + 30, right: 30, zIndex: 601 });
    }
    setShowPopup(true);
    if (publicLink) {
      const url = publicLink;
      const el = document.createElement('textarea');
      el.value = url;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  };

  const updateFlightDetails = (): void => {
    if (flightId) {
      const newFlight = flights.find((flight) => flight.id === flightId);
      if (newFlight) {
        const {
          sector_number,
          trip: { number },
        } = newFlight;
        setSector(sector_number);
        setTripNumber(number);
      }
    } else {
      setSector(null);
      setTripNumber(null);
    }
  };

  const handleChangeOfFlight = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenFlight(option);
    updateDefectData([{ value: option.value, key: 'flight_id' }]);
  };

  const handleChangeOfAircraft = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenAircraft(option);
    setAircraftId(option.value);
  };

  const handleDateChange = (newDate): void => {
    updateDefectData([{ value: newDate, key: 'raised_at' }]);
  };

  const handleDeferDefect = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
    setDefectDeferred(true);
  };

  const handleBackgoundLinkClick = (): void => {
    setShowPopup(false);
  };

  const handleBackClick = (): void => {
    dispatch(handleBackButtonClick());
  };

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevFlights = usePrevious(aircraftFlightsMaps);

  useEffect(() => {
    if (aircraftFlightsMaps.get(chosenAircraft?.value)) {
      setFlightLoading(false);
    }
  }, [aircraftFlightsMaps, prevFlights]);

  useEffect(() => {
    if (flightId && flights.length) {
      updateFlightDetails();
    }
  }, [flights, flightId]);

  useEffect(() => {
    if (chosenFlight) {
      updateFlightDetails();
    }
  }, [chosenFlight]);

  useEffect(() => {
    setFlights([]);
    setTripNumber('');
    setSector('');
    setChosenFlight(null);
    if (defect && !defect?.raised_at) {
      updateDefectData([{ value: moment().toISOString(), key: 'raised_at' }]);
    }
  }, [defect]);

  useEffect(() => {
    if (defect?.aircraft?.id) {
      const existingAircraft = aircraftOptions.find((aircraft) => aircraft?.value === defect?.aircraft?.id);
      if (existingAircraft) {
        setChosenAircraft(existingAircraft);
        setAircraftId(existingAircraft.value);
      }
    } else if (acId) {
      setChosenAircraft(aircraftOptions.find((aircraft) => aircraft?.value === acId));
    } else {
      setChosenAircraft(aircraftOptions[0]);
    }
  }, [aircraftOptions, defect]);

  useEffect(() => {
    if (defect && defect.id) {
      setPublicLinkLoading(true);
      getDefectUrl({ id: defect.id }).then((response: any) => {
        if (response && response.res && response.res.status > 199 && response.res.status < 399) {
          setPublicLink(`${servers.pub === 'localhost:8000' ? 'http://' : 'https://'}${servers.pub + response.url}`);
          setLocalDefectLink(response.url);
          setPublicLinkLoading(false);
        }
      });
    }
  }, [defect]);

  useEffect(() => {
    if (chosenAircraft?.value) {
      setFlightLoading(true);
      dispatch(getFlightsForAircraft({ payload: { id: chosenAircraft?.value } }));
    }
  }, [chosenAircraft?.value]);

  useEffect(() => {
    const createFlightTitle = (input): void => {
      let currentFlightIndex = 0;
      const titleArray = input.map((flight, index) => {
        if (flight.id === defect?.flight_id) {
          currentFlightIndex = index + 1;
        }

        return {
          title: `${flight.departure_airport}${
            flight.time_takeoff !== null
              ? `(${moment(flight.time_takeoff)
                  .utc()
                  .format('HH:mm')})`
              : ''
          }-${flight.arrival_airport}${
            flight.time_landing !== null
              ? `(${moment(flight.time_landing)
                  .utc()
                  .format('HH:mm')})`
              : ''
          } ${flight?.trip?.date}`,
          value: flight.id,
        };
      });
      titleArray.unshift({ title: 'None', value: null });
      setLocalFlightTitles(titleArray);
      setChosenFlight(titleArray[currentFlightIndex]);
      if (defect?.flight_id && defect?.flight_id !== titleArray[currentFlightIndex].value)
        updateDefectData([{ value: titleArray[currentFlightIndex].value, key: 'flight_id' }]);
    };
    if (flightsMap && Array.from(flightsMap.values()).length > 0 && chosenAircraft) {
      const values = Array.from(flightsMap.values());
      let filteredValues = [];
      if (chosenAircraft) {
        filteredValues = values
          .filter((item) => item?.aircraft?.id === chosenAircraft?.value && item?.status !== 'in_progress')
          .sort((a, b) => {
            if (a.trip.date === b.trip.date) {
              return moment(b.time_takeoff).diff(moment(a.time_takeoff));
            }
            return moment(b.trip.date, 'YYYY-MM-DD').diff(moment(a.trip.date, 'YYYY-MM-DD'));
          });
      }
      createFlightTitle(filteredValues);
      setFlights(filteredValues);
      updateFlightDetails();
    }
  }, [flightsMap, chosenAircraft]);

  useEffect(() => {
    if (flights.length > 0) {
      const currentFlight = flights.find((flight) => flight.id === flightId);
      if (currentFlight) {
        setTripId(currentFlight?.trip?.id);
      } else {
        setTripId(null);
      }
    }
  }, [flightId, flights, tripId]);

  useEffect(() => {
    if (chosenFlight?.value !== null && chosenFlight?.value !== defect?.flight_id) {
      const foundFlight = flights.find((item) => item.id === chosenFlight.value);
      if (foundFlight) {
        const newDate = foundFlight?.time_landing;
        updateDefectData([{ value: newDate, key: 'raised_at' }]);
      }
    }
  }, [chosenFlight?.value]);

  useEffect(() => {
    if (Array.from(aircraftMap.values()).length === 0 && Date.now() - aircraftLastFetched >= 300000) {
      setAircraftLoading(true);
      getAllAircraft();
    }
  }, [aircraftLastFetched, aircraftMap]);

  useEffect(() => {
    if (Array.from(aircraftMap.values()).length > 0) {
      setAircraftLoading(false);
    }
  }, [aircraftMap]);

  useEffect(() => {
    if (document.location.pathname === '/defects') {
      const createAircraftOptions = (values): void => {
        const newAircraftOptions = [];
        values.forEach((aircraft) => {
          const displayName = {
            title: `${aircraft?.registration} (${aircraft?.aircraft_type.designator})`,
            value: aircraft.id,
          };
          newAircraftOptions.push(displayName);
        });
        newAircraftOptions.sort((a, b) => a.title.localeCompare(b.title));
        setAircraftOptions(newAircraftOptions);
        if (mode === 'add') {
          let selectAircraft = newAircraftOptions.find((item) => item.value === acId);
          // eslint-disable-next-line prefer-destructuring
          if (!selectAircraft) selectAircraft = newAircraftOptions[0];
          setChosenAircraft(selectAircraft);
          setAircraftId(selectAircraft.value);
        }
      };
      if (aircraftMap && Array.from(aircraftMap.values()).length > 0) {
        const values = Array.from(aircraftMap.values()).filter((item) => {
          const usersPermissions = userSettings?.details?.people?.find((person) =>
            person?.permission_groups[1]?.aircraft.includes(item?.id),
          );
          return hasAircraftPermission(
            item,
            AircraftResource.AIRCRAFT,
            AircraftPermission.READ,
            usersPermissions?.permission_groups[1],
          );
        });
        createAircraftOptions(values);
      }
    }
  }, [aircraftMap, defect]);

  const isMobile = window.innerWidth < 451;

  const hasRectificationIntervalExtension = (): null | object => {
    return defect?.rectification_interval_extension;
  };

  const hasExtensionDateDue = (): string => {
    return hasRectificationIntervalExtension() && defect?.rectification_interval_extension.extension_date_due;
  };

  const drawerBanner = (): ReactElement | null => {
    if (hasRectificationIntervalExtension() && !hasExtensionDateDue()) {
      return <DrawerBanner message={formatMessage({ id: 'text.defectWillNotExpire' })} />;
    }

    return null;
  };

  let defectButtonText = formatMessage({ id: 'form.button.addCRS' });
  if (featureFlags?.includes(FeatureFlag.STANDALONERELEASE)) {
    defectButtonText = formatMessage({ id: 'form.button.submitResolution' });
    if (defect?.status === 'resolved') defectButtonText = formatMessage({ id: 'form.button.addCRS' });
  } else if (defect?.status === 'resolved') {
    defectButtonText = formatMessage({ id: 'form.button.viewCRS' });
  }

  return (
    <Header>
      <DefectTitle id="DefectTitle" data-testid="DefectHeader--Title">
        {drawerHistory?.length ? (
          <StyledBackButton onClick={handleBackClick}>
            <img src={backArrow} alt="back arrow" />
          </StyledBackButton>
        ) : null}
        {mode === 'edit' || mode === 'add' ? (
          <>
            {defect && Object.keys(defect).length > 0 && (
              <span>
                {mode === 'add' ? formatMessage({ id: 'menu.Add.Defect' }) : formatMessage({ id: 'title.editDefect' })}
                <strong>{defect?.number}</strong>
              </span>
            )}
            {!defect && <span>{formatMessage({ id: 'title.addNewDefect' })}</span>}
          </>
        ) : (
          <>
            <span>
              {formatMessage({ id: 'title.defect' })}: <strong>{defect && defect.number}</strong>
            </span>
            {defect?.status !== 'resolved' && (
              <FlexWrapper marginLeft={10}>
                <AuthDropdownMenu
                  options={{
                    read: false,
                    update: true,
                    delete: false,
                    custom: !defect?.deferred,
                  }}
                  menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
                  resource={AircraftResource.DEFECT}
                  aircraftId={acId}
                  customText="Defer Defect"
                  customCallback={(): any => handleDeferDefect()}
                  editCallback={(): any => dispatch(changeDrawerMode({ payload: 'edit' }))}
                  noOffset
                />
              </FlexWrapper>
            )}
          </>
        )}
      </DefectTitle>
      {drawerBanner()}
      <FlexWrapper justifyContent="space-between">
        <FlexWrapper flexWrap="wrap" column={isMobile} width="100%">
          {document.location.pathname === '/defects' ? (
            <InfoSection isMobile={isMobile} margin colSpan={2} editDefect data-testid="DefectHeader--AircraftSection">
              <Label
                marginBottom={4}
                fontSize="12px"
                color={mode === 'edit' || mode === 'add' ? '#242d41' : 'rgba(36, 45, 65, 0.4)'}
              >
                {mode === 'edit' || mode === 'add'
                  ? formatMessage({ id: 'title.aircraft' })
                  : formatMessage({ id: 'title.aircraftCaps' })}
              </Label>
              {mode === 'edit' || mode === 'add' ? (
                <SelectWrapper>
                  <Loading loading={aircraftLoading} contain width={20} height={20} />
                  <TFSearchableSelect
                    initial={chosenAircraft}
                    options={aircraftOptions}
                    handleSelectChange={handleChangeOfAircraft}
                    inputWidth={200}
                  />
                </SelectWrapper>
              ) : (
                <FlexWrapper>{chosenAircraft?.title}</FlexWrapper>
              )}
            </InfoSection>
          ) : null}
          <FlexWrapper>
            <InfoSection
              isMobile={isMobile}
              width={id && mode === 'view' ? '150px' : ''}
              margin
              colSpan={2}
              editDefect
              data-testid="DefectHeader--SectorSection"
            >
              <Label
                marginBottom={4}
                fontSize="12px"
                color={mode === 'edit' || mode === 'add' ? '#242d41' : 'rgba(36, 45, 65, 0.4)'}
              >
                {mode === 'edit' || mode === 'add'
                  ? formatMessage({ id: 'title.sector' })
                  : formatMessage({ id: 'title.sectorCaps' })}
              </Label>
              {mode === 'edit' || mode === 'add' ? (
                <SelectWrapper column={isMobile} flex={!!id}>
                  <Loading loading={flightLoading} contain height={20} width={20} />
                  <TFSearchableSelect
                    options={localFlightTitles}
                    inputWidth={340}
                    handleSelectChange={handleChangeOfFlight}
                    initial={chosenFlight}
                  />
                  {id ? (
                    <DateWrapper data-testid="DefectHeader--DateSection">
                      <DateTimePicker
                        headerDate="Date Recorded"
                        headerTime="Time Recorded"
                        handleDateTimeChange={handleDateChange}
                        dateTime={defect?.raised_at}
                        noFuture
                      />
                    </DateWrapper>
                  ) : null}
                </SelectWrapper>
              ) : (
                <FlexWrapper>
                  <Loading loading={flightLoading} contain height={20} width={20} />
                  {sector && tripNumber ? (
                    <StyledButton
                      type="button"
                      onClick={(): void => {
                        dispatch(changeDrawerVisibility({ payload: true }));
                        dispatch(
                          changeDrawerContent({ payload: { content: <FlightDrawer flight={chosenFlight?.value} /> } }),
                        );
                      }}
                    >
                      {`${sector} ${`(Trip #${tripNumber})`}`}
                    </StyledButton>
                  ) : (
                    '-'
                  )}
                  <StyledLinkWrapper tripId={tripId}>
                    {tripId ? (
                      <a
                        href={`${servers.api}/srp/${tripId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={formatMessage({ id: 'text.srp' })}
                      >
                        <FlexWrapper alignItems="center">
                          <img src={pdfIcon} alt="pdf icon" className={styles.pdfIcon} />
                          <span>SRP</span>
                        </FlexWrapper>
                      </a>
                    ) : null}
                  </StyledLinkWrapper>
                </FlexWrapper>
              )}
            </InfoSection>
            <InfoSection
              margin
              isMobile={isMobile}
              marginBottom="0"
              width={id ? '150px' : ''}
              editDefect={mode === 'edit' || mode === 'add'}
              data-testid="DefectHeader--DateSection"
              className={styles.dateWrapper}
            >
              {mode === 'view' ? (
                <Label marginBottom={4} fontSize="12px" color={mode !== 'view' ? '#242d41' : 'rgba(36, 45, 65, 0.4)'}>
                  {mode !== 'view'
                    ? formatMessage({ id: 'text.dateRecorded' })
                    : formatMessage({ id: 'text.dateRecordedCaps' })}
                </Label>
              ) : null}
              {mode !== 'view' ? (
                <>
                  {!id && (
                    <DateTimePicker
                      headerDate="Date Recorded"
                      headerTime="Time Recorded"
                      handleDateTimeChange={handleDateChange}
                      dateTime={defect?.raised_at || moment().toISOString()}
                      noFuture
                    />
                  )}
                </>
              ) : (
                <span>
                  {moment(defect?.raised_at)
                    .utc()
                    .format(`${userSettings.dateFormat} HH:mm`) || '-'}
                </span>
              )}
            </InfoSection>
          </FlexWrapper>
          <FlexWrapper width={isMobile || !id ? 'auto' : '340px'}>
            {mode === 'view' && (
              <>
                <InfoSection margin isMobile={isMobile} data-testid="DefectHeader--StatusSection">
                  <Label marginBottom={4} fontSize="12px" color="rgba(36, 45, 65, 0.4)">
                    {formatMessage({ id: 'title.statusCaps' })}
                  </Label>
                  <FlexWrapper width="150px" flexShrink="1">
                    {(defect && defect.status && (
                      <span>
                        <StatusBadge status={defect?.status} />
                        <span>{defect?.status}</span>
                      </span>
                    )) ||
                      '-'}
                    <StyledLinkWrapper res={defectButtonText === 'Submit Resolution'}>
                      <AircraftAuthenticationWrapper
                        aircraftId={defect?.aircraft?.id}
                        requiredResource={AircraftResource.RELEASE}
                        requiredPermissionLevel={AircraftPermission.CREATE}
                      >
                        <Loading loading={flightLoading} contain height={20} width={20} />
                        <a href={localDefectLink} target="_blank" rel="noopener noreferrer">
                          {defectButtonText}
                        </a>
                      </AircraftAuthenticationWrapper>
                    </StyledLinkWrapper>
                  </FlexWrapper>
                </InfoSection>
              </>
            )}
            {mode === 'view' && (id || isMobile) && (
              <FlexWrapper position="relative" alignItems="center" width="100%" justifyContent="flex-end">
                <AircraftAuthenticationWrapper
                  aircraftId={defect?.aircraft?.id}
                  requiredResource={AircraftResource.RELEASE}
                  requiredPermissionLevel={AircraftPermission.CREATE}
                >
                  <StyledButton type="button" id="publicButton" onClick={handlePopoverOpen}>
                    <img src={shareIcon} alt="share icon" /> {formatMessage({ id: 'text.publicShareLink' })}
                  </StyledButton>
                  {showPopup && (
                    <TFPortal>
                      <LinkBackground onClick={handleBackgoundLinkClick} />
                      <PopoverWrapper style={menuStyle}>
                        <ImageContainer>
                          <img src={greenTick} alt="Green Tick" />
                        </ImageContainer>
                        <ClipboardText>{formatMessage({ id: 'text.copiedToClipboard' })}</ClipboardText>
                        <Loading loading={publicLinkLoading} contain />
                        <LinkText>{publicLink}</LinkText>
                        <PopoverText>{formatMessage({ id: 'text.validFor30days' })}</PopoverText>
                      </PopoverWrapper>
                    </TFPortal>
                  )}
                </AircraftAuthenticationWrapper>
              </FlexWrapper>
            )}
          </FlexWrapper>
          {mode === 'view' && !id && !isMobile && (
            <FlexWrapper
              position="relative"
              alignItems="center"
              width="100%"
              marginBottom={20}
              justifyContent="flex-end"
            >
              <AircraftAuthenticationWrapper
                aircraftId={defect?.aircraft?.id}
                requiredResource={AircraftResource.RELEASE}
                requiredPermissionLevel={AircraftPermission.CREATE}
              >
                <StyledButton type="button" id="publicButton" onClick={handlePopoverOpen}>
                  <img src={shareIcon} alt="share icon" /> {formatMessage({ id: 'text.publicShareLink' })}
                </StyledButton>
                {showPopup && (
                  <TFPortal>
                    <LinkBackground onClick={handleBackgoundLinkClick} />
                    <PopoverWrapper style={menuStyle}>
                      <ImageContainer>
                        <img src={greenTick} alt="Green Tick" />
                      </ImageContainer>
                      <ClipboardText>{formatMessage({ id: 'text.copiedToClipboard' })}</ClipboardText>
                      <Loading loading={publicLinkLoading} contain />
                      <LinkText>{publicLink}</LinkText>
                      <PopoverText>{formatMessage({ id: 'text.validFor30days' })}</PopoverText>
                    </PopoverWrapper>
                  </TFPortal>
                )}
              </AircraftAuthenticationWrapper>
            </FlexWrapper>
          )}
        </FlexWrapper>
      </FlexWrapper>
    </Header>
  );
};

export default DefectHeader;
