/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import { intl } from '../../providers/IntlProvider';
import { addAircraftMaintenance, updateAircraft } from '../../services/api';
import {
  queryAircraft,
  queryAircrafts,
  queryAircraftByOperator,
  getAircraftStatus,
  getAircraftPeople,
} from '../../services/apiNew';
import { AircraftActionTypes } from './index';

export function getAllAircraft() {
  return async (dispatch: any): Promise<any> => {
    const res = await queryAircrafts();
    dispatch({
      type: AircraftActionTypes.SAVE_ALL,
      payload: res.data,
    });
  };
}

export const getAircraftByOperators = (operator) => async (dispatch): Promise<any> => {
  const res = await queryAircraftByOperator(operator.id);
  dispatch({
    type: AircraftActionTypes.SAVE_ALL,
    payload: res.data,
  });
};

export const fetchAircraftStatus = ({ payload }) => async (dispatch): Promise<any> => {
  const res = await getAircraftStatus(payload);
  dispatch({
    type: AircraftActionTypes.SAVE_STATUS,
    payload: {
      maintenance_status: res.data,
      id: payload,
    },
  });
};

export const getSingleAircraft = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await queryAircraft(payload);
  dispatch({
    type: AircraftActionTypes.SAVE,
    payload: response.data,
  });
};

export const saveSingleAircraft = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({
    type: AircraftActionTypes.SAVE,
    payload,
  });
};

export const fetch = ({ payload }) => async (dispatch): Promise<any> => {
  console.error('aircraft/fetch is deprecated please use getSingleAircraft instead');
  const response = await queryAircraft(payload);
  dispatch({
    type: AircraftActionTypes.SAVE,
    payload: response.data,
  });
};

export const add = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({
    type: AircraftActionTypes.SAVE_NEW,
    payload,
  });
};

export const addMaintenance = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await addAircraftMaintenance(payload);
  if (response.statusCode > 199 && response.statusCode < 400) {
    dispatch({
      type: AircraftActionTypes.SAVE_NEW,
      payload: response.body.aircraft,
    });
  }
};

export const update = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await updateAircraft(payload);
  if (response.statusCode > 199 && response.statusCode < 400) {
    message.success(intl.formatMessage({ id: 'message.aircraftUpdated' }));
    dispatch({
      type: AircraftActionTypes.SAVE,
      payload: response.body,
    });
  }
};

export const updateNewAircraft = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({
    type: AircraftActionTypes.SAVE_NEW,
    payload,
  });
};

export const updateSkipAPI = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({
    type: AircraftActionTypes.SAVE,
    payload,
  });
};

export const fetchAircraftPeople = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getAircraftPeople(payload);
  dispatch({
    type: AircraftActionTypes.ADD_PEOPLE,
    payload: {
      people: response.data,
      id: payload,
    },
  });
};
