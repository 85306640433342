import { BaseModel } from '..';

const initialState: FuelState = {
  fuelMap: new Map(),
  lastFetched: 0,
  totalReceipts: 0,
};

export enum FuelsActionTypes {
  SAVE = 'fuel/save',
  SAVE_ALL = 'fuel/saveAll',
}

export interface SaveFuelsActionTypes {
  type: FuelsActionTypes.SAVE;
  payload: Fuel;
}

export interface SaveAllFuelsActionTypes {
  type: FuelsActionTypes.SAVE_ALL;
  payload: { fuel: Fuel[]; total: number };
}

type FuelsAction = SaveFuelsActionTypes | SaveAllFuelsActionTypes;

export default function(state = initialState, action: FuelsAction): FuelState {
  switch (action.type) {
    case FuelsActionTypes.SAVE_ALL: {
      const fuelMap = new Map();
      if (action?.payload?.fuel)
        action.payload.fuel.forEach((receipt) => {
          fuelMap.set(receipt.id, {
            ...fuelMap.get(receipt.id),
            ...receipt,
          });
        });
      return {
        ...state,
        fuelMap,
        lastFetched: Date.now(),
        totalReceipts: Number(action.payload.total),
      };
    }
    case FuelsActionTypes.SAVE: {
      const fuelMap = new Map(state.fuelMap.entries());
      fuelMap.set(action.payload.id, {
        ...fuelMap.get(action.payload.id),
        ...action.payload,
      });
      return {
        ...state,
        fuelMap,
      };
    }
    default:
      return state;
  }
}

export interface FuelState {
  fuelMap: Map<string, Fuel>;
  lastFetched: number;
  totalReceipts: number;
}

export interface Fuel extends BaseModel {
  id: string;
  density: unknown;
  aircraft_unit: string;
  truck_unit: unknown;
  aircraft_planned_uplift: string;
  aircraft_actual_uplift: string;
  truck_actual_uplift: unknown;
  price_value: unknown;
  price_currency: unknown;
  paid: false;
  price_quote: unknown;
  attachments: {}[];
  flight: {
    id: string;
    time_offblocks: string;
    time_onblocks: string;
    time_takeoff: string;
    time_landing: string;
    aircraft: {
      registration: string;
      id: string;
      type: string;
    };
    departure_airport: unknown;
    arrival_airport: unknown;
    status: string;
    landings_count: number;
    source: unknown;
    marked_as_checked: false;
    sector_number: number;
    aircraft_registration: string;
    trip: {
      id: string;
      number: number;
      date: string;
      srp_number: number;
    };
  };
}
