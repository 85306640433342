import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { updateReleaseAttachments } from '../../../models/defects/actions';
import FileViewer from '../../TFFileViewer/fileViewer';
import TFButton from '../../TFButton/TFButton';
import servers from '../../../utils/servers';
import { DashboardState } from '../../../models';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';
import ViewDeferralOptions from './ViewDeferralOptions';
import ViewMelDetails from './ViewMelDetails';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface DefectDetailsProps {
  defect: Defect;
  updateDefectData: (changes: any[]) => void;
  editDefect: boolean;
  signature: string;
  attachments?: any[];
  setAttachments?: (input: any) => void;
  defectDeferred: boolean;
  rectificationCategory: { title: string; colour?: string };
  setRectificationCategory: (option: { title: string; colour?: string }) => void;
  setDateDue?: (input) => void;
  apuInstalled?: boolean;
  reference: string;
  setReference: (value: string) => void;
  ATAChapter: string;
  setATAChapter: (value: string) => void;
  ATASection: string;
  setATASection: (value: string) => void;
  deferredTime: Moment;
  setDeferredTime: (input: Moment) => void;
}

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
`;

const BorderLine = styled.div`
  width: 680px;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-left: -22px;
  @media (max-width: 450px) {
    width: calc(100vw - 40px);
  }
`;

const StyledImg = styled.img`
  height: 70px;
  max-width: 240px;
`;

const DefectDetails: React.FC<DefectDetailsProps> = ({
  defect,
  editDefect,
  updateDefectData,
  signature,
  attachments,
  setAttachments,
  defectDeferred,
  rectificationCategory,
  setRectificationCategory,
  setDateDue,
  apuInstalled,
  reference,
  setReference,
  ATAChapter,
  setATAChapter,
  ATASection,
  setATASection,
  deferredTime,
  setDeferredTime,
}) => {
  const [isMelTableVisible, setIsMelTableVisible] = useState(true);
  const [sigSource, setSigSource] = useState('');
  const [resAttachments, setResAttachments] = useState(null);
  const [details, setDetails] = useState('');

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { userSettings } = useSelector((state: DashboardState) => state);

  const {
    userSettings: { dateFormat },
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const lastMXEvent = defect?.mx_events && defect?.mx_events[defect?.mx_events.length - 1];

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleResAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const newAttachments = resAttachments?.concat(...base64Attachments);
      setResAttachments(newAttachments);
      dispatch(updateReleaseAttachments({ payload: { id: lastMXEvent?.release_id, body: newAttachments } }));
    }
  };

  const handleResDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList = attachments.filter(
      (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    dispatch(updateReleaseAttachments({ payload: { id: lastMXEvent?.release_id, body: newAttachmentsList } }));
    setResAttachments(newAttachmentsList);
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      setAttachments(attachments?.concat(...base64Attachments));
      updateDefectData([{ value: attachments?.concat(...base64Attachments), key: 'attachments' }]);
    }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList = attachments.filter(
      (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    setAttachments(newAttachmentsList);
    updateDefectData([{ value: newAttachmentsList, key: 'attachments' }]);
  };

  const handleMELTableClick = (): void => {
    setIsMelTableVisible(!isMelTableVisible);
  };

  useEffect(() => {
    if (defect) {
      setAttachments(defect?.attachments || []);
      setReference(defect?.reference || '');
      setDetails(defect?.details || '');
      if (defect?.display_data) {
        setATAChapter(defect?.display_data?.ata.split(' ')[0]);
        setATASection(defect?.display_data?.ata_section);
      }
    } else {
      setAttachments([]);
      setReference('');
      setDetails('');
    }
    if (lastMXEvent) {
      setResAttachments(lastMXEvent?.attachments);
    }
  }, [defect, lastMXEvent, setATAChapter, setATASection, setAttachments, setReference]);

  useEffect(() => {
    let imageSource = `${servers.api}${signature}`;
    if (!signature?.startsWith('/signature')) imageSource = `data:image/png;base64,${signature}`;
    setSigSource(imageSource);
  }, [signature]);

  const isMobile = window.innerWidth < 451;

  const extendedOn = (): string => {
    if (!defect || !defect.rectification_interval_extension) {
      return '-';
    }

    return moment(defect.rectification_interval_extension.extension_date)
      .utc()
      .format(`${userSettings.dateFormat}`);
  };

  const newDueDate = (): string => {
    if (
      !defect ||
      !defect.rectification_interval_extension ||
      !defect.rectification_interval_extension.extension_date_due
    ) {
      return '-';
    }

    return moment(defect.rectification_interval_extension.extension_date_due)
      .utc()
      .format(`${userSettings.dateFormat}`);
  };
  return (
    <Card marginBottom={20} identifier="DefectDetailsWrapper">
      <Label fontWeight={600} identifier="DefectDetailsLabel">
        {formatMessage({ id: 'form.labels.defectDetails' })}
      </Label>
      <GridWrapper columns={isMobile ? '1fr' : '1fr 1fr'} marginBottom={20} marginTop={20} columnGap={20}>
        <FlexWrapper column colSpan={0}>
          <Label
            fontSize={editDefect ? '14' : '12'}
            color={editDefect ? '' : 'rgba(36, 45, 65, 0.4)'}
            textTransform={editDefect ? '' : 'uppercase'}
            identifier="NotesLabel"
          >
            Notes
          </Label>
          {editDefect ? (
            <StyledTextarea
              id="notes"
              name="notes"
              data-testid="DefectDetails--TextArea"
              cols={30}
              rows={5}
              onChange={(e): void => {
                setDetails(e.target.value);
                updateDefectData([{ value: e.target.value, key: 'details' }]);
              }}
              value={details}
            />
          ) : (
            <span data-testid="DefectDetails--Details">{defect?.details || '-'}</span>
          )}
        </FlexWrapper>
        <FlexWrapper width="100%" column marginTop={isMobile ? 20 : 0}>
          <Label
            fontSize={editDefect ? '14' : '12'}
            color={editDefect ? '' : 'rgba(36, 45, 65, 0.4)'}
            textTransform={editDefect ? '' : 'uppercase'}
            identifier="AttachmentsLabel"
          >
            {formatMessage({ id: 'title.attachments' })}
          </Label>
          <FileViewer
            attachments={attachments}
            handleDeleteClick={handleDelete}
            addAttachment={handleAttachmentUpload}
            uploaderVisible={editDefect}
            allowDelete={editDefect}
          />
        </FlexWrapper>
      </GridWrapper>
      {editDefect && !defectDeferred ? (
        <>
          <ManuallyEnterMELDetails
            defect={defect}
            rectificationCategory={rectificationCategory}
            setRectificationCategory={setRectificationCategory}
            deferred={defectDeferred}
            setDateDue={setDateDue}
            noMEL
            apuInstalled={apuInstalled}
            reference={reference}
            setReference={setReference}
            ATAChapter={ATAChapter}
            setATAChapter={setATAChapter}
            ATASection={ATASection}
            setATASection={setATASection}
            deferredTime={deferredTime}
            setDeferredTime={setDeferredTime}
            updateDefectData={updateDefectData}
          />
        </>
      ) : null}
      {!editDefect && (defect?.reporter?.first_name || defect?.reporter?.last_name || defect?.signature_image_url) ? (
        <>
          <BorderLine />
          <FlexWrapper
            justifyContent="space-between"
            alignItems={isMobile ? '' : 'center'}
            marginTop={mode === 'view' ? 20 : 35}
            marginBottom={20}
            column={isMobile}
            height={isMobile ? null : 40}
          >
            <FlexWrapper alignItems="center">
              <Label fontWeight={500} marginRight={4}>
                {formatMessage({ id: 'text.reportedBy' })}
              </Label>
              <Label fontWeight={600} marginRight={15}>
                {defect?.reporter?.first_name || '-'} {defect?.reporter?.last_name || null}
              </Label>
              {defect?.signature_image_url && (
                <StyledImg alt="Signature" src={`${servers.api}${defect?.signature_image_url}`} />
              )}
            </FlexWrapper>
            <FlexWrapper flexShrink="0">
              <Label fontWeight={500} marginRight={4}>
                {formatMessage({ id: 'form.labels.date' })}:
              </Label>
              <Label fontWeight={600}>
                {moment(defect?.raised_at)
                  .utc()
                  .format(dateFormat) || '-'}
              </Label>
            </FlexWrapper>
          </FlexWrapper>
        </>
      ) : null}
      {!editDefect && (
        <>
          <BorderLine />
          <ViewDeferralOptions defect={defect} />
          {defect?.defect_type === 'MEL' && defect?.mel_item?.id ? (
            <>
              <TFButton padding="0" clear handleClick={handleMELTableClick}>
                {isMelTableVisible ? 'Hide MEL Table' : 'Show MEL Table'}
              </TFButton>
              <ViewMelDetails defect={defect} isMelTableVisible={isMelTableVisible} />
            </>
          ) : null}
          <FlexWrapper column marginBottom={30}>
            <Label marginBottom={20} marginTop={defect?.defect_type === 'MEL' ? 30 : 1} fontWeight={600}>
              {formatMessage({ id: 'form.labels.aircraftLimitations' })}
            </Label>
            <GridWrapper columns="1fr 1fr">
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'form.labels.aircraftLimitations' })}
                </Label>
                <span>{defect?.limitations || '-'}</span>
              </FlexWrapper>
            </GridWrapper>
          </FlexWrapper>
          <BorderLine />
          <FlexWrapper column marginBottom={30}>
            <Label marginBottom={20} marginTop={30} fontWeight={600}>
              {formatMessage({ id: 'title.additionalInformation' })}
            </Label>
            <GridWrapper columns="1fr 1fr">
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'text.reasonToDefer' })}
                </Label>
                <span>{defect?.reason_to_defer || '-'}</span>
              </FlexWrapper>
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'text.deferralApprovalReference' })}
                </Label>
                <span>{defect?.defer_approval_reference || '-'}</span>
              </FlexWrapper>
            </GridWrapper>
          </FlexWrapper>
          {!editDefect &&
          defect?.deferred &&
          defect?.display_data?.category &&
          defect?.display_data?.category !== 'A' ? (
            <>
              <BorderLine />
              <FlexWrapper column marginBottom={30}>
                <Label marginBottom={20} marginTop={30} fontWeight={600}>
                  {formatMessage({ id: 'form.labels.rectificationIntervalExtension' })}
                </Label>
                <GridWrapper columns="1fr 1fr 1fr">
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extensionAdded' })}
                    </Label>
                    <span>{defect?.rectification_interval_extension ? 'YES' : 'NO'}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extendedOn' })}
                    </Label>
                    <span>{extendedOn()}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.extensionDue' })}
                    </Label>
                    <span>{newDueDate()}</span>
                  </FlexWrapper>
                </GridWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.edited_by?.first_name || defect?.deferred_by?.id ? (
            <>
              <BorderLine />
              <FlexWrapper
                justifyContent="space-between"
                alignItems={isMobile ? '' : 'center'}
                marginTop={20}
                marginBottom={20}
                column={isMobile}
                height={isMobile ? null : 40}
              >
                <FlexWrapper alignItems="center">
                  <Label fontWeight={500} marginRight={4}>
                    {defect?.edited_by?.first_name ? formatMessage({ id: 'text.editedBy' }) : null}
                    {!defect?.edited_by?.first_name && defect?.deferred_by?.id
                      ? formatMessage({ id: 'text.deferredBy' })
                      : null}
                  </Label>
                  <Label fontWeight={600} marginRight={15}>
                    {!defect?.edited_by?.first_name && !defect?.deferred_by?.id ? '-' : null}
                    {defect?.edited_by?.first_name ? defect?.edited_by?.first_name : ''}{' '}
                    {defect?.edited_by?.last_name || null}
                    {!defect?.edited_by?.first_name && defect?.deferred_by?.id ? (
                      <span>
                        {defect?.deferred_by?.first_name} {defect?.deferred_by?.last_name}
                      </span>
                    ) : null}
                  </Label>
                  {(signature || defect?.deferral_signature_image_url) && (
                    <StyledImg
                      alt="Signature"
                      src={defect?.edited_by?.id ? sigSource : `${servers.api}${defect?.deferral_signature_image_url}`}
                    />
                  )}
                </FlexWrapper>
                <FlexWrapper flexShrink="0">
                  <Label fontWeight={500} marginRight={4}>
                    {defect?.edited_by?.first_name ? formatMessage({ id: 'text.editedOn' }) : null}
                    {!defect?.edited_by?.first_name && defect?.deferred_by?.id
                      ? formatMessage({ id: 'text.deferredOn' })
                      : null}
                  </Label>
                  <Label fontWeight={600}>
                    {defect?.edited_by?.first_name
                      ? (defect?.edited_date &&
                          moment(defect?.edited_date)
                            .utc()
                            .format(`${dateFormat} HH:mm`)) ||
                        '-'
                      : (defect?.deferred_at &&
                          moment(defect?.deferred_at)
                            .utc()
                            .format(`${dateFormat} HH:mm`)) ||
                        '-'}
                  </Label>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.mx_events && defect?.mx_events.length > 0 ? (
            <>
              <BorderLine />
              <FlexWrapper column>
                <Label marginBottom={20} marginTop={30} fontWeight={600}>
                  {formatMessage({ id: 'title.resolutionDetails' })}
                </Label>
                <GridWrapper rowGap={20} columns={isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr'} rows="auto">
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.engineer' })}
                    </Label>
                    <span>
                      {lastMXEvent?.first_name || '-'} {lastMXEvent?.last_name || null}
                    </span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'title.licenceAuthorisationNo' })}.
                    </Label>
                    <span>{lastMXEvent?.licence_number || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'form.labels.part145Organisation' })}
                    </Label>
                    <span>{lastMXEvent?.company_name || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'title.licenceAuthorisationNo' })}.
                    </Label>
                    <span>{lastMXEvent?.approval_number || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column colSpan={2}>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.purchaseOrder' })}
                    </Label>
                    <span>{lastMXEvent?.workpack_reference || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column colSpan={2}>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'form.labels.attachments' })}.
                    </Label>
                    <FileViewer
                      attachments={resAttachments}
                      handleDeleteClick={handleResDelete}
                      addAttachment={handleResAttachmentUpload}
                    />
                  </FlexWrapper>
                </GridWrapper>
                <FlexWrapper
                  justifyContent="space-between"
                  alignItems={isMobile ? '' : 'center'}
                  marginTop={20}
                  marginBottom={20}
                  column={isMobile}
                  height={isMobile ? null : 40}
                >
                  <FlexWrapper alignItems="center">
                    <Label fontWeight={500} marginRight={4}>
                      {formatMessage({ id: 'title.resolvedBy' })}:
                    </Label>
                    <Label fontWeight={600} marginRight={15}>
                      {lastMXEvent?.reporter_first_name || lastMXEvent?.first_name || '-'}{' '}
                      {lastMXEvent?.reporter_last_name || lastMXEvent?.last_name || null}
                    </Label>
                    {lastMXEvent?.signature_image_url && (
                      <StyledImg alt="Signature" src={`${servers.api}${lastMXEvent?.signature_image_url}`} />
                    )}
                  </FlexWrapper>
                  <FlexWrapper flexShrink="0" marginRight={30}>
                    <Label fontWeight={500} marginRight={4}>
                      {formatMessage({ id: 'title.resolvedOn' })}:
                    </Label>
                    <Label fontWeight={600}>
                      {lastMXEvent?.date
                        ? moment(lastMXEvent?.date)
                            .utc()
                            .format(dateFormat)
                        : '-'}
                    </Label>
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default DefectDetails;
