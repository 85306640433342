/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getAllMxItemsWorkpacks } from '../../../models/maintenance/actions';
import { getAllDefectsForWorkpacks } from '../../../models/defects/actions';
import { DashboardState } from '../../../models/index';
import TFCloseableBackground from '../../TFCloseableBackground';
import TFSelect from '../../TFSelect/TFSelect';
import styles from '../workpackDrawer.module.less';

const ResultsList = ({ results, addWpItem, resetResults, setShowResults }): JSX.Element =>
  results.map((item) => {
    let mxType;
    let boxColour = 'rgba(36,45,65,0.10)';
    if (item.defect_type || item.defect_type === null) {
      boxColour = '#1e589f';
      mxType = 'defect';
    } else if (item.mx_type && item.mx_type.includes && item.mx_type.includes('scheduled')) {
      boxColour = '#470cfe';
      mxType = 'scheduled_mx_item';
    } else if (item.mx_type && item.mx_type.includes && item.mx_type.includes('oop')) {
      boxColour = '#794efc';
    } else if (item.mx_type && item.mx_type.includes && item.mx_type.includes('llp')) {
      boxColour = '#baa3ff';
    }
    return (
      <button
        type="button"
        key={item.id}
        onClick={(): void => {
          addWpItem(item, mxType);
          resetResults();
          setShowResults(false);
        }}
        className={styles.resultItem}
      >
        <div className={styles.itemTitleWrapper}>
          <div className={styles.colourSquare} style={{ backgroundColor: boxColour }} />
          <span className={styles.itemTitle}>{item.name || item.details}</span>
        </div>
        {item.days_remaining && <span className={styles.estDue}>{`Est. Due: In ${item.days_remaining} days`}</span>}
      </button>
    );
  });

interface SortableByDaysRemaining extends Record<string, unknown> {
  days_remaining: number | null;
}

const MXSelector = ({ mxMap, defectsMap, addWpItem, aircraft, wpItems = [] }): JSX.Element => {
  const [searchResults, updateSearchResults] = useState([]);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [listHeight, setListHeight] = useState(null);
  const inputRef = useRef(null);
  const selectOptions = [
    { title: 'all types', colour: 'rgba(36,45,65,0.10)' },
    { title: 'scheduled', colour: '#470cfe' },
    { title: 'oop', colour: '#794efc' },
    { title: 'llp', colour: '#baa3ff' },
    { title: 'defect', colour: '#1e589f' },
  ];

  const mxArray = useMemo(
    () => Array.from(mxMap.values()).filter((item: any) => item?.aircraft_id === aircraft?.id && !item.draft),
    [mxMap],
  );

  const defectsArray = useMemo(
    () =>
      Array.from(defectsMap.values()).filter(
        (item: any) => item?.aircraft.id === aircraft?.id && (item?.status === 'open' || item?.status === 'overdue'),
      ),
    [defectsMap],
  );

  const resetResults = (): void => {
    updateSearchResults([]);
  };

  const filterArray = (array): any[] => {
    const wpItemIdsAlreadyIn = wpItems.map((item) => {
      return item.mx_item_id;
    });
    const filteredArray = array.filter((item) => {
      return !wpItemIdsAlreadyIn.includes(item.id);
    });
    return filteredArray;
  };

  const handleSearch = (e): void => {
    let results;
    let searchString = '';
    if (e && e.target && e.target.value && e.target.value !== '') searchString = e.target.value.toLowerCase();
    const mxType = selectedTypeOption.title;
    if (mxType === 'all types') {
      const defectResults = defectsArray.filter((item: any) => {
        return item && item.details && item.details.toLowerCase().includes(searchString);
      });
      const mxResults = mxArray.filter((item: any) => {
        return item && item.name && item.name.toLowerCase().includes(searchString);
      });
      results = defectResults.concat(mxResults);
    } else if (mxType === 'defect') {
      results = defectsArray.filter((defect: any) => defect && defect.details.toLowerCase().includes(searchString));
    } else {
      results = mxArray.filter(
        (mxItem: any) => mxItem && mxItem.mx_type.includes(mxType) && mxItem.name.toLowerCase().includes(searchString),
      );
    }
    const filteredArray = filterArray(results);

    const sortedResults = filteredArray.sort((a: SortableByDaysRemaining, b: SortableByDaysRemaining) => {
      if (b.days_remaining === null) {
        return -1;
      }
      if (a.days_remaining === null) {
        return 1;
      }
      return a.days_remaining - b.days_remaining;
    });

    updateSearchResults(sortedResults);
  };

  const handleSelectChange = async (option: { title: string; colour?: string }): Promise<void> => {
    setSelectedTypeOption(option);
  };

  const handleInputClick = (): void => {
    if (!showResults) {
      setShowResults(true);
      if (selectedTypeOption !== null) {
        const event = { target: { value: '' } };
        event.target.value = inputRef.current.value;
        handleSearch(event);
      }
    }
  };

  const handleSelectClose = (): void => {
    resetResults();
    setShowResults(false);
  };

  useEffect(() => {
    setSelectedTypeOption(selectOptions[0]);
  }, []);

  useEffect(() => {
    if (selectedTypeOption !== null) {
      const event = { target: { value: '' } };
      event.target.value = inputRef.current.value;
      handleSearch(event);
    }
  }, [selectedTypeOption]);

  useEffect(() => {
    if (showResults) document.getElementById('workpackDrawerWrapper').setAttribute('style', 'overflow: hidden');
    if (!showResults) document.getElementById('workpackDrawerWrapper').setAttribute('style', 'overflow: scroll');
  }, [showResults]);

  useEffect(() => {
    const list = document.getElementById('resultListWrapper');
    if (list) {
      const rect = list.getBoundingClientRect();
      const { height } = rect;
      const newHeight = height * -1 - 10;
      setListHeight(newHeight);
    }
  }, [showResults, searchResults]);

  return (
    <div className={styles.addItemsWrapper}>
      <div className={styles.formLabel}>Would you like to add any additional items?</div>
      <div className={styles.selectWrapper}>
        <TFSelect
          options={selectOptions}
          handleSelectChange={handleSelectChange}
          initial={selectedTypeOption}
          display="above"
        />
        <div className={styles.resultListContainer}>
          <input
            type="text"
            onChange={(e): void => handleSearch(e)}
            onClick={(): void => showResults === false && handleInputClick()}
            className={styles.searchInput}
            ref={inputRef}
          />
          {showResults && searchResults.length > 0 && (
            <TFCloseableBackground handleClose={handleSelectClose}>
              <div id="resultListWrapper" style={{ top: listHeight }} className={styles.resultListWrapper}>
                <ResultsList
                  results={searchResults}
                  addWpItem={addWpItem}
                  resetResults={resetResults}
                  setShowResults={setShowResults}
                />
              </div>
            </TFCloseableBackground>
          )}
        </div>
      </div>
    </div>
  );
};

const selectMaintenanceItems = (state: DashboardState): any => ({
  maintenance: state.maintenance,
  defects: state.defects,
});

const AddItemsToWorkpack = ({ aircraft, addWpItem, setLoadingState, wpItems }): JSX.Element => {
  const Dispatch = useDispatch();
  const [contentReady, setContentReady] = useState(false);
  const [defectsLoading, setDefectsLoading] = useState(false);
  const [mxItemsLoading, setMxItemsLoading] = useState(false);

  const aircraft_id = aircraft.id;

  const {
    maintenance: { lastFetched: maintenanceLastFetched, maintenanceMap: mxMap },
    defects: { lastFetched: defectsLastFetched, defectsMap },
  } = useSelector(selectMaintenanceItems);

  const currentDate = Date.now();

  const usePreviousDefects = (prevVal) => {
    const prevRef = useRef(null);
    useEffect(() => {
      prevRef.current = prevVal;
    });
    return prevRef.current;
  };

  const prevDefectsMap = usePreviousDefects(defectsMap);

  const usePreviousMx = (prevVal) => {
    const prevRef = useRef(null);
    useEffect(() => {
      prevRef.current = prevVal;
    });
    return prevRef.current;
  };

  const prevMxMap = usePreviousMx(mxMap);

  const usePreviousAircraftId = (prevVal) => {
    const prevRef = useRef(null);
    useEffect(() => {
      prevRef.current = prevVal;
    });
    return prevRef.current;
  };

  const prevAircraftId = usePreviousAircraftId(aircraft_id);

  useEffect(() => {
    if (!_.isEqual(mxMap, prevMxMap) || (mxItemsLoading && _.isEqual(mxMap, prevMxMap))) setMxItemsLoading(false);
  }, [mxMap]);

  useEffect(() => {
    if (!_.isEqual(defectsMap, prevDefectsMap) || (defectsLoading && _.isEqual(defectsMap, prevDefectsMap)))
      setDefectsLoading(false);
  }, [defectsMap]);

  useEffect(() => {
    if (defectsLastFetched > 0 && maintenanceLastFetched > 0) {
      setContentReady(true);
    }
  }, [defectsLastFetched, maintenanceLastFetched]);

  useEffect(() => {
    if (defectsLoading || mxItemsLoading) setLoadingState(true);
  }, [defectsLoading, mxItemsLoading]);

  useEffect(() => {
    if (
      (mxMap?.size === 0 && currentDate - maintenanceLastFetched > 30000) ||
      (currentDate - maintenanceLastFetched > 30000 && !mxItemsLoading) ||
      aircraft_id !== prevAircraftId
    ) {
      setMxItemsLoading(true);
      setContentReady(false);
      const payload = { aircraft_id, status: ['open', 'overdue'] };
      Dispatch(getAllMxItemsWorkpacks({ payload, skipDeleteAll: true }));
    }
    if (
      (defectsMap?.size === 0 && currentDate - defectsLastFetched > 30000) ||
      (currentDate - defectsLastFetched > 30000 && !defectsLoading) ||
      aircraft_id !== prevAircraftId
    ) {
      setDefectsLoading(true);
      setContentReady(false);
      const payload = { aircraft_id };
      Dispatch(getAllDefectsForWorkpacks({ payload }));
    }
  }, [aircraft_id, Dispatch, maintenanceLastFetched, defectsLastFetched]);

  useEffect(() => {
    if (contentReady) {
      setLoadingState(false);
    }
  }, [contentReady]);

  return (
    <div className={styles.addItemsToWorkpack}>
      <MXSelector mxMap={mxMap} defectsMap={defectsMap} addWpItem={addWpItem} aircraft={aircraft} wpItems={wpItems} />
    </div>
  );
};

export default AddItemsToWorkpack;
