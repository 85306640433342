import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TFPortal from '../Portal/Portal';
import chevron from '../../assets/dropdown-icon.svg';

interface TFSearchableSelectProps {
  options: { title: string; value?: string }[];
  initial: { title: string; value?: string };
  handleSelectChange: (option: { title: string; colour?: string; value?: string }) => void;
  inputWidth?: number;
}

const StyledWrapper = styled.div`
  height: ${({ height }): string => (height ? `${height}px` : '34px')};
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  color: rgba(36, 45, 65, 0.9);
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: solid 1px #126fd6;
  border-radius: 4px;
  padding: 0 10px;
  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #fff;
  border-radius: ${({ borderRadius }): string => (borderRadius ? `${borderRadius}` : '4px')};
  border: solid 1px rgba(36, 45, 65, 0.2);
`;

const InvisibleBackground = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 501;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  max-height: 320px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: #fff;
  z-index: 502;
`;

const InnerDisplayWrapper = styled.div`
  border: 1px solid rgba(36, 45, 65, 0.1);
  max-height: 320px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: #fff;
  margin-top: 9px;
`;

const TitleWrapper = styled.span`
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
`;

const SelectOption = styled.button`
  height: 32px;
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
  padding: 4px 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-align: left;
  border: none;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: rgba(18, 111, 214, 0.05);
    color: #126fd6;
  }
  &:focus {
    border: solid 1px #126fd6;
  }
  color: ${({ current }): string => current && '#126fd6'};
`;

const SelectTitle = styled.span`
  text-transform: ${({ buttonTitle }): string =>
    buttonTitle === 'llp' || buttonTitle === 'oop' ? 'upperCase' : 'unset'};
  @media (max-width: 450px) {
    overflow: auto;
    height: 22px;
  }
`;

const DisplayOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  position: relative;
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
`;

const ChevronIcon = styled.img`
  width: 12px;
  height: 7px;
  transform: ${({ searchVisible }): string => (searchVisible ? 'rotate(180deg)' : '')};
  position: ${({ searchVisible }): string => (searchVisible ? 'absolute' : 'relative')};
  right: ${({ searchVisible }): string => (searchVisible ? '10px' : 'unset')};
`;

const TFSearchableSelect: React.FC<TFSearchableSelectProps> = ({
  options,
  initial,
  inputWidth,
  handleSelectChange,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchVisible, setSearchVisible] = useState(false);
  const [chosenOption, setChosenOption] = useState(null);
  const [menuStyle, setMenuStyle] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [useableOptions, setUseableOptions] = useState([]);

  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  const handleSearchInputChange = (e): void => {
    const text = e.target.value;
    setSearchValue(text.toLowerCase());
  };

  const handleSearchButtonClick = (): void => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const { top, left, width } = rect;
      setMenuStyle({
        position: 'absolute',
        top: top + window.scrollY,
        left: left + window.scrollX,
        width,
      });
      setBackgroundStyle({ top: 0 + window.scrollY, left: 0 + window.scrollX });
    }
    setSearchVisible(true);
    setSearchValue('');
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  };

  const handleOptionSelection = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenOption(option);
    setSearchVisible(false);
    handleSelectChange(option);
  };

  const displayOptions = useableOptions.map((option) => {
    return (
      <SelectOption
        type="button"
        width={inputWidth - 20}
        key={option.title}
        data-testid={`TFSearchableSelect-${option.title}`}
        current={chosenOption && option.title === chosenOption.title}
        onClick={(): void => handleOptionSelection(option)}
      >
        <SelectTitle chosenOption={option.title}>{option.title}</SelectTitle>
      </SelectOption>
    );
  });

  useEffect(() => {
    if (searchValue) {
      const searchedOptions = [];
      options.forEach((item) => {
        if (item.title.toLowerCase().includes(searchValue)) searchedOptions.push(item);
      });
      setUseableOptions(searchedOptions);
    } else {
      setUseableOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (options) setUseableOptions(options);
  }, [options]);

  useEffect(() => {
    if (initial) {
      setChosenOption(initial);
    } else if (useableOptions.length) setChosenOption(useableOptions[0]);
  }, [initial, useableOptions]);
  return (
    <StyledWrapper width={inputWidth}>
      {searchVisible && options.length > 1 ? (
        <InputWrapper width={inputWidth}>
          <StyledInput
            type="text"
            onClick={(): void => {
              if (window.innerWidth > 450) {
                setSearchValue(chosenOption?.title);
                setSearchVisible(false);
              }
            }}
            onChange={handleSearchInputChange}
            value={searchValue}
            ref={inputRef}
            width={inputWidth}
          />
          <ChevronIcon src={chevron} alt="chevron" searchVisible={searchVisible} />
        </InputWrapper>
      ) : (
        <StyledButton
          data-testid="TFSearchableSelect-SelectInput"
          type="button"
          onClick={options.length > 1 ? handleSearchButtonClick : undefined}
          ref={buttonRef}
        >
          <TitleWrapper width={inputWidth}>{chosenOption?.title}</TitleWrapper>
          {options.length > 1 && <ChevronIcon src={chevron} alt="chevron" searchVisible={searchVisible} />}
        </StyledButton>
      )}
      {searchVisible ? (
        <TFPortal>
          <InvisibleBackground
            style={backgroundStyle}
            onClick={(e): void => {
              e.stopPropagation();
              setSearchVisible(false);
              setSearchValue('');
            }}
          />
          {useableOptions.length > 0 ? (
            <OptionsWrapper style={menuStyle}>
              <InputWrapper>
                <StyledInput
                  type="text"
                  onClick={(): void => {
                    if (window.innerWidth > 450) {
                      setSearchValue(chosenOption?.title);
                      setSearchVisible(false);
                    }
                  }}
                  onChange={handleSearchInputChange}
                  value={searchValue}
                  ref={inputRef}
                />
                <ChevronIcon src={chevron} alt="chevron" searchVisible={searchVisible} />
              </InputWrapper>
              <InnerDisplayWrapper>
                <DisplayOptionsWrapper>{displayOptions}</DisplayOptionsWrapper>
              </InnerDisplayWrapper>
            </OptionsWrapper>
          ) : null}
        </TFPortal>
      ) : null}
    </StyledWrapper>
  );
};

export default TFSearchableSelect;
