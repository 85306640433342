import { combineReducers } from 'redux';
import aircraft from './aircraft';
import aircraftDocuments from './aircraftDocuments';
import aircraftLogbook from './aircraftLogbook';
import airports from './airports';
import damageMaps from './damageMaps';
import damageReports from './damageReports';
import defects from './defects';
import documentTypes from './documentTypes';
import flights from './flights';
import fuel from './fuel';
import maintenance from './maintenance';
import mels from './mels';
import menu from './menu';
import drawer from './drawer';
import operations from './operations';
import organisationDocuments from './organisationDocuments';
import people from './people';
import subscriptions from './subscriptions';
import trips from './trips';
import userSettings from './userSettings';
import workpacks from './workpacks';

const reducers = {
  aircraft,
  aircraftDocuments,
  aircraftLogbook,
  airports,
  damageMaps,
  damageReports,
  defects,
  documentTypes,
  flights,
  fuel,
  maintenance,
  mels,
  menu,
  drawer,
  operations,
  organisationDocuments,
  people,
  subscriptions,
  trips,
  userSettings,
  workpacks,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;

export type DashboardState = ReturnType<typeof rootReducer>;

export interface BaseModel {
  lastFetched: number;
}
