import { Loading } from '@arcflight/tf-component-library';
import React from 'react';
import TFSearchableSelect from '../TFSearchableSelect/TFSearchableSelect';

interface SelectPilotProps {
  people: any[];
  handlePersonChange: (id: string) => void;
  pilotFlying: { title: string; value: string };
  width?: number;
  loading?: boolean;
}

const SelectPilot: React.FC<SelectPilotProps> = ({ people, handlePersonChange, pilotFlying, width, loading }) => {
  const handleChange = (option: { title: string; value: string }): void => {
    handlePersonChange(option.value);
  };
  return (
    <>
      {loading ? (
        <Loading contain size={30} />
      ) : (
        <TFSearchableSelect
          inputWidth={width || 285}
          options={people}
          handleSelectChange={handleChange}
          initial={pilotFlying}
        />
      )}
    </>
  );
};

export default SelectPilot;
