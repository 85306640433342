import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import greenTick from '../../../assets/complete-icon.svg';
import shareIcon from '../../../assets/link-share.svg';
import StatusBadge from '../../Status/StatusBadge';
import styles from '../workpackDrawer.module.less';
import TFPopover from '../../TFPopover/TFPopover';
import { DashboardState } from '../../../models';

const WorkpackInfo = ({ dateCreated, status, dateCompleted, publicLink }): JSX.Element => {
  const [popover, setPopover] = useState(false);

  const { formatMessage } = useIntl();

  const {
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState): any => ({
    userSettings: state.userSettings,
  }));

  const handlePopupClick = (): void => {
    setPopover(true);
    const url = publicLink;
    const el = document.createElement('textarea');
    el.value = url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handlePopoverClose = (): void => {
    setPopover(false);
  };

  return (
    <>
      <div className={styles.workpackInfo}>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>{formatMessage({ id: 'text.crsAcceptance' })}</span>
          <span className={styles.statusInfo}>
            <StatusBadge status={status} /> {status}
          </span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>{formatMessage({ id: 'text.engineeringWork' })}</span>
          <span className={styles.statusInfo}>
            <StatusBadge status={status === 'pending' ? 'completed' : status} />{' '}
            {status === 'pending' ? 'completed' : status}
          </span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>{formatMessage({ id: 'text.dateCreated' })}</span>
          <span>{moment(dateCreated, 'YYYY-MM-DD').format(dateFormat)}</span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>{formatMessage({ id: 'text.dateCompleted' })}</span>
          <span>
            {(dateCompleted && moment(dateCompleted.replace(/T.*/gm, ''), 'YYYY-MM-DD').format(dateFormat)) || '-'}
          </span>
        </div>
        {status !== 'draft' ? (
          <div className={`${styles.rowItem} ${styles.publicLink}`}>
            <button type="button" onClick={handlePopupClick} id="publicLink" className={styles.publicLinkWrapper}>
              <img src={shareIcon} alt="" /> {formatMessage({ id: 'text.publicShareLink' })}
            </button>
          </div>
        ) : null}
      </div>
      {popover ? (
        <TFPopover right="36px" top="143px" onClick={handlePopoverClose}>
          <div className={styles.popoverContainer}>
            <div className={styles.imageContainer}>
              <img src={greenTick} alt="Green Tick" />
            </div>
            <div className={styles.clipboardText}>{formatMessage({ id: 'text.copiedToClipboard' })}</div>
            <div className={styles.linkText}>{publicLink}</div>
            <div className={styles.popoverText}>{formatMessage({ id: 'text.validFor30daysWorkpack' })}</div>
          </div>
        </TFPopover>
      ) : null}
    </>
  );
};

export default WorkpackInfo;
