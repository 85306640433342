import { Table, Button, Dropdown, Menu, Icon } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Loading, Modal, Button as TFButton } from '@arcflight/tf-component-library';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import EmptyState from '../../components/EmptyState/EmptyState';
import { fetchAircraftPeople } from '../../models/aircraft/actions';
import ImageAvatar from '../../components/ImageAvatar';
import emptyCrewSearch from '../../assets/emptyState/empty-state-crew.svg';
import PageOverlayAction from '../../components/PageOverlayAction';
import styles from './SettingsCrewList.module.less';

class SettingsCrewList extends Component {
  static propTypes = {
    crew: PropTypes.array,
    intl: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isCrewSearched: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    resetFilters: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isCrewSearched: false,
    crew: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      modalVisible: false,
      deleteModalId: null,
    };
  }

  componentDidMount() {
    this.getAircraftPeople();
  }

  getAircraftPeople = () => {
    const { dispatch, selectedAircraft } = this.props;
    if (selectedAircraft) {
      const payload = selectedAircraft.id;
      dispatch(fetchAircraftPeople({ payload }));
    }
  };

  confirmDelete = (id) => {
    const { selectedRowKeys } = this.state;
    const index = selectedRowKeys.indexOf(id);
    if (index) selectedRowKeys.splice(index, 1);
    this.setState({ modalVisible: true, deleteModalId: id, selectedRowKeys });
  };

  handleDeleteModalConfirm = () => {
    const { deleteModalId } = this.state;
    const { handleDelete } = this.props;
    handleDelete(deleteModalId);
    this.setState({ modalVisible: false, deleteModalId: null });
  };

  handleModalClose = () => {
    this.setState({ modalVisible: false, deleteModalId: null });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getTableColumns = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const columns = [
      {
        title: formatMessage({ id: 'title.crew' }),
        dataIndex: 'crew',
        width: 220,
        className: 'crewColumn',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
        render: (text, record) => (
          <div className={styles.noWrap}>
            <div className={styles.avatarContainer}>
              <ImageAvatar avatar_url={record.avatar_url} avatarUrl={record.avatar_url} user={record} />
            </div>
            <div className={styles.nameContainer}>{`${record.first_name} ${record.last_name}`}</div>
          </div>
        ),
      },
      {
        title: formatMessage({ id: 'title.email' }),
        dataIndex: 'email',
        width: 300,
        className: 'emailColumn',
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (text) => <div className={styles.noWrap}>{text}</div>,
      },
      {
        title: formatMessage({ id: 'title.role' }),
        dataIndex: 'position',
        className: 'roleColumn',
        sorter: (a, b) => a.position.localeCompare(b.position),
      },
      {
        title: formatMessage({ id: 'title.lastFlown' }),
        dataIndex: 'last_flown',
        className: 'lastFlownColumn',
        sorter: (a, b) => moment(a.last_flown) - moment(b.last_flown),
        render(date) {
          return date ? moment(date).fromNow() : '';
        },
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <div className={styles.editableRowOperations}>
              <Dropdown
                placement="bottomLeft"
                overlay={
                  <Menu>
                    <Menu.Item key="delete" onClick={() => this.confirmDelete(record.id)}>
                      {formatMessage({ id: 'form.button.remove' })}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="link">
                  <Icon className={styles.icon} type="more" />
                </Button>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  handleMultipleRemove = () => {
    const { handleDelete } = this.props;
    this.state.selectedRowKeys.forEach((id) => {
      handleDelete(id);
    });
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      crew,
      intl: { formatMessage },
      isCrewSearched,
      loading,
      resetFilters,
    } = this.props;

    const { selectedRowKeys, modalVisible } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    return (
      <div className={styles.crewTableDiv}>
        <PageOverlayAction
          visible={selectedRowKeys.length > 0}
          type="crew"
          items={selectedRowKeys}
          onSuccess={() => this.handleMultipleRemove()}
          onCancel={() => this.setState({ selectedRowKeys: [] })}
        />
        <div>
          <Loading loading={loading} contain />
          {crew.length ? (
            <Table
              columns={this.getTableColumns()}
              data-test="crewTable"
              dataSource={crew}
              scroll={{ x: 760 }}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              pagination={false}
            />
          ) : (
            <EmptyState
              image={emptyCrewSearch}
              text={isCrewSearched ? "We couldn't find any matching crew members" : 'No crew members'}
              subText={
                isCrewSearched
                  ? 'Try searching with another term.'
                  : 'Add your first crew member by searching for their name'
              }
              button={isCrewSearched ? 'Clear all' : null}
              buttonAction={isCrewSearched ? resetFilters : null}
            />
          )}
        </div>
        <Modal isOpen={modalVisible} width={420} handleClose={() => this.setState({ modalVisible: false })}>
          <div className={styles.modalContentWrapper}>
            <div className={styles.modalTitle}>{formatMessage({ id: 'title.removeCrew' })}</div>
            <div className={styles.modalMessage}>{formatMessage({ id: 'form.question.areYouSureRemoveCrew' })}</div>
            <div className={styles.modalButtonWrapper}>
              <div className={styles.submitButton}>
                <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={this.handleDeleteModalConfirm}>
                  {formatMessage({ id: 'form.button.remove' })}
                </TFButton>
              </div>
              <TFButton
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                primary={false}
                onClick={() => this.setState({ modalVisible: false })}
              >
                Cancel
              </TFButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default injectIntl(connect()(SettingsCrewList));
