import { BaseModel } from '..';

const initialState: AircraftLogbookState = {
  data: [],
  selectableColumns: [],
  chosenColumns: [],
};

export enum AircraftLogbookActionTypes {
  SAVE = 'aircraftLogbook/save',
}

export interface SaveAircraftLogbookActionTypes {
  type: AircraftLogbookActionTypes.SAVE;
  payload: AircraftLogbookState;
}

export default function(state = initialState, action: SaveAircraftLogbookActionTypes): AircraftLogbookState {
  switch (action.type) {
    case AircraftLogbookActionTypes.SAVE: {
      return {
        ...state,
        data: action?.payload?.data,
        selectableColumns: action?.payload?.selectableColumns,
        chosenColumns: action?.payload?.chosenColumns,
      };
    }
    default:
      return state;
  }
}

export interface AircraftLogbookState {
  data: AircraftLogbook[];
  selectableColumns: [];
  chosenColumns: [];
}

export interface AircraftLogbook extends BaseModel {
  columns: string[];
  entries: {}[];
  selectable_columns: string[];
  totals: {
    brought_forward: {};
    carried_forward: {};
  };
  first_trip: string;
}
