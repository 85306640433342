import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

interface TFToggleProps {
  active: boolean;
  handleToggle: (value: boolean) => void;
}

const slideRight = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: 15px;
  }
`;

const slideLeft = keyframes`
  0% {
    left: 15px;
  }
  100% {
    left: 0;
  }
`;

const slideRightAnimation = css`
  animation: ${slideRight} 0.3s;
`;

const slideLeftAnimation = css`
  animation: ${slideLeft} 0.3s;
`;

const Slider = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  border: solid 0.5px #dedede;
  border-radius: 50%;
  background-color: #fff;
  left: ${({ checked }): string => (checked ? '15px' : '0')};
  ${(props): string =>
    props.checked &&
    css`
      ${slideRightAnimation}
    `};
  ${(props): string =>
    !props.checked &&
    css`
      ${slideLeftAnimation}
    `}
`;

const ToggleWrapper = styled.button`
  width: 34px;
  height: 20px;
  background-color: ${({ checked }): string => (checked ? '#126fd6' : '#d3d3d3')};
  border-radius: 25px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: none;
`;

const TFToggle: React.FC<TFToggleProps> = ({ active, handleToggle }) => {
  const [checked, setChecked] = useState(false);

  const handleClick = (): void => {
    setChecked(!checked);
    handleToggle(!checked);
  };

  useEffect(() => {
    setChecked(active);
  }, [active]);

  return (
    <ToggleWrapper type="button" onClick={handleClick} checked={checked}>
      <Slider checked={checked} />
    </ToggleWrapper>
  );
};

export default TFToggle;
