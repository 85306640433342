import React from 'react';
import styled from 'styled-components';
import FlexWrapper from '../DefectDrawer/components/FlexWrapper';
import QuestionMarkIcon from '../../assets/icon-tooltip.svg';

interface TFTooltipsProps {
  handleMouseOver: () => void;
  handleMouseLeave: () => void;
  showToolTip: boolean;
  left?: number;
}

const StyledDiv = styled.div`
  position: relative;
`;

const TooltipDiv = styled.div`
  width: max-content;
  font-size: 10px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  z-index: 1;
  max-width: 350px;
`;

const TFTooltip: React.FC<TFTooltipsProps> = ({ children, handleMouseOver, handleMouseLeave, showToolTip, left }) => {
  return (
    <StyledDiv
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onBlur={handleMouseLeave}
    >
      <img src={QuestionMarkIcon} alt="question mark" />
      {showToolTip && (
        <FlexWrapper column position="absolute" left={left || 0} top={20}>
          <TooltipDiv>{children}</TooltipDiv>
        </FlexWrapper>
      )}
    </StyledDiv>
  );
};

export default TFTooltip;
