import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';
import StyledRadioButton from './StyledRadioButton';

interface AircraftLimitationProps {
  defect: Defect | null;
  editDefect: boolean;
  updateDefectData?: (changes: any[]) => void;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const AircraftLimitations: React.FC<AircraftLimitationProps> = ({ defect, editDefect, updateDefectData }) => {
  const [isLimitations, setIsLimitations] = useState(false);
  const [limitation, setLimitation] = useState(null);

  const { formatMessage } = useIntl();

  const isMobile = window.innerWidth < 451;

  useEffect(() => {
    if (defect) {
      if (defect?.limitations) {
        setIsLimitations(true);
        setLimitation(defect.limitations);
      } else {
        setIsLimitations(false);
        setLimitation('');
      }
    }
  }, [defect]);
  return (
    <Wrapper>
      <FlexWrapper column={editDefect} marginTop={editDefect ? 0 : 30} marginBottom={20}>
        <Label marginBottom={editDefect ? 20 : 0} fontWeight={600} marginRight={5}>
          {formatMessage({ id: 'form.labels.aircraftLimitations' })}
        </Label>
        <Label marginBottom={10} fontWeight={500}>
          {formatMessage({ id: 'text.areThereAnyLimitations' })}
        </Label>
        <FlexWrapper>
          <StyledRadioButton
            id="yesLimit"
            group="limitationsPresent"
            value="Yes"
            marginRight={5}
            checked={isLimitations}
            onClick={(): void => setIsLimitations(true)}
          />
          <StyledRadioButton
            id="noLimit"
            group="limitationsPresent"
            value="No"
            checked={!isLimitations}
            onClick={(): void => setIsLimitations(false)}
          />
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper column={editDefect}>
        {isLimitations ? (
          <FlexWrapper column marginBottom={20} marginRight={!editDefect && 100}>
            <Label marginBottom={5} fontWeight={500}>
              {formatMessage({ id: 'title.limitations' })}
            </Label>
            <StyledInput
              id="limitations"
              type="text"
              width={isMobile ? 300 : 400}
              value={limitation}
              onChange={(e): void => {
                setLimitation(e.target.value);
                updateDefectData([{ value: e.target.value, key: 'limitations' }]);
              }}
            />
          </FlexWrapper>
        ) : null}
      </FlexWrapper>
    </Wrapper>
  );
};

export default AircraftLimitations;
