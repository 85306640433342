import { Card, Col, message, Row } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _, { debounce } from 'lodash';
import Sticky from 'react-stickynode';
import { Button, FilterSideBar, Search } from '@arcflight/tf-component-library';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import Loading from '../../components/TFLoading';
import MXItemsImporter from '../../components/MXItemsImporter';
import ListWrapper from '../../components/MXManagementList/listWrapper';
import PageOverlayAction from '../../components/PageOverlayAction';
import SlidingDrawer from '../../components/SlidingDrawer';
import CampSyncButton from '../../components/CampSyncButton';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { Aircraft, AircraftState } from '../../models/aircraft';
import { getSingleAircraft } from '../../models/aircraft/actions';
import globalStyles from '../../utils/globalStyles.module.less';
import { MaintenanceState } from '../../models/maintenance';
import MXModal from '../../components/Modals/Maintenance/MXModal';
import plusIcon from '../../assets/plus.svg';
import filtersIcon from '../../assets/filters.svg';
import { getWorkpacks } from '../../services/api';
import { syncWithCamp, getMxItems, getMxItemsTotals } from '../../services/apiNew';
import MXModalTolerance from '../../components/Modals/Maintenance/MXModalTolerance';
import MXDraftQueue from '../../components/Modals/Maintenance/MXDraftQueue/MXDraftQueue';
import BooleanFilter from '../../components/FiltersSidebar/BooleanFilter';
import { Workpack } from '../../models/workpacks';
import styles from './MaintenanceManagement.module.less';

// MX Periods to be shown in dropdown
export enum MX_LIST_PERIODS {
  CRITICAL = 'crit',
  OVERDUE = 0,
  NEXT_DAY = 1,
  NEXT_3_DAYS = 3,
  NEXT_10_DAYS = 10,
  NEXT_30_DAYS = 30,
  NEXT_60_DAYS = 60,
  NEXT_120_DAYS = 120,
  NEXT_365_DAYS = 365,
  ALL_RECORDS = 1000000,
}

const MX_PERIODS = [
  {
    days: 3,
    listPeriods: [MX_LIST_PERIODS.OVERDUE, MX_LIST_PERIODS.NEXT_DAY, MX_LIST_PERIODS.NEXT_3_DAYS],
  },
  {
    days: 10,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
    ],
  },
  {
    days: 30,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
    ],
  },
  {
    days: 60,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
    ],
  },
  {
    days: 120,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
      MX_LIST_PERIODS.NEXT_120_DAYS,
    ],
  },
  {
    days: 1000000,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
      MX_LIST_PERIODS.NEXT_120_DAYS,
      MX_LIST_PERIODS.NEXT_365_DAYS,
      MX_LIST_PERIODS.ALL_RECORDS,
    ],
  },
];

const DEFAULT_PAGE = 1;
const DEFAULT_PER = 10;
const DEBOUNCE_MILLISECONDS = 300;

class MaintenanceManagement extends Component<MaintenanceManagementProps, MaintenanceManagementState> {
  handleSearchChange = debounce((searchTerm) => {
    const { filterParams } = this.state;
    const newFilterParams = { ...filterParams };
    newFilterParams.page = 1;
    const formattedSearchTerm = searchTerm === '' ? null : searchTerm;

    newFilterParams.search_term = formattedSearchTerm;

    this.setState({ loading: true, filterParams: newFilterParams });
  }, DEBOUNCE_MILLISECONDS);

  handlePaginationChange = debounce((page, per) => {
    const { filterParams } = this.state;
    const newFilterParams = { ...filterParams };
    const previousPer = newFilterParams.per;

    if (per !== previousPer) {
      newFilterParams.page = 1;
      newFilterParams.per = per;
    } else {
      newFilterParams.page = page;
    }

    // TODO: fix - currently the pagination component triggers an event on blur that
    //  results in the same value being returned
    if (JSON.stringify(newFilterParams) !== JSON.stringify(filterParams)) {
      this.setState({ loading: true, filterParams: newFilterParams });
    }
  }, DEBOUNCE_MILLISECONDS);

  constructor(props) {
    super(props);
    this.state = {
      // default maintenance period for MX filter dropdown in maintenance timeline
      currentOperator: undefined,
      mxPeriodDays: 1000000,
      selectedMXItems: [],
      loading: true,
      modalVisible: false,
      modalToleranceVisible: false,
      modalDraftsVisible: false,
      editedItem: null,
      synchronising: false,
      passedMxItemId: null,
      openMxItems: [],
      filterParams: {
        aircraft_id: this.getAircraft()?.id,
        page: DEFAULT_PAGE,
        per: DEFAULT_PER,
        start_date: null,
        end_date: null,
        search_term: null,
        status: [],
        item_type: [],
        area: [],
        source: [],
        limits: [],
        planning: [],
        tolerance: [],
      },
      filterGroups: [],
      total: 0,
      totalsCount: 0,
      draftCount: 0,
      draftItems: [],
      isForcingRefresh: false,
      expandAll: false,
      workpacks: [],
      showFiltersDrawer: false,
      reset: false,
    };
  }

  componentDidMount(): void {
    const {
      location: { state },
      ttl,
      userSettings,
    } = this.props;

    const aircraft = this.getAircraft();
    const { operators } = userSettings.details;
    const currentOperator = operators.find((operator) => {
      return operator.id === aircraft?.operator_id;
    });

    this.setState({ currentOperator });

    if (!aircraft || Date.now() - aircraft.lastFetched > ttl) {
      this.getAircraft(true);
    }

    this.fetchTotalsAndMxItems(state?.id);
    this.getWorkpacks();
  }

  componentDidUpdate(prevProps, prevState): void {
    const {
      aircraftMap,
      match: {
        params: { id },
      },
    } = this.props;
    const { modalVisible } = this.state;

    aircraftMap.get(id);
    if (aircraftMap.get(id) !== prevProps.aircraftMap.get(id)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
      });
    }

    // TODO: extract to method
    const { isForcingRefresh, filterParams } = this.state;
    const paramsHaveChanged = JSON.stringify(prevState.filterParams) !== JSON.stringify(filterParams);

    if (isForcingRefresh) {
      this.fetchTotalsAndMxItems();
    } else if (paramsHaveChanged) {
      this.fetchOpenMxItems();
    }

    if (modalVisible) {
      document.querySelector('body').setAttribute('style', 'overflow: hidden');
    } else {
      document.querySelector('body').setAttribute('style', 'overflow: scroll');
    }
  }

  componentWillUnmount(): void {
    this.setPassedMxItemId(null);
  }

  setLoading(isLoading: boolean): void {
    this.setState({ loading: isLoading, reset: false });
  }

  fetchTotalsAndMxItems = (selectedMxItemId?: string): void => {
    const params = { aircraft_id: this.getAircraft()?.id };

    this.setState({ isForcingRefresh: false });

    getMxItemsTotals(params)
      .then((res) => {
        const totals = res.data;
        const draftCount = totals.draft;
        this.setState({ draftCount, totalsCount: totals.total });
        this.setFilterGroups(totals);
        this.fetchOpenMxItems(selectedMxItemId);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        console.error(err);
        this.setLoading(false);
      });
  };

  setFilterGroups = (totals): void => {
    const aircraft = this.getAircraft() as any;
    const engineCount = aircraft.aircraft_type.engine_count;
    const engineFilters = [];

    for (let i = 1; i <= engineCount; i += 1) {
      const engineFilter = {
        label: `Engine ${i}`,
        key: 'area',
        value: `engine_${i}`,
      };

      engineFilters.push(engineFilter);
    }

    const areaFilters = [
      {
        label: 'Airframe',
        key: 'area',
        value: 'airframe',
        count: totals.area.airframe,
      },
      {
        label: 'Engine',
        key: 'area',
        count: totals.area.airframe,
        childFilters: engineFilters,
      },
    ];

    if (aircraft.apu_installed) {
      const apuFilter = {
        label: 'APU',
        key: 'area',
        value: 'apu',
        count: totals.area.apu,
      };

      areaFilters.push(apuFilter);
    }

    const filterGroups = [
      {
        groupLabel: 'Due in',
        filters: [
          {
            label: 'Next 7 days',
            key: 'date',
            value: 7,
            radio: true,
            count: totals.showing.seven,
          },
          {
            label: 'Next 14 days',
            key: 'date',
            value: 14,
            radio: true,
            count: totals.showing.fourteen,
          },
          {
            label: 'Next 30 days',
            key: 'date',
            value: 30,
            radio: true,
            count: totals.showing.thirty,
          },
          {
            label: 'Next 90 days',
            key: 'date',
            value: 90,
            radio: true,
            count: totals.showing.ninety,
          },
        ],
      },
      {
        groupLabel: 'Item Status',
        filters: [
          {
            label: 'Overdue',
            key: 'status',
            value: 'overdue',
            count: totals.item_status.overdue,
          },
          {
            label: 'In Tolerance',
            key: 'status',
            value: 'critical',
            count: totals.item_status.in_tolerance,
          },
          {
            label: 'Open',
            key: 'status',
            value: 'open',
            count: totals.item_status.open,
          },
          {
            label: 'Resolved',
            key: 'status',
            value: 'resolved',
            count: totals.item_status.resolved,
          },
        ],
      },
      {
        groupLabel: 'Item Type',
        filters: [
          {
            label: 'Package',
            key: 'item_type',
            value: 'package',
            count: totals.item_type.package,
          },
          {
            label: 'Scheduled',
            key: 'item_type',
            value: 'scheduled',
            count: totals.item_type.scheduled,
          },
          {
            label: 'Out of Phase',
            key: 'item_type',
            value: 'oop',
            count: totals.item_type.oop,
          },
          {
            label: 'Life Limited Parts',
            key: 'item_type',
            value: 'llp',
            count: totals.item_type.llp,
          },
        ],
      },
      {
        groupLabel: 'Area',
        filters: areaFilters,
      },
      {
        groupLabel: 'Source',
        filters: [
          {
            label: 'CAMP',
            key: 'source',
            value: 'camp',
            count: totals.source.camp,
            filterFunction: (item): boolean => item.status === 'camp',
          },
          {
            label: 'TrustFlight',
            key: 'source',
            value: 'trustflight',
            count: totals.source.trustflight,
            filterFunction: (item): boolean => item.status === 'trustflight',
          },
        ],
      },
      {
        groupLabel: 'Limits',
        filters: [
          {
            label: 'Cycles',
            key: 'limits',
            value: 'cycles',
            count: totals.limits.cycles,
            filterFunction: (item): boolean => item.status === 'cycles',
          },
          {
            label: 'Days',
            key: 'limits',
            value: 'days',
            count: totals.limits.days,
            filterFunction: (item): boolean => item.status === 'days',
          },
          {
            label: 'Flight Hours',
            key: 'limits',
            value: 'flight_hours',
            count: totals.limits.flight_hours,
            filterFunction: (item): boolean => item.status === 'flight_hours',
          },
          {
            label: 'APU Hours',
            key: 'limits',
            value: 'apu_hours',
            count: totals.limits.apu_hours,
            filterFunction: (item): boolean => item.status === 'apu_hours',
          },
        ],
      },
      {
        groupLabel: 'Planning',
        filters: [
          {
            label: 'Planned in Workpack',
            key: 'planning',
            value: 'planned',
            count: totals.planning.planned,
            filterFunction: (item): boolean => item.status === 'planned',
          },
          {
            label: 'Unplanned',
            key: 'planning',
            value: 'unplanned',
            count: totals.planning.unplanned,
            filterFunction: (item): boolean => item.status === 'unplanned',
          },
        ],
      },
      {
        groupLabel: 'Tolerance',
        filters: [
          {
            label: 'Tolerance Applied',
            key: 'tolerance',
            value: 'applied',
            count: totals.tolerance.applied,
            filterFunction: (item): boolean => item.status === 'applied',
          },
          {
            label: 'Tolerance Not Applied',
            key: 'tolerance',
            value: 'notapplied',
            count: totals.tolerance.notapplied,
            filterFunction: (item): boolean => item.status === 'notapplied',
          },
        ],
      },
    ];

    this.setState({ filterGroups });
  };

  setPassedMxItemId = (id): void => {
    this.setState({ passedMxItemId: id });
  };

  handleDateChange = (dateRange: any[], days: any): void => {
    const endDate = dateRange[1];
    const { filterParams } = this.state;
    const newfilterParams = { ...filterParams };

    newfilterParams.end_date = days === 1000000 ? null : endDate;

    this.setState({ loading: true, filterParams: newfilterParams, mxPeriodDays: days });
  };

  onItemSelect = (value, itemID): void => {
    const { selectedMXItems: activeMXItems } = this.state;

    if (value) {
      activeMXItems.push(itemID);
    } else {
      const itemIndex = activeMXItems.indexOf(itemID);
      if (itemIndex > -1) {
        activeMXItems.splice(itemIndex, 1);
      }
    }
    this.setState({
      selectedMXItems: [...new Set(activeMXItems)],
    });
  };

  getAircraft = (
    forceRefetch = false,
  ): {
    id: string;
    registration: string;
    lastFetched: number;
    camp_integration_enabled: boolean;
    camp_last_synced: string;
    operator_id: string;
  } => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (forceRefetch) {
      this.setState(
        {
          loading: true,
        },
        () => this.props.fetchAircraft(id),
      );
    }
    return this.props.aircraftMap.get(id);
  };

  getWorkpacks = async (): Promise<any> => {
    const { match } = this.props;
    const payload = {
      aircraft_id: match.params.id,
    };
    try {
      const res = await getWorkpacks(payload);
      const completedWorkpacks = res.workpacks.filter((w) => w.status !== 'complete');
      this.setState({ workpacks: completedWorkpacks });
    } catch (err) {
      console.error(err);
    }
  };

  getItemsSeparatedByStatus = (items): { overdueItems; criticalItems; standardItems; resolvedItems } => {
    const overdueItems = [];
    const criticalItems = [];
    const resolvedItems = [];
    const standardItems = [];

    items.forEach((item) => {
      if (item.status === 'overdue') {
        overdueItems.push(item);
      } else if (item.status === 'critical') {
        criticalItems.push(item);
      } else if (item.status === 'resolved') {
        resolvedItems.push(item);
      } else {
        standardItems.push(item);
      }
    });

    return { overdueItems, criticalItems, standardItems, resolvedItems };
  };

  onDraftsSuccess = (): void => {
    this.forceRefetchofMxItems();
  };

  deselectAllItems = (): void => {
    this.setState({ selectedMXItems: [] });
  };

  modalSuccess = (estimatedDate, payload): void => {
    // const { mxPeriodDays } = this.state;
    // const today = moment().startOf('day');
    // const daysUntilNewMXItem = moment(estimatedDate).diff(today, 'days');
    // let days = mxPeriodDays;

    // if (daysUntilNewMXItem >= 0 && daysUntilNewMXItem <= 3) {
    //   days = 3;
    // } else if (daysUntilNewMXItem > 3 && daysUntilNewMXItem <= 10) {
    //   days = 10;
    // } else if (daysUntilNewMXItem > 10 && daysUntilNewMXItem <= 30) {
    //   days = 30;
    // } else if (daysUntilNewMXItem > 30 && daysUntilNewMXItem <= 60) {
    //   days = 60;
    // } else if (daysUntilNewMXItem > 60 && daysUntilNewMXItem <= 120) {
    //   days = 120;
    // } else {
    //   days = 100000;
    // }
    // TODO: follow the above logic by extracting the date ranges and resetting state

    this.forceRefetchofMxItems();
  };

  forceRefetchofMxItems = (): void => {
    this.setState({ isForcingRefresh: true, loading: true, selectedMXItems: [] });
  };

  hideModal = (): void => {
    this.setState({
      modalVisible: false,
      modalToleranceVisible: false,
      modalDraftsVisible: false,
      editedItem: null,
    });
  };

  onToleranceChange = (): void => {
    this.hideModal();
    this.setState({ isForcingRefresh: true, loading: true });
  };

  onEdit = (item): void => {
    this.setState({
      modalVisible: true,
      editedItem: item,
    });
  };

  onApplyTolerance = (item): void => {
    this.setState({
      modalToleranceVisible: true,
      editedItem: item,
    });
  };

  showMXDraftQueue = (): void => {
    this.setState({ modalDraftsVisible: true });
  };

  toggleFiltersDrawer = (): void => {
    const { showFiltersDrawer } = this.state;
    this.setState({ showFiltersDrawer: !showFiltersDrawer });
  };

  handleSyncButtonClick = (): void => {
    this.setState({ synchronising: true });
    const {
      match: {
        params: { id },
      },
    } = this.props;
    syncWithCamp(id)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          this.setState({ synchronising: false, loading: true, isForcingRefresh: true }, () => {
            this.getAircraft(true);
          });
        } else {
          message.error('Unable to synchronise. Please try again');
        }
      })
      .catch(() => {
        message.error('Unable to synchronise. Please try again');
        this.setState({ synchronising: false });
      });
  };

  handleFilterChange = (filtersArray): void => {
    const filterableKeys = ['item_type', 'area', 'status', 'source', 'limits', 'planning', 'tolerance', 'date'];
    const { filterParams } = this.state;
    const newFilterParams = { ...filterParams };
    newFilterParams.page = 1;

    filterableKeys.forEach((key) => {
      const filterValuesForKey = filtersArray.filter((filter) => filter.key === key).map((filter) => filter.value);

      newFilterParams[key] = filterValuesForKey;
    });
    if (!_.isEqual(filterParams, newFilterParams)) {
      this.setState({ loading: true, filterParams: newFilterParams });
    }
  };

  toggleExpandAll = (valueKey: any): void => {
    const { expandAll } = this.state;

    this.setState({ expandAll: !expandAll });
  };

  handleAddItem = (): void => {
    this.setState({ modalVisible: true });
  };

  resetFilters = (): void => {
    this.setState({
      filterParams: {
        aircraft_id: this.getAircraft()?.id,
        page: DEFAULT_PAGE,
        per: DEFAULT_PER,
        start_date: null,
        end_date: null,
        search_term: null,
        status: [],
        item_type: [],
        area: [],
        source: [],
        limits: [],
        planning: [],
        tolerance: [],
      },
      reset: true,
    });
  };

  fetchOpenMxItems(selectedMxItemId?: string): void {
    const { filterParams } = this.state;

    getMxItems(filterParams)
      .then((res) => {
        const openMxItems = res.data.scheduled_mx_items;
        const { total } = res.data;
        const loading = false;
        const isForcingRefresh = false;

        const stateUpdate = { loading, openMxItems, total, isForcingRefresh } as any;

        if (selectedMxItemId) {
          stateUpdate.passedMxItemId = selectedMxItemId;
        }

        this.setState(stateUpdate);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.error(err);
        this.setLoading(false);
      });
  }

  render(): object {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      mxPeriodDays,
      loading,
      selectedMXItems,
      modalVisible,
      editedItem,
      modalToleranceVisible,
      modalDraftsVisible,
      synchronising,
      passedMxItemId,
      total,
      draftCount,
      draftItems,
      filterGroups,
      openMxItems,
      filterParams,
      expandAll,
      showFiltersDrawer,
      workpacks,
      currentOperator,
      reset,
      totalsCount,
    } = this.state;
    const aircraft = this.getAircraft();
    const filteredItems = this.getItemsSeparatedByStatus(openMxItems);
    const filterSearchActive =
      (filterParams.search_term && filterParams.search_term.length > 0) ||
      filterParams.status.length > 0 ||
      filterParams.item_type.length > 0 ||
      filterParams.area.length > 0 ||
      filterParams.source.length > 0 ||
      filterParams.limits.length > 0 ||
      filterParams.planning.length > 0 ||
      filterParams.tolerance.length > 0;

    const onFailedParentToggle = (): void => {
      message.warning(formatMessage({ id: 'message.deselectItems' }));
    };
    let filtersActive = false;
    if (
      !_.isEqual(filterParams, {
        aircraft_id: this.getAircraft()?.id,
        page: DEFAULT_PAGE,
        per: DEFAULT_PER,
        start_date: null,
        end_date: null,
        search_term: null,
        status: [],
        item_type: [],
        area: [],
        source: [],
        limits: [],
        planning: [],
        tolerance: [],
      })
    ) {
      filtersActive = true;
    }
    return (
      <>
        <InnerMenuLayout>
          <Loading contain topPosition={60} loading={loading} />
          <PageOverlayAction
            visible={selectedMXItems ? selectedMXItems.length > 0 : false}
            type="workpack"
            workpacks={workpacks}
            onSuccess={this.forceRefetchofMxItems}
            items={selectedMXItems}
            aircraft={aircraft}
            onCancel={this.deselectAllItems}
            data-test="pageOverlayWorkpack"
          />
          <MXModal
            aircraft={aircraft}
            visible={modalVisible}
            hideModal={this.hideModal}
            onSuccess={this.modalSuccess}
            item={editedItem}
            data-test="mxModal"
          />
          <MXModalTolerance
            aircraft={aircraft}
            visible={modalToleranceVisible}
            hideModal={this.onToleranceChange}
            item={editedItem}
            data-test="mxModalTolerance"
          />
          <MXDraftQueue
            aircraft={aircraft}
            visible={modalDraftsVisible}
            items={draftItems}
            onSuccess={this.onDraftsSuccess}
            hideModal={this.hideModal}
          />
          <Card className={window.innerWidth > 450 ? globalStyles.pageCard : styles.pageCard}>
            <div className={styles.layoutDiv}>
              <div className={styles.mainCol}>
                <div className={styles.headerWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={`${globalStyles.overCardTitle} ${styles.overCardTitle}`}>
                      {formatMessage({ id: 'title.maintenance' })}
                    </div>
                    {aircraft && aircraft.camp_last_synced && (
                      <CampSyncButton
                        camp_last_synced={aircraft.camp_last_synced}
                        synchronising={synchronising}
                        formatMessage={formatMessage}
                        handleSyncButtonClick={this.handleSyncButtonClick}
                      />
                    )}
                  </div>
                  <div className={styles.mobileWrapper}>
                    <div id="searchWrapper" data-test="searchInput">
                      <Search
                        onChange={(e): void => this.handleSearchChange(e.currentTarget.value)}
                        onClear={(): void => this.handleSearchChange('')}
                        reset={reset}
                      />
                    </div>
                    <div className={styles.buttonsWrapper}>
                      <div className={styles.addButton}>
                        <Button
                          size={ButtonSize.MEDIUM}
                          onClick={(): void => {
                            this.setState({
                              modalVisible: true,
                            });
                          }}
                          data-test="addNewButton"
                        >
                          <img src={plusIcon} alt="plus icon" />
                          <span>
                            {window.innerWidth > 450
                              ? formatMessage({ id: 'form.button.addNewItem' })
                              : formatMessage({ id: 'form.button.add' })}
                          </span>
                        </Button>
                      </div>
                      <div className={styles.filterButton}>
                        <button className={styles.filterButton} type="button" onClick={this.toggleFiltersDrawer}>
                          <img src={filtersIcon} alt="Open filters" className={styles.filterIcon} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  {currentOperator?.operator_setting?.requires_camp_mx_approval && draftCount ? (
                    <Col md={24} className={styles.mxItemsImporter}>
                      <MXItemsImporter draftCount={draftCount} onActionClick={this.showMXDraftQueue} />
                    </Col>
                  ) : null}
                  <Col md={24} className={styles.mxListCol}>
                    <ListWrapper
                      items={filteredItems}
                      filtersSearchActive={filterSearchActive}
                      aircraft={aircraft as Aircraft}
                      selectedItems={selectedMXItems}
                      onItemSelect={(value, itemID): void => this.onItemSelect(value, itemID)}
                      mxPeriods={MX_PERIODS}
                      mxPeriodDays={mxPeriodDays}
                      onEdit={(item): void => this.onEdit(item)}
                      onDeleteSuccess={this.forceRefetchofMxItems}
                      onApplyTolerance={(item): void => this.onApplyTolerance(item)}
                      expandAll={expandAll}
                      expandItem={passedMxItemId}
                      setPassedMxItemId={this.setPassedMxItemId}
                      loading={loading}
                      onPaginationChange={this.handlePaginationChange}
                      total={total}
                      handleAddItem={this.handleAddItem}
                      resetFilters={this.resetFilters}
                      filtersActive={filtersActive}
                      totalsCount={totalsCount}
                    />
                  </Col>
                </Row>
              </div>
              <div className={styles.filtersCol}>
                <Sticky top={80} className={styles.stickyFilters}>
                  <div className={styles.booleanFilter}>
                    <BooleanFilter title="Expand All" valueKey="" value={expandAll} onChange={this.toggleExpandAll} />
                  </div>
                  <div className={styles.innerScroll}>
                    <FilterSideBar
                      data={[]}
                      filterGroups={filterGroups}
                      onFailedParentToggle={onFailedParentToggle}
                      onChange={this.handleFilterChange}
                      reset={reset}
                    />
                  </div>
                </Sticky>
              </div>
            </div>
            <div id="cardBottomBoundary" className={styles.bottomBoundary} />
          </Card>
          {showFiltersDrawer && (
            <SlidingDrawer filterDrawer toggleDrawer={this.toggleFiltersDrawer}>
              <div className={styles.drawerHeader}>
                <span>{formatMessage({ id: 'title.filters' })}</span>
              </div>
              <FilterSideBar
                data={[]}
                filterGroups={filterGroups}
                onFailedParentToggle={onFailedParentToggle}
                onChange={showFiltersDrawer && this.handleFilterChange}
              />
            </SlidingDrawer>
          )}
        </InnerMenuLayout>
      </>
    );
  }
}

const mapStateToProps = ({
  aircraft,
  maintenance,
  userSettings,
}: {
  aircraft: AircraftState;
  maintenance: MaintenanceState;
  userSettings: any;
}): {
  ttl: number;
  mxFetched: boolean;
  aircraftMap: Map<
    string,
    {
      id: string;
      registration: string;
      lastFetched: number;
      camp_integration_enabled: boolean;
      camp_last_synced: string;
      operator_id: string;
    }
  >;
  userSettings: any;
  draftMxItems: any[];
} => {
  const draftMxItems = Array.from(maintenance.draftMaintenanceMap.values())
    .filter((a) => a.draft)
    .sort((a, b) => {
      const statusOrder = b.status.localeCompare(a.status);
      if (statusOrder === 0) {
        return a.id.localeCompare(b.id);
      }
      return statusOrder;
    });
  return {
    aircraftMap: aircraft.aircraftMap,
    ttl: aircraft.ttl,
    draftMxItems,
    mxFetched: maintenance.fetched,
    userSettings,
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  fetchAircraft: (id: string) => Promise<void>;
} => ({
  fetchAircraft: (id): Promise<void> => {
    return dispatch(
      getSingleAircraft({
        payload: id,
      }),
    );
  },
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(MaintenanceManagement)));

export type MaintenanceManagementProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps<'intl'> &
  RouteComponentProps<{ id: string }, any, LocationState | any>;

export type MaintenanceManagementState = {
  currentOperator: {
    operator_setting: {
      requires_camp_mx_approval: boolean;
    };
  };
  mxPeriodDays: number;
  selectedMXItems: {}[];
  loading: boolean;
  modalVisible: boolean;
  modalToleranceVisible: boolean;
  modalDraftsVisible: boolean;
  editedItem: {};
  synchronising: boolean;
  passedMxItemId: string;
  openMxItems: Array<[]>;
  filterGroups: Array<any>;
  filterParams: {
    aircraft_id: string;
    page: number;
    per: number;
    start_date: Date;
    end_date: Date;
    search_term: string;
    status: [];
    item_type: [];
    area: [];
    source: [];
    limits: [];
    planning: [];
    tolerance: [];
  };
  total: number;
  draftCount: number;
  draftItems: [];
  isForcingRefresh: boolean;
  expandAll: boolean;
  workpacks: Array<Workpack>;
  showFiltersDrawer: boolean;
  reset: boolean;
  totalsCount: number;
};

type LocationState = {
  id: string;
};
