/* eslint-disable no-underscore-dangle */
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { FeatureFlag } from '../../models/userSettings';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import StyledRadioButton from '../DefectDrawer/components/StyledRadioButton';
import DeleteIcon from '../../assets/delete.svg';
import plusIcon from '../../assets/plus-blue.svg';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';

interface FuelUpliftsProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div``;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  row-gap: 20px;
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '20px')};
  grid-template-rows: auto;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    return '1fr 1fr';
  }};
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '16px')};
  grid-template-rows: auto;
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const UpliftNum = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.black};
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const StyledInput = styled.input`
  width: 100px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const LineBreak = styled.div`
  height: 1px;
  margin: 20px 0 20px;
  background-color: ${({ theme }): string => theme.colors.black05Alpha};
`;

const FuelUplifts: React.FC<FuelUpliftsProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const isMobile = window.innerWidth < 451;

  const currentAircraft = aircraftMap.get(id);
  let fuelunit;
  let featureFlags;
  let standardFuelUpliftsTypes;
  let showPercentageType = [];
  let percentageTitle = [];
  if (currentAircraft) {
    fuelunit = currentAircraft?.fuel_unit;
    featureFlags = currentAircraft?.feature_flags;
    standardFuelUpliftsTypes = currentAircraft?.standard_fields?.fuel_uplift?.allowed_fuel_types || [];
    showPercentageType = currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels
      ? Object.keys(currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels)
      : [];
    percentageTitle = currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels
      ? Object.values(currentAircraft?.standard_fields?.fuel_uplift?.mixed_fuels)
      : [];
  }

  const { formatMessage } = useIntl();

  const [fuelUplifts, setFuelUplifts] = useState([]);
  const [fuelTypeFlag, setFuelTypeFlag] = useState(false);

  const addUplift = (): void => {
    const newArray = fuelUplifts;
    newArray.push({
      aircraft_actual_uplift: null,
      aircraft_planned_uplift: null,
      density: null,
      truck_actual_uplift: null,
      saf_percentage: 0,
      fuel_type: standardFuelUpliftsTypes[0],
    });
    setFuelUplifts([...newArray]);
  };

  const removeUplift = (index): void => {
    const newArray = fuelUplifts;
    const isStoredInCore = typeof newArray[index].id === 'string';
    if (isStoredInCore) {
      // eslint-disable-next-line no-underscore-dangle
      newArray[index]._destroy = true;
    } else {
      newArray.splice(index, 1);
    }
    setFuelUplifts([...newArray]);
  };

  const handleInputChange = (value: string, index: number, key: string): void => {
    if (key === 'saf_percentage' && (parseInt(value, 10) > 100 || parseInt(value, 10) < 1)) {
      message.error('SAF Percentage must be between 0 and 100');
      return;
    }
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    newArray[index][key] = value;
    setFuelUplifts([...newArray]);
    updateFlightData([{ value: [...newArray], key: 'fuel_uplifts_attributes' }]);
  };

  const handleFuelTypeChange = (value, index): void => {
    const newArray = fuelUplifts ? [...fuelUplifts] : [];
    newArray[index].fuel_type = value;
    setFuelUplifts([...newArray]);
    updateFlightData([{ value: [...newArray], key: 'fuel_uplifts_attributes' }]);
  };

  const buildFuelTypeSelectors = (uplift, index): ReactNode => {
    return standardFuelUpliftsTypes.map((type) => {
      let value = type;
      if (type === 'saf') value = 'SAF';
      if (type === 'standard') value = 'Standard';
      return (
        <StyledRadioButton
          checked={uplift?.fuel_type === type}
          id={type}
          group="fuel_type"
          width={70}
          value={value}
          onClick={(): void => handleFuelTypeChange(type, index)}
          marginRight={8}
        />
      );
    });
  };

  useEffect(() => {
    if (featureFlags && featureFlags.some((flag) => flag.feature_name === FeatureFlag.FUELTYPE)) {
      setFuelTypeFlag(true);
    }
  }, [featureFlags]);

  useEffect(() => {
    if (!fuelUplifts.length) {
      const uplift = [
        {
          aircraft_actual_uplift: null,
          aircraft_planned_uplift: null,
          density: null,
          truck_actual_uplift: null,
          saf_percentage: 0,
          fuel_type: standardFuelUpliftsTypes[0],
        },
      ];
      setFuelUplifts(uplift);
    }
  }, [fuelUplifts, mode, standardFuelUpliftsTypes]);

  useEffect(() => {
    if (flight && flight?.fuel_uplifts_attributes) {
      setFuelUplifts(flight?.fuel_uplifts_attributes);
    } else if (flight && flight?.fuel_uplifts?.length) {
      setFuelUplifts(flight?.fuel_uplifts);
    } else {
      setFuelUplifts([]);
    }
  }, [flight]);

  useEffect(() => {
    if (fuelUplifts.length) {
      fuelUplifts.forEach((uplift) => {
        if (!uplift?.density) {
          // eslint-disable-next-line no-param-reassign
          uplift.density = '0.800';
        }
      });
      setFuelUplifts(fuelUplifts);
    }
  }, [fuelUplifts]);

  const buildFuelUplifts = (): ReactNode => {
    return fuelUplifts.map((uplift, index) => {
      let displayActual = null;
      if (uplift?.truck_actual_uplift && uplift?.density) {
        displayActual = uplift?.truck_actual_uplift * uplift?.density;
      }

      if (uplift._destroy) {
        return null;
      }

      let displayFuelType = 'SAF';
      if (uplift?.fuel_type === 'standard') displayFuelType = 'Standard';
      if (uplift?.fuel_type === 'saf') displayFuelType = 'SAF';

      return (
        <>
          {fuelUplifts.length > 0 && (
            <HeaderWrapper>
              <UpliftNum>{`Uplift ${index + 1}`}</UpliftNum>
              <ButtonWrapper>
                {mode !== 'view' && fuelUplifts.length > 1 && (
                  <DeleteButton
                    type="button"
                    onClick={(): void => {
                      removeUplift(index);
                    }}
                  >
                    <img src={DeleteIcon} alt="delete button" />
                  </DeleteButton>
                )}
              </ButtonWrapper>
            </HeaderWrapper>
          )}
          <DetailsWrapper
            key={uplift?.id}
            edit={mode !== 'view'}
            noMargin={fuelUplifts.length === index + 1}
            isMobile={isMobile}
          >
            {fuelTypeFlag ? (
              <>
                {standardFuelUpliftsTypes.length ? (
                  <div>
                    <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.fuelType' })}</Header>
                    {mode === 'view' ? (
                      <DisplayText>{displayFuelType || '-'}</DisplayText>
                    ) : (
                      <RadioButtonWrapper>{buildFuelTypeSelectors(uplift, index)}</RadioButtonWrapper>
                    )}
                  </div>
                ) : null}
                {showPercentageType && showPercentageType?.includes(uplift?.fuel_type) ? (
                  <div>
                    <Header editable={mode !== 'view'}>
                      <span>
                        {percentageTitle[showPercentageType.findIndex((item) => item === uplift?.fuel_type)]}{' '}
                      </span>
                      {formatMessage({
                        id: 'text.percentage',
                      })}
                    </Header>
                    <UnitWrapper>
                      {mode === 'view' ? (
                        <DisplayText>{uplift?.saf_percentage || '-'}</DisplayText>
                      ) : (
                        <StyledInput
                          type="number"
                          step="0.01"
                          placeholder="-"
                          value={uplift?.saf_percentage}
                          onChange={(e): void => handleInputChange(e.target.value, index, 'saf_percentage')}
                        />
                      )}
                      <StyledUnit edit={mode !== 'view'}>%</StyledUnit>
                    </UnitWrapper>
                  </div>
                ) : null}
              </>
            ) : null}
            {Object.keys(uplift).includes('aircraft_planned_uplift') ? (
              <div>
                <Header editable={mode !== 'view'}>{formatMessage({ id: 'status.planned' })}</Header>
                <UnitWrapper>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.aircraft_planned_uplift || '-'}</DisplayText>
                  ) : (
                    <StyledInput
                      type="number"
                      step="0.1"
                      placeholder="-"
                      value={uplift?.aircraft_planned_uplift || ''}
                      onChange={(e): void => handleInputChange(e.target.value, index, 'aircraft_planned_uplift')}
                    />
                  )}
                  <StyledUnit edit={mode !== 'view'}>
                    {uplift?.aircraft_planned_uplift || mode !== 'view' ? fuelunit : ''}
                  </StyledUnit>
                </UnitWrapper>
              </div>
            ) : (
              <div />
            )}
            {Object.keys(uplift).includes('truck_actual_uplift') ? (
              <div>
                <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.bowserUplift' })}</Header>
                <UnitWrapper>
                  {mode === 'view' ? (
                    <DisplayText>{uplift?.truck_actual_uplift || '-'}</DisplayText>
                  ) : (
                    <StyledInput
                      type="number"
                      step="0.1"
                      placeholder="-"
                      value={uplift?.truck_actual_uplift || ''}
                      onChange={(e): void => handleInputChange(e.target.value, index, 'truck_actual_uplift')}
                    />
                  )}
                  <StyledUnit edit={mode !== 'view'}>
                    {uplift?.truck_actual_uplift || mode !== 'view' ? fuelunit : ''}
                  </StyledUnit>
                </UnitWrapper>
              </div>
            ) : (
              <div />
            )}
            {Object.keys(uplift).includes('density') ? (
              <div>
                <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.specificGravity' })}</Header>
                {mode === 'view' ? (
                  <DisplayText>{uplift?.density || '-'}</DisplayText>
                ) : (
                  <StyledInput
                    type="number"
                    step="0.001"
                    placeholder="-"
                    value={uplift?.density || '0.800'}
                    onChange={(e): void => handleInputChange(e.target.value, index, 'density')}
                  />
                )}
              </div>
            ) : (
              <div />
            )}
            {mode === 'view' ? (
              <div>
                <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.actual' })}</Header>
                <UnitWrapper>
                  <DisplayText>{displayActual || '-'}</DisplayText>
                  <StyledUnit>{displayActual || mode !== 'view' ? fuelunit : ''}</StyledUnit>
                </UnitWrapper>
              </div>
            ) : null}
          </DetailsWrapper>
          {index + 1 < fuelUplifts.length && <LineBreak />}
        </>
      );
    });
  };
  return (
    <SectionWrapper>
      <SectionHeader>
        <HeaderInner>
          Fuel Uplifts
          <ButtonWrapper>
            {mode !== 'view' ? (
              <Button primary={false} height="24px" onClick={addUplift}>
                <AddTextWrapper>
                  <PlusIcon src={plusIcon} alt="plus icon" /> Add uplift
                </AddTextWrapper>
              </Button>
            ) : null}
          </ButtonWrapper>
        </HeaderInner>
      </SectionHeader>
      {fuelUplifts.length ? buildFuelUplifts() : null}
    </SectionWrapper>
  );
};

export default FuelUplifts;
