import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FileViewer from '../TFFileViewer/fileViewer';
import { Flight } from '../../models/flights';
import EmptyStateAttachments from '../../assets/emptyState/empty-state-upload-file.svg';
import { DashboardState } from '../../models';
import { SectionHeader } from './FlightDrawer';

interface FlightAttachmentProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const EmptyStateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FlightAttachements: React.FC<FlightAttachmentProps> = ({ flight, updateFlightData }) => {
  const [attachments, setAttachments] = useState([]);
  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const newAttachments = attachments?.concat(...base64Attachments);
      setAttachments(newAttachments);
      updateFlightData([{ value: newAttachments, key: 'sector_attachments' }]);
    }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList: any[] = attachments.filter(
      (item: any) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    updateFlightData([{ value: newAttachmentsList, key: 'sector_attachments' }]);
    setAttachments(newAttachmentsList);
  };

  useEffect(() => {
    if (flight && flight?.sector_attachments) {
      setAttachments(flight?.sector_attachments || []);
    }
  }, [flight]);

  let displayingAttachments = attachments;
  if (flight?.other_attachments?.length) {
    displayingAttachments = attachments.concat(flight?.other_attachments);
  }
  return (
    <SectionWrapper>
      <SectionHeader data-testid="FlightAttachments-Header">Attachments</SectionHeader>
      {mode !== 'view' || attachments.length ? (
        <FileViewer
          attachments={displayingAttachments}
          handleDeleteClick={handleDelete}
          addAttachment={handleAttachmentUpload}
          uploaderVisible={mode !== 'view'}
          allowDelete={mode !== 'view'}
        />
      ) : (
        <EmptyStateWrapper data-testid="FlightAttachments-EmptyState">
          <img src={EmptyStateAttachments} alt="no defects" />
          <div>No attachments on this flight</div>
        </EmptyStateWrapper>
      )}
    </SectionWrapper>
  );
};

export default FlightAttachements;
