import React, { useState } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Button as TFButton } from '@arcflight/tf-component-library';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import globalStyles from '../../utils/globalStyles.module.less';
import { postWorkpack, putWorkpack } from '../../services/api';
import styles from './index.module.less';

const WorkpackContent = ({ items, onSuccess, intl: { formatMessage }, workpacks, aircraft }) => {
  const [submitting, setSubmitting] = useState(false);
  const [workpackID, setWorkpackID] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const handleSubmit = async () => {
    if (workpackID) {
      // add to existing workpack
      setSubmitting(true);
      try {
        const response = await putWorkpack({
          id: workpackID,
          body: {
            workpack_items: items.map((item) => ({ mx_item_id: item })),
          },
        });
        if (response && response.statusCode === 200) {
          message.success(formatMessage({ id: 'message.workpackUpdateSuccess' }));
          onSuccess();
        } else {
          if (response && response.body && response.body.workpack_items) {
            message.error(response.body.workpack_items);
          } else {
            message.error(formatMessage({ id: 'message.workpackUpdateError' }));
          }
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        message.error(formatMessage({ id: 'message.workpackUpdateError' }));
      }
    } else if (searchValue) {
      // create new workpack
      setSubmitting(true);
      try {
        const response = await postWorkpack({
          po_number: searchValue,
          date: moment().format('YYYY-MM-DD'),
          status: 'draft',
          workpack_items: items.map((item) => ({ mx_item_id: item })),
          aircraft_id: aircraft.id,
        });

        if (response && response.statusCode === 201) {
          message.success(formatMessage({ id: 'message.workpackCreateSuccess' }));
          onSuccess();
        } else {
          message.error(formatMessage({ id: 'message.workpackCreateError' }));
          setSubmitting(false);
        }
      } catch (error) {
        message.error(formatMessage({ id: 'message.workpackCreateError' }));
        setSubmitting(false);
      }
    } else {
      message.error('Please enter a PO Number');
    }
  };

  return (
    <>
      <div id="pageOverlay" className={styles.inputWrapper}>
        <span className={styles.workpackText}>
          {workpacks && workpacks.length > 0
            ? formatMessage({ id: 'text.addToExistingWorkpack' })
            : formatMessage({ id: 'text.createNewWorkpack' })}
          :
        </span>
        <SearchDropdown
          options={workpacks}
          handleSelect={setWorkpackID}
          handleSearch={setSearchValue}
          valueKey="id"
          textKey="po_number"
          placement="bottom"
          placeholder="Enter PO Number"
        />
      </div>
      <TFButton
        size={ButtonSize.MEDIUM}
        className={`${globalStyles.addNewButton} ${globalStyles.buttonPreserveText} ${styles.addWorkpackButton}`}
        onClick={() => handleSubmit()}
        loading={submitting}
        data-test="approveButton"
      >
        <span className={globalStyles.buttonText}>
          {workpackID ? formatMessage({ id: 'text.addToWorkpack' }) : formatMessage({ id: 'text.createNewWorkpack' })}
        </span>
      </TFButton>
    </>
  );
};

WorkpackContent.propTypes = {
  items: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  workpacks: PropTypes.array.isRequired,
  aircraft: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(WorkpackContent);
