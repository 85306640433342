import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ModernPagination from '../ModernPagination';
import EmptyState from '../EmptyState/EmptyState';
import { Aircraft } from '../../models/aircraft';
import { MX_LIST_PERIODS } from '../../pages/AircraftMaintenance/MaintenanceManagement';
import EmptyStateMaintenance from '../../assets/emptyState/empty-state-maintenance.svg';
import styles from './listWrapper.module.less';
import SelectableMXItem from './SelectableMXItem';

const ListWrapper: React.FC<ListWrapperProps> = ({
  items,
  aircraft,
  onItemSelect,
  mxPeriods,
  mxPeriodDays,
  selectedItems,
  onEdit,
  onDeleteSuccess,
  onApplyTolerance,
  expandAll,
  filtersSearchActive,
  expandItem,
  setPassedMxItemId,
  loading,
  onPaginationChange,
  total,
  handleAddItem,
  resetFilters,
  filtersActive,
  totalsCount,
}) => {
  const { formatMessage } = useIntl();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { listPeriods } = mxPeriods.find(({ days }) => days === mxPeriodDays);

  const usePrevious = (value: any): any => {
    const ref = useRef(null);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevPage = usePrevious(currentPage);

  useEffect(() => {
    if (prevPage !== currentPage) {
      window.scrollTo(0, 0);
    }
  }, [currentPage, prevPage]);

  const paginatedItems = useMemo(() => {
    const { overdueItems, criticalItems, standardItems, resolvedItems } = items;

    return overdueItems
      .concat(criticalItems)
      .concat(standardItems)
      .concat(resolvedItems);
  }, [items]);

  const getPeriodTitle = (title, type = null): object => {
    return (
      <div
        className={`${styles.periodTitle} ${type ? styles[`periodTitle${type}`] : undefined}`}
        key={`title${title}`}
        data-test="periodTitle"
      >
        {title}
      </div>
    );
  };

  const getDateTitle = (period, date, overdue?): object => {
    const { overdueItems, criticalItems } = items;
    return (
      <div
        className={`${styles.periodDate} ${
          period === MX_LIST_PERIODS.CRITICAL && !overdue && criticalItems.length > 0
            ? styles.periodDatewarning
            : undefined
        }
         ${
           period === MX_LIST_PERIODS.OVERDUE && overdue && overdueItems.length > 0 ? styles.periodDateerror : undefined
         }`}
        key={`${period}_${date}`}
        data-test="periodTitleDate"
      >
        {moment(date).format('DD MMM YYYY')}
      </div>
    );
  };

  const getItemPeriod = (item, startingPeriod): number => {
    const estimatedDate = item.estimated_date || moment().format('YYYY-MM-DD');
    const itemRemainingDays = moment
      .duration(moment(estimatedDate).diff(moment().format('YYYY-MM-DD'), 'days'), 'days')
      .asDays();
    if (
      startingPeriod === MX_LIST_PERIODS.OVERDUE &&
      (item.status === 'overdue' || item.status === 'critical' || itemRemainingDays === 0)
    ) {
      return startingPeriod;
    }
    let period;
    for (period = startingPeriod; period < listPeriods.length; period += 1) {
      if ((itemRemainingDays <= listPeriods[period] && itemRemainingDays > listPeriods[period - 1]) || 0) {
        break;
      }
    }
    return period;
  };

  const handlePageChange = (newPageNumber): void => {
    setCurrentPage(newPageNumber);

    if (onPaginationChange) {
      onPaginationChange(newPageNumber, pageSize);
    }
  };

  const handlePageSizeChange = (newPageSize): void => {
    setPageSize(newPageSize);
    setCurrentPage(1);

    if (onPaginationChange) {
      onPaginationChange(1, newPageSize);
    }
  };

  const { overdueItems, criticalItems, standardItems, resolvedItems } = items;
  const totalItems = overdueItems.length + criticalItems.length + standardItems.length + resolvedItems.length;

  if (!totalItems) {
    return (
      <EmptyState
        image={EmptyStateMaintenance}
        text={totalsCount !== 0 ? "We couldn't find any matching maintenance items" : 'No maintenance items'}
        subText={
          totalsCount !== 0
            ? 'Try adjusting your filters or searching with another term.'
            : 'You can add your first maintenance item now.'
        }
        button={totalsCount !== 0 ? 'Clear all' : 'Add item'}
        buttonAction={totalsCount !== 0 ? resetFilters : handleAddItem}
      />
    );
  }
  let currentPeriod = getItemPeriod(paginatedItems[0], MX_LIST_PERIODS.OVERDUE);
  // currentDate keeps track of the previous items estimated date - so that items can be grouped by date
  // is a bit hacky and could do with a further refactor.
  let currentDate = paginatedItems[0].estimated_date || moment().format('YYYY-MM-DD');
  return (
    <>
      {listPeriods[currentPeriod] === MX_LIST_PERIODS.OVERDUE &&
        overdueItems.length > 0 &&
        paginatedItems.some((item) => overdueItems.includes(item)) &&
        getPeriodTitle(formatMessage({ id: 'text.overdueItems' }), 'error')}
      {overdueItems &&
        paginatedItems.some((item) => overdueItems.includes(item)) &&
        overdueItems.length > 0 &&
        paginatedItems.map((item) => {
          const itemIndex = paginatedItems?.findIndex((pagItem) => pagItem?.id === item?.id);
          const previousItemIsNotOverdue = paginatedItems[itemIndex - 1]?.status !== 'overdue';
          const itemIsOverdue = paginatedItems[itemIndex]?.status === 'overdue';

          const itemEstimatedDate = item?.estimated_date || moment().format('YYYY-MM-DD');
          let dateTitle = null;

          if (previousItemIsNotOverdue) {
            currentDate = itemEstimatedDate;
            dateTitle = getDateTitle(0, currentDate, true);
          }

          if (itemEstimatedDate !== currentDate) {
            currentDate = itemEstimatedDate;
            dateTitle = getDateTitle(0, currentDate, true);
          }
          if (itemIsOverdue) {
            return (
              <div key={item.id}>
                {dateTitle}
                <SelectableMXItem
                  key={item.id}
                  item={item}
                  aircraft={aircraft}
                  checked={selectedItems.includes(item.id)}
                  onChange={(value, itemID): void => onItemSelect(value, itemID)}
                  isOverdue={item.status === 'overdue'}
                  isCritical={item.status === 'critical'}
                  onEdit={(): void => onEdit(item)}
                  onSuccess={(): void => onDeleteSuccess(item)}
                  onApplyTolerance={(): void => onApplyTolerance(item)}
                  disabledMessage={formatMessage({ id: 'message.resolvedItemsNotSelectable' })}
                  expandOverride={expandAll}
                  expandItem={expandItem}
                  setPassedMxItemId={setPassedMxItemId}
                  loading={loading}
                />
              </div>
            );
          }
          return <></>;
        })}
      {// listPeriods[currentPeriod] === MX_LIST_PERIODS.CRITICAL &&
      criticalItems.length > 0 &&
        paginatedItems.some((item) => criticalItems.includes(item)) &&
        getPeriodTitle(formatMessage({ id: 'text.inTolerance' }), 'warning')}
      {criticalItems &&
        criticalItems.length > 0 &&
        criticalItems.map((item) => {
          const itemIndex = paginatedItems?.findIndex((pagItem) => pagItem?.id === item?.id);
          const previousItemIsNotCritical = paginatedItems[itemIndex - 1]?.status !== 'critical';

          const itemEstimatedDate = item?.estimated_date || moment().format('YYYY-MM-DD');
          let dateTitle = null;

          if (previousItemIsNotCritical) {
            currentDate = itemEstimatedDate;
            dateTitle = getDateTitle('crit', currentDate);
          }

          if (itemEstimatedDate !== currentDate) {
            currentDate = itemEstimatedDate;
            dateTitle = getDateTitle('crit', currentDate);
          }

          return (
            <div key={item.id}>
              {dateTitle}
              <SelectableMXItem
                key={item.id}
                item={item}
                aircraft={aircraft}
                checked={selectedItems.includes(item.id)}
                onChange={(value, itemID): void => onItemSelect(value, itemID)}
                isOverdue={item.status === 'overdue'}
                isCritical={item.status === 'critical'}
                onEdit={(): void => onEdit(item)}
                onSuccess={(): void => onDeleteSuccess(item)}
                onApplyTolerance={(): void => onApplyTolerance(item)}
                disabledMessage={formatMessage({ id: 'message.resolvedItemsNotSelectable' })}
                expandOverride={expandAll}
                expandItem={expandItem}
                setPassedMxItemId={setPassedMxItemId}
                loading={loading}
              />
            </div>
          );
        })}
      {standardItems &&
        standardItems.map((item, index) => {
          if (paginatedItems.includes(item)) {
            // defensive assignment as its possible for core to have items with null dates for some reason
            const standardEstimatedDate = item.estimated_date || moment().format('YYYY-MM-DD');
            const itemRemainingDays = moment
              .duration(moment(standardEstimatedDate).diff(moment().format('YYYY-MM-DD'), 'days'), 'days')
              .asDays();
            let periodTitle;
            const firstItemOnPage = paginatedItems.findIndex((itemP) => itemP === item) === 0;
            if (itemRemainingDays === 0 && index === 0 && (overdueItems.length === 0 || criticalItems.length === 0)) {
              periodTitle = getPeriodTitle(formatMessage({ id: 'text.lessThan24Hours' }), 'warning');
            } else if (firstItemOnPage || itemRemainingDays > listPeriods[currentPeriod]) {
              currentPeriod = getItemPeriod(item, currentPeriod);
              if (listPeriods[currentPeriod] === MX_LIST_PERIODS.NEXT_DAY) {
                periodTitle = getPeriodTitle(formatMessage({ id: 'text.next24Hours' }), 'warning');
              } else if (listPeriods[currentPeriod] === MX_LIST_PERIODS.NEXT_60_DAYS) {
                periodTitle = getPeriodTitle(formatMessage({ id: 'text.within60Days' }));
              } else if (listPeriods[currentPeriod] === MX_LIST_PERIODS.NEXT_120_DAYS) {
                periodTitle = getPeriodTitle(formatMessage({ id: 'text.within120Days' }));
              } else if (listPeriods[currentPeriod] === MX_LIST_PERIODS.NEXT_365_DAYS) {
                periodTitle = getPeriodTitle(formatMessage({ id: 'text.within365Days' }));
              } else if (listPeriods[currentPeriod] === MX_LIST_PERIODS.ALL_RECORDS) {
                periodTitle = getPeriodTitle(formatMessage({ id: 'text.allTime' }));
              } else {
                periodTitle = getPeriodTitle(
                  formatMessage({ id: 'text.withinXDays' }, { days: listPeriods[currentPeriod] }),
                );
              }
            }

            let dateTitle;
            if (standardEstimatedDate !== currentDate || paginatedItems.findIndex((itemP) => itemP === item) === 0) {
              currentDate = standardEstimatedDate;
              dateTitle = getDateTitle(3, currentDate);
            }
            return (
              <div key={item.id}>
                {periodTitle}
                <>
                  {dateTitle}
                  <SelectableMXItem
                    key={item.id}
                    item={item}
                    aircraft={aircraft}
                    checked={selectedItems.includes(item.id)}
                    onChange={(value, itemID): void => onItemSelect(value, itemID)}
                    isOverdue={item.status === 'overdue'}
                    isCritical={item.status === 'critical'}
                    onEdit={(): void => onEdit(item)}
                    onSuccess={(): void => onDeleteSuccess(item)}
                    onApplyTolerance={(): void => onApplyTolerance(item)}
                    disabledMessage={formatMessage({ id: 'message.resolvedItemsNotSelectable' })}
                    expandOverride={expandAll}
                    expandItem={expandItem}
                    setPassedMxItemId={setPassedMxItemId}
                    loading={loading}
                  />
                </>
              </div>
            );
          }
          return null;
        })}
      {resolvedItems &&
        resolvedItems.map((item, index) => {
          let resolvedTitle = null;
          if (paginatedItems.includes(item) && index === 0) {
            resolvedTitle = <div className={styles.periodTitle}>Resolved</div>;
          }
          return (
            <>
              {resolvedTitle}
              <SelectableMXItem
                key={item.id}
                item={item}
                aircraft={aircraft}
                checked={selectedItems.includes(item.id)}
                onChange={(value, itemID): void => onItemSelect(value, itemID)}
                isOverdue={item.status === 'overdue'}
                isCritical={item.status === 'critical'}
                onEdit={(): void => onEdit(item)}
                onSuccess={(): void => onDeleteSuccess(item)}
                onApplyTolerance={(): void => onApplyTolerance(item)}
                disabledMessage={formatMessage({ id: 'message.resolvedItemsNotSelectable' })}
                expandOverride={expandAll}
                expandItem={expandItem}
                setPassedMxItemId={setPassedMxItemId}
                loading={loading}
              />
            </>
          );
        })}
      <ModernPagination
        pageSize={pageSize}
        current={currentPage}
        total={total}
        onPageNoChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        data-test="modernPagination"
      />
    </>
  );
};

export default ListWrapper;

export interface ListWrapperProps {
  items: {
    overdueItems: Array<{ date_due: string; estimated_date: string; id: string; status: string }>;
    criticalItems: Array<{ date_due: string; estimated_date: string; id: string; status: string }>;
    standardItems: Array<{ date_due: string; estimated_date: string; id: string; status: string }>;
    resolvedItems: Array<{ date_due: string; estimated_date: string; id: string; status: string }>;
  };
  aircraft: Aircraft;
  onItemSelect: (value: string, itemId: string) => void;
  mxPeriods: Array<{ listPeriods: MX_LIST_PERIODS[]; days: number }>;
  mxPeriodDays: number;
  selectedItems: Array<{}>;
  onEdit: (item: object) => void;
  onApplyTolerance: (item: object) => void;
  onDeleteSuccess: (item: object) => void;
  expandAll: boolean;
  filtersSearchActive: boolean;
  expandItem: string;
  setPassedMxItemId: (id: any) => any;
  loading: boolean;
  onPaginationChange?: (currentPage: number, itemsPerPage: number) => void;
  total: number;
  handleAddItem: () => void;
  resetFilters: () => void;
  filtersActive: boolean;
  totalsCount: number;
}
