import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, InputNumber, Row, Col } from 'antd';
import { Modal, Button } from '@arcflight/tf-component-library';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import defaults from '../../../utils/defaults';
import Header from '../common/header';
import Footer from '../common/footer';
import { update } from '../../../models/aircraft/actions';
import styles from './styles.module.less';

class ThresholdSettingsModal extends Component {
  static propTypes = {
    updateAircraftSettings: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    visibleThresholdModal: PropTypes.bool,
    closeThresholdModal: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    visibleThresholdModal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      daysError: '',
      cyclesError: '',
      flightsError: '',
      landingsError: '',
      APUHoursError: '',
      flightHoursError: '',
    };
  }

  onChange = async (value, field) => {
    const { selectedAircraft } = this.props;
    await new Promise((resolve) => {
      if (field === 'days') {
        this.setState({ daysError: '' }, resolve);
      }
      if (field === 'cycles') {
        this.setState({ cyclesError: '' }, resolve);
      }
      if (field === 'flights') {
        this.setState({ flightsError: '' }, resolve);
      }
      if (field === 'landings') {
        this.setState({ landingsError: '' }, resolve);
      }
      if (field === 'apu_seconds') {
        this.setState({ APUHoursError: '' }, resolve);
      }
      if (field === 'flight_seconds') {
        this.setState({ flightHoursError: '' }, resolve);
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      formData: { ...selectedAircraft, ...prevState.formData, mx_thresholds: { [`${field}`]: value } },
    }));
  };

  getModalHeader = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Header
        handleModalVisible={() => {
          this.props.closeThresholdModal();
          this.resetErrors();
        }}
        headerTitle={formatMessage({ id: 'title.maintenanceWarnings' })}
        headerSubtitle={formatMessage({ id: 'title.pleaseSelectThreshold' })}
      />
    );
  };

  getModalFooter = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Footer
        handleModalVisible={() => {
          this.props.closeThresholdModal();
          this.resetErrors();
        }}
        submitButtonText={formatMessage({ id: 'form.button.save' })}
        handleFormUpload={() => this.saveThresholdValues()}
        type="primary"
      />
    );
  };

  thresholdDetailsForm = (edit) => {
    const {
      selectedAircraft,
      intl: { formatMessage },
      closeThresholdModal,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { daysError, cyclesError, flightsError, landingsError, APUHoursError, flightHoursError } = this.state;
    let mxThresholds = {};
    if (selectedAircraft && Object.keys(selectedAircraft.mx_thresholds).length === 0) {
      mxThresholds = defaults.defaultMXThresholds;
    } else {
      mxThresholds = selectedAircraft ? selectedAircraft.mx_thresholds : defaults.defaultMXThresholds;
    }
    return (
      <div data-test="thresholdSettingsModal">
        <div className={styles.headerWrapper}>
          <div className={styles.title} data-test="headerTitle">
            {formatMessage({ id: 'title.maintenanceWarnings' })}
          </div>
          <div className={styles.subTitle} data-test="headerSubtitle">
            {formatMessage({ id: 'title.pleaseSelectThreshold' })}
          </div>
        </div>
        <Form className={edit ? styles.editableDetailsForm : styles.detailsForm}>
          <Row gutter={24}>
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                data-test="formDays"
                className={styles.valueInputDiv}
                labelAlign="left"
                label={formatMessage({ id: 'period.days' })}
              >
                {getFieldDecorator('days', {
                  rules: [
                    {
                      required: !!edit,
                      message: () => this.setState({ daysError: formatMessage({ id: 'message.addDays' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () =>
                        this.setState({
                          daysError: formatMessage({ id: 'message.addWholeNumbers' }),
                        }),
                    },
                  ],
                  initialValue: mxThresholds.days,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'days')}
                    min={0}
                    data-test="inputDays"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                data-test="formCycles"
                className={styles.valueInputDiv}
                labelAlign="left"
                label={formatMessage({ id: 'period.Cycles' })}
              >
                {getFieldDecorator('cycles', {
                  rules: [
                    {
                      required: !!edit,
                      message: () => this.setState({ cyclesError: formatMessage({ id: 'message.addCycles' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () =>
                        this.setState({
                          cyclesError: formatMessage({ id: 'message.addWholeNumbers' }),
                        }),
                    },
                  ],
                  initialValue: mxThresholds.cycles,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'cycles')}
                    min={0}
                    data-test="inputCycles"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                data-test="formFlights"
                className={styles.valueInputDiv}
                labelAlign="left"
                label={formatMessage({ id: 'period.flights' })}
              >
                {getFieldDecorator('flights', {
                  rules: [
                    {
                      required: !!edit,
                      message: () => this.setState({ flightsError: formatMessage({ id: 'message.addFlights' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () =>
                        this.setState({
                          flightsError: formatMessage({ id: 'message.addWholeNumbers' }),
                        }),
                    },
                  ],
                  initialValue: mxThresholds.flights,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'flights')}
                    min={0}
                    data-test="inputFlights"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={daysError || cyclesError || flightsError ? styles.errorDiv : styles.errorDivHidden}>
            {(daysError || cyclesError || flightsError) && (
              <Row gutter={24} style={{ width: '100%' }}>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <div className={`${styles.errorMessage} ${styles.serialNumError}`} data-test="daysErrorMessage">
                    {this.state.daysError}
                  </div>
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <div className={styles.errorMessage} data-test="cyclesErrorMessage">
                    {this.state.cyclesError}
                  </div>
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <div className={styles.errorMessage} data-test="flightsErrorMessage">
                    {this.state.flightsError}
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <Row gutter={24}>
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                data-test="formLandings"
                className={styles.valueInputDiv}
                labelAlign="left"
                label={formatMessage({ id: 'period.landings' })}
              >
                {getFieldDecorator('landings', {
                  rules: [
                    {
                      required: !!edit,
                      message: () => this.setState({ landingsError: formatMessage({ id: 'message.addLandings' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () =>
                        this.setState({
                          landingsError: formatMessage({ id: 'message.addWholeNumbers' }),
                        }),
                    },
                  ],
                  initialValue: mxThresholds.landings,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'landings')}
                    min={0}
                    data-test="inputLandings"
                  />,
                )}
              </Form.Item>
            </Col>
            {selectedAircraft && selectedAircraft.apu_installed === true ? (
              <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  data-test="formAPUHours"
                  className={styles.valueInputDiv}
                  labelAlign="left"
                  label={formatMessage({ id: 'period.APUhours' })}
                >
                  {getFieldDecorator('apu_seconds', {
                    rules: [
                      {
                        required: !!edit,
                        message: () =>
                          this.setState({ APUHoursError: formatMessage({ id: 'message.addAPUHoursError' }) }),
                      },
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: () =>
                          this.setState({
                            APUHoursError: formatMessage({ id: 'message.addWholeNumbers' }),
                          }),
                      },
                    ],
                    initialValue: mxThresholds.apu_seconds / 3600,
                  })(
                    <InputNumber
                      className={styles.editableCellCapacityInput}
                      onChange={(e) => this.onChange(e, 'apu_seconds')}
                      min={0}
                      data-test="inputAPUHours"
                    />,
                  )}
                </Form.Item>
              </Col>
            ) : null}
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                data-test="formFlightHours"
                className={styles.valueInputDiv}
                labelAlign="left"
                label={formatMessage({ id: 'period.fltHours' })}
              >
                {getFieldDecorator('flight_seconds', {
                  rules: [
                    {
                      required: !!edit,
                      message: () =>
                        this.setState({ flightHoursError: formatMessage({ id: 'message.addFlightHours' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () =>
                        this.setState({
                          flightHoursError: formatMessage({ id: 'message.addWholeNumbers' }),
                        }),
                    },
                  ],
                  initialValue: mxThresholds.flight_seconds / 3600,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'flight_seconds')}
                    min={0}
                    data-test="inputFlightHours"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={landingsError || APUHoursError || flightHoursError ? styles.errorDiv : styles.errorDivHidden}>
            {(landingsError || APUHoursError || flightHoursError) && (
              <Row gutter={24} style={{ width: '100%' }}>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <div className={`${styles.errorMessage} ${styles.serialNumError}`} data-test="landingsErrorMessage">
                    {this.state.landingsError}
                  </div>
                </Col>
                {selectedAircraft && selectedAircraft.apu_installed === true ? (
                  <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <div className={styles.errorMessage} data-test="APUHoursErrorMessage">
                      {this.state.APUHoursError}
                    </div>
                  </Col>
                ) : null}
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <div className={styles.errorMessage} data-test="flightHoursErrorMessage">
                    {this.state.flightHoursError}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Form>
        <div className={styles.footerWrapper}>
          <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={this.saveThresholdValues}>
            {formatMessage({ id: 'form.button.save' })}
          </Button>
          <div className={styles.cancelButtonWrapper}>
            <Button
              size={ButtonSize.MEDIUM}
              data-test="cancelButton"
              primary={false}
              padding="0 28px"
              onClick={() => {
                closeThresholdModal();
                this.resetErrors();
              }}
            >
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  resetErrors = () => {
    this.setState({
      daysError: '',
      cyclesError: '',
      flightsError: '',
      landingsError: '',
      APUHoursError: '',
      flightHoursError: '',
    });
  };

  saveThresholdValues = () => {
    const { form, selectedAircraft } = this.props;
    form.validateFields((err, values) => {
      const flightSeconds = values.flight_seconds * 3600;
      const apuSeconds = values.apu_seconds * 3600;
      const newValues = {
        mx_thresholds: {
          days: values.days,
          cycles: values.cycles,
          flights: values.flights,
          landings: values.landings,
          apu_seconds: apuSeconds,
          flight_seconds: flightSeconds,
        },
      };
      if (!err) {
        this.props.form.resetFields();
        this.setState({ editable: false });
        const formValues = newValues;
        const payload = {
          update: true,
          aircraftSettings: true,
          aircraftId: selectedAircraft.id,
          aircraft: {
            ...selectedAircraft,
            ...formValues,
          },
        };
        this.props.updateAircraftSettings(payload);
        this.props.form.resetFields();
        this.props.closeThresholdModal();
        this.props.setLoading();
        this.resetErrors();
      }
    });
  };

  handleModalClose = () => {
    const { closeThresholdModal } = this.props;
    this.resetErrors();
    closeThresholdModal();
  };

  render() {
    const { visibleThresholdModal } = this.props;
    return (
      <Modal isOpen={visibleThresholdModal} handleClose={this.handleModalClose}>
        {this.thresholdDetailsForm(true)}
      </Modal>
    );
  }
}

const ThresholdSettingsModalWithForm = Form.create()(ThresholdSettingsModal);

export default compose(
  injectIntl,
  connect(
    ({ aircraft }) => ({
      aircraftMap: aircraft.aircraftMap,
    }),
    (dispatch) => ({
      updateAircraftSettings: (payload) => {
        return dispatch(
          update({
            payload,
          }),
        );
      },
    }),
  ),
)(ThresholdSettingsModalWithForm);
