/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Table } from '@arcflight/tf-component-library';
import { useTable, useSortBy, usePagination } from 'react-table';
import defaults from '../../utils/defaults';
import { AircraftResource } from '../../models/aircraft';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import tableStyles from './IntermittentFaultsTable.module.less';

const IntermittentFaultsTable = ({
  data,
  handleDeleteIntermittentFault,
  onPaginationChange,
  onSortChange,
  match,
  dateFormat,
  total,
  pageSize,
  pageIndex,
  resetToOne,
  onRowClick,
}) => {
  const columns = useMemo(
    () => [
      {
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortable: true,
        Header: 'Details',
        accessor: 'details',
      },
      {
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
        sortable: true,
        Header: 'Reported By',
        accessor: 'reported_by_name',
      },
      {
        Header: 'Date added',
        accessor: 'reported_at',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return dateFormat ? moment(value).format(dateFormat) : moment(value).format(defaults.defaultDateFormat);
          }
          return '-';
        },
      },
      {
        Header: '',
        id: '3dot',
        width: 20,
        Cell: ({ row }) => {
          return (
            <>
              <AuthDropdownMenu
                handleDelete={() => handleDeleteIntermittentFault(row.original.id)}
                resource={AircraftResource.DEFECT}
                aircraftId={match.params.id}
                options={{ create: false, read: false, update: false, delete: true }}
                forTable
              />
            </>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );
  return (
    <div>
      <div className={tableStyles.tableContainer}>
        <Table
          tableInstance={tableInstance}
          total={total}
          handlePaginationChange={(currentPage, numberOfItems) => onPaginationChange(currentPage, numberOfItems)}
          passedPageSize={pageSize}
          handleSortChange={(test) => onSortChange(test)}
          resetToOne={resetToOne}
          handleRowClick={(row) => onRowClick(row.original)}
        />
      </div>
    </div>
  );
};

IntermittentFaultsTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleDeleteIntermittentFault: PropTypes.func.isRequired,
};

export default injectIntl(IntermittentFaultsTable);
