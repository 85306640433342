import React from 'react';
import styled from 'styled-components';

interface CommentsSectionProps {
  comments: string;
  editable: boolean;
  form: any;
}

const Header = styled.div`
  font-weight: 500;
  letter-spacing: 0.06px;
  color: #181818;
  margin-top: 12.5px;
`;

const StyledTextArea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
`;

const CommentsSection: React.FC<CommentsSectionProps> = ({ comments, editable, form }) => {
  return (
    <div>
      <Header>Comments</Header>
      {editable ? (
        <>
          {form.getFieldDecorator('comments', {
            initialValue: comments,
          })(
            <StyledTextArea rows={5} cols={50} placeholder="Add comments here">
              {comments}
            </StyledTextArea>,
          )}
        </>
      ) : (
        <span>{comments}</span>
      )}
    </div>
  );
};

export default CommentsSection;
