import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface AdditionalInformationProps {
  defect: Defect | null;
  updateDefectData?: (changes: any[]) => void;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({ defect, updateDefectData }) => {
  const [reasonToDefer, setReasonToDefer] = useState(null);
  const [approvalRef, setApprovalRef] = useState(null);

  const { formatMessage } = useIntl();
  const isMobile = window.innerWidth < 451;

  useEffect(() => {
    if (defect) {
      if (defect?.reason_to_defer) {
        setReasonToDefer(defect?.reason_to_defer);
      } else {
        setReasonToDefer('');
      }
      if (defect?.defer_approval_reference) {
        setApprovalRef(defect?.defer_approval_reference);
      } else {
        setApprovalRef('');
      }
    }
  }, [defect]);

  return (
    <Wrapper>
      <FlexWrapper column>
        <Label marginBottom={20} fontWeight={600}>
          {formatMessage({ id: 'text.pleaseEnterAdditional' })} {formatMessage({ id: 'form.labels.optional' })}
        </Label>
        <Label marginBottom={10} fontWeight={500}>
          {formatMessage({ id: 'text.reasonToDefer' })}
        </Label>
        <StyledInput
          width={isMobile ? 300 : 620}
          value={reasonToDefer}
          type="text"
          id="reasonToDefer"
          onChange={(e): void => {
            setReasonToDefer(e.target.value);
            updateDefectData([{ value: e.target.value, key: 'reason_to_defer' }]);
          }}
        />
        <Label fontWeight={500} marginBottom={5} marginTop={20}>
          {formatMessage({ id: 'text.deferralApprovalReference' })}
        </Label>
        <StyledInput
          width={300}
          value={approvalRef}
          placeholder="Please enter deferral approval reference..."
          id="deferApprovalRef"
          onChange={(e): void => {
            setApprovalRef(e.target.value);
            updateDefectData([{ value: e.target.value, key: 'defer_approval_reference' }]);
          }}
          type="text"
        />
      </FlexWrapper>
    </Wrapper>
  );
};

export default AdditionalInformation;
