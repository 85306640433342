import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _, { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { injectIntl } from 'react-intl';
import { FilterSideBar, Button as TFButton, Search, Modal } from '@arcflight/tf-component-library';
import { queryWorkpackTotals } from '../../services/apiNew';
import { getWorkpacks, deleteWorkpack, getWorkpack } from '../../services/api';
import Loading from '../../components/TFLoading/index';
import EmptyState from '../../components/EmptyState/EmptyState';
import { getAllWorkpacks } from '../../models/workpacks/actions';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { getSingleAircraft } from '../../models/aircraft/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import PageMessage from '../../components/PageMessage';
import infoIcon from '../../assets/icon-info-white.svg';
import EmptyStateWorkpacks from '../../assets/emptyState/empty-state-workpacks.svg';
import WorkpackDrawer from '../../components/WorkPackDrawer/index';
import filtersIcon from '../../assets/filters.svg';
import SlidingDrawer from '../../components/SlidingDrawer';
import plusIcon from '../../assets/plus.svg';
import produceFilterModel from './produceFilterModel';
import WorkPacksTable from './WorkpacksTable';
import styles from './Workpacks.module.less';

class Workpacks extends PureComponent {
  static propTypes = {
    workpacksArray: PropTypes.array.isRequired,
    currentAircraft: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        status: [],
        source: [],
      },
      searchValue: '',
      searchVisible: false,
      loading: false,
      wpLoading: false,
      showDrawer: false,
      currentWorkpack: null,
      workpacksFilterModel: produceFilterModel(),
      workpackId: false,
      mode: false,
      localWorkpacksArray: [],
      originalList: [],
      showFiltersDrawer: false,
      page: 1,
      limit: 10,
      sortBy: [],
      workpackCount: 0,
      resetToOne: false,
      modalVisible: false,
      deleteModalId: null,
      reset: false,
    };
  }

  componentDidMount() {
    this.getWorkpacks(true);
    this.getTotals();
  }

  componentDidUpdate(prevProps, prevState) {
    const { history } = this.props;
    const { loading, searchValue, workpackId, page, limit, filters, sortBy } = this.state;
    const { location } = history;
    if (location?.state?.workpackId && !workpackId) {
      this.handleOpenDrawer(location.state.workpackId);
      const { state } = location;
      delete state.workpackId;
      history.replace({ location });
    }
    if (location?.state?.addWorkpack) {
      this.handleAddNewWorkpack();
      const { state } = location;
      delete state.addWorkpack;
      history.replace({ location });
    }
    const firstSortBy = sortBy.length === 0 && prevState.sortBy.length === 0;
    if (!loading && (page !== prevState.page || limit !== prevState.limit)) {
      this.getWorkpacks();
    }
    if (!loading && (!_.isEqual(filters, prevState.filters) || (!firstSortBy && sortBy !== prevState.sortBy))) {
      this.handleFilterData();
    }
    if (!loading && searchValue !== prevState.searchValue) {
      this.getDebounceWorkpacks();
    }
  }

  getDebounceWorkpacks = debounce(() => {
    this.setState({ resetToOne: true, page: 1 }, () => this.getWorkpacks());
  }, 300);

  getWorkpacks = async (first) => {
    const { match } = this.props;
    const { page, limit, filters, sortBy, searchValue } = this.state;
    this.setState({ loading: true });
    const sort_by = sortBy.reduce((obj, item) => ({ ...obj, [item.id]: item.desc ? 'desc' : 'asc' }), {});
    const payload = {
      aircraft_id: match.params.id,
      page,
      limit,
      source: filters?.source,
      status: filters?.status,
      sort_by,
    };
    if (searchValue) payload.search = searchValue;
    const res = await getWorkpacks(payload);
    if (first) this.setState({ originalList: res?.workpacks });
    this.setState({
      loading: false,
      localWorkpacksArray: res?.workpacks,
      workpackCount: res?.count,
      resetToOne: false,
    });
  };

  async getTotals() {
    const { match } = this.props;
    const res = await queryWorkpackTotals(match.params.id);
    const filtersWithCounts = produceFilterModel(res.data);
    this.setState({ workpacksFilterModel: filtersWithCounts });
  }

  handleFilterData = () => {
    this.setState({ resetToOne: true, page: 1 }, () => this.getWorkpacks());
  };

  toggleDrawer = () => {
    const { showDrawer } = this.state;
    if (showDrawer) this.setState({ workpackId: null });
    this.setState({ showDrawer: !showDrawer });
  };

  handleSetCurrentWorkpack = (workpack) => {
    this.getWorkpacks();
    this.getTotals();
    this.setState({ currentWorkpack: workpack, loading: false });
  };

  toggleWorkpackDrawer = (row, mode) => {
    const {
      original: { id },
    } = row;
    this.setState({ workpackId: id, mode, wpLoading: true, loading: true }, async () => {
      const res = await getWorkpack(id);
      this.setState({ currentWorkpack: res, loading: false }, () => this.toggleDrawer());
    });
  };

  handleWorkpackLoading = (value) => {
    this.setState({ wpLoading: value });
  };

  updateLocalWorkpacksArray = (filteredWorkPacksArray) => {
    this.setState({ localWorkpacksArray: filteredWorkPacksArray, reset: false });
  };

  setLoading = async (loading) => {
    await new Promise((resolve) => this.setState({ loading }, resolve));
  };

  handleOpenDrawer = async (id) => {
    const res = await getWorkpack(id);
    this.setState({ showDrawer: true, workpackId: id, currentWorkpack: res, mode: 'view' });
  };

  handleModalClose = () => {
    this.setState({ modalVisible: false });
  };

  confirmDeleteWorkpack = () => {
    const { showDrawer, deleteModalId } = this.state;
    new Promise((res, rej) => res(deleteWorkpack(deleteModalId))).then(() => {
      this.getWorkpacks();
      this.getTotals();
      if (showDrawer) this.toggleDrawer();
    });
    this.setState({ modalVisible: false, deleteModalId: null });
  };

  handleDeleteWorkpack = (id) => {
    this.setState({ modalVisible: true, deleteModalId: id });
  };

  setSearchValue = (value) => {
    this.setState({ searchValue: value, reset: false });
  };

  showSearchInput = () => {
    const { searchVisible, searchValue } = this.state;
    if (searchVisible && searchValue) {
      this.setSearchValue(null);
    }
    this.setState({
      searchVisible: !searchVisible,
    });
  };

  toggleFiltersDrawer = () => {
    const { showFiltersDrawer } = this.state;
    this.setState({ showFiltersDrawer: !showFiltersDrawer });
  };

  handleAddNewWorkpack = () => {
    this.setState({ showDrawer: true, mode: 'new', currentWorkpack: null, workpackId: null });
  };

  updateWorkpackId = (id) => {
    this.setState({ workpackId: id });
  };

  onSortChange = (sortBy) => {
    this.setState({ sortBy });
  };

  resetFilters = () => {
    this.setState({
      reset: true,
      sortBy: [],
      searchValue: '',
      page: 1,
      filters: {
        status: [],
        source: [],
      },
    });
  };

  render() {
    const {
      userSettings,
      workpacksArray,
      currentAircraft,
      intl: { formatMessage },
      match,
      menu,
    } = this.props;
    const {
      searchValue,
      loading,
      wpLoading,
      localWorkpacksArray,
      originalList,
      showDrawer,
      workpackId,
      mode,
      showFiltersDrawer,
      workpacksFilterModel,
      currentWorkpack,
      page,
      limit,
      workpackCount,
      resetToOne,
      modalVisible,
      reset,
    } = this.state;
    const workpacksToReview = workpacksArray.filter((workpack) => {
      return workpack.status === 'pending';
    });

    let tableStyles = styles.tableWrapper;
    let buttonText = formatMessage({ id: 'form.button.addWorkpack' });
    if (window.innerWidth < 1100) buttonText = 'Add';
    if (!menu.collapsed && window.innerWidth < 1250) buttonText = 'Add';
    if (menu.collapsed) {
      tableStyles = styles.tableWrapperCollapsedMenu;
    }
    return (
      <InnerMenuLayout>
        <div>
          {showDrawer && (
            <WorkpackDrawer
              toggleDrawer={this.toggleDrawer}
              initialMode={mode}
              wp={currentWorkpack}
              pendingWps={workpacksToReview}
              ac={currentAircraft}
              wpId={workpackId}
              handleDeleteWorkpack={this.handleDeleteWorkpack}
              updateWorkpackid={this.updateWorkpackId}
              match={match}
              wpLoading={wpLoading}
              handleWorkpackLoading={this.handleWorkpackLoading}
              bannerVisible={menu.bannerVisible}
              handleSetCurrentWorkpack={this.handleSetCurrentWorkpack}
            />
          )}
        </div>
        <Card className={styles.workpacksCard}>
          <Loading contain loading={loading && !showDrawer} />
          <div className={styles.pageContentWrapper}>
            <div className={styles.mainCol}>
              {workpacksToReview.length > 0 && (
                <PageMessage
                  type="info"
                  typeIcon={infoIcon}
                  text={formatMessage({ id: 'title.workpacksToReview' }, { workpacks: workpacksToReview.length })}
                  actionText={formatMessage({ id: 'message.startReviewing' })}
                  actionFunction={() =>
                    this.toggleWorkpackDrawer({ original: { id: workpacksToReview[0].id } }, 'view')
                  }
                  data-test="pageMessage"
                />
              )}
              <div className={styles.tableHeader}>
                <span className={styles.tableTitle}>
                  {formatMessage({ id: 'title.workpacksCount' }, { count: workpackCount })}
                </span>

                <div className={styles.tableActions}>
                  <div id="searchWrapper" className={styles.searchWrapper}>
                    <Search
                      onChange={(e) => this.setSearchValue(e.currentTarget.value)}
                      onClear={() => this.setSearchValue('')}
                      reset={reset}
                    />
                  </div>
                  <TFButton size={ButtonSize.MEDIUM} onClick={this.handleAddNewWorkpack}>
                    <span className={styles.buttonText}>
                      <img src={plusIcon} alt="plus icon" /> {buttonText}
                    </span>
                  </TFButton>
                  <button className={styles.filterButton} type="button" onClick={this.toggleFiltersDrawer}>
                    <img src={filtersIcon} alt="Open filters" className={styles.filterIcon} />
                  </button>
                </div>
              </div>
              {localWorkpacksArray && localWorkpacksArray.length ? (
                <div id="workpackTable" className={tableStyles}>
                  <WorkPacksTable
                    toggleWorkpackDrawer={this.toggleWorkpackDrawer}
                    data={localWorkpacksArray}
                    userSettings={userSettings}
                    globalFilter={searchValue}
                    match={match}
                    dateFormat={userSettings?.dateFormat}
                    loading={loading}
                    handleDeleteWorkpack={this.handleDeleteWorkpack}
                    onPaginationChange={(currentPage, numberOfItems) => {
                      if (numberOfItems !== limit) {
                        this.setState({ page: 1, limit: numberOfItems });
                      } else if (currentPage !== page) {
                        this.setState({ page: currentPage });
                      }
                    }}
                    onSortChange={(sort) => this.onSortChange(sort)}
                    total={workpackCount}
                    pageSize={limit}
                    pageIndex={page - 1}
                    resetToOne={resetToOne}
                  />
                </div>
              ) : (
                <EmptyState
                  image={EmptyStateWorkpacks}
                  text={originalList.length === 0 ? 'No workpacks' : "We couldn't find any matching workpacks"}
                  subText={
                    originalList.length === 0
                      ? 'You can add your first workpack now.'
                      : 'Try adjusting your filters or searching with another term.'
                  }
                  button={originalList.length === 0 ? 'Add new workpack' : 'Clear all'}
                  buttonAction={originalList.length === 0 ? this.handleAddNewWorkpack : this.resetFilters}
                />
              )}
            </div>
            <div className={styles.filtersCol}>
              <FilterSideBar
                data={workpacksArray}
                updateArray={this.updateLocalWorkpacksArray}
                filterGroups={workpacksFilterModel}
                reset={reset}
                onChange={(e) => {
                  if (e.length === 0) {
                    this.setState({ filters: { source: [], status: [] } });
                  } else {
                    // const newFilters = e.map((filterObj) => filterObj.value);
                    const newFilters = e.reduce((newObject, object) => {
                      const workingObject = newObject;
                      if (!workingObject[object.key]) {
                        workingObject[object.key] = [object.value];
                      } else {
                        workingObject[object.key].push(object.value);
                      }
                      return workingObject;
                    }, {});
                    this.setState({ filters: newFilters });
                  }
                }}
              />
            </div>
          </div>
        </Card>
        <Modal isOpen={modalVisible} width={420} handleClose={() => this.setState({ modalVisible: false })}>
          <div className={styles.modalContentWrapper}>
            <div className={styles.modalTitle}>{formatMessage({ id: 'title.deleteItem' })}</div>
            <div className={styles.modalMessage}>
              {`${formatMessage({
                id: 'form.question.areYouSureDeleteDefect',
              })} ${formatMessage({
                id: 'form.labels.cannotBeUndone',
              })}`}
            </div>
            <div className={styles.modalButtonWrapper}>
              <div className={styles.submitButton}>
                <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={this.confirmDeleteWorkpack}>
                  Delete
                </TFButton>
              </div>
              <TFButton
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                primary={false}
                onClick={() => this.setState({ modalVisible: false })}
              >
                Cancel
              </TFButton>
            </div>
          </div>
        </Modal>
        {showFiltersDrawer && (
          <SlidingDrawer filterDrawer toggleDrawer={this.toggleFiltersDrawer}>
            <div className={styles.drawerHeader}>
              <span>{formatMessage({ id: 'title.filters' })}</span>
            </div>
            <FilterSideBar
              data={workpacksArray}
              updateArray={this.updateLocalWorkpacksArray}
              filterGroups={workpacksFilterModel}
              reset={reset}
              onChange={(e) => {
                if (e.length === 0) {
                  this.setState({ filters: { source: [], status: [] } });
                } else {
                  // const newFilters = e.map((filterObj) => filterObj.value);
                  const newFilters = e.reduce((newObject, object) => {
                    const workingObject = newObject;
                    if (!workingObject[object.key]) {
                      workingObject[object.key] = [object.value];
                    } else {
                      workingObject[object.key].push(object.value);
                    }
                    return workingObject;
                  }, {});
                  this.setState({ filters: newFilters });
                }
              }}
            />
          </SlidingDrawer>
        )}
      </InnerMenuLayout>
    );
  }
}

export default withRouter(
  injectIntl(
    connect(
      ({ workpacks, userSettings, aircraft, menu }, { match }) => ({
        workpacksArray: Array.from(workpacks.workpacksMap.values()).filter(
          (pack) => pack.aircraft_id === match.params.id,
        ),
        currentAircraft: Array.from(aircraft.aircraftMap.values()).filter(
          (currAircraft) => currAircraft.id === match.params.id,
        ),
        lastFetched: workpacks.lastFetched,
        userSettings,
        menu,
      }),
      (dispatch) => ({
        getAllWorkpacks: (payload) => {
          dispatch(
            getAllWorkpacks({
              payload,
            }),
          );
        },
        fetchSingleAircraft: (payload) => {
          dispatch(
            getSingleAircraft({
              payload,
            }),
          );
        },
      }),
    )(Workpacks),
  ),
);
