import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { SectionHeader } from './FlightDrawer';
import Deicing from './Deicing';
import OilUplifts from './OilUplifts';

interface UpliftsSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
  displayUplifts: () => boolean;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const UpliftsSection: React.FC<UpliftsSectionProps> = ({ flight, updateFlightData, displayUplifts }) => {
  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);
  const { formatMessage } = useIntl();
  return (
    <SectionWrapper data-testid="FlightDrawer-UpliftsSection">
      <HeaderWrapper>
        <SectionHeader marginLeft="20px" reducedBottomMargin data-testid="UpliftsSection-Header">
          {formatMessage({ id: 'text.sectorUplifts' })}
        </SectionHeader>
      </HeaderWrapper>
      {flight?.deice_events?.length || mode !== 'view' ? (
        <Deicing flight={flight} updateFlightData={updateFlightData} />
      ) : null}
      {displayUplifts() || mode !== 'view' ? <OilUplifts flight={flight} updateFlightData={updateFlightData} /> : null}
    </SectionWrapper>
  );
};

export default UpliftsSection;
