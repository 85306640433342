import React, { useCallback, useEffect, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { intl } from '../../../providers/IntlProvider';
import style from './SignaturePad.module.less';

const Signature = ({ setAcceptanceSig, width, revertChanges }) => {
  const [canvasTouched, setCanvasTouched] = useState(false);

  let sigPad = {};

  const clear = useCallback(() => {
    sigPad.clear();
    setCanvasTouched(false);
    setAcceptanceSig('');
  }, [setAcceptanceSig, sigPad]);

  const onSignatureBegin = () => {
    setCanvasTouched(true);
  };

  const onSignatureEnd = () => {
    if (sigPad) {
      const signatureData = sigPad
        .getCanvas()
        .toDataURL()
        .substring(22);
      setAcceptanceSig(signatureData);
    }
  };

  useEffect(() => {
    if (revertChanges) clear();
  }, [clear, revertChanges]);

  return (
    <div>
      <div className={style.signaturePad}>
        <SignaturePad
          ref={(ref) => {
            sigPad = ref;
          }}
          canvasProps={{
            width,
            height: 100,
          }}
          onBegin={() => onSignatureBegin()}
          onEnd={() => onSignatureEnd()}
        />
        <div className={style.signatureHelper} data-test="signatureHelper">
          <span>{intl.formatMessage({ id: 'text.pleaseSignHereToConfirmDefect' })}</span>
          {canvasTouched && (
            <button type="button" onClick={() => clear()} className={style.clearButton}>
              {intl.formatMessage({ id: 'form.button.clear' })}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Signature.propTypes = {
  setAcceptanceSig: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revertChanges: PropTypes.bool.isRequired,
};

Signature.defaultProps = {
  width: 500,
};

export default Signature;
