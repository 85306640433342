import React from 'react';
import { Button } from '@arcflight/tf-component-library';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import styles from './index.module.less';
import ApproveContent from './ApproveContent';
import WorkpackContent from './WorkpackContent';

const PageOverlayAction = ({ visible, type, items, onCancel, onSuccess, workpacks, aircraft }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {visible && (
        <div className={styles.pageOverlay}>
          <div className={styles.leftParentWrapper}>
            <div className={styles.leftWrapper} data-test="selectedItems">
              {formatMessage({ id: 'text.selectedItems' })}:{' '}
              <div className={styles.selectedButton} data-test="selectedItemsButton">
                {items.length}
              </div>
            </div>
            <div className={styles.centralWrapper}>
              {type === 'crew' && (
                <Button onClick={() => onSuccess()} size={ButtonSize.MEDIUM}>
                  {formatMessage({ id: 'form.button.removeSelected' })}
                </Button>
              )}
              {type === 'drafts' && (
                <ApproveContent items={items} onSuccess={() => onSuccess()} data-test="approveContent" />
              )}
              {type === 'workpack' && (
                <WorkpackContent
                  items={items}
                  workpacks={workpacks}
                  aircraft={aircraft}
                  onSuccess={() => onSuccess()}
                  data-test="approveContent"
                />
              )}
            </div>
          </div>
          <div className={styles.rightWrapper}>
            <Button size={ButtonSize.MEDIUM} primary={false} onClick={() => onCancel()} data-test="cancelButton">
              {formatMessage({ id: 'form.button.cancel' })}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

PageOverlayAction.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  workpacks: PropTypes.array,
  aircraft: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

PageOverlayAction.defaultProps = {
  aircraft: {},
  workpacks: [],
};

export default PageOverlayAction;
