import { Card, Col, Row } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { compose } from 'redux';
import Loading from '../../components/TFLoading';
import ReactGoogleMaps from '../../components/ReactGoogleMaps';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { getRoutes } from '../../services/api';
import { getSingleAircraft } from '../../models/aircraft/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { getAirports } from '../../models/airports/actions';
import rightChevron from '../../assets/right-chevron.svg';
import AircraftOverviewTiles from './AircraftOverviewTiles';
import OverviewRecentTrips from './OverviewRecentTrips';
import styles from './Overview.module.less';
import AircraftCard from './AircraftCard';

class Overview extends Component {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    airportsMap: PropTypes.instanceOf(Map).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      timeRange: [
        moment()
          .subtract(1, 'weeks')
          .format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
      selection: 'week',
      routes: [],
      maxZoomOut: 2,
      zoomVal: 2,
      switchZoomOn: true,
      isAircraftTotalsVisible: false,
      loading: false,
      selectedAircraft: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const aircraft = this.getAircraft(true);
    if (aircraft && Date.now() - aircraft.lastFetched < 30000) {
      this.getAirport();
    }

    if (match.params.id) {
      this.getFilteredRoutes();
      this.getZoom();
      window.addEventListener('resize', this.getZoom);
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { match, aircraftMap } = this.props;
    const prevAircraft = prevProps.aircraftMap.get(prevProps.match.params.id);
    const aircraft = this.getAircraft();

    if (prevAircraft !== aircraft) {
      this.getAirport();
    }

    if (!_.isEqual(aircraftMap, prevProps.aircraftMap)) {
      this.setSelectedAircraft();
    }

    if (prevProps.match.params.id !== match.params.id) {
      this.getFilteredRoutes();
      this.getZoom();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getZoom);
  }

  getAircraft = (forceRefetch = false) => {
    if (forceRefetch) {
      this.setState({ loading: true });
      this.props.dispatch(
        getSingleAircraft({
          payload: this.props.match.params.id,
        }),
      );
    }
    return this.props.aircraftMap.get(this.props.match.params.id);
  };

  setSelectedAircraft = () => {
    const { aircraftMap, match } = this.props;
    const selectedAircraft = aircraftMap.get(match.params.id);
    if (selectedAircraft?.tiles) {
      this.setState({ selectedAircraft, loading: false });
    }
  };

  getAirport = () => {
    const aircraft = this.getAircraft();
    if (aircraft.last_airport && aircraft.flight_status !== 'in_flight') {
      this.props.dispatch(
        getAirports({
          payload: aircraft.last_airport,
        }),
      );
    }
  };

  getFilteredRoutes = () => {
    if (this.props.match.params.id) {
      getRoutes({
        from: moment(this.state.timeRange[0]).format('YYYY-MM-DD'),
        to: moment(this.state.timeRange[1]).format('YYYY-MM-DD'),
        aircraft: this.props.match.params.id,
      })
        .then((routes) => {
          this.setState({ routes });
        })
        .catch(() => {
          this.setState({ routes: [] });
        });
    } else {
      this.setState({ routes: [] });
    }
  };

  getZoom = () => {
    if (this.state.switchZoomOn) {
      let maxZoomLevel = 2;
      let zoomVal = 1;
      if (window.innerWidth >= 1800) {
        maxZoomLevel = 3;
        zoomVal = 2;
      } else if (window.innerWidth < 1800 && window.innerWidth > 1200) {
        maxZoomLevel = 2;
        zoomVal = 2;
      } else if (window.innerWidth <= 1200 && window.innerWidth > 992) {
        maxZoomLevel = 1;
        zoomVal = 2;
      }
      this.setState({ maxZoomOut: maxZoomLevel, zoomVal });
    }
  };

  selectDate = (type) => {
    if (type === 'all') {
      this.setState(
        {
          timeRange: [moment('1900-01-01').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          selection: type,
        },
        () => {
          this.getFilteredRoutes();
        },
      );
    } else if (type === 'week') {
      this.setState(
        {
          timeRange: [
            moment()
              .subtract(1, 'weeks')
              .format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
          selection: type,
        },
        () => {
          this.getFilteredRoutes();
        },
      );
    } else if (type === 'month') {
      this.setState(
        {
          timeRange: [
            moment()
              .subtract(30, 'days')
              .format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
          selection: type,
        },
        () => {
          this.getFilteredRoutes();
        },
      );
    } else if (type === '3month') {
      this.setState(
        {
          timeRange: [
            moment()
              .subtract(90, 'days')
              .format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
          ],
          selection: type,
        },
        () => {
          this.getFilteredRoutes();
        },
      );
    }
  };

  isActive = (type) => {
    if (type === this.state.selection) {
      return `${styles.mapDateLink} ${styles.mapDateLinkActive}`;
    }
    return styles.mapDateLink;
  };

  handleShowAircraftTotalsClick = () => {
    const { isAircraftTotalsVisible } = this.state;
    this.setState({ isAircraftTotalsVisible: !isAircraftTotalsVisible });
  };

  render() {
    const {
      intl: { formatMessage },
      airportsMap,
    } = this.props;
    const { routes, zoomVal, maxZoomOut, isAircraftTotalsVisible, loading, selectedAircraft } = this.state;
    const aircraft = this.getAircraft();
    const currentAirport = aircraft ? airportsMap.get(aircraft.last_airport) : null;

    return (
      <InnerMenuLayout loading={loading}>
        <Loading loading={loading} />

        <div className={styles.overviewContent}>
          <Card>
            <AircraftCard
              aircraft={selectedAircraft}
              currentAirport={currentAirport}
              isAircraftTotalsVisible={isAircraftTotalsVisible}
              handleShowAircraftTotalsClick={this.handleShowAircraftTotalsClick}
            />
          </Card>
          <Row gutter={25} className={styles.tilesRow}>
            <Col lg={24} xl={11} xxl={10}>
              <Card>
                {!loading && selectedAircraft ? (
                  <AircraftOverviewTiles aircraft={selectedAircraft} />
                ) : (
                  <div className={styles.loading}>{formatMessage({ id: 'text.loading' })}</div>
                )}
              </Card>
            </Col>
            <Col lg={24} xl={13} xxl={14} style={{ marginTop: window.innerWidth < 1200 && '24px' }}>
              <Card className={styles.overviewCard}>
                <div className={styles.tripsContainer}>
                  <div className={styles.headerWrapper}>
                    <div className={styles.partTitle} data-test="tripsTitle">
                      {formatMessage({ id: 'title.recentTrips' })}
                    </div>
                    <Link
                      to={aircraft ? `/aircraft/${aircraft.id}/trips` : ''}
                      className={styles.viewAllButton}
                      data-test="viewAllTripsLink"
                    >
                      <Button size={ButtonSize.MEDIUM} primary={false} onClick={null}>
                        <div className={styles.viewAllWrapper}>
                          <div className={styles.viewAllText}>{formatMessage({ id: 'title.viewAll' })}</div>
                          <img src={rightChevron} alt="chevron" />
                        </div>
                      </Button>
                    </Link>
                  </div>
                </div>
                <OverviewRecentTrips aircraft={aircraft} data-test="tripsTable" />
              </Card>
            </Col>
          </Row>
          <Row>
            <Card className={styles.mapCard}>
              <div className={styles.partTitleContainer}>
                <div className={styles.partTitle} data-test="aircraftRoutesTitle">
                  {formatMessage({ id: 'title.routes' })}
                </div>
                <div className={styles.routesDatesSelection}>
                  <div className={styles.partSubTitle}>Showing:</div>
                  <button
                    type="button"
                    className={this.isActive('week')}
                    onClick={() => this.selectDate('week')}
                    data-test="routesWeek"
                  >
                    {formatMessage({ id: 'period.last7Days' })}
                  </button>
                  <button
                    type="button"
                    className={this.isActive('month')}
                    onClick={() => this.selectDate('month')}
                    data-test="routesMonth"
                  >
                    {formatMessage({ id: 'period.last30Days' })}
                  </button>
                  <button
                    type="button"
                    className={this.isActive('3month')}
                    onClick={() => this.selectDate('3month')}
                    data-test="routes3Month"
                  >
                    {formatMessage({ id: 'period.last90Days' })}
                  </button>
                  <button
                    type="button"
                    className={this.isActive('all')}
                    onClick={() => this.selectDate('all')}
                    data-test="routesAll"
                  >
                    {formatMessage({ id: 'period.allTime' })}
                  </button>
                </div>
              </div>
              <div className={styles.mapHolder}>
                <ReactGoogleMaps
                  routes={!routes ? [] : routes}
                  className={styles.mapRoutes}
                  initialZoom={zoomVal}
                  maxZoomOut={maxZoomOut}
                  currentLocation={currentAirport}
                  mapSource="Overview"
                  containerWidth="100%"
                  containerHeight="350px"
                  fullscreenControl={false}
                  data-test="routesMap"
                />
              </div>
            </Card>
          </Row>
        </div>
      </InnerMenuLayout>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(({ aircraft, airports, operations, trips }) => ({
    aircraftMap: aircraft.aircraftMap,
    airportsMap: airports.airportsMap,
    operations,
    routesMap: operations.routesMap,
    trip: trips,
  })),
)(Overview);
