import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

interface TimeInputProps {
  input: number;
  onChange: (value: number) => void;
  sectorDrawer?: boolean;
  onBlur?: () => void;
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: ${({ mins }): string => (mins ? '43px' : '55px')};
  height: ${({ sectorDrawer }): string => (sectorDrawer ? '34px' : '40px')};
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  padding: 11px;
`;

const TimeInput: React.FC<TimeInputProps> = ({ input, onChange, sectorDrawer, onBlur }): ReactElement => {
  const [hours, setHours] = useState(undefined);
  const [mins, setMins] = useState(undefined);
  useEffect(() => {
    if (input) {
      setHours(Math.floor(input / 3600));
      setMins(Math.floor(input / 60) % 60);
    }
  }, [input]);

  const calculateTime = (hrs: number, minutes: number): number => {
    const newTime = (hrs || 0) * 3600 + (minutes || 0) * 60;
    return newTime;
  };

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setHours(event.target.value);
    onChange(calculateTime(parseInt(event.target.value, 10), mins));
  };

  const handleMinsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMins(event.target.value);
    onChange(calculateTime(hours, parseInt(event.target.value, 10)));
  };
  return (
    <InputWrapper>
      <StyledInput
        sectorDrawer={sectorDrawer}
        type="number"
        value={hours}
        onChange={handleHoursChange}
        min={0}
        onBlur={onBlur}
      />
      {sectorDrawer ? '.' : ':'}
      <StyledInput
        mins
        sectorDrawer={sectorDrawer}
        type="number"
        value={mins}
        onChange={handleMinsChange}
        min={0}
        onBlur={onBlur}
        max={59}
      />
    </InputWrapper>
  );
};

export default TimeInput;
