import React from 'react';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Flight } from '../../models/flights';
import EmptyStateDefects from '../../assets/emptyState/empty-state-sector-defects.svg';
import { DashboardState } from '../../models';
import plusIcon from '../../assets/plus-blue.svg';
import { changeDrawerContent, changeDrawerMode } from '../../models/drawer';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import { SectionHeader } from './FlightDrawer';
import FlightDefectsTable from './FlightDefectsTable';

interface DefectsSectionProps {
  flight: Flight;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const NoDefectsText = styled.span`
  margin-left: 8px;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const DefectsSection: React.FC<DefectsSectionProps> = ({ flight }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const handleAddDefect = (): void => {
    dispatch(
      changeDrawerContent({
        payload: {
          content: <DefectDrawer defectId="" passedFlightId={flight?.id} />,
          backButton: true,
        },
      }),
    );
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  return (
    <SectionWrapper data-testid="FlightDrawer-DefectsSection">
      <HeaderWrapper>
        <SectionHeader marginLeft="20px" reducedBottomMargin data-testid="DefectsSection-Header">
          {formatMessage({ id: 'text.sectorDefects' })}
        </SectionHeader>
        {mode !== 'add' && flight?.defects?.length !== 0 ? (
          <Button height="24px" onClick={handleAddDefect} primary={false}>
            <AddTextWrapper>
              <PlusIcon src={plusIcon} alt="plus icon" /> Add defect
            </AddTextWrapper>
          </Button>
        ) : null}
      </HeaderWrapper>
      <div>
        {flight?.defects?.length ? (
          <FlightDefectsTable defects={flight?.defects} />
        ) : (
          <EmptyStateDiv data-testid="DefectsSection-EmptyState">
            <div>
              <img src={EmptyStateDefects} alt="no defects" />
              <NoDefectsText>No defects currently recorded on this sector</NoDefectsText>
            </div>
            {mode !== 'add' ? (
              <Button height="24px" onClick={handleAddDefect} primary={false}>
                <AddTextWrapper>
                  <PlusIcon src={plusIcon} alt="plus icon" /> Add defect
                </AddTextWrapper>
              </Button>
            ) : null}
          </EmptyStateDiv>
        )}
      </div>
    </SectionWrapper>
  );
};

export default DefectsSection;
