import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Row, Col, Radio, Select } from 'antd';
import { Modal, Button } from '@arcflight/tf-component-library';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { FeatureFlag } from '../../../models/userSettings';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import APUPopconfirm from '../APUPopconfirm/APUPopconfirm';
import { update } from '../../../models/aircraft/actions';
import styles from './styles.module.less';

const { Option } = Select;

class AircraftSettingsModal extends Component {
  static propTypes = {
    updateAircraftSettings: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    visibleSettingsModal: PropTypes.bool,
    closeSettingsModal: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    srpTemplates: PropTypes.array.isRequired,
  };

  static defaultProps = {
    visibleSettingsModal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      serialError: '',
      yearError: '',
      fuelError: '',
    };
  }

  onChange = async (value, title) => {
    const { selectedAircraft } = this.props;
    await new Promise((resolve) => {
      if (title === 'serial_number') {
        this.setState({ serialError: '' }, resolve);
      }
      if (title === 'year') {
        this.setState({ yearError: '' }, resolve);
      }
      if (title === 'max_fuel_value') {
        this.setState({ fuelError: '' }, resolve);
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      formData: { ...selectedAircraft, ...prevState.formData, [`${title}`]: value },
    }));
  };

  settingsDetailsForm = () => {
    const {
      intl: { formatMessage },
      form,
      selectedAircraft,
      srpTemplates,
      userSettings: { operators },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { serialError, yearError, fuelError } = this.state;
    const operator = operators.find((op) => op.id === selectedAircraft.operator_id);
    const featureFlags = operator.feature_flags.map((f) => f.feature_name);
    const hasSRPFeatureFlag = featureFlags.includes(FeatureFlag.SRPVIEW);
    return (
      <div data-test="aircraftSettingsModal">
        <div className={styles.headerWrapper}>
          <div className={styles.title} data-test="headerTitle">
            {formatMessage(
              { id: 'title.generalSettingsReg' },
              { field: selectedAircraft ? selectedAircraft.registration : null },
            )}
          </div>
          <div className={styles.subTitle} data-test="headerSubtitle">
            {formatMessage({ id: 'title.generalSettingsSub' })}
          </div>
        </div>
        <Form onSubmit={this.handleSubmit} className={styles.editableDetailsForm} data-test="aircraftForm">
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.serialNum' })} data-test="formSerialNumber">
                {getFieldDecorator('serial_number', {
                  rules: [
                    {
                      required: true,
                      message: () => this.setState({ serialError: formatMessage({ id: 'message.addSerialNum' }) }),
                    },
                  ],
                  initialValue: selectedAircraft ? selectedAircraft.serial_number || '-' : '-',
                })(
                  <Input
                    className={styles.editableCellInput}
                    onChange={(e) => this.onChange(e.target.value.toUpperCase(), 'serial_number')}
                    data-test="inputSerialNum"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.year' })} data-test="formYear">
                {getFieldDecorator('year', {
                  rules: [
                    {
                      required: true,
                      type: 'number',
                      message: () =>
                        this.setState({ yearError: formatMessage({ id: 'message.addYearOfManufacture' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () => this.setState({ yearError: formatMessage({ id: 'message.addYear' }) }),
                    },
                  ],
                  initialValue: selectedAircraft ? selectedAircraft.year || 1903 : 1903,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'year')}
                    min={1903}
                    data-test="inputYear"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={serialError || yearError ? styles.errorDiv : styles.errorDivHidden}>
            {(serialError || yearError) && (
              <Row style={{ width: '100%' }} gutter={24}>
                <Col xs={12}>
                  <div className={`${styles.errorMessage} ${styles.serialNumError}`} data-test="serialErrorMessage">
                    {this.state.serialError}
                  </div>
                </Col>
                <Col xs={12}>
                  <div className={styles.errorMessage} data-test="yearErrorMessage">
                    {this.state.yearError}
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.fuelCapacity' })} data-test="formMaxCapacity">
                <div className={styles.fuelCapacityEditDiv}>
                  {getFieldDecorator('max_fuel_value', {
                    rules: [
                      {
                        required: true,
                        type: 'number',
                        message: () =>
                          this.setState({ fuelError: formatMessage({ id: 'message.addMaxFuelCapacity' }) }),
                      },
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: () => this.setState({ fuelError: formatMessage({ id: 'message.addYear' }) }),
                      },
                    ],
                    initialValue: selectedAircraft ? selectedAircraft.max_fuel_value || 0 : 0,
                  })(
                    <InputNumber
                      className={styles.editableCellCapacityInput}
                      onChange={(e) => this.onChange(e, 'max_fuel_value')}
                      min={0}
                      data-test="inputMaxFuel"
                    />,
                  )}
                  <span style={{ marginLeft: '4px' }} className={styles.fuelUnit} data-test="fuelUnitSuffix">
                    {this.props.form.getFieldValue('fuel_unit') || (selectedAircraft && selectedAircraft.fuel_unit)}
                  </span>
                </div>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.fuelUnit' })} data-test="formFuelUnit">
                {getFieldDecorator('fuel_unit', {
                  initialValue: selectedAircraft ? selectedAircraft.fuel_unit || 'lb' : '-',
                })(
                  <Radio.Group buttonStyle="solid" onChange={(e) => this.onChange(e)} data-test="radioGroupFuel">
                    <Radio.Button className={styles.radioButton} value="lb" data-test="lbRadioButtonFuel">
                      {formatMessage({ id: 'units.lb' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="kg" data-test="kgRadioButtonFuel">
                      {formatMessage({ id: 'units.kg' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="l" data-test="lRadioButtonFuel">
                      {formatMessage({ id: 'units.ltr' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="usg" data-test="usgRadioButtonFuel">
                      {formatMessage({ id: 'units.usg' })}
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={fuelError ? styles.errorDiv : styles.errorDivHidden}>
            {fuelError && (
              <Row gutter={24} style={{ width: '100%' }}>
                <div className={styles.errorMessage} data-test="fuelErrorMessage">
                  {this.state.fuelError}
                </div>
              </Row>
            )}
          </div>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.oilUnit' })} data-test="formOilUnit">
                {getFieldDecorator('oil_unit', {
                  initialValue: selectedAircraft ? selectedAircraft.oil_unit || 'ml' : '-',
                })(
                  <Radio.Group buttonStyle="solid" onChange={(e) => this.onChange(e)} data-test="radioGroupOil">
                    <Radio.Button className={styles.radioButton} value="ml" data-test="firstRadioButtonOil">
                      {formatMessage({ id: 'units.ml' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="qt" data-test="secondRadioButtonOil">
                      {formatMessage({ id: 'units.qt' })}
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label={formatMessage({ id: 'form.labels.apu' })}
                className={styles.apuFormItem}
                data-test="formAPU"
              >
                {getFieldDecorator('apu_mode', {
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'message.hasApu' }),
                    },
                  ],
                  initialValue: selectedAircraft.apu_mode,
                })(
                  <Select style={{ width: 130, height: 34 }} data-test="apuSelector">
                    <Option value="not_installed">{formatMessage({ id: 'text.notInstalled' })}</Option>
                    <Option value="totalled">{formatMessage({ id: 'text.totalled' })}</Option>
                    <Option value="cumulative">{formatMessage({ id: 'text.cumulative' })}</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          {hasSRPFeatureFlag && (
            <Row gutter={24}>
              <Col xs={12}>
                <Form.Item label={formatMessage({ id: 'form.labels.srpTemplate' })} data-test="srpTemplate">
                  {getFieldDecorator('srp_template_id', {
                    initialValue: selectedAircraft ? selectedAircraft.srp_template_id || '' : '-',
                  })(
                    <Select style={{ height: 34 }} data-test="srpSelector">
                      {srpTemplates.map((template) => (
                        <Option key={template.id} value={template.id}>
                          {template.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
        <div className={styles.footerWrapper}>
          <Button padding="0 28px" size={ButtonSize.MEDIUM}>
            <APUPopconfirm aircraft={selectedAircraft} form={form} handleSubmit={this.handleSubmit} />
          </Button>
          <div className={styles.cancelButtonWrapper}>
            <Button
              size={ButtonSize.MEDIUM}
              primary={false}
              padding="0 28px"
              onClick={() => {
                this.props.closeSettingsModal();
                this.resetErrors();
              }}
              data-test="cancelButton"
            >
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  resetErrors = () => {
    this.setState({
      regError: '',
      serialError: '',
      yearError: '',
      fuelError: '',
    });
  };

  handleSubmit = () => {
    const { form, selectedAircraft } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.form.resetFields();
        const formValues = values;
        delete selectedAircraft.apu_installed;
        const payload = {
          update: true,
          aircraftSettings: true,
          aircraftId: selectedAircraft.id,
          aircraft: {
            ...selectedAircraft,
            ...formValues,
          },
        };
        this.props.updateAircraftSettings(payload);
        this.props.closeSettingsModal();
        this.props.setLoading();
      }
    });
  };

  handleModalClose = () => {
    const { closeSettingsModal } = this.props;
    this.resetErrors();
    closeSettingsModal();
  };

  render() {
    const { visibleSettingsModal } = this.props;
    return (
      <Modal isOpen={visibleSettingsModal} handleClose={this.handleModalClose}>
        {this.settingsDetailsForm(true)}
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    ({ aircraft, userSettings }) => ({
      aircraftMap: aircraft.aircraftMap,
      userSettings: userSettings.details,
    }),
    (dispatch) => ({
      updateAircraftSettings: (payload) => {
        return dispatch(
          update({
            payload,
          }),
        );
      },
    }),
  ),
  Form.create(),
)(AircraftSettingsModal);
