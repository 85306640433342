import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSingleAircraft } from '../../models/aircraft/actions';
import styles from './AircraftSettings.module.less';

class AircraftSettingsForm extends Component {
  static propTypes = {
    fetchAircraft: PropTypes.func.isRequired,
    aircraftMap: PropTypes.instanceOf(Map),
    id: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    activeTemplate: PropTypes.object.isRequired,
  };

  static defaultProps = {
    aircraftMap: new Map(),
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      regError: '',
      serialError: '',
      yearError: '',
      fuelError: '',
    };
  }

  componentDidMount() {
    const aircraft = this.getAircraft();
    if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
      this.getAircraft(true);
    }
  }

  componentDidUpdate(prevProps) {
    // if the aircraft has changed reset the from
    const { id } = this.props;
    const { loading } = this.state;
    if (id !== prevProps.id) {
      const aircraft = this.getAircraft();
      if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
        this.getAircraft(true);
      }
    }
    // if the aircaft has been updated reset the form
    const aircraft = this.getAircraft();
    const prevAircraft = prevProps.aircraftMap.get(prevProps.id);
    if (loading && prevAircraft && aircraft.lastFetched !== prevAircraft.lastFetched) {
      this.onLoaded();
    }
  }

  onLoaded = () => {
    this.setState({ loading: false });
  };

  getAircraft = (forceRefetch = false) => {
    if (forceRefetch) {
      this.setState({ loading: true }, () => this.props.fetchAircraft(this.props.id));
    }
    return this.props.aircraftMap.get(this.props.id);
  };

  settingsDetailsForm = () => {
    const {
      intl: { formatMessage },
      activeTemplate,
    } = this.props;
    const { regError, serialError, yearError, fuelError } = this.state;
    const selectedAircraft = this.getAircraft();

    let displayAPUFormItem = null;
    if (selectedAircraft && selectedAircraft.apu_installed) {
      displayAPUFormItem = (
        <Form.Item label={formatMessage({ id: 'form.labels.apu' })} className={styles.apuFormItem} data-test="formAPU">
          <span data-test="aircraftAPU" className={styles.cellInput}>
            {formatMessage({ id: 'form.button.yes' })}
          </span>
        </Form.Item>
      );
    }

    let fuelUnitDisplay = '-';
    if (selectedAircraft && selectedAircraft.fuel_unit === 'l') {
      fuelUnitDisplay = 'ltr';
    } else if (selectedAircraft) {
      fuelUnitDisplay = selectedAircraft.fuel_unit;
    }

    return (
      <>
        <Form onSubmit={this.handleSubmit} className={styles.detailsForm} data-test="aircraftForm">
          <Row gutter={24} className={styles.formRow}>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.registration' })} data-test="formRegistration">
                <span data-test="aircraftReg" className={styles.cellInput}>
                  {selectedAircraft && selectedAircraft.registration ? selectedAircraft.registration : '-'}
                </span>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.serialNum' })} data-test="formSerialNumber">
                <span data-test="aircraftSerial" className={styles.cellInput}>
                  {selectedAircraft && selectedAircraft.serial_number ? selectedAircraft.serial_number : '-'}
                </span>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.year' })} data-test="formYear">
                <span data-test="aircraftYear" className={styles.cellInput}>
                  {selectedAircraft && selectedAircraft.year ? selectedAircraft.year : 1903}
                </span>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.fuelCapacity' })} data-test="formMaxCapacity">
                <div className={styles.fuelCapacityDiv}>
                  <span data-test="aircraftMaxFuel" className={styles.cellInput}>
                    {selectedAircraft && selectedAircraft.max_fuel_value ? selectedAircraft.max_fuel_value : '-'}
                  </span>
                  <span data-test="aircraftMaxFuelUnit" style={{ marginLeft: '4px' }} className={styles.fuelUnit}>
                    {selectedAircraft && selectedAircraft.fuel_unit ? selectedAircraft.fuel_unit : '-'}
                  </span>
                </div>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.fuelUnit' })} data-test="formFuelUnit">
                <span data-test="aircraftFuelUnit" className={styles.cellInput}>
                  {fuelUnitDisplay}
                </span>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.oilUnit' })} data-test="formOilUnit">
                <span data-test="aircraftOilUnit" className={styles.cellInput}>
                  {selectedAircraft && selectedAircraft.oil_unit ? selectedAircraft.oil_unit : '-'}
                </span>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item
                label={formatMessage({ id: 'form.labels.apuMode' })}
                className={styles.apuFormItem}
                data-test="formAPUMode"
              >
                <span data-test="aircraftAPUMode" className={styles.cellInput}>
                  {selectedAircraft &&
                    selectedAircraft?.apu_mode
                      ?.split('_')
                      ?.map((v) => `${v[0].toUpperCase()}${v.slice(1)}`)
                      ?.join(' ')}
                </span>
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              {displayAPUFormItem}
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
              <Form.Item label={formatMessage({ id: 'form.labels.srpTemplate' })} data-test="srpTemplate">
                <span data-test="aircraftSrpTemplate" className={styles.cellInput}>
                  {activeTemplate ? activeTemplate.name : '-'}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <div className={regError || serialError || yearError || fuelError ? styles.errorDiv : styles.errorDivHidden}>
            {(regError || serialError || yearError || fuelError) && (
              <div className={styles.displayErrorDiv}>
                <div className={styles.errorMessage} data-test="regErrorMessage">
                  {this.state.regError}
                </div>
                <div className={styles.errorMessage} data-test="serialErrorMessage">
                  {this.state.serialError}
                </div>
                <div className={styles.errorMessage} data-test="yearErrorMessage">
                  {this.state.yearError}
                </div>
                <div className={styles.errorMessage} data-test="fuelErrorMessage">
                  {this.state.fuelError}
                </div>
              </div>
            )}
          </div>
        </Form>
      </>
    );
  };

  render() {
    return this.settingsDetailsForm();
  }
}

export default injectIntl(
  connect(
    ({ aircraft, menu }) => ({
      aircraftMap: aircraft.aircraftMap,
      collapsed: menu.collapsed,
    }),
    (dispatch) => ({
      fetchAircraft: (id) => {
        return dispatch(
          getSingleAircraft({
            payload: id,
          }),
        );
      },
    }),
  )(AircraftSettingsForm),
);
