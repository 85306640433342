/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { Trip } from '../../models/trips';
import { getAirport } from '../../services/api';
import SelectAirport from '../SelectAirport';
import errorIcon from '../../assets/login-error.svg';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';
import SelectPilot from './SelectPilot';
import SectorTimesSection from './SectorTimesSection';

interface TripDetailsSectionProps {
  flight?: Flight;
  flightDate: string;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
  trip?: Trip;
}

const LocalSelectAirport = SelectAirport as any;

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile && !edit) return '1fr 1fr';
    if (isMobile) return '1fr';
    if (edit) return '1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: 1fr;
  row-gap: 20px;
  margin-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
  padding-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
  border-top: ${({ borderTop }): string => (borderTop ? '1px solid rgba(36, 45, 65, 0.05)' : null)};
`;

const WarningDiv = styled.div`
  color: ${({ error }): string => (error ? '#f5222d' : '#faad14')};
  grid-column: span 2;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const StyledInput = styled.input`
  width: 110px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const TripDetailsSection: React.FC<TripDetailsSectionProps> = ({ flight, flightDate, updateFlightData, trip }) => {
  const { formatMessage } = useIntl();
  const [pilots, setPilots] = useState([]);
  const [pilotFlying, setPilotFlying] = useState(null);
  const [pilotMonitoring, setPilotMonitoring] = useState(null);
  const [departureAirportId, setDepartureAirportId] = useState(undefined);
  const [departAirport, setDepartAirport] = useState(null);
  const [arrivalAirportId, setArrivalAirportId] = useState(undefined);
  const [arrivalAirport, setArrivalAirport] = useState(null);
  const [previousAirportId, setPreviousAirportId] = useState(null);
  const [previousAirport, setPreviousAirport] = useState(null);
  const [airportError, setAirportError] = useState(false);
  const [hobbsArrival, setHobbsArrival] = useState(null);
  const [hobbsDeparture, setHobbsDeparture] = useState(null);

  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const isMobile = window.innerWidth < 451;

  const handlePersonChange = (personId: string): void => {
    updateFlightData([{ value: personId, key: 'pilot_flying_id' }]);
    const captain = pilots.find((p) => p?.value === personId);
    setPilotFlying(captain);
    if (pilots?.length > 1 && personId === pilotMonitoring?.value) {
      setPilotMonitoring({ title: 'None', value: null });
      updateFlightData([
        { value: null, key: 'pilot_monitoring_id' },
        { value: personId, key: 'pilot_flying_id' },
      ]);
    }
  };

  const handleFirstOfficerChange = (personId: string): void => {
    if (!personId) {
      updateFlightData([{ value: null, key: 'pilot_monitoring_id' }]);
    } else {
      updateFlightData([{ value: personId, key: 'pilot_monitoring_id' }]);
    }

    const firstOfficer = pilots.find((p) => p?.value === personId);
    setPilotMonitoring(firstOfficer);
    if (pilots?.length > 1 && personId === pilotFlying?.value) {
      setPilotFlying({ title: 'None', value: null });
      updateFlightData([
        { value: null, key: 'pilot_flying_id' },
        { value: personId, key: 'pilot_monitoring_id' },
      ]);
    }
  };

  const handleAirportChange = (airportId: string, type: string): void => {
    if (type === 'departure') {
      if (airportId && previousAirportId && airportId !== previousAirportId) {
        setAirportError(true);
      } else {
        setAirportError(false);
      }
      setDepartureAirportId(airportId);
      updateFlightData([{ value: airportId, key: 'departure_airport_id' }]);
    }
    if (type === 'arrival') {
      setArrivalAirportId(airportId);
      updateFlightData([{ value: airportId, key: 'arrival_airport_id' }]);
    }
  };

  const handleHobbsChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (type === 'dep') {
      setHobbsDeparture(newValue);
      updateFlightData([{ value: newValue, key: 'hobbs_departure' }]);
    }
    if (type === 'arr') {
      setHobbsArrival(newValue);
      updateFlightData([{ value: newValue, key: 'hobbs_arrival' }]);
    }
  };

  useEffect(() => {
    if (previousAirportId) {
      const getPrevAirport = async (): Promise<any> => {
        const airport = await getAirport(previousAirportId);
        Promise.all([airport]).then((value) => {
          setPreviousAirport(value[0]);
        });
      };
      getPrevAirport();
    }
  }, [previousAirportId]);

  useEffect(() => {
    if (mode !== 'add') {
      if (flight?.pilot_flying?.id) {
        let pilotFly = {
          title: `${flight?.pilot_flying?.first_name} ${flight?.pilot_flying?.last_name}`,
          value: flight?.pilot_flying?.id,
        };
        if (!pilotFly) {
          pilotFly = flight?.trip?.first_officer?.id
            ? {
                title: `${flight?.trip?.first_officer?.first_name} ${flight?.trip?.first_officer?.last_name}`,
                value: flight?.trip?.first_officer?.id,
              }
            : null;
        }
        let pilotMonitor = flight?.pilot_monitoring?.id
          ? {
              title: `${flight?.pilot_monitoring?.first_name} ${flight?.pilot_monitoring?.last_name}`,
              value: flight?.pilot_monitoring?.id,
            }
          : null;
        if (!pilotMonitor) {
          pilotMonitor = flight?.trip?.first_officer?.id
            ? {
                title: `${flight?.trip?.first_officer?.first_name} ${flight?.trip?.first_officer?.last_name}`,
                value: flight?.trip?.first_officer?.id,
              }
            : null;
        }
        if (pilotFlying?.value !== pilotFly?.value) {
          setPilotFlying(pilotFly);
        }
        if (pilotMonitoring?.value !== pilotMonitor?.value) {
          setPilotMonitoring(pilotMonitor);
        }
        if (pilotMonitor) {
          setPilots([{ title: 'None', value: null }, pilotFly, pilotMonitor]);
        } else {
          setPilots([{ title: 'None', value: null }, pilotFly]);
        }
      }
    } else if (mode === 'add') {
      let capObject;
      let firstObj;
      if (trip && trip?.captain?.id) {
        capObject = {
          title: `${trip?.captain?.first_name} ${trip?.captain?.last_name}`,
          value: trip?.captain?.id,
        };
      }
      if (trip && trip?.first_officer?.id) {
        firstObj = {
          title: `${trip?.first_officer?.first_name} ${trip?.first_officer?.last_name}`,
          value: trip?.first_officer?.id,
        };
      }
      if (!pilotFlying && capObject) setPilotFlying(capObject);
      if (capObject && !pilots.find((item) => item?.value === capObject?.value)) {
        pilots.push(capObject);
        setPilots(pilots);
      }
      if (firstObj && pilots.filter((item) => item?.value === firstObj?.value).length === 0) {
        pilots.push(firstObj);
        if (!pilotMonitoring) setPilotMonitoring(firstObj);
        setPilots(pilots);
      }
      if (pilots.length <= 2) {
        pilots.unshift({ title: 'None', value: null });
      }
      updateFlightData([
        { value: capObject?.value, key: 'pilot_flying_id' },
        { value: firstObj?.value, key: 'pilot_monitoring_id' },
      ]);
    }
  }, []);

  useEffect(() => {
    if (trip && trip?.flights?.length) {
      const lastFlight = trip.flights[trip.flights.length - 1];
      const departAirportId = lastFlight.arrival_airport_id;
      setDepartureAirportId(departAirportId);
      updateFlightData([{ value: departAirportId, key: 'departure_airport_id' }]);
      setPreviousAirportId(departAirportId);
    }
  }, [trip]);

  useEffect(() => {
    if (flight && !flight?.arrival_airport_id && flight?.trip?.flights?.length) {
      const lastFlight = flight?.trip.flights[flight?.trip.flights.length - 1];
      const depAirportId = lastFlight.departure_airport_id;
      if (departureAirportId && depAirportId && departureAirportId !== depAirportId) {
        setDepartureAirportId(depAirportId);
        updateFlightData([{ value: depAirportId, key: 'departure_airport_id' }]);
        setPreviousAirportId(depAirportId);
      }
    }
  }, [flight]);

  useEffect(() => {
    if (flight) {
      if (flight?.arrival_airport_id) {
        setArrivalAirportId(flight?.arrival_airport_id);
      } else {
        setArrivalAirport(undefined);
      }
      if (flight?.departure_airport_id) {
        setDepartureAirportId(flight?.departure_airport_id);
      } else {
        setDepartureAirportId(undefined);
      }
      if (flight?.hobbs_arrival) {
        setHobbsArrival(flight.hobbs_arrival);
      } else {
        setHobbsArrival('');
      }
      if (flight?.hobbs_departure) {
        setHobbsDeparture(flight.hobbs_departure);
      } else {
        setHobbsDeparture('');
      }
    }
  }, [flight]);

  const hobbsSection = (
    <SectionWrapper borderTop isMobile={isMobile}>
      <div>
        <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.hobbsDeparture' })}</Header>
        <UnitWrapper>
          {mode === 'view' ? (
            <DisplayText>{hobbsDeparture || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              value={hobbsDeparture || undefined}
              onChange={(e): void => handleHobbsChange(e.target.value, 'dep')}
              type="number"
            />
          )}
          <StyledUnit edit={mode !== 'view'}>hrs</StyledUnit>
        </UnitWrapper>
      </div>
      <div>
        <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.hobbsArrival' })}</Header>
        <UnitWrapper>
          {mode === 'view' ? (
            <DisplayText>{hobbsArrival || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              value={hobbsArrival || undefined}
              onChange={(e): void => handleHobbsChange(e.target.value, 'arr')}
              type="number"
            />
          )}
          <StyledUnit edit={mode !== 'view'}>hrs</StyledUnit>
        </UnitWrapper>
      </div>
      {flight?.hobbs_departure && flight?.hobbs_arrival && mode === 'view' ? (
        <div>
          <Header>{formatMessage({ id: 'form.labels.hobbsTotal' })}</Header>
          <UnitWrapper>
            <DisplayText>{Math.round((flight?.hobbs_arrival - flight?.hobbs_departure) * 10) / 10}</DisplayText>
            <StyledUnit>hrs</StyledUnit>
          </UnitWrapper>
        </div>
      ) : null}
    </SectionWrapper>
  );

  return (
    <>
      <SectionHeader data-testid="FlightDetailsSection-Header">Sector Details</SectionHeader>
      <SectionWrapper isMobile={isMobile} edit={mode !== 'view'}>
        <div data-testid="FlightDetailsSection-PIC">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.pilotFlying' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{`${pilotFlying?.title || '-'}`}</DisplayText>
          ) : (
            <div data-testid="FlightDetailsSection-SelectPIC">
              <SelectPilot people={pilots} handlePersonChange={handlePersonChange} pilotFlying={pilotFlying} />
            </div>
          )}
        </div>
        <div data-testid="FlightDetailsSection-SIC">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.pilotMonitoring' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{`${pilotMonitoring?.title || '-'}`}</DisplayText>
          ) : (
            <>
              {pilots.length > 2 ? (
                <div data-testid="FlightDetailsSection-SelectSIC">
                  <SelectPilot
                    people={pilots}
                    handlePersonChange={handleFirstOfficerChange}
                    pilotFlying={pilotMonitoring}
                  />
                </div>
              ) : (
                '-'
              )}
            </>
          )}
        </div>
        <div data-testid="FlightDetailsSection-From">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.departure' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{flight?.departure_airport || '-'}</DisplayText>
          ) : (
            <LocalSelectAirport
              value={departureAirportId}
              onChange={(airportId): void => handleAirportChange(airportId, 'departure')}
              onChangeObj={(airport): void => setDepartAirport(airport)}
              width="285px"
            />
          )}
        </div>
        <div data-testid="FlightDetailsSection-To">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'text.arrival' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{flight?.arrival_airport || '-'}</DisplayText>
          ) : (
            <LocalSelectAirport
              value={arrivalAirportId}
              onChange={(airportId): void => handleAirportChange(airportId, 'arrival')}
              onChangeObj={(airport): void => setArrivalAirport(airport)}
              width="285px"
            />
          )}
        </div>
        {airportError ? (
          <WarningDiv error>
            <img src={errorIcon} alt="warning icon" />
            <span>
              {`Previous arrival destination ${previousAirport?.ident}
               doesn't match selected departure airport ${departAirport?.ident}`}
            </span>
          </WarningDiv>
        ) : null}
      </SectionWrapper>
      <SectorTimesSection
        flight={flight}
        flightDate={flightDate}
        updateFlightData={updateFlightData}
        arrivalAirport={arrivalAirport}
        departAirport={departAirport}
        trip={trip}
      />
      {flight?.hobbs_arrival || flight?.hobbs_departure || mode !== 'view' ? hobbsSection : null}
    </>
  );
};

export default TripDetailsSection;
