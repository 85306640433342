import { Card, Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import isScreenMobile from '../../utils/isScreenMobile';
import EditTripDrawer from '../../components/EditTripDrawer/EditTripDrawer';
import { getAircraftTripEntries } from '../../models/trips/actions';
import TripsTable from '../../components/TripsTable';
import infoIcon from '../../assets/i-icon.png';
import closeIcon from '../../assets/cancel.svg';
import servers from '../../utils/servers';
import NonStyledButton from '../../components/NonStyledButton/NonStyledButton';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import editIcon from '../../assets/edit@2x.svg';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import overrideIcon from '../../assets/override@2x.svg';
import { getSingleAircraft } from '../../models/aircraft/actions';
import styles from './Trips.module.less';

class Trips extends PureComponent {
  static propTypes = {
    fetchAircraft: PropTypes.func.isRequired,
    getAircraftTrips: PropTypes.func.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    ttl: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    tripsArr: PropTypes.array.isRequired,
  };

  state = {
    loading: true,
    isEditTripDrawerVisible: false,
    selectedTrip: {},
    editable: false,
    override: false,
    isMobile: false,
    tripNumber: '',
    tripDate: '',
    tripSrpNumber: '',
  };

  componentDidMount() {
    const { selectedAircraft, ttl, fetchAircraft, getAircraftTrips } = this.props;
    if (!selectedAircraft.id || Date.now() - selectedAircraft.lastFetched > ttl) {
      getAircraftTrips();
      fetchAircraft().then(() => {
        this.setState({ loading: false });
      });
    } else if (selectedAircraft.id) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { tripsArr } = this.props;
    const { selectedTrip } = this.state;
    if (selectedTrip !== prevState.selectedTrip) {
      this.getDrawerHeader();
    }
    if (tripsArr !== prevProps.tripsArr) {
      const data = tripsArr.filter((item) => item.id === selectedTrip.id)[0];
      this.updateHeader(data);
    }
  }

  updateMediaScreenSize = () => {
    this.setState({ isMobile: isScreenMobile('768px') });
  };

  updateHeader = async (data) => {
    if (data)
      await new Promise((resolve) =>
        this.setState(
          {
            tripNumber: data.number,
            tripDate: data.date,
            tripSrpNumber: data.srp_number,
          },
          resolve,
        ),
      );
  };

  handleEditClick = async (trip) => {
    const { isEditTripDrawerVisible } = this.state;
    await new Promise((resolve) =>
      isEditTripDrawerVisible
        ? this.setState({
            isEditTripDrawerVisible: !isEditTripDrawerVisible,
            editable: false,
            override: false,
            srpLocked: true,
          })
        : this.setState(
            {
              isEditTripDrawerVisible: !isEditTripDrawerVisible,
              selectedTrip: trip,
              tripNumber: trip.number,
              tripDate: trip.date,
              tripSrpNumber: trip.srp_number,
              editable: false,
              override: false,
              srpLocked: true,
            },
            resolve,
          ),
    );
  };

  handleEditTripClick = (bool) => {
    this.setState({ editable: bool, override: false });
  };

  handleOverrideClick = (bool) => {
    this.setState({ override: bool, editable: false });
  };

  handleHeaderInputChange = (input, label) => {
    if (label === 'tripNumber') {
      this.setState({ tripNumber: input });
    }
    if (label === 'tripDate') {
      this.setState({ tripDate: input });
    }
    if (label === 'tripSRP') {
      this.setState({ tripSrpNumber: input });
    }
  };

  getDrawerHeader = (tripNumber, tripDate, tripSrpNumber) => {
    const {
      intl: { formatMessage },
      userSettings,
    } = this.props;
    const { selectedTrip, editable, override, srpLocked, isMobile } = this.state;
    let drawerHeaderDisplay = editable ? (
      <div>
        <div className={styles.headerWrapper} id="drawerHeader">
          <div className={styles.headerWrapperInner}>
            <div className={styles.tripUpdateHeader}>
              {`Editing Trip Update #${selectedTrip ? selectedTrip.number : '-'}`}
            </div>
          </div>
          <div className={styles.closeIcon}>
            <NonStyledButton onClick={() => this.setState({ isEditTripDrawerVisible: false })}>
              <img src={closeIcon} alt="close-icon" />
            </NonStyledButton>
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <div>
            <div className={styles.label}>
              <span>TRIP NUMBER</span>
            </div>
            <div>
              <input
                type="number"
                className={styles.tripNumberInput}
                value={tripNumber}
                onChange={(e) => this.handleHeaderInputChange(e.target.value, 'tripNumber')}
              />
            </div>
          </div>
          <div className={styles.dateWrapper}>
            <div className={styles.label}>
              <span>DATE</span>
            </div>
            <div>
              <input
                type="date"
                className={styles.tripDateInput}
                value={tripDate}
                onChange={(e) => this.handleHeaderInputChange(e.target.value, 'tripDate')}
              />
            </div>
          </div>
          <div className={styles.srpWrapper}>
            <div>
              <div className={srpLocked ? styles.label : styles.labelActive}>
                <span>SRP</span>
              </div>
              <div>
                <input
                  type="number"
                  className={srpLocked ? styles.srpNumberInput : styles.srpNumberInputActive}
                  value={tripSrpNumber}
                  onChange={(e) => this.handleHeaderInputChange(e.target.value, 'tripSRP')}
                  disabled={srpLocked}
                />
              </div>
            </div>
            <div className={srpLocked ? styles.unlockButtonWrapper : styles.unlockHidden}>
              <button type="button" className={styles.unlockButton} onClick={() => this.setState({ srpLocked: false })}>
                <span>Unlock</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className={styles.headerWrapper} id="drawerHeader">
          <div className={styles.headerWrapperInner}>
            <div className={styles.tripUpdateHeader}>{`Trip Update #${tripNumber || '-'}`}</div>
            <div className={styles.headerDate}>{moment(tripDate).format(userSettings && userSettings.dateFormat)}</div>
            <div className={styles.srp}>
              <a
                href={`${servers.api}/srp/${selectedTrip && selectedTrip.id}.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                download={formatMessage({ id: 'text.srp' })}
              >
                {formatMessage({ id: 'text.srp' })} <img src={pdfIcon} alt="pdf icon" className={styles.pdfIcon} />{' '}
                {`${tripSrpNumber || '-'}`}
              </a>
            </div>
          </div>
          <div className={styles.closeIcon}>
            <NonStyledButton onClick={() => this.setState({ isEditTripDrawerVisible: false })}>
              <img src={closeIcon} alt="close-icon" />
            </NonStyledButton>
          </div>
        </div>
        <div className={styles.subHeading}>
          <div>
            <img src={infoIcon} alt="Info Icon" />
          </div>
          <div>{formatMessage({ id: 'text.tripUpdatesInfo' })}</div>
        </div>
        <div className={editable || override ? styles.buttonsHidden : styles.buttons}>
          <button type="button" onClick={() => this.handleEditTripClick(true)}>
            <img src={editIcon} alt="edit icon" className={styles.editIcon} />
            <span>{formatMessage({ id: 'form.button.edit' })}</span>
          </button>
          <button type="button" onClick={() => this.handleOverrideClick(true)}>
            <img src={overrideIcon} alt="override icon" className={styles.overrideIcon} />
            <span>{formatMessage({ id: 'form.button.overrideValues' })}</span>
          </button>
        </div>
      </div>
    );
    if (isMobile)
      drawerHeaderDisplay = editable ? (
        <div>
          <div className={styles.tripUpdateHeaderMobile}>
            {`Editing Trip Update #${selectedTrip ? selectedTrip.number : '-'}`}
          </div>
          <div>
            <div className={styles.label}>
              <span>TRIP NUMBER</span>
            </div>
            <div>
              <input
                type="number"
                className={styles.tripNumberInput}
                value={tripNumber}
                onChange={(e) => this.handleHeaderInputChange(e.target.value, 'tripNumber')}
              />
            </div>
          </div>
          <div className={styles.dateWrapperMobile}>
            <div className={styles.label}>
              <span>DATE</span>
            </div>
            <div>
              <input
                type="date"
                className={styles.tripDateInput}
                value={tripDate}
                onChange={(e) => this.handleHeaderInputChange(e.target.value, 'tripDate')}
              />
            </div>
          </div>
          <div className={styles.srpWrapper}>
            <div>
              <div className={srpLocked ? styles.label : styles.labelActive}>
                <span>SRP</span>
              </div>
              <div>
                <input
                  type="number"
                  className={srpLocked ? styles.srpNumberInput : styles.srpNumberInputActive}
                  value={tripSrpNumber}
                  onChange={(e) => this.handleHeaderInputChange(e.target.value, 'tripSRP')}
                  disabled={srpLocked}
                />
              </div>
            </div>
            <div className={srpLocked ? styles.unlockButtonWrapper : styles.unlockHidden}>
              <button type="button" className={styles.unlockButton} onClick={() => this.setState({ srpLocked: false })}>
                <span>Unlock</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.headerWrapper} id="drawerHeader">
            <div className={styles.headerWrapperInner}>
              <div className={styles.tripUpdateHeader}>{`Trip Update #${tripNumber || '-'}`}</div>
              <div className={styles.headerDate}>
                {moment(tripDate).format(userSettings && userSettings.dateFormat)}
              </div>
              <div className={styles.srp}>
                <a
                  href={`${servers.api}/srp/${selectedTrip && selectedTrip.id}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={formatMessage({ id: 'text.srp' })}
                >
                  {formatMessage({ id: 'text.srp' })}
                  <img src={pdfIcon} alt="pdf icon" className={styles.pdfIcon} /> {`${tripSrpNumber || '-'}`}
                </a>
              </div>
            </div>
            <div className={styles.closeIcon}>
              <NonStyledButton onClick={() => this.setState({ isEditTripDrawerVisible: false })}>
                <img src={closeIcon} alt="close-icon" />
              </NonStyledButton>
            </div>
          </div>
          <div className={styles.subHeading}>
            <div>
              <img src={infoIcon} alt="Info Icon" />
            </div>
            <div>{formatMessage({ id: 'text.tripUpdatesInfo' })}</div>
          </div>
          <div className={editable || override ? styles.buttonsHidden : styles.buttons}>
            <button type="button" onClick={() => this.handleEditTripClick(true)}>
              <img src={editIcon} alt="edit icon" className={styles.editIcon} />
              <span>{formatMessage({ id: 'form.button.edit' })}</span>
            </button>
            <button type="button" onClick={() => this.handleOverrideClick(true)}>
              <img src={overrideIcon} alt="override icon" className={styles.overrideIcon} />
              <span>{formatMessage({ id: 'form.button.overrideValues' })}</span>
            </button>
          </div>
        </div>
      );
    return drawerHeaderDisplay;
  };

  render() {
    const { selectedAircraft, menu } = this.props;
    const {
      loading,
      isEditTripDrawerVisible,
      selectedTrip,
      editable,
      override,
      isMobile,
      tripNumber,
      tripDate,
      tripSrpNumber,
    } = this.state;
    this.updateMediaScreenSize();

    matchMedia('(max-width: 768px)').addListener(this.updateMediaScreenSize);
    const { bannerVisible } = menu;
    return (
      <>
        <Drawer
          closable={false}
          placement="right"
          visible={isEditTripDrawerVisible}
          onClose={() => this.handleEditClick()}
          width={isMobile ? 'auto' : (window.innerWidth > 907 && 907) || '100vw'}
          style={{ paddingTop: bannerVisible ? '68px' : '10px' }}
          bodyStyle={{ borderRadius: '5px', backgroundColor: '#f4f4f5' }}
          headerStyle={{ borderRadius: '5px', backgroundColor: '#f4f4f5' }}
          maskStyle={{ height: '100vh' }}
          title={this.getDrawerHeader(tripNumber, tripDate, tripSrpNumber)}
          className={bannerVisible ? styles.drawerComponentBanVis : styles.drawerComponent}
        >
          <EditTripDrawer
            trip={selectedTrip}
            editable={editable}
            override={override}
            handleEditTripClick={this.handleEditTripClick}
            handleOverrideClick={this.handleOverrideClick}
            isMobile={isMobile}
            tripNumber={tripNumber}
            tripDate={tripDate}
            tripSrpNumber={tripSrpNumber}
            aircraft={selectedAircraft}
          />
        </Drawer>
        <InnerMenuLayout loading={loading}>
          <Card bordered={false} className={styles.cardStyling}>
            <TripsTable
              showSearchAdd
              showPagination
              aircraft={selectedAircraft}
              data-test="tripsTable"
              handleEditClick={this.handleEditClick}
            />
          </Card>
        </InnerMenuLayout>
      </>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    (
      { aircraft, userSettings, trips, menu },
      {
        match: {
          params: { id },
        },
      },
    ) => {
      const currentAircraft = aircraft.aircraftMap.get(id) || {};
      const tripsArr = Array.from(trips.tripsMap.values())
        .filter((tripEntry) => currentAircraft && tripEntry.aircraft_id === currentAircraft.id)
        .sort((a, b) => moment(b.date).diff(moment(a.date)));
      return {
        selectedAircraft: aircraft.aircraftMap.get(id) || {},
        ttl: aircraft.ttl,
        userSettings,
        tripsArr,
        menu,
      };
    },
    (
      dispatch,
      {
        match: {
          params: { id },
        },
      },
    ) => ({
      fetchAircraft: () => {
        return dispatch(
          getSingleAircraft({
            payload: id,
          }),
        );
      },
      getAircraftTrips: () => {
        return dispatch(
          getAircraftTripEntries({
            payload: { id },
          }),
        );
      },
    }),
  ),
)(Trips);
