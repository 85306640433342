import { Icon, Layout } from 'antd';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { version } from '../../package.json';
import GlobalFooter from '../components/GlobalFooter';
import config, { ConfigKey } from '../utils/config';

const { Footer } = Layout;
const FooterView = (props) => {
  const { lightText } = props;
  return (
    <Footer style={{ padding: 0, backgroundColor: '#f6f8fb' }}>
      <GlobalFooter
        copyright={
          <>
            Copyright <Icon type="copyright" />{' '}
            {moment()
              .year()
              .toString()}{' '}
            TrustFlight
            <br />
            Dashboard {version} ({config.get(ConfigKey.HASHVERSION)})
          </>
        }
        data-test="globalFooter"
        lightText={lightText}
      />
    </Footer>
  );
};

FooterView.propTypes = {
  lightText: PropTypes.bool,
};

FooterView.defaultProps = {
  lightText: false,
};

export default FooterView;
