import React, { useEffect, useState } from 'react';
import { message, TimePicker } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Flight } from '../../models/flights';
import DeicingIcon from '../../assets/icon-card-deicing.svg';
import { DashboardState } from '../../models';
import plusIcon from '../../assets/plus-blue.svg';
import DeleteIcon from '../../assets/delete.svg';
import { DisplayText, Header } from './FlightDrawer';

interface DeicingProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 41px;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-column-start: ${({ column }): string => column || 'auto'};
`;

const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const HeaderText = styled.span`
  margin-left: 8px;
  font-size: 14px !important;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
`;

const DeicingNum = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 12px 0 12px;
`;

const LineBreak = styled.div`
  height: 1px;
  margin: 20px 0 20px 8px;
  background-color: ${({ theme }): string => theme.colors.black05Alpha};
`;

const EmptyStateDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  row-gap: 20px;
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '20px')};
  margin-left: 41px;
  grid-template-rows: auto;
`;

const DeicingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  margin-bottom: ${({ noMargin }): string => (noMargin ? '0' : '20px')};
  grid-template-rows: auto;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const TypeRadio = styled.div`
  width: 24px;
  height: 32px;
  margin: 4px 2px;
  padding: 7px 0;
  border-radius: 2px;
  border: ${({ theme, selected }): string =>
    selected ? `solid 1px ${theme.colors.brandBlue70Alpha}` : `solid 1px ${theme.colors.black10Alpha}`};
  background-color: ${({ theme, selected }): string => (selected ? theme.colors.brandBlue10Alpha : theme.colors.salt)};
  color: ${({ theme, selected }): string => (selected ? theme.colors.brandBlue70Alpha : theme.colors.black70Alpha)};
  text-align: center;
  line-height: 1.29;
  display: inline-block;
  cursor: pointer;
`;

const Deicing: React.FC<DeicingProps> = ({ flight, updateFlightData }) => {
  const { formatMessage } = useIntl();
  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);
  const [deicings, setDeicings] = useState([]);

  const isMobile = window.innerWidth < 451;

  useEffect(() => {
    if (flight) {
      if (flight?.deice_events_attributes) {
        setDeicings(flight?.deice_events_attributes || []);
      } else {
        setDeicings(flight?.deice_events || []);
      }
    } else {
      setDeicings([]);
    }
  }, [flight]);

  const handleInputChange = (value: string, index: number, key: string): void => {
    if (key === 'fluid_ratio_left' && (parseFloat(value) > 100 || parseFloat(value) < 0)) {
      message.error('Fluid ratio must be between 0 and 100');
      return;
    }
    if (key === 'fluid_ratio_right' && (parseFloat(value) > 100 || parseFloat(value) < 0)) {
      return;
    }
    const newArray = deicings ? [...deicings] : [];
    newArray[index][key] = value;
    setDeicings([...newArray]);
    updateFlightData([{ value: [...newArray], key: 'deice_events_attributes' }]);
  };

  const handleAddDeicing = (): void => {
    const defaultTime = flight.time_offblocks || moment();
    const newArray = deicings;
    newArray.push({
      start_time: moment(defaultTime)
        .utc()
        .format('HH:mm'),
      end_time: moment(defaultTime)
        .utc()
        .format('HH:mm'),
      fluid_ratio_left: null,
      fluid_ratio_right: null,
      quantity: null,
      fluid_type: 'I',
      holdover_seconds: 0,
    });
    setDeicings([...newArray]);
  };

  const removeDeicing = (index): void => {
    const newArray = deicings;
    const isStoredInCore = typeof newArray[index].id === 'string';
    if (isStoredInCore) {
      // eslint-disable-next-line no-underscore-dangle
      newArray[index]._destroy = true;
    } else {
      newArray.splice(index, 1);
    }
    setDeicings([...newArray]);
  };

  // if all deicing start and end time values are only times in database this can be removed
  const checkTimeFormat = (value: string): string => {
    let newValue = value;
    if (value && value.split(' ').length > 1) {
      newValue = newValue.split(' ')[1].substring(0, 5);
    }
    return newValue;
  };

  return (
    <SectionWrapper data-testid="FlightDrawer-DeicingSection">
      <DeicingWrapper isMobile={isMobile} edit={mode !== 'view'}>
        <EmptyStateDiv data-testid="DeicingSection-EmptyState">
          <div>
            <img src={DeicingIcon} alt="no deicing" />
            <HeaderText>Deicing</HeaderText>
          </div>
          {mode !== 'view' && deicings?.length === 0 ? (
            <Button height="24px" onClick={handleAddDeicing} primary={false}>
              <AddTextWrapper>
                <PlusIcon src={plusIcon} alt="plus icon" /> Add deicing
              </AddTextWrapper>
            </Button>
          ) : null}
        </EmptyStateDiv>
        {deicings?.map((deicing, index) => {
          const formattedHoldover = moment.utc(deicing.holdover_seconds * 1000).format('HH:mm');
          let formattedRatio = '-';
          if (deicing.fluid_ratio_left !== null && deicing.fluid_ratio_right !== null) {
            formattedRatio = `${deicing?.fluid_ratio_left}/${deicing?.fluid_ratio_right}`;
          }
          // eslint-disable-next-line no-underscore-dangle
          if (deicing._destroy) {
            return null;
          }
          return (
            <>
              {deicings.length > 0 && (
                <HeaderWrapper>
                  <DeicingNum>{`Deicing ${index + 1}`}</DeicingNum>
                  <ButtonWrapper>
                    {mode !== 'view' && (
                      <DeleteButton
                        type="button"
                        onClick={(): void => {
                          removeDeicing(index);
                        }}
                      >
                        <img src={DeleteIcon} alt="delete button" />
                      </DeleteButton>
                    )}
                  </ButtonWrapper>
                </HeaderWrapper>
              )}
              <DetailsWrapper
                isMobile={isMobile}
                key={deicing?.id}
                edit={mode !== 'view'}
                noMargin={deicing.length === index + 1}
              >
                <div>
                  <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.startTime' })}</Header>
                  <UnitWrapper>
                    {mode === 'view' ? (
                      <DisplayText>{checkTimeFormat(deicing?.start_time) || '-'}</DisplayText>
                    ) : (
                      <TimePicker
                        value={moment(deicing.start_time, 'HH:mm')}
                        onChange={(timeMoment, timeString): void => handleInputChange(timeString, index, 'start_time')}
                        suffixIcon={<div />}
                        placeholder="-"
                        allowClear={false}
                        format="HH:mm"
                        style={{ width: 100 }}
                      />
                    )}
                    <StyledUnit edit={mode !== 'view'}>z</StyledUnit>
                  </UnitWrapper>
                </div>
                <div>
                  <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.endTime' })}</Header>
                  <UnitWrapper>
                    {mode === 'view' ? (
                      <DisplayText>{checkTimeFormat(deicing?.end_time) || '-'}</DisplayText>
                    ) : (
                      <TimePicker
                        value={moment(deicing.end_time, 'HH:mm')}
                        onChange={(timeMoment, timeString): void => handleInputChange(timeString, index, 'end_time')}
                        suffixIcon={<div />}
                        placeholder="-"
                        allowClear={false}
                        format="HH:mm"
                        style={{ width: 100 }}
                      />
                    )}
                    <StyledUnit edit={mode !== 'view'}>z</StyledUnit>
                  </UnitWrapper>
                </div>
                <div>
                  <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.holdover' })}</Header>
                  <UnitWrapper>
                    {mode === 'view' ? (
                      <DisplayText>{formattedHoldover || '-'}</DisplayText>
                    ) : (
                      <TimePicker
                        value={moment(formattedHoldover || '00:00', 'HH:mm')}
                        onChange={(timeMoment): void => {
                          const secs = timeMoment.diff(moment().startOf('day'), 'seconds');
                          handleInputChange(secs.toString(), index, 'holdover_seconds');
                        }}
                        suffixIcon={<div />}
                        placeholder="-"
                        allowClear={false}
                        format="HH:mm"
                        style={{ width: 100 }}
                      />
                    )}
                  </UnitWrapper>
                </div>
                <div>
                  <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.fluidRatio' })}</Header>
                  <UnitWrapper>
                    {mode === 'view' ? (
                      <DisplayText>{formattedRatio}</DisplayText>
                    ) : (
                      <StyledInput
                        type="number"
                        step="0.1"
                        placeholder="-"
                        value={deicing?.fluid_ratio_left || ''}
                        onChange={(e): void => {
                          handleInputChange(e.target.value, index, 'fluid_ratio_left');
                          handleInputChange((100 - e.target.value).toString(), index, 'fluid_ratio_right');
                        }}
                      />
                    )}
                    {mode !== 'view' ? (
                      <StyledUnit edit={mode !== 'view'}>
                        {deicing.fluid_ratio_right ? `/ ${deicing.fluid_ratio_right}` : ''}
                      </StyledUnit>
                    ) : null}
                  </UnitWrapper>
                </div>
                <div>
                  <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.type' })}</Header>
                  <UnitWrapper>
                    {mode === 'view' ? (
                      <DisplayText>{deicing?.fluid_type || '-'}</DisplayText>
                    ) : (
                      <div>
                        <TypeRadio
                          onClick={(): void => handleInputChange('I', index, 'fluid_type')}
                          selected={deicing.fluid_type === 'I'}
                        >
                          I
                        </TypeRadio>
                        <TypeRadio
                          onClick={(): void => handleInputChange('II', index, 'fluid_type')}
                          selected={deicing.fluid_type === 'II'}
                        >
                          II
                        </TypeRadio>
                        <TypeRadio
                          onClick={(): void => handleInputChange('III', index, 'fluid_type')}
                          selected={deicing.fluid_type === 'III'}
                        >
                          III
                        </TypeRadio>
                        <TypeRadio
                          onClick={(): void => handleInputChange('IV', index, 'fluid_type')}
                          selected={deicing.fluid_type === 'IV'}
                        >
                          IV
                        </TypeRadio>
                      </div>
                    )}
                  </UnitWrapper>
                </div>
                <div>
                  <Header editable={mode !== 'view'}>{formatMessage({ id: 'form.labels.quantity' })}</Header>
                  <UnitWrapper>
                    {mode === 'view' ? (
                      <DisplayText>{deicing?.quantity || '-'}</DisplayText>
                    ) : (
                      <StyledInput
                        type="number"
                        step="0.1"
                        placeholder="-"
                        value={deicing?.quantity || ''}
                        onChange={(e): void => handleInputChange(e.target.value, index, 'quantity')}
                      />
                    )}
                    <StyledUnit edit={mode !== 'view'}>l</StyledUnit>
                  </UnitWrapper>
                </div>
                <ButtonWrapper column={isMobile ? 2 : 4}>
                  {mode !== 'view' && deicings.length === index + 1 ? (
                    <Button primary={false} height="24px" onClick={handleAddDeicing}>
                      <AddTextWrapper>
                        <PlusIcon src={plusIcon} alt="plus icon" /> Add deicing
                      </AddTextWrapper>
                    </Button>
                  ) : null}
                </ButtonWrapper>
              </DetailsWrapper>
              {index + 1 < deicings.length && <LineBreak />}
            </>
          );
        })}
      </DeicingWrapper>
    </SectionWrapper>
  );
};

export default Deicing;
