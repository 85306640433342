/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import LoadingLight from './loadingLight.json';
import LoadingDark from './loadingDark.json';

const colourThemes = {
  light: css`
    background: rgba(255, 255, 255, 0.9);
  `,
  dark: css`
    background: rgba(0, 30, 61, 0.35);
  `,
  transparent: css`
    background: transparent;
  `,
};

const containOverlay = css`
  position: absolute;
`;

const fullPageOverlay = css`
  position: fixed;
`;

const fullPageLottie = css`
  height: 100vh;
`;

const setVisible = css`
  z-index: 1000;
`;

const setInvisible = css`
  z-index: -1;
`;

const lottieOptions = {
  dark: {
    loop: true,
    autoplay: true,
    animationData: LoadingLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },

  light: {
    loop: true,
    autoplay: true,
    animationData: LoadingDark,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  transparent: {
    loop: true,
    autoplay: true,
    animationData: LoadingDark,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
};

const Wrapper = styled.div`
  .fadeIn {
    opacity: 1;
  }
`;
interface LoadingDivProps {
  height?: number;
  width?: number;
  loading: boolean;
  theme?: string;
  small?: boolean;
  contain?: boolean;
  topPosition?: number;
}

interface Contained {
  contain?: boolean;
  theme: string;
  loading: boolean;
}

const LoadingOverlay = styled.div<Contained>`
  ${({ theme }): FlattenSimpleInterpolation => colourThemes[theme]};
  ${({ contain }): FlattenSimpleInterpolation => (contain ? containOverlay : fullPageOverlay)};
  ${({ loading }): FlattenSimpleInterpolation => (loading ? setVisible : setInvisible)};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s;
  opacity: 0;
`;

const LottieWrapper = styled.div<Contained>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ contain, topPosition }): FlattenSimpleInterpolation => {
    if (contain) {
      return css`
        height: ${topPosition}%;
      `;
    }
    return fullPageLottie;
  }};
`;

const TFLoading: React.FC<LoadingDivProps> = ({
  height = 80,
  width = 80,
  topPosition = 100,
  loading,
  children,
  theme = 'light',
  contain = false,
}) => {
  const overlayRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (loading) {
      overlayRef.current.classList.add('fadeIn');
    } else if (!loading) {
      overlayRef.current.classList.remove('fadeIn');
    }
  }, [loading]);

  return (
    <>
      <Wrapper>
        <LoadingOverlay ref={overlayRef} contain={contain} theme={theme} loading={loading}>
          {loading && (
            <LottieWrapper contain={contain} topPosition={topPosition}>
              <Lottie options={lottieOptions[theme]} height={height} width={width} className="lottie" />
            </LottieWrapper>
          )}
        </LoadingOverlay>
      </Wrapper>
      {children && !loading && children}
    </>
  );
};

export default TFLoading;
