import React from 'react';
import styled from 'styled-components';

interface StyledInputProps {
  type: string;
  id: string;
  defaultValue?: number | string;
  value?: number | string;
  width?: number;
  padding?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = styled.input`
  width: ${({ width }): string => (width ? `${width}px` : 'auto')};
  height: 34px;
  padding: ${({ padding }): string => (padding ? `${padding}` : `9px 10px`)};
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  ::placeholder {
    color: rgba(36, 45, 65, 0.3);
  }
  &:focus {
    outline: none;
  }
`;

const StyledInput: React.FC<StyledInputProps> = ({
  type,
  id,
  defaultValue,
  width,
  padding,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <Input
      type={type}
      id={id}
      name={id}
      width={width}
      padding={padding}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      data-testid="StyledInput--Input"
    />
  );
};

export default StyledInput;
