/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { getPeople } from '../../models/people/actions';
import { Trip } from '../../models/trips';
import servers from '../../utils/servers';
import calendarIcon from '../../assets/icon-small-calendar.svg';
import { DisplayText, Header, SectionHeader, StyledInput } from '../FlightDrawer/FlightDrawer';
import SelectPilot from '../FlightDrawer/SelectPilot';
import TFSelect from '../TFSelect/TFSelect';

interface TripDetailsSectionProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any, secondKey?: string, secondValue?: any) => void;
  aircraftId: string;
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, editable }): string => {
    if (isMobile) return '1fr';
    if (editable) return '1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
`;

const SignatureSection = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 20px;
`;

const StyledImg = styled.img`
  width: 240px;
  margin-left: 12px;
`;

const CaptialiseSpan = styled.span`
  text-transform: capitalize;
`;

const TripDetailsSection: React.FC<TripDetailsSectionProps> = ({ trip, editable, updateTripData, aircraftId }) => {
  const {
    userSettings: { dateFormat },
    drawer: { mode },
    people: { peopleMap },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const [callsign, setCallsign] = useState(null);
  const [category, setCategory] = useState(null);
  const [customDataArray, setCustomDataArray] = useState([]);
  const [peopleArray, setPeopleArray] = useState([]);
  const [flyingPeople, setFlyingPeople] = useState([]);
  const [monitoringPeople, setMonitoringPeople] = useState([]);
  const [pilotFlying, setPilotFlying] = useState(null);
  const [pilotMonitoring, setPilotMonitoring] = useState(null);
  const [date, setDate] = useState(moment());
  const [peopleLoading, setPeopleLoading] = useState(false);

  const operatorId = aircraftMap.get(aircraftId)?.operator_id;

  const dispatch = useDispatch();

  const handleCategoryChange = (option): void => {
    setCategory(option.title);
    updateTripData('trip_category', option.title);
  };

  const handleCallsignChange = (value: string): void => {
    setCallsign(value);
    updateTripData('callsign', value);
  };

  const selectOptions = [
    {
      title: 'private',
    },
    {
      title: 'commercial',
    },
  ];

  const handlePersonChange = (id: string, role: string): void => {
    const filteredPeople = peopleArray.filter((p) => p?.value !== id);
    if (role === 'captain') {
      const pilot = peopleArray.find((person) => person?.value === id);
      setPilotFlying(pilot);
      if (id) {
        setMonitoringPeople(filteredPeople);
      } else {
        setMonitoringPeople(peopleArray);
      }
      const cap = peopleMap.get(id);
      updateTripData('captain', cap, 'captain_id', id);
    }
    if (role === 'first') {
      const firstOfficer = peopleArray.find((person) => person?.value === id);
      if (id) {
        setFlyingPeople(filteredPeople);
      } else {
        setFlyingPeople(peopleArray);
      }
      if (firstOfficer) {
        setPilotMonitoring(firstOfficer);
        const fO = peopleMap.get(id);
        updateTripData('first_officer', fO, 'first_officer_id', id);
      } else {
        setPilotMonitoring({ value: '', title: 'None' });
        updateTripData('first_officer_id', null);
        updateTripData('first_officer', null);
      }
    }
  };

  const handleDateChange = (dateMoment): void => {
    setDate(dateMoment);
    updateTripData('date', dateMoment.format('YYYY-MM-DD'));
  };

  const disabledDate = (current): boolean => {
    if (mode === 'add') {
      return (
        current &&
        current >
          moment()
            .add(7, 'day')
            .startOf('day')
      );
    }
    return false;
  };

  useEffect(() => {
    if (peopleMap.size === 0) {
      setPeopleLoading(true);
      dispatch(getPeople());
    } else {
      setPeopleLoading(false);
    }
  }, [peopleMap]);

  useEffect(() => {
    if (peopleMap.size > 0) {
      const newArray = Array.from(peopleMap.values());
      const pilotArray = newArray
        .filter((person) => person.position === 'Pilot' && person.organisation.id === operatorId)
        .sort((a, b) => {
          if (a?.last_name && b?.last_name) return a.last_name.localeCompare(b.last_name);
          return 0;
        })
        .map((person) => {
          return {
            value: person.id,
            title: `${person.first_name} ${person.last_name}`,
          };
        });
      setPeopleArray(pilotArray);
      pilotArray.push({ title: 'None', value: undefined });
      let peopleFlying = [...pilotArray];
      let peopleMonitoring = [...pilotArray];
      const captain = pilotArray.find((person) => person.value === trip?.captain_id);
      if (captain) {
        setPilotFlying(captain);
        peopleMonitoring = pilotArray.filter((p) => p.value !== captain.value);
      } else {
        setPilotFlying({ value: '', title: 'None' });
      }
      if (peopleMap.size > 1 && pilotArray.length > 1) {
        const firstOfficer = pilotArray.find((person) => person.value === trip?.first_officer_id);
        if (firstOfficer) {
          setPilotMonitoring(firstOfficer);
          peopleFlying = pilotArray.filter((p) => p.value !== firstOfficer.value);
          updateTripData('first_officer_id', firstOfficer.value);
        } else {
          setPilotMonitoring({ value: '', title: 'None' });
        }
      }
      setFlyingPeople(peopleFlying);
      setMonitoringPeople(peopleMonitoring);
    }
  }, [peopleMap]);

  useEffect(() => {
    if (trip?.custom_data && Array.of(Object.keys(trip?.custom_data)).length) {
      const useCustomData = Object.entries(trip?.custom_data).filter((array) => array[0] !== 'trend_monitoring');
      setCustomDataArray(useCustomData);
    }
    if (trip?.callsign) {
      setCallsign(trip.callsign);
    } else {
      setCallsign('');
    }
    if (trip?.trip_category && category !== trip?.trip_category) {
      setCategory(trip.trip_category);
      updateTripData('trip_category', trip?.trip_category);
    } else if (!category) {
      setCategory('private');
      updateTripData('trip_category', 'private');
    } else if (trip?.trip_category === null) {
      setCategory('private');
    }
    if (trip?.date) setDate(moment(trip?.date));
    if (trip?.captain?.id && !trip?.captain_id) updateTripData('captain_id', trip?.captain?.id);
    if (trip?.first_officer?.id && !trip?.first_officer_id) updateTripData('first_officer_id', trip?.first_officer?.id);
  }, [trip]);

  useEffect(() => {
    if (pilotMonitoring && !trip?.first_officer_id) {
      updateTripData('first_officer_id', pilotMonitoring.value);
    }
  }, [pilotMonitoring]);

  const handleCustomDataChange = (value: string, index: number, key: string): void => {
    const newArray = [...customDataArray];
    newArray[index][1] = value;
    setCustomDataArray(newArray);
    const newCustomData = {
      ...trip.custom_data,
      [key]: value,
    };
    updateTripData('custom_data', newCustomData);
  };

  const customDataElements = (): ReactNode => {
    return customDataArray.map((array, index) => {
      return (
        <div key={array[0]} data-testid={`TripDetailsSection-CustomData${index}`}>
          <Header editable={editable}>{array[0].replace(/_/g, ' ')}</Header>
          {editable ? (
            <StyledInput
              width={110}
              value={array[1]}
              onChange={(e): void => handleCustomDataChange(e.target.value, index, array[0])}
            />
          ) : (
            <DisplayText>{array[1]}</DisplayText>
          )}
        </div>
      );
    });
  };
  const isMobile = window.innerWidth < 451;
  return (
    <>
      <SectionHeader data-testid="TripDetailsSection-Header">Trip Details</SectionHeader>
      <ContentWrapper isMobile={isMobile} editable={mode !== 'view'}>
        <div data-testid="TripDetailsSection-Date">
          <Header editable={mode !== 'view'}>Date</Header>
          {mode === 'view' ? (
            <DisplayText>{moment(trip?.date).format(dateFormat)}</DisplayText>
          ) : (
            <DatePicker
              format={dateFormat}
              value={date}
              suffixIcon={<img src={calendarIcon} alt="calendarIcon" />}
              allowClear={false}
              onChange={(dateMoment, dateString): void => handleDateChange(dateMoment)}
              disabledDate={disabledDate}
            />
          )}
        </div>
        {trip?.duty_logs && trip?.duty_logs?.length && mode === 'view' ? null : (
          <div data-testid="TripDetailsSection-Captain">
            <Header editable={mode !== 'view'}>PIC</Header>
            {mode === 'view' ? (
              <DisplayText>{`${trip?.captain?.first_name || '-'} ${trip?.captain?.last_name || ''}`}</DisplayText>
            ) : (
              <SelectPilot
                width={200}
                people={flyingPeople}
                handlePersonChange={(id): void => handlePersonChange(id, 'captain')}
                pilotFlying={pilotFlying}
                loading={peopleLoading}
              />
            )}
          </div>
        )}
        {trip?.duty_logs && trip?.duty_logs?.length && mode === 'view' && peopleArray.length <= 1 ? null : (
          <div data-testid="TripDetailsSection-FirstOfficer">
            <Header editable={mode !== 'view'}>SIC</Header>
            {mode === 'view' ? (
              <DisplayText>
                {`${trip?.first_officer?.first_name || '-'} ${trip?.first_officer?.last_name || ''}`}
              </DisplayText>
            ) : (
              <SelectPilot
                width={200}
                people={monitoringPeople}
                handlePersonChange={(id): void => handlePersonChange(id, 'first')}
                pilotFlying={pilotMonitoring}
                loading={peopleLoading}
              />
            )}
          </div>
        )}
        <div data-testid="TripDetailsSection-Callsign">
          <Header editable={editable}>Callsign</Header>
          {editable ? (
            <StyledInput width={200} value={callsign} onChange={(e): void => handleCallsignChange(e.target.value)} />
          ) : (
            <DisplayText>{trip?.callsign}</DisplayText>
          )}
        </div>
        <div data-testid="TripDetailsSection-Category">
          <Header editable={editable}>Category</Header>
          {editable ? (
            <TFSelect
              height={34}
              width={200}
              options={selectOptions}
              initial={{ title: category || 'private' }}
              handleSelectChange={handleCategoryChange}
            />
          ) : (
            <DisplayText>
              <CaptialiseSpan>{trip?.trip_category || '-'}</CaptialiseSpan>
            </DisplayText>
          )}
        </div>
        {customDataArray?.length ? customDataElements() : null}
      </ContentWrapper>
      {trip?.signature_image_url ? (
        <SignatureSection data-testid="TripDetailsSection-Signature">
          Signed by:
          <StyledImg alt="Signature" src={`${servers.api}${trip?.signature_image_url}`} />
        </SignatureSection>
      ) : null}
    </>
  );
};

export default TripDetailsSection;
