/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import { intl } from '../../providers/IntlProvider';
import { addPeople, getUserDetails, queryPeople, removePeople, updatePeople, getUser } from '../../services/api';
import { PeopleActionTypes } from '.';

export const getPeople = (callback = null) => async (dispatch): Promise<any> => {
  const response = await queryPeople();
  if (callback) callback();
  dispatch({
    type: PeopleActionTypes.SAVE_ALL,
    payload: response,
  });
};

export const getSinglePerson = (userId, callback) => async (dispatch): Promise<any> => {
  const response = await getUser(userId);
  if (response && response.statusCode > 199 && response.statusCode < 400) {
    dispatch({
      type: PeopleActionTypes.SAVE_SINGLE,
      payload: response.body,
    });
    callback();
  } else {
    message.error(intl.formatMessage({ id: 'message.failedToFetchUser' }));
    callback();
  }
};

export const resetUserSettingsDrawer = () => async (dispatch): Promise<any> => {
  dispatch({
    type: PeopleActionTypes.SAVE_SINGLE,
    payload: {},
  });
};

export const getMe = () => async (dispatch): Promise<any> => {
  const response = await getUserDetails();
  dispatch({
    type: PeopleActionTypes.SAVE,
    payload: response,
  });
};
export const add = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await addPeople(payload);
  if (response.statusCode > 199 && response.statusCode < 400) {
    message.success(intl.formatMessage({ id: 'message.newUserAdded' }));
    dispatch({
      type: PeopleActionTypes.SAVE,
      payload: response.body,
    });
  } else {
    message.error(intl.formatMessage({ id: 'message.userAlreadyExists' }));
  }
  if (response.statusCode > 199 && response.statusCode < 400) return response.body;
  return null;
};
export const remove = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await removePeople(payload);
  if (response && response.statusCode > 199 && response.statusCode < 400) {
    dispatch({
      type: PeopleActionTypes.REMOVE,
      payload: response
        ? {
            removedId: payload.id,
          }
        : {},
    });
    message.success(intl.formatMessage({ id: 'message.userDeleted' }));
  } else {
    message.error(intl.formatMessage({ id: 'message.userDeletedFailed' }));
  }
};
export const update = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await updatePeople(payload);
  if (response && response.statusCode > 199 && response.statusCode < 400) {
    dispatch({
      type: PeopleActionTypes.SAVE,
      payload: response.body,
    });
    dispatch({
      type: PeopleActionTypes.SAVE_SINGLE,
      payload: response.body,
    });
    message.success(intl.formatMessage({ id: 'message.permissionsUpdated' }));
  } else {
    message.error(intl.formatMessage({ id: 'message.failedToUpdateUser' }));
  }
};
