/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import servers from '../../utils/servers';
import styles from './fileViewer.module.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MapAttachmentThumbnails = ({ attachments, toggleShowFile }): JSX.Element => {
  const showFile = (e): void => {
    const imgIndex = attachments.findIndex((item) => item.id === e.target.id);
    toggleShowFile({ visible: true, index: imgIndex === -1 ? 0 : imgIndex });
  };

  return (
    (attachments &&
      attachments.length > 0 &&
      attachments.map((item) => {
        const attachmentIsBeingRemoved = item?._destroy;

        if (attachmentIsBeingRemoved) {
          return null;
        }

        // core returns a PNG thumbnail for PDFs - so we only have to handle local PDF attachments here
        if (item?.attachment_file_name?.includes('pdf')) {
          return (
            <button type="button" className={styles.thumbNailButton} onClick={showFile} id={item?.id} key={item?.id}>
              <Document file={item.attachment}>
                <Page pageNumber={1} height={50} />
              </Document>
            </button>
          );
        }

        return (
          <button type="button" className={styles.thumbNailButton} onClick={showFile} id={item?.id} key={item?.id}>
            <img
              className={styles.attachmentThumbnail}
              src={item?.attachment || `${servers.api}/${item?.thumbnail_url}`}
              alt="attachments"
              id={item?.id}
            />
          </button>
        );
      })) ||
    null
  );
};

export default MapAttachmentThumbnails;
