/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion } from '@arcflight/tf-component-library';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import oilIcon from '../../assets/icon-card-uplift-general.svg';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { DisplayText, Header, StyledInput } from './FlightDrawer';

interface OilUpliftsProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1.33;
  font-size: 12px;
  text-transform: capitalize;
  img {
    margin-right: 8px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PartsDiv = styled.div`
  margin-right: 20px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }): string => (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
  padding: 16px 20px;
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
`;

const UnitSpan = styled.span`
  margin-left: 8px;
`;

const OilUplifts: React.FC<OilUpliftsProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [oilUplift, setOilUplift] = useState([]);
  const [displayOilUplift, setDisplayOilUplift] = useState([]);
  const [engineCount, setEngineCount] = useState(null);
  const [oilUnit, setOilUnit] = useState(null);

  const handleUpliftChange = (value: string, key: string): void => {
    const foundUplift = oilUplift.find((item) => item.fluid_destination_number === key);
    const foundUpliftIndex = oilUplift.findIndex((item) => item.fluid_destination_number === key);
    foundUplift.uplift = value;
    oilUplift.splice(foundUpliftIndex, 1, foundUplift);
    setOilUplift([...oilUplift]);
    updateFlightData([{ value: oilUplift, key: 'oil_uplifts' }]);
  };

  useEffect(() => {
    if (flight && flight?.oil_uplifts?.length) {
      setOilUplift([...flight?.oil_uplifts]);
    } else {
      setOilUplift([]);
    }
  }, [flight]);

  useEffect(() => {
    if (id && aircraftMap.size) {
      const eCount = aircraftMap.get(id)?.aircraft_type?.engine_count;
      setEngineCount(eCount);
      const oil_unit = aircraftMap.get(id)?.oil_unit;
      setOilUnit(oil_unit);
    }
  }, [id, aircraftMap]);

  useEffect(() => {
    if (engineCount) {
      const newUplift = [];
      for (let i = 0; i < engineCount; i += 1) {
        const currentUplift = oilUplift[i];
        newUplift[i] = oilUplift[i];
        if (!currentUplift) {
          newUplift[i] = {
            fluid_destination: 'engine',
            fluid_destination_number: i,
            fluid_type: 'oil',
            uplift_unit: oilUnit,
            uplift: 0,
          };
        }
      }
      setDisplayOilUplift(newUplift);
    }
  }, [oilUplift, engineCount]);

  const engineOilUplifts = (): ReactNode => {
    if (displayOilUplift?.length) {
      return displayOilUplift?.map((uplift) => {
        const title = `${uplift?.fluid_destination} ${uplift?.fluid_destination_number}`;
        return (
          <div key={title}>
            <Header editable={mode !== 'view'}>{title.replace(/_/g, ' ')}</Header>
            {mode !== 'view' ? (
              <>
                <StyledInput
                  width={74}
                  value={uplift?.uplift}
                  type="number"
                  placeholder="-"
                  onChange={(e): void => handleUpliftChange(e.target.value, uplift?.fluid_destination_number)}
                />
                <UnitSpan>{uplift?.uplift_unit}</UnitSpan>
              </>
            ) : (
              <DisplayText>{uplift?.uplift ? `${uplift?.uplift} ${uplift?.uplift_unit}` : '-'}</DisplayText>
            )}
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <Accordion
        dash
        add
        isExpanded={mode !== 'view'}
        headerContent={
          <RowHeader>
            <TitleWrapper>
              <img src={oilIcon} alt="engine icon" />
              Oil Uplifts
            </TitleWrapper>
            <PartsDiv>{`${oilUplift?.length} parts`}</PartsDiv>
          </RowHeader>
        }
      >
        <ContentWrapper>{engineOilUplifts()}</ContentWrapper>
      </Accordion>
    </>
  );
};

export default OilUplifts;
