import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PageMessage from '../PageMessage';
import infoIcon from './assets/info.svg';
import styles from './index.module.less';

class MXItemsImporter extends Component {
  static propTypes = {
    draftCount: PropTypes.number.isRequired,
    type: PropTypes.string,
    onActionClick: PropTypes.func,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    onActionClick: null,
    type: '',
  };

  getItemsCount = () => {
    const {
      draftCount,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className={styles.itemsCountParent}>
        {draftCount > 0 && (
          <div className={styles.singleItemCount}>
            {formatMessage({ id: 'message.newItems' })}: <span>{draftCount}</span>
          </div>
        )}
      </div>
    );
  };

  showReviewModal = () => {
    this.props.onActionClick();
  };

  render() {
    const {
      draftCount,
      intl: { formatMessage },
      type,
    } = this.props;
    const messageText = `${draftCount} ${
      draftCount > 1
        ? formatMessage({ id: 'message.draftItemsImported' })
        : formatMessage({ id: 'message.draftItemImported' })
    }`;

    return draftCount > 0 ? (
      <PageMessage
        type="warning"
        typeIcon={infoIcon}
        text={messageText}
        actionDescription={type === 'draftsOnly' ? null : this.getItemsCount()}
        actionText={type === 'draftsOnly' ? null : formatMessage({ id: 'message.startReviewing' })}
        actionFunction={() => this.showReviewModal()}
        data-test="pageMessage"
      />
    ) : null;
  }
}

export default injectIntl(MXItemsImporter);
