/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import chevron from '../../assets/dropdown-icon.svg';
import FlexWrapper from '../DefectDrawer/components/FlexWrapper';
import TFPortal from '../TFPortal/TFPortal';

interface TFSelectProps {
  options: { title: string; colour?: string; value?: string }[];
  handleSelectChange: (option: { title: string; colour?: string; value?: string }) => void;
  initial: { title: string; colour?: string; value?: string };
  width?: number;
  height?: number;
  disabled?: boolean;
  borderRadius?: string;
  display?: string;
  pagination?: boolean;
  allowEmpty?: boolean;
}

const StyledWrapper = styled.div`
  height: ${({ height }): string => (height ? `${height}px` : '34px')};
  width: ${({ width }): string => (width ? `${width}px` : '160px')};
  color: rgba(36, 45, 65, 0.9);
`;

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #fff;
  border-radius: ${({ borderRadius }): string => (borderRadius ? `${borderRadius}` : '2px')};
  ${({ optionsVisible }): string =>
    optionsVisible
      ? css`
          border: solid 1px #126fd6;
        `
      : css`
          border: solid 1px rgba(36, 45, 65, 0.2);
        `};
  cursor: pointer;
`;

const StyledButtonDisabled = styled.button`
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: rgba(36, 45, 65, 0.05);
  border-radius: ${({ borderRadius }): string => (borderRadius ? `${borderRadius}` : '2px')};
  border: solid 1px rgba(36, 45, 65, 0.2);
`;

const ColourSquare = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 10px;
`;

const ChevronIcon = styled.img`
  transform: ${({ optionsVisible }): string => (optionsVisible ? 'rotate(180deg)' : '')};
`;

const InvisibleBackground = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 501;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  border: 1px solid rgba(36, 45, 65, 0.1);
  max-height: 320px;
  flex-wrap: wrap;
  border-radius: 2px;
  background-color: #fff;
  margin-top: ${({ pagination }): string => (pagination ? 'unset' : '9px')};
  margin-left: -1px;
  z-index: 510;
  overflow: scroll;
`;

const SelectOption = styled.button`
  height: ${({ doubleHeight }): string => (doubleHeight ? '48px' : '32px')};
  width: 100%;
  padding: 4px 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-align: left;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: rgba(18, 111, 214, 0.05);
    color: #126fd6;
  }
  &:focus {
    border: solid 1px #126fd6;
  }
  color: ${({ current }): string => current && '#126fd6'};
`;

const TwoLineEllipsis = css`
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const SelectTitle = styled.span`
  text-transform: ${({ buttonTitle }): string =>
    buttonTitle === 'llp' || buttonTitle === 'oop' ? 'upperCase' : 'unset'};
  width: ${({ width }): string => (width ? `${width}px` : '160px')};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  ${({ noWrap }) => !noWrap && TwoLineEllipsis}
  @media (max-width: 450px) {
    overflow: scroll;
    height: 22px;
  }
`;

const TFSelect: React.FC<TFSelectProps> = ({
  options,
  handleSelectChange,
  initial,
  width,
  height,
  disabled,
  borderRadius,
  display,
  pagination,
  allowEmpty,
}) => {
  const [buttonTitle, setButtonTitle] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const buttonRef = useRef(null);

  const handleOptionSelection = (option: { title: string; colour?: string; value?: string }): void => {
    setButtonTitle(option);
    setOptionsVisible(false);
    handleSelectChange(option);
  };

  const selectOptions = options.map((option) => {
    return (
      <SelectOption
        type="button"
        doubleHeight={option?.title?.length > 40}
        key={option.title}
        current={buttonTitle && option.title === buttonTitle.title}
        onClick={(): void => handleOptionSelection(option)}
      >
        {option.colour ? <ColourSquare style={{ backgroundColor: option.colour }} /> : null}
        <SelectTitle width={width * 2} buttonTitle={option.title}>
          {option.title}
        </SelectTitle>
      </SelectOption>
    );
  });

  const handleButtonClick = (): void => {
    setOptionsVisible(!optionsVisible);
    const rect = buttonRef.current.getBoundingClientRect();
    const { top, left, width: recWidth } = rect;
    const useHeight = height || 27;
    setMenuStyle({
      position: 'absolute',
      top: display === 'above' ? top - 182 + window.scrollY : top + useHeight + window.scrollY,
      left: left + 1 + window.scrollX,
      width: recWidth,
    });
    setBackgroundStyle({ top: 0 + window.scrollY, left: 0 + window.scrollX });
  };

  useEffect(() => {
    if (initial || (initial && pagination)) {
      setButtonTitle(initial);
    }
  }, [initial, options]);

  useEffect(() => {
    if ((disabled || !buttonTitle) && !allowEmpty) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [buttonTitle?.title]);

  return (
    <StyledWrapper width={width} height={height}>
      {buttonDisabled ? (
        <StyledButtonDisabled
          type="button"
          ref={buttonRef}
          disabled={disabled}
          optionsVisible={optionsVisible}
          borderRadius={borderRadius}
        >
          <FlexWrapper alignItems="center">
            {buttonTitle?.colour ? <ColourSquare style={{ backgroundColor: buttonTitle?.colour }} /> : null}
            <SelectTitle noWrap width={width - 40} buttonTitle={buttonTitle?.title}>
              {buttonTitle?.title}
            </SelectTitle>
          </FlexWrapper>
        </StyledButtonDisabled>
      ) : (
        <StyledButton
          type="button"
          ref={buttonRef}
          disabled={disabled}
          optionsVisible={optionsVisible}
          onClick={handleButtonClick}
          borderRadius={borderRadius}
        >
          <FlexWrapper alignItems="center" marginRight={5} identifier="dropdown">
            {buttonTitle?.colour ? <ColourSquare style={{ backgroundColor: buttonTitle?.colour }} /> : null}
            <SelectTitle noWrap width={width - 40} buttonTitle={buttonTitle?.title}>
              {buttonTitle?.title}
            </SelectTitle>
          </FlexWrapper>
          <ChevronIcon src={chevron} alt="chevron" optionsVisible={optionsVisible} />
        </StyledButton>
      )}
      {optionsVisible ? (
        <TFPortal>
          <InvisibleBackground
            style={backgroundStyle}
            onClick={(e): void => {
              e.stopPropagation();
              setOptionsVisible(false);
            }}
          />
          <OptionsWrapper style={menuStyle} pagination>
            <FlexWrapper column alignItems="center">
              {selectOptions}
            </FlexWrapper>
          </OptionsWrapper>
        </TFPortal>
      ) : null}
    </StyledWrapper>
  );
};

export default TFSelect;
