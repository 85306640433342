import React from 'react';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';

interface ButtonSectionProps {
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  loading?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 450px) {
    justify-content: left;
  }
`;

const ButtonWrapper = styled.div`
  margin-right: 16px;
`;

const ButtonSection: React.FC<ButtonSectionProps> = ({ handleCancelClick, handleSaveClick, loading }) => {
  return (
    <Wrapper>
      <ButtonWrapper>
        <Button disabled={loading} size={ButtonSize.MEDIUM} padding="0 28px" onClick={handleSaveClick}>
          Save
        </Button>
      </ButtonWrapper>
      <Button size={ButtonSize.MEDIUM} padding="0 28px" onClick={handleCancelClick} primary={false}>
        Cancel
      </Button>
    </Wrapper>
  );
};

export default ButtonSection;
