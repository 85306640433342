/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trip } from '../../models/trips';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import { DisplayText, Header, StyledInput } from '../FlightDrawer/FlightDrawer';
import servers from '../../utils/servers';

interface SRPSectionProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any) => void;
}

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const DetailsWrapper = styled.div`
  margin-right: 40px;
  width: 100px;
  @media (max-width: 450px) {
    margin-right: 10px;
  }
`;

const StyledIcon = styled.img`
  width: 16px;
`;

const StyledLink = styled.a`
  color: #126fd6;
  font-size: 12px;
  letter-spacing: -0.08px;
  &:hover {
    color: #1e5bb5;
  }
`;

const SRPSection: React.FC<SRPSectionProps> = ({ trip, editable, updateTripData }) => {
  const [tripNo, setTripNo] = useState(trip?.number);
  const [SRPNumber, setSRPNumber] = useState(trip?.srp_number);

  const handleTripNumberUpdate = (value: string): void => {
    updateTripData('number', value);
  };

  const handleSRPUpdate = (value: string): void => {
    updateTripData('srp_number', value);
  };

  useEffect(() => {
    if (trip) {
      setTripNo(trip?.number);
      setSRPNumber(trip?.srp_number);
    }
  }, [trip]);

  return (
    <RowWrapper>
      <DetailsWrapper data-testid="SRPSection-Header">
        <Header editable={editable}>Trip No.</Header>
        {editable ? (
          <StyledInput width={100} value={tripNo} onChange={(e): void => handleTripNumberUpdate(e.target.value)} />
        ) : (
          <DisplayText>{trip?.number || '-'}</DisplayText>
        )}
      </DetailsWrapper>
      <DetailsWrapper data-testid="SRPSection-SRP">
        <Header editable={editable}>Trip SRP</Header>
        {editable ? (
          <StyledInput width={100} value={SRPNumber} onChange={(e): void => handleSRPUpdate(e.target.value)} />
        ) : (
          <DisplayText>
            <StyledLink
              href={`${servers.api}/srp/${trip?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              download="srp"
            >
              SRP <StyledIcon src={pdfIcon} alt="pdf icon" /> {`${trip?.srp_number}`}
            </StyledLink>
          </DisplayText>
        )}
      </DetailsWrapper>
      <DetailsWrapper data-testid="SRPSection-JourneyLog">
        <Header>Journey Log</Header>
        <DisplayText>
          <StyledLink
            href={`${servers.api}/srp/journey_log/${trip?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            download="journey_log"
          >
            View <StyledIcon src={pdfIcon} alt="pdf icon" />
          </StyledLink>
        </DisplayText>
      </DetailsWrapper>
    </RowWrapper>
  );
};

export default SRPSection;
