import { Badge, message } from 'antd';
import { connect } from 'react-redux';
import _, { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { FilterSideBar, Modal, Button as TFButton } from '@arcflight/tf-component-library';
import { hasAircraftPermission } from '../_utils/AuthenticationWrapper';
import Loading from '../TFLoading/index';
import defaults from '../../utils/defaults';
import servers from '../../utils/servers';
import TFTable from '../TFTable/TFTable';
import {
  getV3Defects as getAllDefectsAction,
  getAircraftsDefects,
  saveParams,
  saveFilters,
  remove,
} from '../../models/defects/actions';
import { newGetAircraftDefects, newGetAllDefects, getDefectTotals } from '../../services/apiNew';
import { fetchAircraftStatus, getAllAircraft } from '../../models/aircraft/actions';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { fetch } from '../../models/userSettings/actions';
import EmptyState from '../EmptyState/EmptyState';
import EmptyStateDefects from '../../assets/emptyState/empty-state-defects.svg';
import NonStyledButton from '../NonStyledButton/NonStyledButton';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import pdfIcon from '../../assets/icon-filetype-pdf.svg';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import defectsFilterModel from './defectsFilterModel';
import styles from './index.module.less';
import DefectTableHeader, { ButtonSize } from './DefectTableHeader';

class PaginatedDefectsTable extends PureComponent {
  static propTypes = {
    getAircraftList: PropTypes.func.isRequired,
    refetchAircraft: PropTypes.func.isRequired,
    getUserSettings: PropTypes.func.isRequired,
    saveFilters: PropTypes.func.isRequired,
    removeDefect: PropTypes.func.isRequired,
    saveParams: PropTypes.func.isRequired,
    changeDrawerContentDispatch: PropTypes.func.isRequired,
    changeDrawerModeDispatch: PropTypes.func.isRequired,
    changeDrawerVisiblityDispatch: PropTypes.func.isRequired,
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    aircraftList: PropTypes.shape({
      list: PropTypes.array.isRequired,
      lastFetched: PropTypes.number.isRequired,
    }),
    defects: PropTypes.shape({
      pagination: PropTypes.object.isRequired,
      list: PropTypes.array.isRequired,
      lastFetched: PropTypes.number.isRequired,
    }),
    aircraft: PropTypes.object,
    intl: PropTypes.object.isRequired,
    history: PropTypes.object,
  };

  static defaultProps = {
    defects: {
      pagination: {},
      list: [],
      lastFetched: 0,
    },
    aircraftList: {
      list: [],
      lastFetched: 0,
    },
    aircraft: null,
    history: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      localDefectArray: [],
      originalList: [],
      defectFilterModel: defectsFilterModel(),
      params: null,
      filters: null,
      pagination: null,
      loading: false,
      showDefectDrawer: false,
      page: 1,
      limit: 10,
      filteredCount: 0,
      resetToOne: false,
      sortBy: [],
      searchValue: '',
      userSettingsLoading: true,
      historyDataUsed: false,
      modalVisible: false,
      modalId: null,
      reset: false,
    };
  }

  componentDidMount() {
    const { aircraftList, getAircraftList, aircraft, getUserSettings } = this.props;
    this.getDefectData(true);
    this.getTotals();
    getUserSettings();
    if (!aircraft && Date.now() - aircraftList?.lastFetched > 30000) {
      getAircraftList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { aircraft, userSettings, history, defects, match, refetchAircraft } = this.props;
    const { loading, historyDataUsed, page, limit, filters, sortBy, localDefectArray, searchValue } = this.state;
    if (aircraft && (!prevProps.aircraft || prevProps.aircraft.id !== aircraft.id)) {
      this.getDefectData();
    }
    if (!_.isEqual(defects.list, prevProps.defects.list)) {
      this.getDefectData();
      this.getTotals();
      if (match?.params?.id) refetchAircraft(match?.params?.id);
    }
    const firstSortBy = sortBy.length === 0 && prevState.sortBy.length === 0;
    if (!loading && (!_.isEqual(filters, prevState.filters) || (!firstSortBy && sortBy !== prevState.sortBy))) {
      this.handleFilterData();
    }
    if (!loading && (page !== prevState.page || limit !== prevState.limit)) {
      this.getDefectData();
    }
    if (!loading && searchValue !== prevState.searchValue) {
      this.getDebounceDefects();
    }
    if (userSettings?.details?.email) {
      this.handleUserSettingsLoading();
    }
    if (history && history?.location?.state?.id && localDefectArray && !historyDataUsed) {
      const foundDefect = localDefectArray.find((defect) => defect.id === history?.location?.state?.id);
      if (foundDefect) this.handleOpenDrawer(foundDefect);
    }
  }

  componentWillUnmount = () => {
    const { params, filters } = this.state;
    if (params) {
      this.props.saveParams(params);
      this.props.saveFilters(filters);
    }
  };

  getDebounceDefects = debounce(() => {
    this.setState({ page: 1, resetToOne: true }, () => {
      this.getDefectData();
    });
  }, 300);

  handleUserSettingsLoading = () => {
    this.setState({ userSettingsLoading: false });
  };

  handleOpenDrawer = (selectedDefect) => {
    const { changeDrawerVisiblityDispatch, changeDrawerContentDispatch } = this.props;
    changeDrawerVisiblityDispatch(true);
    changeDrawerContentDispatch({
      content: <DefectDrawer defectId={selectedDefect.id} />,
    });
    this.setState({ historyDataUsed: true });
  };

  handleFilterData = () => {
    this.setState({ resetToOne: true, page: 1 }, () => this.getDefectData());
  };

  getDefectData = async (first) => {
    this.setState({ loading: true });
    const { aircraft } = this.props;
    const { page, limit, filters, sortBy, searchValue } = this.state;
    const payload = {
      page,
      limit,
    };
    if (filters?.status?.length) payload.status = filters.status;
    if (filters?.type?.length) payload.type = filters.type;
    if (filters?.deferred?.length) payload.deferred = filters.deferred;
    if (sortBy.length) {
      let sort_column = sortBy[0].id;
      // eslint-disable-next-line prefer-destructuring
      if (sort_column.startsWith('display')) sort_column = sort_column.split('.')[1];
      if (sort_column === 'ata') sort_column = 'ata_chapter';
      if (sort_column === 'type') sort_column = 'defect_type';
      const sort_order = sortBy[0].desc ? 'desc' : 'asc';
      payload.sort_column = sort_column;
      payload.sort_order = sort_order;
    }
    if (searchValue) payload.search = searchValue;
    if (aircraft) {
      payload.aircraft_id = aircraft.id;
    }

    if (aircraft !== null) {
      const res = await newGetAircraftDefects(payload);
      if (res.status > 199 && res.status < 400) {
        if (first) this.setState({ originalList: res.data.defects });
        this.setState({ filteredCount: res.data.count, localDefectArray: res.data.defects, loading: false });
      } else {
        message.error('An error occurred when fetching the data');
      }
    } else {
      const res = await newGetAllDefects(payload);
      if (res?.status > 199 && res?.status < 400) {
        if (first) this.setState({ originalList: res.data.defects });
        this.setState({ filteredCount: res.data.count, localDefectArray: res.data.defects, loading: false });
      } else {
        message.error('An error occurred when fetching the data');
      }
    }
    this.setState({ resetToOne: false });
  };

  resetFilters = () => {
    this.setState({ filters: null, page: 1, sortBy: [], searchValue: '', reset: true });
  };

  handleAddDefect = () => {
    const { changeDrawerVisiblityDispatch, changeDrawerContentDispatch, changeDrawerModeDispatch } = this.props;
    changeDrawerVisiblityDispatch(true);
    changeDrawerModeDispatch('add');
    changeDrawerContentDispatch({ content: <DefectDrawer defectId="" /> });
  };

  getTotals = async () => {
    const { aircraft } = this.props;
    let totals;
    if (aircraft) {
      const payload = {
        aircraft_id: aircraft.id,
      };
      totals = await getDefectTotals(payload);
    } else {
      totals = await getDefectTotals();
    }
    const defectFilterModel = defectsFilterModel(totals.data);
    this.setState({ defectFilterModel });
  };

  handleSelectRows = (id, edit) => {
    const { changeDrawerContentDispatch, changeDrawerVisiblityDispatch, changeDrawerModeDispatch } = this.props;
    changeDrawerVisiblityDispatch(true);
    changeDrawerContentDispatch({ content: <DefectDrawer defectId={id} /> });
    if (edit) changeDrawerModeDispatch({ payload: 'edit' });
  };

  editDefect = (defect) => {
    const { changeDrawerVisiblityDispatch, changeDrawerModeDispatch, changeDrawerContentDispatch } = this.props;
    changeDrawerVisiblityDispatch(true);
    changeDrawerModeDispatch('edit');
    changeDrawerContentDispatch({ content: <DefectDrawer defectId={defect?.id} /> });
  };

  viewDefect = (defect) => {
    const { changeDrawerVisiblityDispatch, changeDrawerContentDispatch } = this.props;
    changeDrawerVisiblityDispatch(true);
    changeDrawerContentDispatch({ content: <DefectDrawer defectId={defect?.id} /> });
  };

  deferDefect = (defect) => {
    const { changeDrawerVisiblityDispatch, changeDrawerContentDispatch, changeDrawerModeDispatch } = this.props;
    changeDrawerVisiblityDispatch(true);
    changeDrawerModeDispatch('edit');
    changeDrawerContentDispatch({
      content: <DefectDrawer defectId={defect?.id} deferDefect />,
    });
  };

  handleDeleteDefect = (id) => {
    this.setState({ modalVisible: true, modalId: id });
  };

  modalDeleteDefect = () => {
    const { match } = this.props;
    const { modalId } = this.state;
    this.setState({ loading: true });
    new Promise((res, rej) => res(this.props.removeDefect(modalId))).then(() => {
      this.getDefectData();
      this.getTotals();
      if (match?.params?.id) this.props.refetchAircraft(match?.params?.id);
    });
    this.setState({ modalVisible: false, modalId: null });
  };

  findObjectString = (obj, str) => {
    const keys = Object.keys(obj);
    let val;
    for (let i = 0; i < keys.length; i += 1) {
      val = obj[keys[i]];
      if (
        (typeof val === 'string' && val.toLowerCase().includes(str.toLowerCase())) || // check string values
        (typeof val === 'number' && val.toString().includes(str)) || // check number values
        (val && typeof val === 'object' && this.findObjectString(val, str)) // recursively check child objects
      ) {
        return true;
      }
    }
    return false;
  };

  onSortChange = (sortBy) => {
    this.setState({ sortBy });
  };

  setSearchValue = (value) => {
    this.setState({ searchValue: value, reset: false });
  };

  handleLocalDefectArrayUpdate = (array) => {
    this.setState({ localDefectArray: array, reset: false });
  };

  render() {
    const {
      userSettings,
      defects,
      intl: { formatMessage },
      match,
    } = this.props;
    const {
      loading,
      pagination,
      params,
      showDefectDrawer,
      localDefectArray,
      originalList,
      defectFilterModel,
      page,
      limit,
      filteredCount,
      resetToOne,
      userSettingsLoading,
      modalVisible,
      reset,
    } = this.state;
    if (!pagination && defects?.pagination) {
      if (params) {
        defects.pagination.current = params.current;
      } else {
        delete defects.pagination.current;
      }
    }

    if (pagination) {
      if (!defects.pagination) {
        defects.pagination = {};
      }
      defects.pagination.current = pagination;
    }
    const showAircraftColumn = document.location.pathname === '/defects';
    const hiddenColumns = [];
    if (!showAircraftColumn) hiddenColumns.push('aircraft.registration');
    const status = {
      open: {
        badge: 'warning',
        text: formatMessage({ id: 'status.open' }),
      },
      resolved: {
        badge: 'success',
        text: formatMessage({ id: 'status.resolved' }),
      },
      overdue: {
        badge: 'error',
        text: formatMessage({ id: 'status.overdue' }),
      },
      draft: {
        badge: 'default',
        text: formatMessage({ id: 'status.draft' }),
      },
      critical: {
        badge: 'error',
        text: formatMessage({ id: 'status.inTolerance' }),
      },
    };
    const columns = [
      {
        accessor: 'status',
        Header: formatMessage({ id: 'title.status' }),
        width: 70,
        Cell: ({ value }) => {
          if (value)
            return (
              <div className={styles.badgeStyle}>
                <Badge status={status[value].badge} />
              </div>
            );
          return null;
        },
      },
      {
        Header: formatMessage({ id: 'title.aircraft' }),
        accessor: 'aircraft.registration',
        width: 100,
        sortable: false,
      },
      {
        Header: formatMessage({ id: 'title.numberShort' }),
        accessor: 'number',
        width: 70,
      },
      {
        Header: formatMessage({ id: 'title.item' }),
        accessor: 'details',
        className: 'columnItem',
        width: 270,
        Cell: ({ value }) => value || '-',
      },
      {
        Header: formatMessage({ id: 'title.type' }),
        accessor: 'display_data.type',
        Cell: ({ value }) => value || '-',
        width: 70,
      },
      {
        Header: formatMessage({ id: 'title.ata' }),
        accessor: 'display_data.ata',
        Cell: ({ value }) => {
          if (value === 'UNKNOWN') return '-';
          const number = value.split(' ')[0];
          return number || '-';
        },
        width: 70,
      },
      {
        Header: formatMessage({ id: 'title.date' }),
        accessor: 'date',
        width: 130,
        className: 'date',
        Cell: ({ value }) => {
          if (value)
            return userSettings && userSettings.dateFormat
              ? moment(value).format(userSettings.dateFormat)
              : moment(value).format(defaults.defaultDateFormat);
          return null;
        },
      },
      {
        Header: formatMessage({ id: 'title.daysRemainingShort' }),
        accessor: 'days_remaining',
        width: 80,
        className: 'date',
        Cell: ({ value }) => {
          if (value && Number(value) < 0) {
            return 0;
          }
          if (value) {
            return value;
          }
          return 0;
        },
      },
      {
        accessor: 'ddl_url',
        width: 100,
        className: 'leftBorder',
        Cell: ({ value }) =>
          value === null ? null : (
            <span>
              <NonStyledButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <a
                  href={`${servers.api}${value}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="DDL"
                  className={styles.pdfCell}
                >
                  <img src={pdfIcon} alt="pdf icon" /> {formatMessage({ id: 'text.ddl' })}
                </a>
              </NonStyledButton>
            </span>
          ),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'id',
        Cell: ({ value, row }) => {
          const complete = row?.original?.status === 'resolved';
          const isDefectDeferred = row?.original?.deferred;
          return (
            <AuthDropdownMenu
              options={{
                create: false,
                read: true,
                update: !complete,
                delete: true,
                custom: !complete && !isDefectDeferred,
              }}
              menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
              resource={AircraftResource.DEFECT}
              aircraftId={row.original.aircraft.id}
              handleDelete={() => this.handleDeleteDefect(value)}
              viewCallback={() => this.viewDefect(row.original)}
              editCallback={() => this.editDefect(row.original)}
              forTable
              customText="Defer Defect"
              customCallback={() => this.deferDefect(row.original)}
            />
          );
        },
        width: 50,
      },
    ];
    return (
      <div className={styles.columnContainer} data-testid="PaginatedDefectsTable--ColumnContainer">
        <div className={styles.defectsTable} data-testid="PaginatedDefectsTable--TableContainer">
          <Loading loading={(loading && !showDefectDrawer) || userSettingsLoading} contain />
          <div className={styles.stickyHeader}>
            <DefectTableHeader
              defectCount={filteredCount}
              aircraftId={match.params.id}
              setSearchValue={this.setSearchValue}
              reset={reset}
            />
          </div>
          {localDefectArray && localDefectArray.length > 0 ? (
            <>
              <div id="tableWrapper" className={styles.tableWrapper}>
                <TFTable
                  columns={columns}
                  data={localDefectArray}
                  handleRowClick={this.handleSelectRows}
                  hiddenColumns={hiddenColumns}
                  onPaginationChange={(currentPage, numberOfItems) => {
                    if (numberOfItems !== limit) {
                      this.setState({ page: 1, limit: numberOfItems });
                    } else if (currentPage !== page) {
                      this.setState({ page: currentPage });
                    }
                  }}
                  onSortChange={(sort) => this.onSortChange(sort)}
                  total={filteredCount}
                  pageSize={limit}
                  pageIndex={page - 1}
                  resetToOne={resetToOne}
                />
              </div>
            </>
          ) : (
            <EmptyState
              image={EmptyStateDefects}
              text={originalList.length === 0 ? 'No defects' : "We couldn't find any matching defects"}
              subText={
                originalList.length === 0
                  ? 'You can add your first defect item now.'
                  : 'Try adjusting your filters or searching with another term.'
              }
              button={originalList.length === 0 ? 'Add defect' : 'Clear all'}
              buttonAction={originalList.length === 0 ? this.handleAddDefect : this.resetFilters}
            />
          )}
        </div>
        <div className={styles.filterSidebar} data-testid="PaginatedDefectsTable--FiltersContainer">
          <FilterSideBar
            data={originalList}
            updateArray={this.handleLocalDefectArrayUpdate}
            filterGroups={defectFilterModel}
            reset={reset}
            onChange={(e) => {
              if (e.length === 0) {
                this.setState({ filters: { source: [], status: [] }, reset: false });
              } else {
                const newFilters = e.reduce((newObject, object) => {
                  const workingObject = newObject;
                  if (!workingObject[object.key]) {
                    workingObject[object.key] = [object.value];
                  } else {
                    workingObject[object.key].push(object.value);
                  }
                  return workingObject;
                }, {});
                this.setState({ filters: newFilters, reset: false });
              }
            }}
          />
        </div>
        <Modal
          isOpen={modalVisible}
          width={420}
          handleClose={() => this.setState({ modalVisible: false, loading: false })}
        >
          <div className={styles.modalContentWrapper}>
            <div className={styles.modalTitle}>{formatMessage({ id: 'title.deleteItem' })}</div>
            <div className={styles.modalMessage}>
              {`${formatMessage({
                id: 'form.question.areYouSureDeleteDefect',
              })} ${formatMessage({
                id: 'form.labels.cannotBeUndone',
              })}`}
            </div>
            <div className={styles.modalButtonWrapper}>
              <div className={styles.submitButton}>
                <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={() => this.modalDeleteDefect()}>
                  Delete
                </TFButton>
              </div>
              <TFButton
                padding="0 28px"
                size={ButtonSize.MEDIUM}
                primary={false}
                onClick={() => this.setState({ modalVisible: false, loading: false })}
              >
                Cancel
              </TFButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    ({ defects, aircraft, userSettings, menu, drawer }) => ({
      defects: {
        pagination: defects?.pagination,
        list: Array.from(defects?.defectsMap.values())
          .filter((item) => {
            const usersPermissions = userSettings?.details?.people?.find((person) =>
              person?.permission_groups[1]?.aircraft.includes(item?.aircraft.id),
            );
            return hasAircraftPermission(
              item.aircraft,
              AircraftResource.AIRCRAFT,
              AircraftPermission.READ,
              usersPermissions?.permission_groups[1],
            );
          })
          .reduce(
            (array, item) => {
              if (item.status === 'overdue') {
                array[0].push(item);
                array[0].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              if (item.status === 'open') {
                array[1].push(item);
                array[1].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              if (item.status === 'resolved') {
                array[2].push(item);
                array[2].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              if (item.status === 'draft') {
                array[3].push(item);
                array[3].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              return array;
            },
            [[], [], [], []],
          )
          .flat(),
        lastFetched: defects.lastFetched,
      },
      params: defects.params,
      filters: defects.filters,
      aircraftList: {
        list: Array.from(aircraft.aircraftMap.values()),
        lastFetched: aircraft.lastFetched,
      },
      userSettings,
      menu,
      drawer,
    }),
    (dispatch) => ({
      getAllDefects: (payload) => {
        return dispatch(
          getAllDefectsAction({
            payload,
          }),
        );
      },
      getACDefects: (payload) => {
        return dispatch(getAircraftsDefects(payload));
      },
      getAircraftList: () => {
        return dispatch(getAllAircraft({}));
      },
      saveParams: (params) => {
        return dispatch(
          saveParams({
            payload: params,
          }),
        );
      },
      saveFilters: (filters) => {
        return dispatch(
          saveFilters({
            payload: filters,
          }),
        );
      },
      removeDefect: (id) => {
        return dispatch(
          remove({
            payload: id,
          }),
        );
      },
      refetchAircraft: (id) => {
        return dispatch(
          fetchAircraftStatus({
            payload: id,
          }),
        );
      },
      getUserSettings: () => {
        return dispatch(fetch());
      },
      changeDrawerVisiblityDispatch: (value) => {
        return dispatch(changeDrawerVisibility({ payload: value }));
      },
      changeDrawerModeDispatch: (value) => {
        dispatch(changeDrawerMode({ payload: value }));
      },
      changeDrawerContentDispatch: (value) => {
        dispatch(changeDrawerContent({ payload: value }));
      },
    }),
  ),
)(PaginatedDefectsTable);
