/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface DeferUntilProps {
  defect: Defect | null;
  rectificationCategory?: { title: string; colour?: string };
  calendarDays?: string;
  setDateDue: (input) => void;
  nonMEL?: boolean;
}

const DeferUntil: React.FC<DeferUntilProps> = ({ defect, rectificationCategory, calendarDays, setDateDue, nonMEL }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [newDate, setNewDate] = useState(moment());
  const [displayDate, setDisplayDate] = useState(null);

  const calculateDateDue = (): string => {
    let dateText;
    let date = defect?.raised_at ? moment(defect?.raised_at) : moment();
    const title = rectificationCategory?.title;
    if (title === 'A' && (!calendarDays || calendarDays.length === 0)) {
      dateText = '-';
      setDisplayDate(dateText);
      return dateText;
    }
    if (date) {
      if (title === 'A') {
        date = date
          ? moment(date).add(parseInt(calendarDays, 10), 'days')
          : moment().add(parseInt(calendarDays, 10), 'days');
      }
      if (title === 'B') {
        date = date.add(3, 'days');
      }
      if (title === 'C') {
        date = date.add(10, 'days');
      }
      if (title === 'D') {
        date = date.add(120, 'days');
      }
      dateText = date.format('YYYY-MM-DD');
      setDisplayDate(dateText);
      setNewDate(date);
    }
    return dateText;
  };

  const disableDate = (current) => {
    return current && current.valueOf() < new Date(defect?.raised_at);
  };

  useEffect(() => {
    setShowDatePicker(false);
    const date = calculateDateDue();
    if (rectificationCategory?.title !== 'A') setNewDate(date === '-' ? moment(defect?.raised_at) : moment(date));
  }, [rectificationCategory?.title]);

  useEffect(() => {
    if (defect?.raised_at) {
      setNewDate(moment(defect?.raised_at));
    } else {
      setNewDate(moment());
    }
  }, [defect?.raised_at]);

  useEffect(() => {
    if (rectificationCategory?.title === 'A') {
      if (calendarDays) {
        const calDate = defect?.raised_at
          ? moment(defect?.raised_at).add(parseInt(calendarDays, 10), 'days')
          : moment().add(parseInt(calendarDays, 10), 'days');
        setNewDate(calDate);
        setDisplayDate(calDate.format('YYYY-MM-DD'));
      } else if (!calendarDays || calendarDays?.length === 0) {
        setNewDate(moment());
        setDisplayDate('-');
      }
    }
  }, [calendarDays]);

  return (
    <FlexWrapper marginRight={20}>
      {rectificationCategory &&
      rectificationCategory.title &&
      rectificationCategory.title === 'Advisory only' ? null : (
        <>
          <FlexWrapper column marginRight={20}>
            <Label color="rgba(36, 45, 65, 0.4)" fontWeight={400} marginBottom={nonMEL ? 5 : 0} width="120px">
              DEFERRED UNTIL
            </Label>
            {showDatePicker ? (
              <DatePicker
                disabledDate={disableDate}
                onChange={(date): void => {
                  setDateDue(date);
                  setNewDate(date);
                  setDisplayDate(date.format('YYYY-MM-DD'));
                }}
                defaultValue={newDate}
              />
            ) : (
              <Label height="30px">{displayDate}</Label>
            )}
          </FlexWrapper>
        </>
      )}
    </FlexWrapper>
  );
};

export default DeferUntil;
