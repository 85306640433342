/* eslint-disable @typescript-eslint/no-explicit-any */
enum MenuActionTypes {
  SET_LAYOUT_COLLAPSED = 'menu/setLayoutCollapsed',
  SET_BANNER_NOT_VISIBLE = 'menu/setBannerNotVisible',
}

interface SetLayoutCollapsedMenuActionType {
  type: MenuActionTypes.SET_LAYOUT_COLLAPSED | MenuActionTypes.SET_BANNER_NOT_VISIBLE;
  payload: boolean;
}

type MenuAction = SetLayoutCollapsedMenuActionType;

const initialState: MenuState = {
  collapsed: false,
  bannerVisible: false,
};

export const changeLayoutCollapsed = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: MenuActionTypes.SET_LAYOUT_COLLAPSED,
    payload,
  });
};

export const changeBannerVisible = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: MenuActionTypes.SET_BANNER_NOT_VISIBLE,
    payload,
  });
};

export default function(state: MenuState = initialState, action: MenuAction): MenuState {
  switch (action.type) {
    case MenuActionTypes.SET_LAYOUT_COLLAPSED:
      return {
        ...state,
        collapsed: action.payload,
      };
    case MenuActionTypes.SET_BANNER_NOT_VISIBLE:
      return {
        ...state,
        bannerVisible: action.payload,
      };
    default:
      return state;
  }
}

export interface MenuState {
  collapsed: boolean;
  bannerVisible: boolean;
}
