import { BaseModel } from '..';

export const initialState: MELsState = {
  melsMap: new Map(),
  aircraftMelsMap: new Map(),
  lastFetched: 0,
  dirty: false,
};

export enum MELsActionTypes {
  SAVE = 'mels/save',
  SAVE_AIRCRAFT_MEL = 'mels/saveAircraftMel',
  SAVE_ALL = 'mels/saveAll',
  DELETE = 'mels/delete',
  DELETEITEM = 'mels/deleteItem',
  UPDATE = 'mels/update',
}

export interface SaveMELsAction {
  type: MELsActionTypes.SAVE;
  payload: MELItem;
}

export interface SaveAircraftMelAction {
  type: MELsActionTypes.SAVE_AIRCRAFT_MEL;
  payload: {
    mel: MELItem[];
    id: string;
  };
}

export interface UpdateMELsAction {
  type: MELsActionTypes.UPDATE;
  payload: MELItem;
}

export interface SaveAllMELsAction {
  type: MELsActionTypes.SAVE_ALL;
  payload: MELItem[];
}

export interface DeleteMELsAction {
  type: MELsActionTypes.DELETE;
  payload: string;
}

export interface DeleteItemMELsAction {
  type: MELsActionTypes.DELETEITEM;
  payload: MELItem;
}

type MELsAction =
  | SaveMELsAction
  | SaveAllMELsAction
  | DeleteMELsAction
  | UpdateMELsAction
  | DeleteItemMELsAction
  | SaveAircraftMelAction;

export const isCompleteMELRecord = (record: BaseMEL | MEL): record is MEL =>
  record && 'mel_items' in record && Array.isArray(record.mel_items);

export default function(state = initialState, action: MELsAction): MELsState {
  switch (action.type) {
    case MELsActionTypes.SAVE_ALL: {
      const melsMap = new Map(state.melsMap.entries());
      action.payload.forEach((mel) => {
        melsMap.set(mel.id, {
          ...melsMap.get(mel.id),
          ...mel,
        });
      });
      return {
        ...state,
        melsMap,
        lastFetched: Math.floor(Date.now() / 1000),
      };
    }
    case MELsActionTypes.SAVE_AIRCRAFT_MEL: {
      const aircraftMelsMap = new Map(state.aircraftMelsMap.entries());
      aircraftMelsMap.set(action.payload.id, {
        list: action.payload.mel,
      });
      return {
        ...state,
        aircraftMelsMap,
        lastFetched: Math.floor(Date.now() / 1000),
        dirty: false,
      };
    }
    case MELsActionTypes.SAVE: {
      const melsMap = new Map(state.melsMap.entries());
      const mel = action.payload;
      melsMap.set(mel.id, {
        ...melsMap.get(mel.id),
        ...mel,
      });
      return {
        ...state,
        melsMap,
        lastFetched: Math.floor(Date.now() / 1000),
      };
    }
    case MELsActionTypes.UPDATE: {
      const melsMap = new Map();
      const mel = action.payload;

      const idToDelete = mel.mel_items.map((item: MELItem) => {
        return item.id;
      });

      const currentMelState = state.melsMap.get(mel.id);
      let freshMelItems = currentMelState.mel_items.filter((item) => !idToDelete.includes(item.id));

      freshMelItems = freshMelItems.concat(action.payload.mel_items);

      melsMap.set(mel.id, {
        ...state.melsMap.get(mel.id),
        mel_items: freshMelItems,
      });

      return {
        ...state,
        melsMap,
        lastFetched: Math.floor(Date.now() / 1000),
      };
    }
    case MELsActionTypes.DELETE: {
      const melsMap = new Map(state.melsMap.entries());
      melsMap.delete(action.payload);

      return {
        ...state,
        melsMap,
        lastFetched: Math.floor(Date.now() / 1000),
      };
    }
    case MELsActionTypes.DELETEITEM: {
      const melsMap = new Map();
      const mel = action.payload;

      const idToDelete = mel.mel_items.map((item: MELItem) => {
        return item.id;
      });

      const currentMelState = state.melsMap.get(mel.id);
      const freshMelItems = currentMelState.mel_items.filter((item) => !idToDelete.includes(item.id));

      melsMap.set(mel.id, {
        ...state.melsMap.get(mel.id),
        mel_items: freshMelItems,
      });

      return {
        ...state,
        melsMap,
        lastFetched: Math.floor(Date.now() / 1000),
      };
    }
    default:
      return state;
  }
}

export interface MELsState {
  melsMap: Map<string, MEL>;
  aircraftMelsMap: Map<string, { list: MELItem[] }>;
  lastFetched: number;
  dirty: boolean;
}

export interface BaseMEL extends BaseModel {
  aircraft_id: string;
  aircraft_type_id: unknown;
  last_updated: number;
  organisation_id: string;
  parent_id: unknown;
  source: unknown;
  status: string;
  type: string;
  aircraft_type: {
    designator: unknown;
    manufacturer: unknown;
    model: unknown;
  };
  aircraft: {
    registration: string;
    aircraft_type: {
      designator: string;
      manufacturer: string;
      model: string;
    };
  };
}

export interface MEL extends BaseMEL {
  organisation_name: string;
  mel_items: MELItem[];
}

export interface MELItem {
  id: string;
  mel_id?: string;
  page_number: number;
  chapter_number: number;
  section_number: number;
  subsection_number: number;
  subsubsection: string;
  title: string;
  interval?: string;
  interval_days?: number;
  number_installed?: number;
  number_required?: number;
  maintenance_procedure?: string;
  operational_procedure?: string;
  placard_procedure?: string;
  remarks?: string;
  mel_items?: [];
  operational_limitations?: string;
  sort_order?: unknown;
  flight_seconds_limit?: unknown;
  seconds_limit?: unknown;
  calendar_days_limit?: unknown;
  flight_days_limit?: unknown;
  cycles_limit?: unknown;
  flights_limit?: unknown;
  other_limit?: unknown;
  attachments: [];
  mel_rectifications: Array<MELRectification>;
  rectification_id?: string;
}

export interface MELRectification {
  attachments: [];
  apu_seconds_limit?: number;
  calendar_days_limit: number;
  cycles_limit: number;
  erops_prevented: boolean;
  flight_days_limit: number;
  flight_seconds_limit: number;
  flights_limit: number;
  id: string;
  interval: string;
  maintenance_procedure: string;
  maintenance_procedure_json: Array<MaintenanceProcedure>;
  number_installed: number;
  number_required: number;
  operational_limitations: string;
  operational_limitations_json: [];
  operational_procedure: string;
  operational_procedure_json?: Array<OperationalProcedure>;
  other_limit: string;
  placard_procedure: string;
  placard_procedure_json: [];
  remarks: string;
  remarks_json?: Array<OperationalProcedure>;
  seconds_limit: number;
  title: string;
}

export interface OperationalProcedure {
  children: { text: string }[];
  type: string;
}

export interface MaintenanceProcedure {
  children: { text: string; bold?: boolean }[];
  type: string;
}
