import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button, Search } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import { DashboardResource, UserPermission } from '../../models/userSettings';
import plusIcon from '../../assets/plus.svg';
import AuthenticationWrapper from '../_utils/AuthenticationWrapper';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import styles from './DefectTableHeader.module.less';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface DefectTableHeaderProps {
  defectCount: number;
  aircraftId: string;
  setSearchValue: (value: string) => void;
  reset: boolean;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 10px;
  @media (max-width: 450px) {
    align-items: flex-start;
    flex-direction: column;
    margin: 24px 24px 10px;
  }
`;

const DefectCount = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #242d41;
  span {
    font-weight: 400;
  }
  @media (max-width: 450px) {
    margin-bottom: 12px;
  }
`;

const LinkButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  color: #126fd6;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const LinkSpan = styled.span`
  color: #126fd6;
`;

const AddButtonText = styled.span`
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  &:first-child {
    margin-right: 12px;
  }
  @media (max-width: 450px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const SearchMargin = styled.div`
  margin-right: 18px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const DefectTableHeader: React.FC<DefectTableHeaderProps> = ({ defectCount, aircraftId, setSearchValue, reset }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const addNewDefect = (): void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: 'add' }));
    dispatch(changeDrawerContent({ payload: { content: <DefectDrawer defectId="" /> } }));
  };

  const menuOptions = (
    <Menu className={styles.menuOptions}>
      <Menu.Item>
        <Link
          to={{
            pathname: aircraftId ? `/aircraft/${aircraftId}/workpacks/log` : '',
            state: {
              addWorkpack: true,
            },
          }}
        >
          <LinkSpan>{formatMessage({ id: 'text.workpack' })}</LinkSpan>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={addNewDefect}>
        <LinkButton type="button" onClick={addNewDefect}>
          {formatMessage({ id: 'title.defect' })}
        </LinkButton>
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderWrapper className={styles.headerWrapper}>
      <DefectCount>Defects {defectCount && <span>{`(${defectCount})`}</span>}</DefectCount>
      <SearchWrapper id="searchWrapper">
        <SearchMargin>
          <Search
            onChange={(e): void => setSearchValue(e.currentTarget.value)}
            onClear={(): void => setSearchValue('')}
            reset={reset}
          />
        </SearchMargin>
        <AuthenticationWrapper
          requiredResource={DashboardResource.DEFECT}
          requiredPermissionLevel={UserPermission.CREATE}
        >
          <Dropdown overlay={menuOptions} trigger={['click']}>
            <div>
              <Button size={ButtonSize.MEDIUM} onClick={null}>
                <AddButtonText>
                  <img src={plusIcon} alt="plus icon" />{' '}
                  {window.innerWidth > 1150
                    ? formatMessage({ id: 'form.button.addNew' })
                    : formatMessage({ id: 'form.button.add' })}
                </AddButtonText>
              </Button>
            </div>
          </Dropdown>
        </AuthenticationWrapper>
      </SearchWrapper>
    </HeaderWrapper>
  );
};

export default DefectTableHeader;
