import moment from 'moment';
import axios from 'axios';
import rp from 'request-promise';
import Cookies from 'universal-cookie';
import error from '../utils/externalRequestError';
import servers from '../utils/servers';

const cookies = new Cookies();

export async function getPublicDefect(params) {
  return rp({
    method: 'GET',
    uri: `${servers.api}/defects/${params.id}/public/${params.key}`,
    resolveWithFullResponse: false,
    json: true,
    headers: {
      'Api-Version': 5,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function updatePublicDefect(params) {
  return Promise.resolve(
    rp({
      method: 'POST',
      uri: `${servers.api}/defects/${params.id}/public/${params.public_key}`,
      resolveWithFullResponse: false,
      body: {
        mx_event: {
          work_type: params.resolution, // replaced, repaired, not_reproduced
          date: moment(params.date).format('YYYY-MM-DD'),
          company_name: params.company_name,
          approval_number: params.company_approval,
          first_name: params.first_name,
          last_name: params.last_name,
          limitations: params.limitations,
          description: params.description,
          signature: params.signature,
          mx_item_resolved: true,
        },
      },
      json: true,
      simple: false,
      headers: {
        'Api-Version': 5,
      },
    }),
  ).then((response) => {
    if (params.attachments) {
      const url = `${servers.api}/defects/${params.id}/public/${params.public_key}/upload/${response.mx_events[0].id}`;
      return fetch(url, {
        method: 'POST',
        body: params.attachments,
      }).then(() => {
        return response;
      });
    }
    return null;
  });
}

export async function getWorkpack(params) {
  return rp({
    method: 'GET',
    uri: `${servers.api}/workpacks/${params.id}/public/${params.key}`,
    resolveWithFullResponse: false,
    json: true,
    headers: {
      'Api-Version': 5,
    },
  }).catch((err) => {
    error(err);
  });
}

export const resetPassword = async (params) =>
  axios({
    method: 'POST',
    url: `${servers.api}/forgotten_password`,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
    },
    data: {
      email: params.email,
      person_id: params.id,
    },
    json: true,
  });

export async function getPublicWorkpack(params) {
  return rp({
    method: 'GET',
    uri: `${servers.api}/workpacks/${params.id}/public/${params.key}`,
    resolveWithFullResponse: false,
    json: true,
    headers: {
      'Api-Version': 5,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function postPublicWorkpack(params) {
  return rp({
    method: 'POST',
    uri: `${servers.api}/workpacks/${params.id}/signoff/${params.key}`,
    resolveWithFullResponse: false,
    json: true,
    body: params.body,
    headers: {
      'Api-Version': 5,
    },
  }).catch((err) => {
    error(err);
  });
}

export async function addAttachmentsToMXRelease({ releaseId, public_url_key, formData }) {
  return fetch(`${servers.api}/releases/${releaseId}/public/${public_url_key}/upload`, {
    method: 'POST',
    uri: `${servers.api}/releases/${releaseId}/public/${public_url_key}/upload`,
    redirect: 'follow',
    body: formData,
    headers: {
      'Api-Version': 5,
    },
  })
    .then((response) => response.status)
    .catch((err) => {
      error(err);
    });
}

export async function createStandAloneRelease(params) {
  return rp({
    method: 'POST',
    uri: `${servers.api}/releases/${params.public_url_key}`,
    resolveWithFullResponse: false,
    json: true,
    body: params.formData,
    headers: {
      'Api-Version': 5,
    },
  }).catch((err) => error(err));
}
