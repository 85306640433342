import { Button, Card, Col, Divider, Form, Row, message } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Search } from '@arcflight/tf-component-library';
import AircraftSettingsModal from '../../components/Modals/AircraftSettings/AircraftSettingsModal';
import ThresholdSettingsModal from '../../components/Modals/AircraftSettings/ThresholdSettingsModal';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { fetchAircraftPeople, getSingleAircraft } from '../../models/aircraft/actions';
import servers from '../../utils/servers';
import SelectPerson from '../../components/SelectPerson';
import { addCrew, fetchSrpTemplates, removeCrew } from '../../services/apiNew';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import Loading from '../../components/TFLoading';
import OrgAuthWrapper from '../../components/_utils/OrgAuthWrapper';
import AircraftSettingsDetails from './AircraftSettingsDetails';
import SettingsCrewList from './SettingsCrewList';
import ThresholdSettingsDetails from './ThresholdSettingsDetails';
import styles from './AircraftSettings.module.less';

class AircraftSettings extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    fetchAircraft: PropTypes.func.isRequired,
    getAircraftPeople: PropTypes.func.isRequired,
    aircraftMap: PropTypes.instanceOf(Map),
    form: PropTypes.object.isRequired,
    ttl: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    aircraftMap: new Map(),
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visibleThresholdModal: false,
      visibleSettingsModal: false,
      visibleCrewMemberSearch: false,
      searchValue: '',
      filteredCrew: [],
      srpTemplates: [],
      reset: false,
    };
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    const { ttl } = this.props;
    const aircraft = this.getAircraft();
    this.filterTable();
    if (aircraft) {
      this.getTemplates();
    }
    if (!aircraft || Date.now() - aircraft.lastFetched > ttl) {
      this.getAircraft(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.state;
    // if the aircaft has been updated reset the form
    const aircraft = this.getAircraft();
    const prevAircraft = prevProps.aircraftMap.get(prevProps.match.params.id);
    if (loading && aircraft && ((aircraft && !prevAircraft) || aircraft.lastFetched > prevAircraft?.lastFetched)) {
      this.onLoaded();
    }
  }

  getTemplates = async () => {
    const aircraft = this.getAircraft();
    const res = await fetchSrpTemplates(aircraft.operator_id);
    if (res.status === 200) {
      this.setState({ srpTemplates: res.data });
    }
  };

  onLoaded = () => {
    this.setState({ loading: false }, () => {
      this.props.form.resetFields();
    });
  };

  addCrewMember = async (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    if (!id) {
      return;
    }
    try {
      const aircraft = this.getAircraft();
      const response = await addCrew({ aircraft: aircraft.id, person_id: id });
      if (response && response.status >= 200 && response.status < 400) {
        message.success(formatMessage({ id: 'message.crewMemberAdded' }));
        this.props.getAircraftPeople(aircraft.id);
        this.setState({ visibleCrewMemberSearch: false });
        this.getAircraft(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    try {
      const aircraft = this.getAircraft();
      const response = await removeCrew({ aircraft: aircraft.id, person_id: id });
      if (response && response.status >= 200 && response.status < 400) {
        this.props.getAircraftPeople(aircraft.id);
        this.getAircraft(true);
        message.success(formatMessage({ id: 'message.crewMemberRemoved' }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  setLoading = () => {
    this.setState({ loading: true });
  };

  getAircraft = (forceRefetch = false) => {
    if (forceRefetch) {
      this.setState({ loading: true }, () =>
        this.props.fetchAircraft(this.props.match.params.id).then(() => {
          this.getTemplates();
        }),
      );
    }
    return this.props.aircraftMap.get(this.props.match.params.id);
  };

  resetEditable = () => {
    this.props.form.resetFields();
  };

  handleEditThresholdClick = () => {
    this.setState({ visibleThresholdModal: true });
  };

  handleEditButtonClick = () => {
    this.setState({ visibleSettingsModal: true });
  };

  closeSettingsModal = () => {
    this.setState({ visibleSettingsModal: false });
    this.props.form.resetFields();
  };

  closeThresholdModal = () => {
    this.setState({ visibleThresholdModal: false });
    this.props.form.resetFields();
  };

  filterTable = () => {
    const filteredBy = this.state.searchValue.toLowerCase();
    const selectedAircraft = this.getAircraft();
    if (filteredBy === null || filteredBy === '') {
      this.setState({ filteredCrew: [] });
      return;
    }

    const filteredCrew = selectedAircraft.aircraft_people
      .filter((ap) =>
        // For each person, we decide whether they should be added based on their object inner properties.
        Object.keys(ap).some((key) => {
          const val = key === 'id' || key === 'role' ? null : ap[key];
          // If a property's value is a string and contains the filtering string,
          // add them to the array and break the loop.
          return typeof val === 'string' && val.toLowerCase().includes(filteredBy);
        }),
      )
      .map((entry) => ({ ...entry }));
    this.setState({ filteredCrew });
  };

  resetFilters = () => {
    this.setState({ reset: true, searchValue: '' });
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      visibleThresholdModal,
      visibleSettingsModal,
      visibleCrewMemberSearch,
      loading,
      searchValue,
      filteredCrew,
      reset,
      srpTemplates,
    } = this.state;
    const selectedAircraft = this.getAircraft();
    const activeTemplate = selectedAircraft
      ? srpTemplates.find((template) => template.id === selectedAircraft.srp_template_id)
      : null;
    return (
      <InnerMenuLayout loading={false}>
        <div className={styles.aircraftSettingsContent}>
          <div data-test="pageTitle" className={styles.pageTitle}>
            <span>Aircraft Settings</span>
          </div>
          <Row gutter={24}>
            <>
              <Col xl={8}>
                <Loading loading={loading} contain />
                <div className={styles.partTitle} data-test="aircraftRegistration">
                  {selectedAircraft
                    ? `${selectedAircraft.registration} (${selectedAircraft.aircraft_type.designator})`
                    : '-'}
                </div>
                <Card>
                  <div
                    className={styles.aircraftImage}
                    data-test="aircraftImage"
                    style={{
                      backgroundImage: selectedAircraft
                        ? `url(${servers.api}${selectedAircraft.aircraft_type.aircraft_image_url})`
                        : null,
                    }}
                  />
                  <div className={styles.settingsHeader}>
                    <span className={styles.settingsTitle} data-test="generalSettingsTitle">
                      {formatMessage({ id: 'title.generalSettings' })}
                    </span>
                    <AircraftAuthenticationWrapper
                      aircraftId={selectedAircraft && selectedAircraft.id}
                      requiredResource={AircraftResource.AIRCRAFT}
                      requiredPermissionLevel={AircraftPermission.CREATE}
                    >
                      <OrgAuthWrapper orgId={selectedAircraft && selectedAircraft.operator_id}>
                        <Button
                          onClick={() => this.handleEditButtonClick()}
                          className={styles.settingsEditButton}
                          data-test="editButton"
                        >
                          {formatMessage({ id: 'form.button.edit' })}
                        </Button>
                      </OrgAuthWrapper>
                    </AircraftAuthenticationWrapper>
                  </div>
                  <div className={styles.detailsColumn}>
                    {selectedAircraft && (
                      <AircraftSettingsDetails
                        id={selectedAircraft.id}
                        activeTemplate={activeTemplate}
                        data-test="aircraftSettingsDetails"
                      />
                    )}
                  </div>
                  <Divider />
                  <div className={styles.thresholdHeader}>
                    <span data-test="maintenanceWarningsTitle" className={styles.thresholdTitle}>
                      {formatMessage({ id: 'title.maintenanceWarnings' })}
                    </span>
                    <AircraftAuthenticationWrapper
                      aircraftId={selectedAircraft && selectedAircraft.id}
                      requiredResource={AircraftResource.AIRCRAFT}
                      requiredPermissionLevel={AircraftPermission.CREATE}
                    >
                      <OrgAuthWrapper orgId={selectedAircraft && selectedAircraft.operator_id}>
                        <Button
                          onClick={() => this.handleEditThresholdClick()}
                          className={styles.thresholdEditButton}
                          data-test="thresholdEditButton"
                        >
                          {formatMessage({ id: 'form.button.edit' })}
                        </Button>
                      </OrgAuthWrapper>
                    </AircraftAuthenticationWrapper>
                  </div>
                  <div className={styles.detailsColumn}>
                    {selectedAircraft && (
                      <ThresholdSettingsDetails id={selectedAircraft.id} data-test="ThresholdSettingsDetails" />
                    )}
                  </div>
                </Card>
              </Col>
              <Col xl={16}>
                <div className={styles.tableHeader}>
                  <div className={styles.crewTitle} data-test="crewTitle">
                    {formatMessage({ id: 'title.aircraftCrew' })}
                  </div>
                </div>
                <div className={styles.tableHeader}>
                  <div id="searchWrapper">
                    <Search
                      onChange={(e) => {
                        this.setState({ searchValue: e.currentTarget.value, reset: false }, () => {
                          this.filterTable();
                        });
                      }}
                      onClear={() => this.setState({ searchValue: '', reset: false }, () => this.filterTable())}
                      reset={reset}
                    />
                  </div>
                  <AircraftAuthenticationWrapper
                    aircraftId={selectedAircraft && selectedAircraft.id}
                    requiredResource={AircraftResource.ADDITIONAL_CREW}
                    requiredPermissionLevel={AircraftPermission.CREATE}
                  >
                    <div className={styles.addButtonDiv}>
                      {visibleCrewMemberSearch ? (
                        <div className={styles.crewSearch}>
                          <SelectPerson
                            placeholder="Search by..."
                            // onBlur={() => this.setState({ visibleCrewMemberSearch: false })}
                            onChange={(id) => this.addCrewMember(id)}
                            settingsPage
                            blockedPeople={selectedAircraft ? selectedAircraft.aircraft_people.map((c) => c.id) : []}
                            currentCrew={selectedAircraft ? selectedAircraft.aircraft_people : []}
                            autoFocus
                            ref={this.searchInput}
                            organisation_id={selectedAircraft.operator_id}
                          />
                        </div>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({ visibleCrewMemberSearch: true });
                            setTimeout(() => {
                              if (this.searchInput.current) {
                                this.searchInput.current.focus();
                                this.searchInput.current.rcSelect.focus();
                                this.searchInput.current.rcSelect.inputRef.focus();
                              }
                            }, 1000);
                          }}
                          type="primary"
                          icon="plus"
                          data-test="addNewButton"
                          className={styles.addNewButton}
                        >
                          <span className={styles.addButtonText}>
                            {formatMessage({ id: 'form.button.addCrewMember' })}
                          </span>
                        </Button>
                      )}
                    </div>
                  </AircraftAuthenticationWrapper>
                </div>
                <div>
                  <div className={styles.crewTable}>
                    {selectedAircraft && (
                      <SettingsCrewList
                        selectedAircraft={selectedAircraft}
                        handleDelete={this.handleDelete}
                        crew={searchValue ? filteredCrew : selectedAircraft.aircraft_people}
                        isCrewSearched={searchValue}
                        loading={loading}
                        resetFilters={this.resetFilters}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </>
          </Row>
          {selectedAircraft && (
            <AircraftSettingsModal
              selectedAircraft={selectedAircraft}
              srpTemplates={srpTemplates}
              visibleSettingsModal={visibleSettingsModal}
              closeSettingsModal={this.closeSettingsModal}
              setLoading={this.setLoading}
            />
          )}
          {selectedAircraft && (
            <ThresholdSettingsModal
              selectedAircraft={selectedAircraft}
              visibleThresholdModal={visibleThresholdModal}
              closeThresholdModal={this.closeThresholdModal}
              setLoading={this.setLoading}
            />
          )}
        </div>
      </InnerMenuLayout>
    );
  }
}

const AircraftSettingsWithForm = Form.create()(AircraftSettings);

export default withRouter(
  injectIntl(
    connect(
      ({ aircraft, people }) => ({
        aircraftMap: aircraft.aircraftMap,
        ttl: aircraft.ttl,
        people: people.peopleMap,
      }),
      (dispatch) => ({
        fetchAircraft: (id) => {
          return dispatch(
            getSingleAircraft({
              payload: id,
            }),
          );
        },
        getAircraftPeople: (id) => {
          return dispatch(
            fetchAircraftPeople({
              payload: id,
            }),
          );
        },
      }),
    )(AircraftSettingsWithForm),
  ),
);
