import React from 'react';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';

interface EmptyStateProps {
  image?: string;
  text?: string;
  subText?: string;
  button?: string;
  backgroundColor?: string;
  buttonAction?: () => void;
}

const EmptyStateWrapper = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: ${({ backgroundColor }): string => backgroundColor || '#fff'};
`;

const StyledImage = styled.img`
  width: 120px;
  height: 120px;
`;

const StyledText = styled.div`
  color: rgba(36, 45, 65, 0.5);
  letter-spacing: 0.42px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin: 8px 0;
`;

const StyledSubText = styled.div`
  color: rgba(36, 45, 65, 0.6);
  line-height: 1.38;
  letter-spacing: 0.2px;
  font-size: 13px;
  margin-bottom: 20px;
  max-width: 260px;
  text-align: center;
`;

const EmptyState: React.FC<EmptyStateProps> = ({ image, text, subText, button, buttonAction, backgroundColor }) => {
  return (
    <EmptyStateWrapper backgroundColor={backgroundColor}>
      {image ? <StyledImage src={image} alt="empty state image" /> : null}
      {text ? <StyledText>{text}</StyledText> : null}
      {subText ? <StyledSubText>{subText}</StyledSubText> : null}
      {button ? (
        <Button height="24px" onClick={(): void => buttonAction()}>
          {button}
        </Button>
      ) : null}
    </EmptyStateWrapper>
  );
};

export default EmptyState;
