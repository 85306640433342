import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Accordion } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { Trip } from '../../models/trips';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import servers from '../../utils/servers';
import { DisplayText, Header, SectionHeader } from '../FlightDrawer/FlightDrawer';

interface TripCrewDutyLogsProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any) => void;
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ editable, isMobile }): string => {
    if (editable && isMobile) return '1fr';
    if (editable || isMobile) return '1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  padding: 16px 20px;
  border-top: none;
`;

const SignatureSection = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 4px 0 20px 20px;
`;

const StyledImg = styled.img`
  width: 240px;
  margin-left: 12px;
`;

const BoldText = styled.span`
  font-weight: bold;
  margin-left: 4px;
`;

const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PartsDiv = styled.div`
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(36, 45, 65 0.8);
  line-height: 1.33;
`;

const SectionWrapper = styled.div`
  margin-bottom: 32px;
`;

const AccordionWrapper = styled.div`
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
`;

const TripCrewDutyLogs: React.FC<TripCrewDutyLogsProps> = ({ trip, editable, updateTripData }) => {
  const {
    userSettings: { dateFormat },
    drawer: { mode },
    people: { peopleMap },
  } = useSelector((state: DashboardState) => state);

  const [dutyLogArray, setDutyLogArray] = useState([]);
  const [captain, setCaptain] = useState(null);
  const [firstOfficer, setFirstOfficer] = useState(null);

  const handleDateTimeChange = (value: string, id: string, key: string): void => {
    const newArray = [...dutyLogArray];

    const foundLogIndex = dutyLogArray.findIndex((log) => log?.person_id === id);
    newArray[foundLogIndex] = { ...newArray[foundLogIndex], [`${key}`]: new Date(value).toISOString() };
    setDutyLogArray(newArray);
    updateTripData('duty_logs', newArray);
  };

  useEffect(() => {
    if (trip?.duty_logs) {
      setDutyLogArray(trip?.duty_logs);
    }
  }, [trip]);

  useEffect(() => {
    const captainId = trip?.captain_id || trip?.captain?.id || null;
    const firstOfficerId = trip?.first_officer_id || trip?.first_officer?.id || null;
    if (captainId) {
      const foundCaptain = peopleMap.get(captainId);
      setCaptain({ first_name: foundCaptain?.first_name, last_name: foundCaptain?.last_name });
    } else {
      setCaptain(null);
    }
    if (firstOfficerId) {
      const foundFirstOfficer = peopleMap.get(firstOfficerId);
      setFirstOfficer({ first_name: foundFirstOfficer?.first_name, last_name: foundFirstOfficer?.last_name });
    } else {
      setFirstOfficer(null);
    }
  }, [peopleMap, trip]);

  useEffect(() => {
    const captainId = trip?.captain_id || trip?.captain?.id || null;
    const firstOfficerId = trip?.first_officer_id || trip?.first_officer?.id || null;
    let newArray = [];
    if (!dutyLogArray.length && captainId && !firstOfficerId) {
      const captainsLog = dutyLogArray.find((log) => log?.person_id === captainId);
      if (captainsLog) {
        newArray = [captainsLog];
        setDutyLogArray(newArray);
      } else {
        setDutyLogArray([{ person_id: captainId }]);
      }
    } else if (!dutyLogArray.length && !captainId && firstOfficerId) {
      const firstOffLog = dutyLogArray.find((log) => log?.person_id === firstOfficerId);
      if (firstOffLog) {
        newArray = [{ person_id: '-' }, firstOffLog];
        setDutyLogArray(newArray);
      } else {
        setDutyLogArray([{ person_id: '-' }, { person_id: firstOfficerId }]);
      }
    } else if (!dutyLogArray.length && captainId && firstOfficerId) {
      const captainsLog = dutyLogArray.find((log) => log?.person_id === captainId);
      const firstOfficerLog = dutyLogArray.find((log) => log?.person_id === firstOfficerId);
      if (captainsLog) {
        newArray = [captainsLog];
        setDutyLogArray(newArray);
        if (firstOfficerLog) {
          newArray.push(firstOfficerLog);
          setDutyLogArray(newArray);
        } else {
          newArray.push({ person_id: firstOfficerId });
          setDutyLogArray(newArray);
        }
      } else {
        newArray = [{ person_id: captainId }];
        setDutyLogArray(newArray);
        if (firstOfficerLog) {
          newArray.push(firstOfficerLog);
          setDutyLogArray(newArray);
        } else {
          newArray.push({ person_id: firstOfficerId });
          setDutyLogArray(newArray);
        }
      }
    } else if (dutyLogArray.length === 1 && captainId && firstOfficerId) {
      const captainsLog = dutyLogArray.find((log) => log?.person_id === captainId);
      const firstOfficerLog = dutyLogArray.find((log) => log?.person_id === firstOfficerId);
      if (captainsLog) {
        newArray = [captainsLog];
        setDutyLogArray(newArray);
        if (firstOfficerLog) {
          newArray.push(firstOfficerLog);
          setDutyLogArray(newArray);
        } else {
          newArray.push({ person_id: firstOfficerId });
          setDutyLogArray(newArray);
        }
      } else {
        newArray = [{ person_id: captainId }];
        setDutyLogArray(newArray);
        if (firstOfficerLog) {
          newArray.push(firstOfficerLog);
          setDutyLogArray(newArray);
        } else {
          newArray.push({ person_id: firstOfficerId });
          setDutyLogArray(newArray);
        }
      }
    } else if (dutyLogArray.length === 2 && captainId && !firstOfficerId) {
      const captainsLog = dutyLogArray.find((log) => log?.person_id === captainId);
      if (captainsLog) {
        newArray = [captainsLog];
        setDutyLogArray(newArray);
      } else {
        newArray = [{ person_id: captainId }];
        setDutyLogArray(newArray);
      }
    } else if (dutyLogArray.length === 2 && dutyLogArray[0].person_id !== '-' && !captainId && firstOfficerId) {
      const firstOffLog = dutyLogArray.find((log) => log?.person_id === firstOfficerId);
      if (firstOffLog) {
        newArray = [{ person_id: '-' }, firstOffLog];
        setDutyLogArray(newArray);
      } else {
        newArray = [{ person_id: '-' }, { person_id: firstOfficerId }];
        setDutyLogArray(newArray);
      }
    }
  }, [dutyLogArray, trip]);

  const isMobile = window.innerWidth < 451;

  const displayLog = dutyLogArray.map((log, index) => (
    <AccordionWrapper marginBottom={index !== dutyLogArray.length - 1}>
      <Accordion
        dash
        add
        isExpanded={mode === 'add'}
        headerContent={
          <RowHeader data-testid={`TripCrewDutyLogs-RowHeader${index}`}>
            <div>
              {index === 0 ? (
                <span>
                  PIC:
                  <BoldText>
                    {captain?.first_name} {captain?.last_name}
                  </BoldText>
                </span>
              ) : (
                <span>
                  SIC:
                  <BoldText>
                    {firstOfficer?.first_name} {firstOfficer?.last_name}
                  </BoldText>
                </span>
              )}
            </div>
            <PartsDiv>4 parts</PartsDiv>
          </RowHeader>
        }
      >
        <ContentWrapper isMobile={isMobile} editable={editable} data-testid={`TripCrewDutyLogs-RowContent${index}`}>
          <div>
            {editable ? (
              <DateTimePicker
                handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'start_time')}
                dateTime={log?.start_time}
                headerDate="On Date"
                headerTime="On Time"
              />
            ) : (
              <>
                <Header>On</Header>
                <DisplayText>
                  {log?.start_time
                    ? moment(log?.start_time)
                        .utc()
                        .format(`${dateFormat} HH:mm`)
                    : '-'}
                </DisplayText>
              </>
            )}
          </div>
          <div>
            {editable ? (
              <DateTimePicker
                handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'split_off')}
                dateTime={log?.split_off}
                headerDate="Split Off"
                headerTime="Split Off Time"
              />
            ) : (
              <>
                <Header>split off</Header>
                <DisplayText>
                  {log?.split_off
                    ? moment(log?.split_off)
                        .utc()
                        .format(`${dateFormat} HH:mm`)
                    : '-'}
                </DisplayText>
              </>
            )}
          </div>
          <div>
            {editable ? (
              <DateTimePicker
                handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'split_on')}
                dateTime={log?.split_on}
                headerDate="Split On"
                headerTime="Split On Time"
              />
            ) : (
              <>
                <Header>split on</Header>
                <DisplayText>
                  {log?.split_on
                    ? moment(log?.split_on)
                        .utc()
                        .format(`${dateFormat} HH:mm`)
                    : '-'}
                </DisplayText>
              </>
            )}
          </div>
          <div>
            {editable ? (
              <DateTimePicker
                handleDateTimeChange={(value): void => handleDateTimeChange(value, log?.person_id, 'end_time')}
                dateTime={log?.end_time}
                headerDate="Off Date"
                headerTime="Off Time"
              />
            ) : (
              <>
                <Header>off</Header>
                <DisplayText>
                  {log?.end_time
                    ? moment(log?.end_time)
                        .utc()
                        .format(`${dateFormat} HH:mm`)
                    : '-'}
                </DisplayText>
              </>
            )}
          </div>
        </ContentWrapper>
        {trip?.completion_signature_image_url ? (
          <SignatureSection>
            Trip completion:
            <StyledImg alt="Signature" src={`${servers.api}${trip?.completion_signature_image_url}`} />
          </SignatureSection>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  ));
  return (
    <SectionWrapper data-testid="TripDrawer-TripCrewDutyLogs">
      <SectionHeader marginLeft="20px" data-testid="TripCrewDutyLogs-Header">
        Crew Duty Logs
      </SectionHeader>
      {displayLog}
    </SectionWrapper>
  );
};

export default TripCrewDutyLogs;
