import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Portal from '../Portal/Portal';
import dropdownIcon from '../../assets/icon-arrow-down.svg';

const Wrapper = styled.div`
  position: relative;
`;

const BackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const InputWrapper = styled.button`
  width: 280px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #fff;
  img {
    width: 12px;
    ${({ searching }): any =>
      searching &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const StyledInput = styled.input`
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: ${({ placement }): string => (placement === 'top' ? '44px' : 'unset')};
  bottom: ${({ placement }): string => (placement === 'bottom' ? '44px' : 'unset')};
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
`;

const StyledOption = styled.button`
  width: 100%;
  height: 32px;
  border: 1px solid #fff;
  &:hover {
    border-radius: 2px;
    border: 1px solid rgba(18, 111, 214, 0.2);
  }
`;

interface SearchDropdownProps {
  options: Array<any>;
  handleSelect: (input: any) => void;
  handleSearch: (input: any) => void;
  valueKey?: string;
  textKey?: string;
  placement?: string;
  placeholder?: string;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  options,
  handleSelect,
  handleSearch,
  valueKey,
  textKey,
  placement,
  placeholder,
}) => {
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchableOptions, setSearchableOptions] = useState([]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
    handleSelect(null);
    setSearching(true);
  };

  const displayOptions = searchableOptions.map((option) => {
    return (
      <StyledOption
        type="button"
        onClick={(): void => {
          handleSelect(option[`${valueKey}`]);
          setSearchValue(option[`${textKey}`]);
          setSearching(false);
        }}
      >
        {option[`${textKey}`]}
      </StyledOption>
    );
  });

  useEffect(() => {
    if (options) {
      if (searchValue) {
        const newOptions = options.filter(
          (option) => option[`${textKey}`] && option[`${textKey}`].includes(searchValue),
        );
        setSearchableOptions(newOptions);
      } else {
        setSearchableOptions(options);
      }
    }
  }, [options, searchValue, textKey]);

  return (
    <div>
      <Portal>
        <BackgroundDiv onClick={(): void => setSearching(false)} />
      </Portal>
      <Wrapper>
        <InputWrapper searching={searching} onClick={(): void => setSearching(!searching)}>
          <StyledInput type="text" value={searchValue} placeholder={placeholder} onChange={handleValueChange} />
          <img src={dropdownIcon} alt="dropdown icon" />
        </InputWrapper>
        {searching && displayOptions.length > 0 ? (
          <OptionsWrapper placement={placement}>{displayOptions}</OptionsWrapper>
        ) : null}
      </Wrapper>
    </div>
  );
};

export default SearchDropdown;
