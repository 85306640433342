/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface DefectNonMelItemsProps {
  defectType: string;
  defect: Defect | null;
  rectificationCategory?: { title: string; colour?: string };
  setRectificationCategory?: (option: { title: string; colour?: string }) => void;
  deferred: boolean;
  setDateDue: (input) => void;
  apuInstalled: boolean;
  deferredTime: Moment;
  setDeferredTime: (input: Moment) => void;
  reference: string;
  setReference: (value: string) => void;
  ATAChapter: string;
  setATAChapter: (value: string) => void;
  ATASection: string;
  setATASection: (value: string) => void;
  updateDefectData: (changes: any[]) => void;
}

const DefectNonMelItems: React.FC<DefectNonMelItemsProps> = ({
  defectType,
  defect,
  rectificationCategory,
  setRectificationCategory,
  deferred,
  setDateDue,
  apuInstalled,
  deferredTime,
  setDeferredTime,
  reference,
  setReference,
  ATAChapter,
  setATAChapter,
  ATASection,
  setATASection,
  updateDefectData,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (defectType) {
      if (defect?.display_data?.category) {
        let recCat = defect?.display_data?.category;
        if (recCat === 'ADVISORYONLY') recCat = 'Advisory only';
        if (recCat === 'NONDEFERRED') recCat = 'A';
        if (recCat !== defect?.display_data?.category) {
          setRectificationCategory({ title: recCat });
          updateDefectData([{ value: recCat, key: 'rectification_category' }]);
        }
      }
    }
    if (!deferred) {
      setRectificationCategory({ title: 'Non Deferred' });
      updateDefectData([{ value: 'Non Deferred', key: 'rectification_category' }]);
    }
  }, [defectType]);

  useEffect(() => {
    if (defect && defect.mel_item) {
      setATAChapter(defect?.display_data?.ata.split(' ')[0]);
      setATASection(defect?.display_data?.ata_section);
      setReference(defect?.reference);
    }
  }, [defect]);

  let displayOption;
  let displayDefectType = 'CDL - Configuration Deviation List';
  if (defectType === 'CAS') displayDefectType = 'CAS - Crew Alerting System';
  if (defectType === 'Other') displayDefectType = 'Other';
  if (defectType === 'CDL' || defectType === 'CAS' || defectType === 'Other')
    displayOption = (
      <>
        <FlexWrapper column>
          <Label marginBottom={15} fontWeight={500}>
            {!deferred ? 'Do Not Defer' : displayDefectType}
          </Label>
          <ManuallyEnterMELDetails
            defect={defect}
            rectificationCategory={rectificationCategory}
            setRectificationCategory={setRectificationCategory}
            deferred={deferred}
            setDateDue={setDateDue}
            apuInstalled={apuInstalled}
            reference={reference}
            setReference={setReference}
            ATAChapter={ATAChapter}
            setATAChapter={setATAChapter}
            ATASection={ATASection}
            setATASection={setATASection}
            deferredTime={deferredTime}
            setDeferredTime={setDeferredTime}
            updateDefectData={updateDefectData}
          />
        </FlexWrapper>
      </>
    );
  if (defectType === 'NEF')
    displayOption = (
      <FlexWrapper column>
        <Label marginBottom={15} fontWeight={500}>
          {formatMessage({ id: 'text.nefExpanded' })}
        </Label>
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage({ id: 'text.pleaseEnterReferenceNumber' })}
        </Label>
        <Label marginBottom={5} fontWeight={500}>
          {formatMessage({ id: 'title.reference' })}
        </Label>
        <StyledInput id="cdlReference" defaultValue={reference} type="text" width={280} />
      </FlexWrapper>
    );
  return displayOption;
};

export default DefectNonMelItems;
