import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import TFSelect from '../TFSelect/TFSelect';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import HoursMinsInput from './HoursMinsInput';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';

interface PostFlightCustomDataSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
`;

const StyledInput = styled.input`
  width: 110px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const PostFlightCustomDataSection: React.FC<PostFlightCustomDataSectionProps> = ({ flight, updateFlightData }) => {
  const isMobile = window.innerWidth < 451;
  const { formatMessage } = useIntl();

  const [APUHours, setAPUHours] = useState(null);
  const [landings, setLandings] = useState(null);
  const [maxFL, setMaxFL] = useState(null);
  const [pressurisedCycles, setPressurisedCycles] = useState(false);
  const [APUFormat, setAPUFormat] = useState('hours');
  const [currentAircraft, setCurrentAircraft] = useState(null);

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const handleInputChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (parseInt(value, 10) < 0) {
      message.error('Input must be positive');
      newValue = '';
    }
    if (type === 'landings') {
      setLandings(newValue);
      updateFlightData([{ value: newValue, key: 'landings_count' }]);
    }
    if (type === 'fl') {
      setMaxFL(newValue);
      updateFlightData([{ value: newValue, key: 'max_flight_level' }]);
    }
  };

  const handleAPUHoursChange = (value: string): void => {
    let newValue = value;
    const hoursValue = value.split('.')[0];
    const minsValue = value.split('.')[1];
    if (!hoursValue) {
      newValue = `0.${minsValue}`;
    }
    if (minsValue?.length > 2) {
      message.error('Input must only be to 2 decimal places');
      const newMinsValue = minsValue.slice(0, 2);
      newValue = `${hoursValue}.${newMinsValue}`;
    }
    setAPUHours(newValue);
  };

  const updateAPUHours = (): void => {
    const stringArray = APUHours.split('.');
    let totalSeconds;
    if (APUFormat === 'hours') {
      const hours = parseInt(stringArray[0], 10) * 3600;
      const minutes = parseInt(stringArray[1], 10) || 0;
      const adjustedMinutes = minutes * 60;
      totalSeconds = hours + adjustedMinutes;
    } else {
      totalSeconds = APUHours * 3600;
    }
    let newKey = 'apu_seconds';
    if (currentAircraft?.apu_mode === 'totalled') newKey = 'apu_reading';
    updateFlightData([{ value: totalSeconds, key: newKey }]);
  };

  const handleSelectChange = (option): void => {
    const value = option.value === 'true';
    setPressurisedCycles(value);
    updateFlightData([{ value, key: 'pressurised_cycle' }]);
  };

  useEffect(() => {
    if (flight) {
      if (flight?.apu_mode === 'totalled' || flight?.apu_mode === 'cumulative') {
        let hours = 0;
        if (flight?.apu_mode === 'totalled') hours = flight?.apu_reading;
        if (flight?.apu_mode === 'cumulative') hours = flight?.apu_seconds;
        if (APUFormat !== 'hours') {
          setAPUHours((Math.round((hours / 3600) * 100) / 100).toFixed(2));
        } else {
          const h = Math.floor(hours / 3600);
          const m = Math.floor((hours % 3600) / 60);
          setAPUHours(`${h}.${m}`);
        }
      }
      if (flight?.landings_count) {
        setLandings(flight?.landings_count);
      } else {
        setLandings('');
      }
      if (flight?.max_flight_level) {
        setMaxFL(flight?.max_flight_level);
      } else {
        setMaxFL('');
      }
      if (flight?.pressurised_cycle) {
        setPressurisedCycles(flight?.pressurised_cycle);
      } else {
        setPressurisedCycles(false);
      }
    }
  }, [APUFormat, flight]);

  useEffect(() => {
    const selectedAircraft = aircraftMap.get(id);
    if (selectedAircraft) {
      setCurrentAircraft(selectedAircraft);
      if (selectedAircraft?.standard_fields?.apu?.format === 'decimal') {
        setAPUFormat('decimal');
      }
    }
  }, [aircraftMap, id]);

  let dataItems = [];
  if (flight?.postflight_custom_data) {
    dataItems = Object.entries(flight.postflight_custom_data);
  }
  const dataElements = dataItems.map((item, index) => {
    const header = item[0].replace(/_/gi, ' ');
    return (
      <div data-testid={`PostFlightCustomDataSection-CustomElement${index}`} key={item[1]}>
        <Header>{header}</Header>
        <DisplayText>{item[1]}</DisplayText>
      </div>
    );
  });

  const selectOptions = [
    {
      title: 'True',
      value: 'true',
    },
    {
      title: 'False',
      value: 'false',
    },
  ];

  const DisplayAPUHoursFormat = (): string => {
    if (!APUHours) return '-';
    const hours = APUHours.split('.')[0];
    const mins = APUHours.split('.')[1];
    return `${hours}:${mins} hrs`;
  };

  return (
    <>
      <SectionHeader data-testid="PostFlightCustomDataSection-Header">Postflight</SectionHeader>
      <SectionWrapper edit={mode !== 'view'} isMobile={isMobile}>
        <div data-testid="PostFlightCustomDataSection-Landings">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'period.landings' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{landings || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              type="number"
              min={0}
              value={landings}
              onChange={(e): void => handleInputChange(e.target.value, 'landings')}
            />
          )}
        </div>
        {currentAircraft?.apu_mode !== 'not_installed' ? (
          <div data-testid="PostFlightCustomDataSection-APUHours">
            <Header editable={mode !== 'view'}>{formatMessage({ id: 'period.APUhours' })}</Header>
            {mode === 'view' ? (
              <DisplayText>{APUFormat === 'hours' ? DisplayAPUHoursFormat() : `${APUHours} hrs` || '-'}</DisplayText>
            ) : (
              <>
                {APUFormat === 'hours' ? (
                  <HoursMinsInput
                    value={APUHours}
                    onChange={(e): void => handleAPUHoursChange(e)}
                    onBlur={updateAPUHours}
                  />
                ) : (
                  <div>
                    <UnitWrapper>
                      <StyledInput
                        placeholder="-"
                        value={APUHours || undefined}
                        onChange={(e): void => handleAPUHoursChange(e.target.value)}
                        onBlur={updateAPUHours}
                        type="number"
                      />
                      <StyledUnit edit={mode !== 'view'}>{APUHours ? 'hrs' : ''}</StyledUnit>
                    </UnitWrapper>
                  </div>
                )}
              </>
            )}
          </div>
        ) : null}
        <div data-testid="PostFlightCustomDataSection-MaxFL">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'period.maxFL' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{maxFL || '-'}</DisplayText>
          ) : (
            <StyledInput
              placeholder="-"
              value={maxFL}
              onChange={(e): void => handleInputChange(e.target.value, 'fl')}
            />
          )}
        </div>
        <div data-testid="PostFlightCustomDataSection-PressurisedCycles">
          <Header editable={mode !== 'view'}>{formatMessage({ id: 'period.pressurisedCycle' })}</Header>
          {mode === 'view' ? (
            <DisplayText>{pressurisedCycles ? 'True' : 'False'}</DisplayText>
          ) : (
            <TFSelect
              initial={{ value: pressurisedCycles.toString(), title: pressurisedCycles.toString() }}
              handleSelectChange={(e): void => handleSelectChange(e)}
              options={selectOptions}
              width={110}
            />
          )}
        </div>
        {dataElements}
      </SectionWrapper>
    </>
  );
};

export default PostFlightCustomDataSection;
