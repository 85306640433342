import React, { PureComponent } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { DashboardState } from '../models';
import BasicLayout from './BasicLayout';
import styles from './ContentLayout.module.less';

class ContentLayout extends PureComponent<{ fixedHeader: boolean }> {
  getContentStyle = (): ContentStyle => {
    const { fixedHeader } = this.props;
    return {
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  render(): JSX.Element {
    const { children } = this.props;

    return (
      <BasicLayout>
        <Layout style={this.getContentStyle()} className={styles.contentLayout}>
          {children}
        </Layout>
      </BasicLayout>
    );
  }
}

export default connect(({ userSettings }: DashboardState) => ({
  fixedHeader: userSettings.ui.fixedHeader,
}))(ContentLayout);

export interface ContentStyle {
  paddingTop: number;
}
