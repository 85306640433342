/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Badge, Table } from '@arcflight/tf-component-library';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Tag } from 'antd';
import defaults from '../../utils/defaults';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import tableStyles from './WorkpacksTable.module.less';

const DocumentAssessmentTable = ({
  data,
  intl,
  toggleWorkpackDrawer,
  handleDeleteWorkpack,
  onPaginationChange,
  onSortChange,
  match,
  dateFormat,
  total,
  pageSize,
  pageIndex,
  resetToOne,
}) => {
  const { formatMessage } = intl;
  const columns = useMemo(
    () => [
      {
        Cell: ({ value }) => {
          let badgeValue = '';
          if (value === 'draft') badgeValue = 'grey';
          if (value === 'active') badgeValue = 'blue';
          if (value === 'pending') badgeValue = 'amber';
          return <Badge color={badgeValue} />;
        },
        Header: 'Status',
        width: 20,
        accessor: 'status',
      },
      {
        accessor: 'po_number',
        Header: 'Po Number',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
      },
      {
        Header: 'date',
        accessor: 'date',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return dateFormat ? moment(value).format(dateFormat) : moment(value).format(defaults.defaultDateFormat);
          }
          return '-';
        },
      },
      {
        Cell: ({ value }) => {
          if (value) {
            return dateFormat ? moment(value).format(dateFormat) : moment(value).format(defaults.defaultDateFormat);
          }
          return '-';
        },
        Header: 'Release Date',
        accessor: 'release_date',
        sortable: true,
      },
      {
        Cell: ({ value }) => (
          <Tag className={value?.defects && value?.defects > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>
            {value?.defects || '-'}
          </Tag>
        ),
        Header: 'DEF',
        sortable: true,
        id: 'defect',
        width: 30,
        accessor: 'item_counts',
      },
      {
        Cell: ({ value }) => (
          <Tag className={value?.scheduled && value?.scheduled > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>
            {value?.scheduled || '-'}
          </Tag>
        ),
        width: 30,
        Header: 'SCH',
        id: 'scheduled',
        sortable: true,
        accessor: 'item_counts',
      },
      {
        Cell: ({ value }) => (
          <Tag className={value?.oop && value?.oop > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>
            {value?.oop || '-'}
          </Tag>
        ),
        Header: 'OOP',
        id: 'oop',
        width: 30,
        accessor: 'item_counts',
      },
      {
        Cell: ({ value }) => (
          <Tag className={value?.llp && value?.llp > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>
            {value?.llp || '-'}
          </Tag>
        ),
        Header: 'LLP',
        id: 'llp',
        width: 30,
        accessor: 'item_counts',
      },
      {
        Header: '',
        id: '3dot',
        width: 20,
        Cell: ({ row }) => {
          const viewButtonChild = (
            <button
              type="button"
              onClick={() => toggleWorkpackDrawer(row, 'view')}
              className={tableStyles.menuDropdownButton}
            >
              <span>{formatMessage({ id: 'form.button.details' })} </span>
            </button>
          );
          if (row.original.status === 'complete' || row.original.status === 'pending') {
            return (
              <AuthDropdownMenu
                viewText={viewButtonChild}
                handleDelete={() => handleDeleteWorkpack(row.original.id)}
                resource={AircraftResource.WORKPACK}
                aircraftId={match.params.id}
                options={{ create: false, read: true, update: false, delete: true }}
                forTable
              />
            );
          }

          const editButtonChild = (
            // <Link to={`/aircraft/${match.params.id}/workpacks/${row.id}/edit`}>
            <button
              type="button"
              onClick={() => toggleWorkpackDrawer(row, 'edit')}
              className={tableStyles.menuDropdownButton}
            >
              <span>{formatMessage({ id: 'form.button.edit' })}</span>
            </button>
            // </Link>
          );

          return (
            <>
              <AuthDropdownMenu
                viewText={viewButtonChild}
                editText={editButtonChild}
                handleDelete={() => handleDeleteWorkpack(row.original.id)}
                resource={AircraftResource.WORKPACK}
                aircraftId={match.params.id}
                options={{ create: false, read: true, update: true, delete: true }}
                forTable
              />
            </>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );
  return (
    <div>
      <div className={tableStyles.tableContainer}>
        <Table
          tableInstance={tableInstance}
          handleRowClick={(row) => toggleWorkpackDrawer(row, 'view')}
          total={total}
          handlePaginationChange={(currentPage, numberOfItems) => onPaginationChange(currentPage, numberOfItems)}
          passedPageSize={pageSize}
          handleSortChange={(test) => onSortChange(test)}
          resetToOne={resetToOne}
        />
      </div>
    </div>
  );
};

DocumentAssessmentTable.propTypes = {
  intl: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  toggleWorkpackDrawer: PropTypes.func.isRequired,
  handleDeleteWorkpack: PropTypes.func.isRequired,
};

export default injectIntl(DocumentAssessmentTable);
