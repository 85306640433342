import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import SelectPerson from '../../SelectPerson';
import Label from './Label';
import StyledInput from './StyledInput';

interface DeferralSignoffDetailsProps {
  defect: Defect;
  operatorID: string;
  deferredById: string;
  setDeferredById: (value: string) => void;
  updateDefectData?: (changes: any[]) => void;
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  margin-right: 20px;
`;

const SelectWrapper = styled.div`
  width: 300px;
  margin-right: 20px;
  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
`;

const DeferralSignoffDetails: React.FC<DeferralSignoffDetailsProps> = ({
  defect,
  operatorID,
  deferredById,
  setDeferredById,
  updateDefectData,
}) => {
  const {
    userSettings: {
      details: { people },
    },
  } = useSelector((state: DashboardState) => state);

  const [authNumber, setAuthNumber] = useState(null);

  const foundPerson = people.find((person) => person?.organisation?.id === operatorID);

  const handlePersonChange = (input: string): void => {
    setDeferredById(input);
  };

  useEffect(() => {
    if (foundPerson) {
      setDeferredById(foundPerson.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPerson]);

  useEffect(() => {
    if (defect) {
      if (defect?.deferral_approval_number) {
        setAuthNumber(defect?.deferral_approval_number);
      } else {
        setAuthNumber('');
      }
    }
  }, [defect]);

  return (
    <Wrapper>
      <SelectWrapper>
        <Label marginBottom={5} fontWeight={500}>
          Deferred by
        </Label>
        <SelectPerson
          addPerson
          onChange={handlePersonChange}
          value={deferredById || foundPerson?.id}
          organisation_id={operatorID}
          defectDrawer
        />
      </SelectWrapper>
      <InputWrapper>
        <Label marginBottom={5} fontWeight={500}>
          Authorisation Number (optional)
        </Label>
        <StyledInput
          id="authorisationNumber"
          type="text"
          width={300}
          value={authNumber}
          onChange={(e): void => {
            setAuthNumber(e.target.value);
            updateDefectData([{ value: e.target.value, key: 'deferral_approval_number' }]);
          }}
        />
      </InputWrapper>
    </Wrapper>
  );
};

export default DeferralSignoffDetails;
