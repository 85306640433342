/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import latestTz from 'moment-timezone/data/packed/latest.json';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import { Flight } from '../../models/flights';
import { Trip } from '../../models/trips';
import DurationFormat from '../DurationFormat';
import warningIcon from '../../assets/login-error-amber.svg';
import errorIcon from '../../assets/login-error.svg';
import { DisplayText, Header } from './FlightDrawer';
import 'moment-timezone';

moment.tz.load(latestTz);

interface SectorTimesSectionProps {
  flight?: Flight;
  flightDate: string;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
  arrivalAirport: any;
  departAirport: any;
  trip: Trip;
}

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile && !edit) return '1fr 1fr';
    if (isMobile) return '1fr';
    if (edit) return '1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-top: ${({ borderTop }): string => (borderTop ? '20px' : null)};
`;

const WarningDiv = styled.div`
  color: ${({ error }): string => (error ? '#f5222d' : '#faad14')};
  grid-column: span 2;
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
`;

const AirportText = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

const SectorTimesSection: React.FC<SectorTimesSectionProps> = ({
  flight,
  flightDate,
  updateFlightData,
  arrivalAirport,
  departAirport,
  trip,
}) => {
  const [blocksOff, setBlocksOff] = useState('');
  const [blocksOn, setBlocksOn] = useState('');
  const [takeoff, setTakeOff] = useState('');
  const [landing, setLanding] = useState('');
  const [blocksOffError, setBlocksOffError] = useState(false);
  const [blocksOffPreviousError, setBlockOffPreviousError] = useState(false);
  const [blocksOnError, setBlocksOnError] = useState(false);
  const [prevBlocksTime, setPrevBlocksTime] = useState(null);

  const {
    drawer: { mode },
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);
  const { formatMessage } = useIntl();

  const flightTime = moment(landing).diff(moment(takeoff), 's');

  const blocksTime = moment(blocksOn).diff(moment(blocksOff), 's');

  const isMobile = window.innerWidth < 451;

  const formatTime = (input: string): string => {
    if (input) {
      return moment(input)
        .utc()
        .format('HH:mm');
    }
    return '-';
  };

  const displayDate = (date: string): string => {
    const tripDate = moment(flightDate).format(dateFormat);
    const passedDate = moment(date).format(dateFormat);
    if (tripDate !== passedDate) return passedDate;
    return null;
  };

  const handleDateTimeChange = (value: string, type: string): void => {
    if (type === 'blocksOff') {
      setBlocksOff(value);
      if (moment.utc(takeoff).diff(moment.utc(value), 'm') < 0 || !takeoff) {
        setLanding(value);
        setBlocksOn(value);
        setTakeOff(value);
        updateFlightData([
          { value, key: 'time_offblocks' },
          { value, key: 'time_takeoff' },
          { value, key: 'time_landing' },
          { value, key: 'time_onblocks' },
        ]);
      } else {
        updateFlightData([{ value, key: 'time_offblocks' }]);
      }
    }
    if (type === 'takeoff') {
      setTakeOff(value);
      if (moment.utc(landing).diff(moment.utc(value), 'm') < 0) {
        setLanding(value);
        setBlocksOn(value);
        updateFlightData([
          { value, key: 'time_takeoff' },
          { value, key: 'time_landing' },
          { value, key: 'time_onblocks' },
        ]);
      } else {
        updateFlightData([{ value, key: 'time_takeoff' }]);
      }
    }
    if (type === 'landing') {
      setLanding(value);
      if (moment.utc(blocksOn).diff(moment.utc(value), 'm') < 0) {
        setBlocksOn(value);
        updateFlightData([
          { value, key: 'time_onblocks' },
          { value, key: 'time_landing' },
        ]);
      } else {
        updateFlightData([{ value, key: 'time_landing' }]);
      }
    }
    if (type === 'blocksOn') {
      setBlocksOn(value);
      updateFlightData([{ value, key: 'time_onblocks' }]);
    }
  };

  const LocalAirportTime = (airport, time): ReactElement => {
    const timezone = airport?.timezone;
    const localTime = timezone && time && moment(time).tz(timezone.format('HH:mm z'));
    if (timezone && moment.tz.zone(timezone)) return <AirportText>Airport: {localTime}</AirportText>;
    return null;
  };

  useEffect(() => {
    if (flightDate && blocksOff) {
      if (moment.utc(blocksOff).diff(moment.utc(flightDate)) < 0) {
        setBlockOffPreviousError(true);
      } else {
        setBlockOffPreviousError(false);
      }
    }
  }, [flightDate, blocksOff]);

  useEffect(() => {
    if (moment.utc(takeoff).diff(moment.utc(blocksOff), 'm') > 30) {
      setBlocksOffError(true);
    } else {
      setBlocksOffError(false);
    }
    if (moment.utc(blocksOn).diff(moment.utc(landing), 'm') > 30) {
      setBlocksOnError(true);
    } else {
      setBlocksOnError(false);
    }
  }, [blocksOff, blocksOn, landing, takeoff]);

  useEffect(() => {
    if (flight) {
      if (flight?.time_offblocks) {
        setBlocksOff(moment.utc(flight?.time_offblocks).toISOString());
      } else {
        setBlocksOff('');
      }
      if (flight?.time_onblocks) {
        setBlocksOn(moment.utc(flight?.time_onblocks).toISOString());
      } else {
        setBlocksOn('');
      }
      if (flight?.time_takeoff) {
        setTakeOff(moment.utc(flight?.time_takeoff).toISOString());
      } else {
        setTakeOff('');
      }
      if (flight?.time_landing) {
        setLanding(moment.utc(flight?.time_landing).toISOString());
      } else {
        setLanding('');
      }
    } else if (flightDate) {
      const timeNow = moment().format('HH:mm');
      const dateFormatted = moment(flightDate).format(dateFormat);
      setBlocksOff(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      setBlocksOn(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      setTakeOff(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      setLanding(moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString());
      updateFlightData([
        { value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(), key: 'time_landing' },
        {
          value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(),
          key: 'time_offblocks',
        },
        { value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(), key: 'time_takeoff' },
        { value: moment.utc(dateFormatted + ' ' + timeNow, `${dateFormat} HH:mm`).toISOString(), key: 'time_onblocks' },
      ]);
    }
  }, [flight, flightDate]);

  useEffect(() => {
    if (trip && trip?.flights?.length) {
      const lastFlight = trip.flights[trip.flights.length - 1];
      const blocksOnTime = lastFlight.time_onblocks;
      if (blocksOnTime) {
        setBlocksOff(blocksOnTime);
        setPrevBlocksTime(blocksOnTime);
        setBlocksOn(blocksOnTime);
        setLanding(blocksOnTime);
        setTakeOff(blocksOnTime);
        updateFlightData([
          { value: blocksOnTime, key: 'time_offblocks' },
          { value: blocksOnTime, key: 'time_onblocks' },
          { value: blocksOnTime, key: 'time_landing' },
          { value: blocksOnTime, key: 'time_takeoff' },
        ]);
      }
    }
  }, [trip]);

  return (
    <SectionWrapper borderTop isMobile={isMobile} edit={mode !== 'view'}>
      <div data-testid="FlightDetailsSection-BlocksOff">
        {mode === 'view' ? <Header>{formatMessage({ id: 'text.blocksOff' })}</Header> : null}
        {mode === 'view' ? (
          <DisplayText>
            {flight?.time_offblocks ? displayDate(flight?.time_offblocks) : '-'}{' '}
            {flight?.time_offblocks ? formatTime(flight?.time_offblocks) : null}z
          </DisplayText>
        ) : (
          <DateTimePicker
            headerDate="Blocks Off Date"
            headerTime="Blocks Off Time"
            dateTime={blocksOff}
            handleDateTimeChange={(value): void => handleDateTimeChange(value, 'blocksOff')}
            timeWidth="150px"
          />
        )}
        <LocalAirportTime airport={departAirport} time={blocksOff} />
      </div>
      <div data-testid="FlightDetailsSection-Takeoff">
        {mode === 'view' ? <Header>{formatMessage({ id: 'text.takeoff' })}</Header> : null}
        {mode === 'view' ? (
          <DisplayText>
            {flight?.time_takeoff ? displayDate(flight?.time_takeoff) : '-'}{' '}
            {flight?.time_takeoff ? formatTime(flight?.time_takeoff) : null}z
          </DisplayText>
        ) : (
          <DateTimePicker
            headerDate="Takeoff Date"
            headerTime="Takeoff Time"
            dateTime={takeoff}
            handleDateTimeChange={(value): void => handleDateTimeChange(value, 'takeoff')}
            timeWidth="150px"
          />
        )}
        <LocalAirportTime airport={departAirport} time={takeoff} />
      </div>
      {blocksOffPreviousError && mode !== 'view' ? (
        <WarningDiv error>
          <img src={errorIcon} alt="warning icon" />
          <span>{`Time of blocks off is before previous sectors blocks on ${prevBlocksTime}`}</span>
        </WarningDiv>
      ) : null}
      {blocksOffError && !blocksOffPreviousError && mode !== 'view' ? (
        <WarningDiv>
          <img src={warningIcon} alt="warning icon" />
          Time between blocks-off and takeoff is more than 30 minutes
        </WarningDiv>
      ) : null}
      <div data-testid="FlightDetailsSection-Landing">
        {mode === 'view' ? <Header>{formatMessage({ id: 'text.landing' })}</Header> : null}
        {mode === 'view' ? (
          <DisplayText>
            {flight?.time_landing ? displayDate(flight?.time_landing) : '-'}{' '}
            {flight?.time_landing ? formatTime(flight?.time_landing) : null}z
          </DisplayText>
        ) : (
          <DateTimePicker
            headerDate="Landing Date"
            headerTime="Landing Time"
            dateTime={landing}
            handleDateTimeChange={(value): void => handleDateTimeChange(value, 'landing')}
            timeWidth="150px"
          />
        )}
        <LocalAirportTime airport={arrivalAirport} time={landing} />
      </div>
      <div data-testid="FlightDetailsSection-BlocksOn">
        {mode === 'view' ? <Header>{formatMessage({ id: 'text.blocksOn' })}</Header> : null}
        {mode === 'view' ? (
          <DisplayText>
            {flight?.time_onblocks ? displayDate(flight?.time_onblocks) : '-'}{' '}
            {flight?.time_onblocks ? formatTime(flight?.time_onblocks) : null}z
          </DisplayText>
        ) : (
          <DateTimePicker
            headerDate="Blocks On Date"
            headerTime="Blocks On Time"
            dateTime={blocksOn}
            handleDateTimeChange={(value): void => handleDateTimeChange(value, 'blocksOn')}
            timeWidth="150px"
          />
        )}
        <LocalAirportTime airport={arrivalAirport} time={blocksOff} />
      </div>
      {blocksOnError && mode !== 'view' ? (
        <WarningDiv>
          <img src={warningIcon} alt="warning icon" />
          Time between landing and blocks-on is more than 30 minutes
        </WarningDiv>
      ) : null}
      <div data-testid="FlightDetailsSection-BlocksOn">
        <Header>{formatMessage({ id: 'text.blocksTime' })}</Header>
        <DisplayText>
          <DurationFormat time={blocksTime} />
        </DisplayText>
      </div>
      <div data-testid="FlightDetailsSection-FlightTime">
        <Header>{formatMessage({ id: 'title.flightTime' })}</Header>
        <DisplayText>
          <DurationFormat time={flightTime} />
        </DisplayText>
      </div>
    </SectionWrapper>
  );
};

export default SectorTimesSection;
