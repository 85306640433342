import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Button } from '@arcflight/tf-component-library';
import { injectIntl } from 'react-intl';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import goArrow from '../../assets/go-arrow.svg';
import AircraftInfoSection from './AircraftInfoSection';
import AircraftTotals from './AircraftTotals';
import AircraftWidgets from './AircraftWidgets';
import styles from './AircraftCard.module.less';

const AircraftCard = ({
  intl: { formatMessage },
  aircraft,
  currentAirport,
  isAircraftTotalsVisible,
  handleShowAircraftTotalsClick,
  loading,
}) => {
  return (
    <div>
      {!loading && aircraft ? (
        <div className={styles.aircraftCard}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={8} data-testid="AircraftCard--AircraftInfoSection">
              <AircraftInfoSection aircraft={aircraft} formatMessage={formatMessage} currentAirport={currentAirport} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={16}>
              <div className={styles.widgetWrapper}>
                <AircraftWidgets aircraft={aircraft} />
                <div
                  className={
                    isAircraftTotalsVisible ? styles.showTotalsButtonWrapperPadded : styles.showTotalsButtonWrapper
                  }
                >
                  <Button size={ButtonSize.MEDIUM} primary={false} onClick={() => handleShowAircraftTotalsClick()}>
                    {isAircraftTotalsVisible
                      ? formatMessage({ id: 'text.hideAircraftTotals' })
                      : formatMessage({ id: 'text.showAircraftTotals' })}
                    <img src={goArrow} alt="arrow" className={styles.goArrow} />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {isAircraftTotalsVisible ? (
            <Row>
              <Col md={24} lg={8} />
              <Col md={24} lg={16}>
                <AircraftTotals aircraft={aircraft} />
              </Col>
            </Row>
          ) : null}
        </div>
      ) : (
        <div className={styles.loading}>{formatMessage({ id: 'text.loading' })}</div>
      )}
    </div>
  );
};

AircraftCard.propTypes = {
  intl: PropTypes.object.isRequired,
  aircraft: PropTypes.object,
  currentAirport: PropTypes.object,
  isAircraftTotalsVisible: PropTypes.bool,
  handleShowAircraftTotalsClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

AircraftCard.defaultProps = {
  isAircraftTotalsVisible: false,
  currentAirport: {},
  aircraft: {},
};

export default injectIntl(AircraftCard);
