/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Select, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import defaults from '../../../utils/defaults';
import { formatTime } from '../../../utils/utils';
import ata from '../../../utils/ata';
import globalStyles from '../../../utils/globalStyles.module.less';
import styles from './MXModal.module.less';

const { Option } = Select;

const MXModalLLPContents = ({ form: { getFieldDecorator }, intl: { formatMessage }, userSettings, aircraft, item }) => {
  const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;
  const hasAPU = aircraft ? aircraft.apu_installed : false;
  const [unitOfTime, setUnitOfTime] = useState('days');
  useEffect(() => {
    if (item && item.unit_of_time) {
      setUnitOfTime(item.unit_of_time);
    }
  }, [item]);

  const activeLimitValue = ({ monthsValue, daysValue }) => {
    if (monthsValue && Number(monthsValue) && Number(monthsValue) > 0) {
      return monthsValue;
    }

    if (daysValue && Number(daysValue) && Number(daysValue) > 0) {
      return daysValue;
    }

    return null;
  };

  return (
    <>
      <Form.Item
        label={formatMessage({ id: 'form.labels.ataChapter' })}
        className={globalStyles.formItem}
        data-test="formItemATA"
      >
        {getFieldDecorator('ata_number', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.selectATA' }) }],
          initialValue: item ? item.ata_number : undefined,
        })(
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder={formatMessage({ id: 'form.placeholder.searchATA' })}
            className={`${globalStyles.formSelect} ${styles.selectFull}`}
            data-test="formSelectATA"
          >
            {ata.map((itemATA) => {
              return (
                <Option key={itemATA.key} value={itemATA.key}>
                  {itemATA.nameFull}
                </Option>
              );
            })}
          </Select>,
        )}
      </Form.Item>
      <Form.Item
        label={ReactHtmlParser(
          `${formatMessage({ id: 'form.labels.assembly' })} <span>${formatMessage({
            id: 'form.labels.optional',
          })}</span>`,
        )}
        className={globalStyles.formItem}
        data-test="formItemAssembly"
      >
        {getFieldDecorator('assembly', {
          rules: [{ required: false }],
          initialValue: item ? item.assembly : null,
        })(<Input className={globalStyles.formInput} data-test="formInputAssembly" />)}
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'form.labels.taskDescription' })}
        className={globalStyles.formItem}
        data-test="formItemName"
      >
        {getFieldDecorator('name', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.addItemName' }) }],
          initialValue: item ? item.name : null,
        })(<Input className={globalStyles.formInput} data-test="formInputName" />)}
      </Form.Item>
      <Form.Item
        label={ReactHtmlParser(
          `${formatMessage({ id: 'form.labels.taskRef' })} <span>${formatMessage({
            id: 'form.labels.optional',
          })}</span>`,
        )}
        className={globalStyles.formItem}
        data-test="formItemTaskRef"
      >
        {getFieldDecorator('reference', {
          rules: [{ required: false }],
          initialValue: item ? item.reference : null,
        })(<Input className={globalStyles.formInput} data-test="formInputTaskRef" />)}
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'form.labels.partNum' })}
        className={globalStyles.formItem}
        data-test="formItemPartNum"
      >
        {getFieldDecorator('part_number', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.inputPartNo' }) }],
          initialValue: item ? item.part_number : null,
        })(<Input className={globalStyles.formInput} data-test="formInputPartNum" />)}
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'form.labels.serialNum' })}
        className={globalStyles.formItem}
        data-test="formItemSerialNum"
      >
        {getFieldDecorator('part_serial', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.inputSerialNo' }) }],
          initialValue: item ? item.part_serial : null,
        })(<Input className={globalStyles.formInput} data-test="formInputSerialNum" />)}
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleFH">
            <span>{formatMessage({ id: 'form.title.addFlightHours' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemFHTolerance"
          >
            {getFieldDecorator('flight_seconds_tolerance', {
              rules: [{ required: false }],
              initialValue:
                item && item.flight_seconds_tolerance ? Math.round(item.flight_seconds_tolerance / 3600) : null,
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputFHTolerance" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Lifespan" className={globalStyles.formItem} data-test="formItemFHLifespan">
            {getFieldDecorator('flight_seconds_repeat', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_repeat ? formatTime(item.flight_seconds_repeat) : null,
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputFHLifespan" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Next Due" className={globalStyles.formItem} data-test="formItemFHNextDue">
            {getFieldDecorator('flight_seconds_due', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_due ? formatTime(item.flight_seconds_due) : null,
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputFHNextDue" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleCycles">
            <span>{formatMessage({ id: 'form.title.addCycles' })}</span>
          </div>
        </Col>

        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesTolerance"
          >
            {getFieldDecorator('cycles_tolerance', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_tolerance : null,
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputCyclesTolerance" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.lifespan' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesLifespan"
          >
            {getFieldDecorator('cycles_repeat', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_repeat : null,
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputCyclesLifespan" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesNextDue"
          >
            {getFieldDecorator('cycles_due', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_due : null,
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputCyclesNextDue" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleMonths">
            <span>{formatMessage({ id: 'form.title.addMonths' })}</span>
          </div>
        </Col>
        <Col md={24}>
          <Form.Item>
            {getFieldDecorator('unit_of_time', {
              rules: [{ required: false }],
              initialValue: unitOfTime,
            })(
              <>
                <div className={styles.dateLabel}>Limit Type</div>
                <RadioInputButton
                  text="Days"
                  active={unitOfTime === 'days'}
                  onClick={() => setUnitOfTime('days')}
                  marginRight="8px"
                />
                <RadioInputButton
                  text="Months"
                  active={unitOfTime === 'months'}
                  onClick={() => setUnitOfTime('months')}
                />
              </>,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsTolerance"
          >
            {getFieldDecorator('months_tolerance', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_tolerance, daysValue: item?.days_tolerance }),
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputMonthsTolerance" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.lifespan' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsLifespan"
          >
            {getFieldDecorator('months_repeat', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_repeat, daysValue: item?.days_repeat }),
            })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputMonthsLifespan" />)}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsNextDue"
          >
            {getFieldDecorator('date_due', {
              rules: [{ required: false }],
              initialValue: item && item.date_due ? moment(item.date_due) : null,
            })(
              <DatePicker
                className={globalStyles.formDatePicker}
                format={dateFormat}
                data-test="formInputMonthsNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      {hasAPU && (
        <Row gutter={16}>
          <Col md={24}>
            <div className={styles.timesTitle} data-test="sectionTitleAPU">
              <span>{formatMessage({ id: 'form.title.addAPUHours' })}</span>
            </div>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.tolerance' })}
              className={globalStyles.formItem}
              data-test="formItemAPUTolerance"
            >
              {getFieldDecorator('apu_seconds_tolerance', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_tolerance ? Math.round(item.apu_seconds_tolerance / 3600) : null,
              })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputAPUTolerance" />)}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.lifespan' })}
              className={globalStyles.formItem}
              data-test="formItemAPULifespan"
            >
              {getFieldDecorator('apu_seconds_repeat', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_repeat ? formatTime(item.apu_seconds_repeat) : null,
              })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputAPULifespan" />)}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.nextDue' })}
              className={globalStyles.formItem}
              data-test="formItemAPUNextDue"
            >
              {getFieldDecorator('apu_seconds_due', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_due ? formatTime(item.apu_seconds_due) : null,
              })(<InputNumber min={1} className={globalStyles.formInputNumber} data-test="formInputAPUNextDue" />)}
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

MXModalLLPContents.propTypes = {
  userSettings: PropTypes.object.isRequired,
  item: PropTypes.object,
  aircraft: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

MXModalLLPContents.defaultProps = {
  item: null,
};

export default injectIntl(MXModalLLPContents);
