import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import Portal from '../../components/TFPortal/TFPortal';

interface ColumnDropdownProps {
  visible: boolean;
  handleColumnDropdownClick: Function;
  selectedColumns: {
    title: string;
    checked: boolean;
  }[];
  buttonPosition?: {
    top?: number;
    left?: number;
  };
  handleColumnSelection: Function;
  fixedColumns: string[];
}

const InvisiBackground = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  height: auto;
  max-height: 450px;
  overflow-y: scroll;
  top: ${({ buttonPosition }): string => (buttonPosition ? `${buttonPosition.top + 28}px` : '0')};
  left: ${({ buttonPosition }): string => (buttonPosition ? `${buttonPosition.left}px` : '0')};
  z-index: 10;
  padding: 24px 50px 24px 24px;
  border-radius: 4px;
  box-shadow: 0 0 10px 5px #dbe3ed;
  border: solid 1px #fff;
  background-color: #fcfcfc;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #242d41;
  margin-bottom: 24px;
`;

const StyledLabel = styled.label`
  text-transform: capitalize;
  margin-left: 8px;
  color: rgba(36, 45, 65, 0.8);
`;

const ColumnDropdown: React.FC<ColumnDropdownProps> = ({
  visible,
  handleColumnDropdownClick,
  selectedColumns,
  buttonPosition,
  handleColumnSelection,
  fixedColumns,
}) => {
  const columnOptions = selectedColumns
    .filter((item) => !fixedColumns.includes(item.title))
    .map((column) => {
      let newTitle = column.title.replace(/_/g, ' ');
      if (column.title === 'airframe_hours_original') newTitle = 'airframe hours';
      if (column.title === 'airframe_landings') newTitle = 'total landings';
      if (column.title.endsWith('uplift')) newTitle = newTitle.replace(' uplift', '');
      return (
        <StyledButton key={column.title} type="button" onClick={(e): void => e.stopPropagation()}>
          <input
            type="checkbox"
            id={column.title}
            name={column.title}
            checked={column.checked}
            onChange={(): void => handleColumnSelection(column.title)}
          />
          <StyledLabel htmlFor={column.title}>{newTitle}</StyledLabel>
        </StyledButton>
      );
    });

  return (
    <>
      {visible ? (
        <Portal>
          <InvisiBackground
            onClick={(e: MouseEvent): void => {
              e.stopPropagation();
              return handleColumnDropdownClick();
            }}
          />
          <MenuWrapper buttonPosition={buttonPosition}>
            <Title>Select the columns to view</Title>
            {columnOptions}
          </MenuWrapper>
        </Portal>
      ) : null}
    </>
  );
};

export default ColumnDropdown;
