import React from 'react';
import styled from 'styled-components';
import { Flight } from '../../models/flights';
import EmptyStateHistory from '../../assets/emptyState/empty-state-history.svg';
import { EmptyStateDiv } from './DefectsSection';
import { SectionHeader } from './FlightDrawer';
import FlightHistoryTable from './FlightHistoryTable';

interface HistroySectionProps {
  flight: Flight;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 12px 0;
`;

const HistorySection: React.FC<HistroySectionProps> = ({ flight }) => {
  return (
    <SectionWrapper data-testid="FlightDrawer-HistorySection">
      <SectionHeader marginLeft="20px" reducedBottomMargin data-testid="HistorySection-Header">
        History
      </SectionHeader>
      <div>
        {flight?.versions?.length ? (
          <FlightHistoryTable data={flight?.versions} />
        ) : (
          <EmptyStateDiv data-testid="HistorySection-EmptyState">
            <EmptyStateWrapper>
              <StyledImage src={EmptyStateHistory} alt="no defects" />
              <div>No events recorded on this flight</div>
            </EmptyStateWrapper>
          </EmptyStateDiv>
        )}
      </div>
    </SectionWrapper>
  );
};

export default HistorySection;
