import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import Loading from '../../TFLoading/index';
import FlexWrapper from './FlexWrapper';

interface ButtonSectionProps {
  handleCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const StyledSubmitButton = styled.input`
  background-color: ${({ theme }): string => theme.colours.brandAccentBlue};
  color: ${({ theme }): string => theme.colours.white};
  height: 30px;
  border-radius: 20px;
  padding: 0 16px;
  border: none;
  outline: none;
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }): string => (disabled ? '0.3' : '1')};
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 30px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
`;

const ButtonSection: React.FC<ButtonSectionProps> = ({ handleCancel, loading, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <FlexWrapper justifyContent="flex-start" marginTop={40} marginBottom={20} padding="0 0 20px 0">
      <InputWrapper>
        <Loading loading={loading} contain width={30} height={30} />
        <StyledSubmitButton type="submit" value="Complete" disabled={disabled} />
      </InputWrapper>
      <Button size={ButtonSize.MEDIUM} primary={false} onClick={handleCancel}>
        {formatMessage({ id: 'text.cancel' })}
      </Button>
    </FlexWrapper>
  );
};

export default ButtonSection;
