/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import { intl } from '../../providers/IntlProvider';
import { getWorkpacks, getWorkpack, deleteWorkpack, addAttachmentWorkpack } from '../../services/api';
import { getPublicWorkpack } from '../../services/external';
import { WorkpacksActionTypes } from '.';

export const getAllWorkpacks = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getWorkpacks(payload);
  dispatch({
    type: WorkpacksActionTypes.SAVE_ALL,
    payload: response,
  });
};

export const getSingleWorkpack = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getWorkpack(payload.id);
  dispatch({
    type: WorkpacksActionTypes.SAVE,
    payload: response,
  });
};

export const uploadAttachment = ({ files, id }) => async (dispatch): Promise<any> => {
  const response = await addAttachmentWorkpack({ attachments: files, id });
  dispatch({
    type: WorkpacksActionTypes.SAVE,
    payload: response,
  });
};

export const removeWorkpack = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await deleteWorkpack(payload);
  if (response && response.statusCode === 204) {
    message.success(intl.formatMessage({ id: 'message.workpackDeleted' }));
    dispatch({
      type: WorkpacksActionTypes.DELETE,
      payload: response ? { removeId: payload } : {},
    });
  } else {
    message.error(intl.formatMessage({ id: 'message.approveItemsError' }));
  }
};

export const getPublicWorkpackDetails = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getPublicWorkpack(payload);
  dispatch({
    type: WorkpacksActionTypes.SAVE_PUBLIC,
    payload: response,
  });
};

export const setPublicWorkpackDetailsWithoutAPI = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({
    type: WorkpacksActionTypes.SAVE_PUBLIC,
    payload,
  });
};
