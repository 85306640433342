import { Checkbox, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ExpandableCard from '../MXCard/ExpandableCard';
import styles from './SelectableMXItem.module.less';

const SelectableMXItem = ({
  item,
  aircraft = {},
  checked,
  isOverdue,
  isCritical,
  onChange,
  onEdit,
  onApplyTolerance,
  onSuccess,
  expandOverride,
  disabledMessage,
  expandItem,
  setPassedMxItemId,
  loading,
}) => {
  const { apu_installed: hasAPU, mx_thresholds: aircraftThreshold, id: aircraftID } = aircraft;
  return (
    <div className={styles.mxItemRow}>
      <div className={styles.checkboxWrapper}>
        {item.status === 'resolved' ? (
          <Tooltip title={disabledMessage}>
            <Checkbox
              checked={checked}
              onChange={(e) => onChange(e.target.checked, item.id)}
              data-test="checkbox"
              disabled
            />
          </Tooltip>
        ) : (
          <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked, item.id)} data-test="checkbox" />
        )}
      </div>
      <ExpandableCard
        hasAPU={hasAPU}
        aircraftThreshold={aircraftThreshold}
        item={item}
        isOverdue={isOverdue}
        isCritical={isCritical}
        aircraftID={aircraftID}
        onEdit={() => onEdit()}
        onApplyTolerance={() => onApplyTolerance()}
        onSuccess={() => onSuccess()}
        expandOverride={expandOverride}
        expandItem={expandItem}
        setPassedMxItemId={setPassedMxItemId}
        data-test="expandableCard"
        loading={loading}
      />
    </div>
  );
};
SelectableMXItem.propTypes = {
  item: PropTypes.object.isRequired,
  aircraft: PropTypes.object,
  isOverdue: PropTypes.bool.isRequired,
  isCritical: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onApplyTolerance: PropTypes.func,
  onSuccess: PropTypes.func,
  expandOverride: PropTypes.bool,
  disabledMessage: PropTypes.string.isRequired,
  expandItem: PropTypes.string,
  setPassedMxItemId: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};

SelectableMXItem.defaultProps = {
  onSuccess: null,
  onApplyTolerance: null,
  onEdit: null,
  expandOverride: false,
  aircraft: undefined,
  expandItem: '',
  setPassedMxItemId: () => {
    // default func
  },
};

export default connect(({ userSettings }) => ({ userSettings }))(SelectableMXItem);
