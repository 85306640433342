import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import moment, { Moment } from 'moment';
import { Defect } from '../../../models/defects';
import TFSelect from '../../TFSelect/TFSelect';
import melChapters from '../../../assets/melChapters.json';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import TFSearchableSelect from '../../TFSearchableSelect/TFSearchableSelect';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import DeferUntil from './DeferUntil';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';
import RectificationLimits from './RectificationLimits';

interface ManuallyEnterMELDetailsProps {
  defect: Defect;
  rectificationCategory?: { title: string; colour?: string };
  setRectificationCategory?: (option: { title: string; colour?: string }) => void;
  deferred: boolean;
  setDateDue: (input) => void;
  noMEL?: boolean;
  apuInstalled?: boolean;
  reference: string;
  setReference: (value: string) => void;
  setATAChapter: (value: string) => void;
  setATASection: (value: string) => void;
  ATAChapter: string;
  ATASection: string;
  deferredTime: Moment;
  setDeferredTime: (input: Moment) => void;
  updateDefectData: (changes: any[]) => void;
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: 20px;
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
  margin-left: -3px;
`;

const ManuallyEnterMELDetails: React.FC<ManuallyEnterMELDetailsProps> = ({
  defect,
  rectificationCategory,
  setRectificationCategory,
  deferred,
  setDateDue,
  noMEL,
  apuInstalled,
  setReference,
  ATAChapter,
  setATAChapter,
  ATASection,
  setATASection,
  deferredTime,
  setDeferredTime,
  updateDefectData,
}) => {
  const { formatMessage } = useIntl();

  const [calendarDays, setCalendarDays] = useState(null);
  const [showRecticationLimits, setShowRectificationLimits] = useState(false);
  const [MELChapters, setMELChapters] = useState([]);
  const [MELSections, setMELSections] = useState([]);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const handleSelectChange = (option: { title: string }): void => {
    setRectificationCategory(option);
    updateDefectData([{ value: option.title, key: 'rectification_category' }]);
  };

  const handleChapterChange = (option): void => {
    setATAChapter(option.value);
    updateDefectData([{ value: option.value, key: 'ata_chapter' }]);
  };

  const handleSectionChange = (option): void => {
    setATASection(option.value);
    updateDefectData([{ value: option.value, key: 'ata_section' }]);
  };

  const handleDeferralTimeChange = (dateTime: string): void => {
    setDeferredTime(moment.utc(dateTime));
    updateDefectData([{ value: moment.utc(dateTime), key: 'deferred_at' }]);
  };

  const handleReferenceChange = (value: string): void => {
    setReference(value);
    updateDefectData([{ value, key: 'reference' }]);
  };

  useEffect(() => {
    const createMELChapters = (): any => {
      const chaptersArray = [];
      Object.keys(melChapters).forEach((chapter) => {
        const childrenArray = [];
        if (melChapters[chapter]?.children && Object.keys(melChapters[chapter]?.children)?.length) {
          Object.keys(melChapters[chapter].children).forEach((section) => {
            childrenArray.push({
              value: section,
              title: `${section} - ${melChapters[chapter].children[section]}`,
            });
          });
        }
        chaptersArray.push({
          value: chapter,
          title: `${chapter} - ${melChapters[chapter].title}`,
          sections: childrenArray,
        });
      });
      return chaptersArray;
    };
    if (melChapters) {
      const chapters = createMELChapters();
      setMELChapters(chapters);
      if (ATAChapter === null) {
        setATAChapter(chapters[0].value);
      }
    }
  }, [ATAChapter, setATAChapter]);

  useEffect(() => {
    if (defect && defect.mel_item) {
      setATAChapter(defect?.display_data?.ata.split(' ')[0]);
      setATASection(defect?.display_data?.ata_section);
      setReference(defect?.reference || '');
      setCalendarDays(defect?.calendar_days_limit);
      if (defect?.display_data?.category) setRectificationCategory({ title: defect?.display_data?.category });
    }
  }, [defect, setATAChapter, setATASection, setRectificationCategory, setReference]);

  useEffect(() => {
    if (MELChapters && ATAChapter) {
      if (MELChapters.find((chap) => chap.value === ATAChapter)) {
        const sections = MELChapters.find((chap) => chap.value === ATAChapter)?.sections;
        if (sections?.length) {
          setMELSections(sections);
          setATASection(sections[0].value);
        }
      }
    }
  }, [ATAChapter, MELChapters, setATASection]);

  useEffect(() => {
    if (rectificationCategory?.title === 'A') {
      setShowRectificationLimits(true);
    } else {
      setShowRectificationLimits(false);
    }
  }, [rectificationCategory]);

  const isMobile = window.innerWidth < 451;

  return (
    <div>
      {noMEL && deferred ? (
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          This aircraft has no MEL currently, please manually enter the MEL details below.
        </Label>
      ) : (
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage({ id: 'text.pleaseEnterReferenceNumber' })}
        </Label>
      )}

      <Label marginBottom={5} fontWeight={500}>
        {formatMessage({ id: 'title.reference' })}
      </Label>
      <StyledInput
        id="cdlReference"
        value={defect?.reference || ''}
        type="text"
        width={300}
        onChange={(e): void => handleReferenceChange(e.target.value)}
      />
      <Label marginTop={20} marginBottom={5}>
        {formatMessage({ id: 'title.ataChapter' })}
      </Label>
      <TFSearchableSelect
        options={MELChapters}
        initial={ATAChapter ? MELChapters.find((chap) => chap.value === ATAChapter) : MELChapters[0]}
        handleSelectChange={handleChapterChange}
        inputWidth={isMobile ? 300 : 450}
      />
      <FlexWrapper column>
        <FlexWrapper column={isMobile}>
          <FlexWrapper column marginRight={20}>
            <Label marginTop={20} marginBottom={5}>
              {formatMessage({ id: 'title.ataSection' })}
            </Label>
            <TFSelect
              options={MELSections}
              initial={
                ATASection ? MELSections.find((section) => section.value === ATASection.toString()) : MELSections[0]
              }
              handleSelectChange={handleSectionChange}
              width={300}
            />
          </FlexWrapper>
          {deferred && (
            <FlexWrapper column marginRight={20}>
              <Label marginTop={20} marginBottom={5}>
                {formatMessage({ id: 'title.rectificationCategory' })}
              </Label>
              <TFSelect
                initial={{ title: rectificationCategory?.title || defect?.display_data?.category }}
                options={[{ title: 'A' }, { title: 'B' }, { title: 'C' }, { title: 'D' }, { title: 'Advisory only' }]}
                handleSelectChange={handleSelectChange}
                width={isMobile ? 300 : 140}
                allowEmpty
              />
            </FlexWrapper>
          )}
        </FlexWrapper>
        {deferred && (
          <FlexWrapper marginTop={20} column={isMobile} width="100%">
            <FlexWrapper marginRight={20}>
              <DateTimePicker
                dateTime={deferredTime.toISOString()}
                headerDate="Deferral date"
                headerTime="Deferral time"
                handleDateTimeChange={handleDeferralTimeChange}
                disabled={!unlockDeferralTime}
              />
            </FlexWrapper>
            <FlexWrapper marginTop={isMobile ? 20 : 0} width="100%">
              <DeferUntil
                defect={defect}
                rectificationCategory={rectificationCategory}
                calendarDays={calendarDays}
                setDateDue={setDateDue}
              />
            </FlexWrapper>
            <ButtonWrapper>
              {!unlockDeferralTime ? (
                <Button
                  primary={false}
                  height="24px"
                  padding="0 12px"
                  onClick={(): void => setUnlockDeferralTime(true)}
                >
                  <StyledIcon src={padlockIcon} alt="padlock icon" />
                  Unlock
                </Button>
              ) : null}
            </ButtonWrapper>
          </FlexWrapper>
        )}
      </FlexWrapper>
      {showRecticationLimits && deferred ? (
        <RectificationLimits defect={defect} setCalendarDays={setCalendarDays} apuInstalled={apuInstalled} />
      ) : null}
    </div>
  );
};

export default ManuallyEnterMELDetails;
