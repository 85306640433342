import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import styled from 'styled-components';
import { Loading } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { addIntermittentFault } from '../../services/api';
import ButtonSection from '../../components/TripDrawer/ButtonSection';
import { IntermittentFault } from '../../models/intermittentFaults';
import DetailsSection from './DetailsSection';
import SectorSection from './SectorSection';

interface IntermittentFaultsDrawerProps {
  fault: IntermittentFault;
  onUpdatedFault: () => {};
  intermittentFaultName: string;
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: ${({ bannerVisible }): string => (bannerVisible ? '16px' : '24px')};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
  }
`;

const InfoDivWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 24px 0 32px;
  padding: 20px;
`;

const IntermittentFaultsDrawer: React.FC<IntermittentFaultsDrawerProps> = ({
  fault,
  onUpdatedFault,
  intermittentFaultName,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);
  const [faultData, setFaultData] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const validateFaultData = (): boolean => {
    if (faultData && !faultData.reported_at) {
      message.error('Date must be entered');
      return false;
    }
    if (faultData && !faultData.details) {
      message.error('Details must be entered');
      return false;
    }
    if (faultData && !faultData.reported_by_id) {
      message.error('Reporter must be selected');
      return false;
    }
    return true;
  };

  const submitFault = async () => {
    if (mode === 'add' && validateFaultData()) {
      setLoading(true);
      const params = {
        ...faultData,
        attachments_attributes: attachments,
        id,
      };
      await addIntermittentFault(params);
      dispatch(changeDrawerVisibility({ payload: false }));
      dispatch(changeDrawerMode({ payload: 'view' }));
      onUpdatedFault();
    }
  };

  const handleCancelClick = (): void => {
    if (mode === 'add') {
      dispatch(changeDrawerVisibility({ payload: false }));
    }
  };

  const handleSaveClick = (): void => {
    submitFault();
  };

  const updateFaultData = (key: string, value: any, secondKey?: string, secondValue?: any): void => {
    let newFaultData = {
      ...faultData,
      [key]: value,
    };
    if (secondKey) {
      newFaultData = {
        ...newFaultData,
        [secondKey]: secondValue,
      };
    }
    setFaultData(newFaultData);
  };

  useEffect(() => {
    if (!faultData?.reported_at)
      setFaultData({
        ...faultData,
        reported_at: moment()
          .utc()
          .format(),
      });
  }, [faultData]);

  useEffect(() => {
    setFaultData(fault);
    if (fault.attachments) {
      setAttachments(fault.attachments);
    }
  }, [fault]);

  return (
    <DrawerWrapper>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <Title>{mode === 'add' ? `Add ${intermittentFaultName}` : intermittentFaultName}</Title>
          <SectorSection
            fault={faultData}
            editable={mode !== 'view'}
            updateFaultData={updateFaultData}
            aircraftId={id}
          />
          <InfoDivWrapper>
            <DetailsSection
              fault={faultData}
              editable={mode !== 'view'}
              updateFaultData={updateFaultData}
              aircraftId={id}
              attachments={attachments}
              setAttachments={setAttachments}
            />
          </InfoDivWrapper>
        </>
      )}

      {mode !== 'view' ? (
        <ButtonSection handleCancelClick={handleCancelClick} handleSaveClick={handleSaveClick} loading={loading} />
      ) : null}
    </DrawerWrapper>
  );
};

export default IntermittentFaultsDrawer;
