/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import moment from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDefectForDrawer, update, submitDefect } from '../../models/defects/actions';
import { DashboardState } from '../../models';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalVisibility,
  handleBackButtonClick,
  setDrawerChanges,
  revertChanges as reduxRevertChanges,
} from '../../models/drawer';
import Loading from '../TFLoading/index';
import Message from '../Message/Message';
import ResolutionDetails from './components/ResolutionDetails';
import AdditionalInformation from './components/AdditionalInformation';
import AircraftLimitations from './components/AircraftLimitations';
import ButtonSection from './components/ButtonSection';
import ConfirmSection from './components/ConfirmSection';
import DefectDetails from './components/DefectDetails';
import DefectHeader from './components/DefectHeader';
import DeferralOptions from './components/DeferralOptions';
import SignatureSection from './components/SignatureSection';
import AddDeferralTile from './components/AddDeferralTile';
import DeferralSignoffDetails from './components/DeferralSignoffDetails';
import RectificationIntervalExtension from './components/RectificationIntervalExtension';
import Card from './components/Card';

interface DefectDrawerProps {
  defectId: string;
  passedFlightId?: string;
  deferDefect?: boolean;
}

const DrawerWrapper = styled.div`
  min-height: calc(100vh - 24px);
  height: 100%;
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const BorderLine = styled.div`
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '')};
`;

const DefectDrawer: React.FC<DefectDrawerProps> = ({ defectId, passedFlightId, deferDefect }) => {
  const [signature, setSignature] = useState('');
  const [rectificationCategory, setRectificationCategory] = useState(null);

  const [flightId, setFlightId] = useState(null);
  const [aircraftId, setAircraftId] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [melItem, setMelItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [melItemsLoading, setMelItemsLoading] = useState(false);
  const [confirmationChecks, setConfirmationChecks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [rectificationId, setRectificationId] = useState('');
  const [melItemId, setMelItemId] = useState('');
  const [defectType, setDefectType] = useState('');
  const [formChanged, setFormChanged] = useState(false);
  const [displayDefect, setDisplayDefect] = useState(null);
  const [defectDeferred, setDefectDeferred] = useState(false);
  const [deferredTime, setDeferredTime] = useState(null);
  const [deferredById, setDeferredById] = useState('');
  const [reference, setReference] = useState(null);
  const [ATAChapter, setATAChapter] = useState(null);
  const [ATASection, setATASection] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [originalDefectData, setOriginalDefectData] = useState(null);

  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  let defect = null;
  const {
    defects: { drawerDefect, defectsMap },
    aircraft: { aircraftMap },
    drawer: { mode, drawerHistory, revertChanges },
    userSettings,
    mels: { aircraftMelsMap },
  } = useSelector((state: DashboardState) => state);
  if (defectId) {
    defect = drawerDefect;
  } else {
    defect = null;
  }

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefectsMap = usePrevious(defectsMap);

  const usePreviousDefect = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevdisplayDefect = usePreviousDefect(displayDefect);

  const usePreviousDefectDrawer = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDrawerDefect = usePreviousDefectDrawer(drawerDefect);

  const usePreviousMelItemId = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevMelItemId = usePreviousMelItemId(melItemId);

  const handleSignature = (input: string): void => {
    setSignature(input);
  };

  const updateDefectData = (changes: { value: any; key: string }[]): void => {
    const newData = defectData || {};
    changes.forEach(({ value, key }) => {
      if (key === 'rectification_category') {
        if (newData.display_data) {
          newData.display_data.category = value;
        } else {
          newData.display_data = {};
          newData.display_data.category = value;
        }
      } else if (key === 'mel_item_id') {
        if (newData.mel_item) {
          newData.mel_item.id = value;
        } else {
          newData.mel_item = {};
          newData.mel_item.id = value;
        }
      } else if (key) {
        newData[`${key}`] = value;
      }
    });
    setDefectData(newData);
    setFormChanged(true);
  };

  const resetForm = (): void => {
    setSignature('');
    setRectificationCategory(null);
    setFlightId(null);
    setAircraftId(null);
    setAttachments(attachments || []);
    setMelItem(null);
    setDisplayDefect(null);
    setConfirmationChecks([]);
    setErrorMessage('');
    setRectificationId('');
    setMelItemId('');
    setDefectType('MEL');
    setFormChanged(false);
    if (document.getElementById('tfDrawerWrapper')) document.getElementById('tfDrawerWrapper').scrollTop = 0;
  };

  useEffect(() => {
    if (melItem) {
      const newConfirmations = [];
      let foundRect = melItem?.mel_rectifications[0];
      if (rectificationId) {
        foundRect = melItem?.mel_rectifications.find((rec) => rec.id === rectificationId);
      }
      if (foundRect?.remarks) {
        newConfirmations.push('remarks');
      }
      if (foundRect?.placard_procedure) {
        newConfirmations.push('placards');
      }
      if (foundRect?.operational_procedure) {
        newConfirmations.push('operations');
      }
      setConfirmationChecks(newConfirmations);
    } else if (defect?.mel_item) {
      const newConfirmations = [];
      let foundRect = defect?.mel_item?.mel_rectifications && defect?.mel_item?.mel_rectifications[0];
      if (rectificationId) {
        foundRect = defect?.mel_item?.mel_rectifications.find((rec) => rec.id === rectificationId);
      }
      if (foundRect?.remarks) {
        newConfirmations.push('remarks');
      }

      if (foundRect?.placard_procedure) {
        newConfirmations.push('placards');
      }

      if (foundRect?.operational_procedure) {
        newConfirmations.push('operations');
      }
      setConfirmationChecks(newConfirmations);
    }
  }, [defect, displayDefect, melItem, rectificationId]);

  const checkAllConfirmed = (payloadKeys, payload): boolean => {
    let allChecked = true;
    if (payload.defectTypeGroup === 'MEL' && confirmationChecks.length > 0) {
      if (confirmationChecks.includes('remarks') && !payloadKeys.includes('RemarksChecked')) {
        message.error('Remarks needs checked');
        allChecked = false;
        document.getElementById('remarksCard').scrollIntoView();
        return allChecked;
      }
      if (confirmationChecks.includes('placards') && !payloadKeys.includes('PlacardsChecked')) {
        message.error('Placards needs checked');
        allChecked = false;
        document.getElementById('placardsCard').scrollIntoView();
        return allChecked;
      }
      if (confirmationChecks.includes('operations') && !payloadKeys.includes('Operational ProceduresChecked')) {
        message.error('Operational Procedures needs checked');
        allChecked = false;
        document.getElementById('opsCard').scrollIntoView();
        return allChecked;
      }
    }
    return allChecked;
  };

  const nullOrInteger = (value: string | null | number): null | number => {
    if (value === null) {
      return null;
    }
    return value === '' ? null : Number(value);
  };

  const hoursToSeconds = (seconds: null | number): null | number => {
    const value = nullOrInteger(seconds);

    return value ? moment.duration(value, 'hours').asSeconds() : value;
  };

  const hasValidCatALimits = (recTitle, payload): boolean => {
    if (payload.defectTypeGroup === 'NEF' || payload.defectTypeGroup === 'MEL' || !defectDeferred) {
      return true;
    }
    if (recTitle !== 'A') {
      return true;
    }

    const requiredCatALimitFields = [
      'flightHoursLimit',
      'hoursLimit',
      'flightDaysLimit',
      'cyclesLimit',
      'flightLimit',
      'calendarDaysLimit',
      'APUHoursLimit',
      'otherLimit',
    ];

    const filledFields = requiredCatALimitFields.filter((field) => payload[field]);

    if (filledFields.length === 0) {
      message.error('Category A defects must have at least one none zero limit specified');
      document.getElementById('rectificationLimits').scrollIntoView();
    }

    return filledFields.length > 0;
  };

  const hasSufficientDetails = (details): boolean => {
    const isDetailsSufficient = details.length >= 5;

    if (!isDetailsSufficient) {
      const notesElement = document.getElementById('notes');

      notesElement.scrollIntoView();
      notesElement.focus();
      message.error('Please enter notes with at least five characters');
    }

    return isDetailsSufficient;
  };

  const hasDefectTypeSelected = (payload, recTitle): boolean => {
    if (!defectDeferred) return true;
    const type = !!payload?.defectTypeGroup;
    if (!type) {
      const deferralOptionsElement = document.getElementById('DeferralOptions');
      if (deferralOptionsElement) {
        deferralOptionsElement.scrollIntoView();
        message.error('Please select a defect type');
      }
    } else if (
      !recTitle &&
      (payload.defectTypeGroup === 'CDL' || payload.defectTypeGroup === 'Other' || payload.defectTypeGroup === 'CAS')
    ) {
      const deferralOptionsElement = document.getElementById('DeferralOptions');
      if (deferralOptionsElement) {
        deferralOptionsElement.scrollIntoView();
        message.error('Please select a rectification category');
      }
      return false;
    }
    return type;
  };

  const isFormValid = (payload, recTitle, payloadKeys): boolean => {
    return (
      hasSufficientDetails(payload.notes) &&
      hasDefectTypeSelected(payload, recTitle) &&
      hasValidCatALimits(recTitle, payload) &&
      checkAllConfirmed(payloadKeys, payload)
    );
  };

  const getRectificationCategory = (deferred, defType, recTitle): null | string => {
    if (!deferred || defType === 'NEF') {
      return null;
    }

    return recTitle;
  };

  const handleFormSubmit = (e): void => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const payload = {} as any;
    const payloadKeys = [];
    let recTitle = rectificationCategory?.title || defectData?.display_data?.category;
    if (rectificationCategory?.title === 'Non Deferred') {
      recTitle = 'NONDEFERRED';
    }
    if (rectificationCategory?.title === 'Advisory only') {
      recTitle = 'ADVISORYONLY';
    }
    formData.forEach((value, key) => {
      payload[key] = value;
      payloadKeys.push(key);
    });
    payload.flightHoursLimit = hoursToSeconds(payload?.flightHoursLimit);
    payload.hoursLimit = hoursToSeconds(payload?.hoursLimit);
    payload.flightDaysLimit = nullOrInteger(payload?.flightDaysLimit);
    payload.cyclesLimit = nullOrInteger(payload?.cyclesLimit);
    payload.flightLimit = nullOrInteger(payload?.flightLimit);
    payload.calendarDaysLimit = nullOrInteger(payload?.calendarDaysLimit);
    payload.APUHoursLimit = hoursToSeconds(payload?.APUHoursLimit);
    payload.otherLimit = payload?.otherLimit || null;

    const isMel = payload?.defectTypeGroup === 'MEL';
    let chosenRectification;
    if (isMel) chosenRectification = melItem?.mel_rectifications.find((item) => item.id === rectificationId);

    if (isFormValid(payload, recTitle, payloadKeys)) {
      const deferred = defectDeferred;
      const defectPayload: any = {
        body: {
          ...defectData,
          attachments_attributes: attachments,
          deferred,
          defect_type: payload?.defectTypeGroup,
          ata_chapter: parseInt(ATAChapter, 10),
          ata_section: ATASection,
          reference: reference || payload?.cdlReference,
          rectification_category: getRectificationCategory(deferred, payload?.defectTypeGroup, recTitle),
          defer_approval_reference: payload?.deferApprovalRef,
          reason_to_defer: payload?.reasonToDefer,
          flight_seconds_limit: isMel ? chosenRectification?.flight_seconds_limit : payload?.flightHoursLimit,
          seconds_limit: isMel ? chosenRectification?.seconds_limit : payload?.hoursLimit,
          flight_days_limit: isMel ? chosenRectification?.flight_days_limit : payload?.flightDaysLimit,
          cycles_limit: isMel ? chosenRectification?.cycles_limit : payload?.cyclesLimit,
          flights_limit: isMel ? chosenRectification?.flights_limit : payload?.flightLimit,
          calendar_days_limit: isMel ? chosenRectification?.calendar_days_limit : payload?.calendarDaysLimit,
          apu_seconds_limit: isMel ? chosenRectification?.apu_seconds_limit : payload?.APUHoursLimit,
          other_limit: isMel ? chosenRectification?.other_limit : payload?.otherLimit,
          limitations: payload?.limitations,
          deferred_by_id: deferredById,
          deferral_approval_number: payload?.authorisationNumber,
        },
      };
      if (deferredTime && defectDeferred) {
        defectPayload.body.deferred_at = deferredTime.toISOString();
      }
      if (!defectDeferred) {
        defectPayload.body.rectification_category = null;
        defectPayload.body.defect_type = null;
        defectPayload.body.limitations = null;
        defectPayload.body.defer_approval_reference = null;
        defectPayload.body.reason_to_defer = null;
      }

      if (mode === 'edit') {
        if (payload.hasRectificationExtension === 'Yes') {
          if (defect.rectification_interval_extension) {
            defectPayload.body.rectification_interval_extension_attributes = {
              id: defect.rectification_interval_extension.id,
              extension_date: payload.rectificationExtenstionExtendedOn,
              extension_date_due: payload.rectificationExtensionDateDue,
            };
          } else {
            defectPayload.body.rectification_interval_extension_attributes = {
              extension_date: payload.rectificationExtenstionExtendedOn,
              extension_date_due: payload.rectificationExtensionDateDue,
            };
          }
        } else if (defect.rectification_interval_extension) {
          defectPayload.body.rectification_interval_extension_attributes = {
            id: defect.rectification_interval_extension.id,
            _destroy: true,
          };
        }
      }

      if (payload?.defectTypeGroup === 'MEL' && melItemId && defectDeferred) {
        defectPayload.body.mel_item_id = melItemId;
      } else {
        defectPayload.body.mel_item_id = null;
      }
      if (payload?.defectTypeGroup === 'MEL' && rectificationId && defectDeferred) {
        defectPayload.body.mel_rectification_id = rectificationId;
      } else {
        defectPayload.body.mel_rectification_id = null;
      }
      if (defectId) {
        defectPayload.id = defectId;
        defectPayload.body.aircraft_id = aircraftId;
        defectPayload.body.edited_by_id = userSettings?.details?.people[0]?.id;
        defectPayload.body.approval_signature_attributes = { data: signature };
        if (defectDeferred) {
          defectPayload.body.deferred_by_id = deferredById;
          defectPayload.body.deferral_signature_attributes = { data: signature };
        }
        setLoading(true);
        const res = dispatch(update({ payload: defectPayload }));

        Promise.all([res]).then(() => {
          resetForm();
          dispatch(
            changeDrawerContent({
              payload: { content: <DefectDrawer defectId={defectId} /> },
            }),
          );
          setFormChanged(false);
        });
      } else {
        defectPayload.id = aircraftId;
        defectPayload.body.reported_by_id = deferredById || userSettings?.details?.people[0]?.id;
        if (defectDeferred) {
          defectPayload.body.deferred_by_id = deferredById;
          defectPayload.body.deferral_signature_attributes = { data: signature };
        }
        defectPayload.body.signature_attributes = { data: signature };
        setLoading(true);
        const res = dispatch(submitDefect({ payload: defectPayload }));

        Promise.all([res]).then((response) => {
          const newDefect = response[0][0];
          if (newDefect) {
            resetForm();
            dispatch(
              changeDrawerContent({
                payload: { content: <DefectDrawer defectId={newDefect.id} /> },
              }),
            );
            setFormChanged(false);
          } else {
            message.error('Error creating new defect');
          }
        });
      }
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  const handleFormChange = (): void => {
    setFormChanged(true);
  };

  const handleMelItemChange = (newMelId: string, rectId: string): void => {
    if (prevMelItemId !== '' && melItemId !== prevMelItemId) {
      setFormChanged(true);
    }
    setMelItemId(newMelId);
    setRectificationId(rectId);
  };

  const cancelClick = (): void => {
    if (formChanged && (mode === 'edit' || mode === 'add')) {
      dispatch(changeModalVisibility({ payload: true }));
    } else {
      if (drawerHistory) {
        dispatch(handleBackButtonClick());
      } else if (defect) {
        setErrorMessage('');
      } else {
        dispatch(changeDrawerVisibility({ payload: false }));
      }
      if (defect?.approval_signature_image_url) {
        setSignature(defect?.approval_signature_image_url);
      } else {
        setSignature('');
      }
      setFormChanged(false);
    }
  };

  useEffect(() => {
    if (!_.isEqual(defectsMap, prevDefectsMap) || !_.isEqual(prevDrawerDefect, drawerDefect)) {
      setLoading(false);
    }
  }, [defectsMap, prevDefectsMap]);

  useEffect(() => {
    if (drawerDefect) {
      setLoading(false);
    }
  }, [drawerDefect]);

  useEffect(() => {
    if (id) setAircraftId(id);
  }, [id, mode]);

  useEffect(() => {
    if (formChanged) {
      setSignature('');
      dispatch(setDrawerChanges({ payload: true }));
    }
  }, [formChanged]);

  useEffect(() => {
    if (defectId && defectId !== defect?.id) {
      const payload = { id: defectId };
      setLoading(true);
      dispatch(getDefectForDrawer(payload));
    }
  }, [defect, defectId, dispatch]);

  useEffect(() => {
    if (defect) {
      setDefectData(defect);
      setOriginalDefectData(defect);
      setFlightId(defect?.flight_id || null);
      setSignature(defect?.approval_signature_image_url);
      if (!deferDefect) setDefectDeferred(defect?.deferred);
      if (defect?.deferred_at) {
        setDeferredTime(moment.utc(defect?.deferred_at));
      } else {
        setDeferredTime(moment.utc());
      }
      setDeferredById(defect?.deferred_by_id);
    } else {
      setDefectData({
        raised_at: moment().toISOString(),
      });
      setOriginalDefectData({
        raised_at: moment().toISOString(),
      });
      setDeferredTime(moment.utc());
    }
  }, [defect]);

  useEffect(() => {
    if (passedFlightId) setFlightId(passedFlightId);
  }, [passedFlightId]);

  useEffect(() => {
    if (deferDefect) {
      setDefectDeferred(true);
    }
  }, [deferDefect]);

  useEffect(() => {
    if (defectDeferred) {
      const header = document.getElementById('DefectTitle');
      if (header) {
        header.scrollIntoView();
      }
    }
  }, [defectDeferred]);

  useEffect(() => {
    if (revertChanges) {
      resetForm();
      if (defectId) {
        const payload = { id: defectId };
        setLoading(true);
        dispatch(getDefectForDrawer(payload));
        setFormChanged(false);
      } else {
        setDefectData({
          raised_at: moment().toISOString(),
        });
        setOriginalDefectData({
          raised_at: moment().toISOString(),
        });
        setDefectDeferred(false);
        setDefectType('');
        setSignature('');
        setRectificationCategory(null);
        setFormChanged(false);
        setATAChapter(null);
        setATASection(null);
      }
      dispatch(reduxRevertChanges({ payload: false }));
      dispatch(setDrawerChanges({ payload: false }));
    }
  }, [revertChanges]);

  useEffect(() => {
    if (displayDefect && prevdisplayDefect === null && defect === null && mode !== 'add') {
      resetForm();
    }
  }, [prevdisplayDefect, defect]);

  useEffect(() => {
    if (loading) {
      document?.getElementById('tfDrawerWrapper')?.setAttribute('style', 'overflow: hidden');
    } else if (window.innerWidth > 450) {
      document?.getElementById('tfDrawerWrapper')?.setAttribute('style', 'overflow: scroll');
    }
  }, [loading]);

  useEffect(() => {
    if (!_.isEqual(defectData, originalDefectData)) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [defectData, originalDefectData]);

  const chosenAircraft = aircraftMap.get(aircraftId);

  let list;
  if (aircraftMelsMap && aircraftMelsMap.get(aircraftId || defect?.aircraft?.id)) {
    list = aircraftMelsMap.get(aircraftId || defect.aircraft.id).list;
  }

  let buttonDisabled = false;
  if (signature && !formChanged && defect) {
    buttonDisabled = true;
  } else if (!signature) {
    buttonDisabled = true;
  } else if (defectDeferred && defectType === 'MEL' && !melItemId && list?.length) {
    buttonDisabled = true;
  }

  const extendableRectificationCategories = ['B', 'C', 'D'];

  const showRectificationExtensionCard = (): boolean => {
    if (!rectificationCategory) {
      return false;
    }

    return mode === 'edit' && defectDeferred && extendableRectificationCategories.includes(rectificationCategory.title);
  };

  const rectificationExtensionCard = (): null | ReactElement => {
    if (!showRectificationExtensionCard()) {
      return null;
    }
    return (
      <Card marginTop={20}>
        <RectificationIntervalExtension defect={defect} onChange={handleFormChange} />
      </Card>
    );
  };

  return (
    <DrawerWrapper id="DefectDrawerWrapper" data-testid="DefectDrawer--DrawerWrapper">
      <Loading loading={loading} contain />
      <form onSubmit={handleFormSubmit} onChange={handleFormChange}>
        <DefectHeader
          defect={defectData}
          flightId={flightId}
          acId={aircraftId}
          setAircraftId={setAircraftId}
          updateDefectData={updateDefectData}
          setDefectDeferred={setDefectDeferred}
        />
        <DefectDetails
          defect={defectData}
          updateDefectData={updateDefectData}
          editDefect={mode === 'edit' || mode === 'add'}
          signature={signature}
          attachments={attachments}
          setAttachments={setAttachments}
          defectDeferred={defectDeferred}
          rectificationCategory={rectificationCategory}
          setRectificationCategory={setRectificationCategory}
          apuInstalled={chosenAircraft?.apu_installed}
          reference={reference}
          setReference={setReference}
          ATAChapter={ATAChapter}
          setATAChapter={setATAChapter}
          ATASection={ATASection}
          setATASection={setATASection}
          deferredTime={deferredTime}
          setDeferredTime={setDeferredTime}
        />
        {(mode === 'edit' || mode === 'add') && (
          <>
            <AddDeferralTile
              updateDefectData={updateDefectData}
              defectDeferred={defectDeferred}
              setDefectDeferred={setDefectDeferred}
            >
              {defectDeferred ? (
                <>
                  <DeferralOptions
                    defect={defectData}
                    updateDefectData={updateDefectData}
                    editDefect={mode === 'edit' || mode === 'add'}
                    rectificationCategory={rectificationCategory}
                    setRectificationCategory={setRectificationCategory}
                    aircraftId={aircraftId}
                    setMelItem={setMelItem}
                    setRectificationId={setRectificationId}
                    melItemsLoading={melItemsLoading && !loading}
                    setMelItemsLoading={setMelItemsLoading}
                    handleMelItemChange={handleMelItemChange}
                    defectType={defectType}
                    setDefectType={setDefectType}
                    displayDefect={displayDefect}
                    setDisplayDefect={setDisplayDefect}
                    apuInstalled={chosenAircraft?.apu_installed}
                    deferredTime={deferredTime}
                    defectDeferred={defectDeferred}
                    setDeferredTime={setDeferredTime}
                    reference={reference}
                    setReference={setReference}
                    ATAChapter={ATAChapter}
                    setATAChapter={setATAChapter}
                    ATASection={ATASection}
                    setATASection={setATASection}
                  />
                  <BorderLine />
                  <AircraftLimitations
                    defect={defectData}
                    editDefect={mode === 'edit' || mode === 'add'}
                    updateDefectData={updateDefectData}
                  />
                  <BorderLine marginTop="20px" />
                  <AdditionalInformation defect={defectData} updateDefectData={updateDefectData} />
                  {defectType === 'MEL' && formChanged && (
                    <ConfirmSection
                      defect={displayDefect}
                      melItem={melItem}
                      setErrorMessage={setErrorMessage}
                      rectificationId={rectificationId}
                    />
                  )}
                  <BorderLine marginTop="20px" />
                  <DeferralSignoffDetails
                    defect={defectData}
                    operatorID={chosenAircraft?.operator_id}
                    deferredById={deferredById}
                    setDeferredById={setDeferredById}
                    updateDefectData={updateDefectData}
                  />

                  {defect?.mx_events.length > 0 ? <ResolutionDetails defect={defect} /> : null}
                </>
              ) : null}
            </AddDeferralTile>
            {rectificationExtensionCard()}
            {(formChanged || !defect) && (
              <>
                <SignatureSection
                  handleSignature={handleSignature}
                  signature={signature}
                  formChanged={formChanged}
                  mode={mode}
                  revertChanges={revertChanges}
                />
              </>
            )}
            <ButtonSection handleCancel={cancelClick} loading={melItemsLoading || loading} disabled={buttonDisabled} />
          </>
        )}
      </form>
      <Message text={errorMessage} clearText={(): void => setErrorMessage('')} />
    </DrawerWrapper>
  );
};

export default DefectDrawer;
