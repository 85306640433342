import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';
import HoursMinsInput from './HoursMinsInput';

interface PreFlightCustomDataSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '0')};
`;

const StyledInput = styled.input`
  width: 110px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const SubHeading = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: 8px;
`;

const BaggageWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
  }
`;

const PreFlightCustomDataSection: React.FC<PreFlightCustomDataSectionProps> = ({ flight, updateFlightData }) => {
  const isMobile = window.innerWidth < 451;
  const { formatMessage } = useIntl();

  const [GPU, setGPU] = useState(null);
  const [malePassengers, setMalePassengers] = useState(null);
  const [femalePassengers, setFemalePassengers] = useState(null);
  const [childrenPassengers, setChildrenPassengers] = useState(null);
  const [infantPassengers, setInfantPassengers] = useState(null);
  const [baggageWeight, setBaggageWeight] = useState(null);

  const {
    drawer: { mode },
  } = useSelector((state: DashboardState) => state);

  const handleTimeChange = (time: string): void => {
    setGPU(time);
  };

  const updateGPU = (): void => {
    const stringArray = GPU.split('.');
    const hours = parseInt(stringArray[0], 10) * 3600;
    const minutes = parseInt(stringArray[1], 10) || 0;
    const adjustedMinutes = minutes * 60;
    const totalSeconds = hours + adjustedMinutes;
    updateFlightData([{ value: totalSeconds, key: 'gpu_seconds' }]);
  };

  const handlePassengerChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (type === 'male') {
      setMalePassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_male' }]);
    }
    if (type === 'female') {
      setFemalePassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_female' }]);
    }
    if (type === 'children') {
      setChildrenPassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_children' }]);
    }
    if (type === 'infant') {
      setInfantPassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_infants' }]);
    }
    if (type === 'bag') {
      setBaggageWeight(newValue);
      updateFlightData([{ value: newValue, key: 'baggage_weight' }]);
    }
  };

  useEffect(() => {
    if (flight) {
      if (flight?.gpu_seconds) {
        const h = Math.floor(flight?.gpu_seconds / 3600);
        const m = Math.floor((flight?.gpu_seconds % 3600) / 60);
        const formattedHours = `${h}.${m}`;
        setGPU(formattedHours);
      } else {
        setGPU('');
      }
      if (flight?.passengers_male) {
        setMalePassengers(flight?.passengers_male);
      } else {
        setMalePassengers('');
      }
      if (flight?.passengers_female) {
        setFemalePassengers(flight?.passengers_female);
      } else {
        setFemalePassengers('');
      }
      if (flight?.passengers_children) {
        setChildrenPassengers(flight?.passengers_children);
      } else {
        setChildrenPassengers('');
      }
      if (flight?.passengers_infants) {
        setInfantPassengers(flight?.passengers_infants);
      } else {
        setInfantPassengers('');
      }
      if (flight?.baggage_weight) {
        setBaggageWeight(flight?.baggage_weight);
      } else {
        setBaggageWeight('');
      }
    }
  }, [flight]);

  let dataItems = [];
  if (flight?.preflight_custom_data) {
    dataItems = Object.entries(flight.preflight_custom_data);
  }
  const dataElements = dataItems.map((item, index) => {
    const header = item[0].replace(/_/gi, ' ');
    return (
      <div key={item[1]} data-testid={`PreFlightCustomDataSection-CustomElement${index}`}>
        <Header>{header}</Header>
        <DisplayText>{item[1]}</DisplayText>
      </div>
    );
  });
  return (
    <>
      <SectionHeader data-testid="PreFlightCustomDataSection-Header">Preflight</SectionHeader>
      <SubHeading>Passengers</SubHeading>
      <SectionWrapper edit={mode !== 'view'} isMobile={isMobile}>
        <div>
          <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-MalePassengers">
            {formatMessage({ id: 'text.malePassengers' })}
          </Header>
          {mode === 'view' ? (
            <DisplayText data-testid="PreFlightCustomDataSection-MalePassengerCount">
              {malePassengers || '-'}
            </DisplayText>
          ) : (
            <StyledInput
              type="number"
              placeholder="-"
              value={malePassengers}
              onChange={(e): void => handlePassengerChange(e.target.value, 'male')}
            />
          )}
        </div>
        <div>
          <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-FemalePassengers">
            {formatMessage({ id: 'text.femalePassengers' })}
          </Header>
          {mode === 'view' ? (
            <DisplayText data-testid="PreFlightCustomDataSection-FemalePassengerCount">
              {femalePassengers || '-'}
            </DisplayText>
          ) : (
            <StyledInput
              type="number"
              placeholder="-"
              value={femalePassengers}
              onChange={(e): void => handlePassengerChange(e.target.value, 'female')}
            />
          )}
        </div>
        <div>
          <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-MalePassengers">
            {formatMessage({ id: 'text.childrenPassengers' })}
          </Header>
          {mode === 'view' ? (
            <DisplayText data-testid="PreFlightCustomDataSection-ChildrenPassengerCount">
              {childrenPassengers || '-'}
            </DisplayText>
          ) : (
            <StyledInput
              type="number"
              placeholder="-"
              value={childrenPassengers}
              onChange={(e): void => handlePassengerChange(e.target.value, 'children')}
            />
          )}
        </div>
        <div>
          <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-InfantPassengers">
            {formatMessage({ id: 'text.infantPassengers' })}
          </Header>
          {mode === 'view' ? (
            <DisplayText data-testid="PreFlightCustomDataSection-InfantPassengerCount">
              {infantPassengers || '-'}
            </DisplayText>
          ) : (
            <StyledInput
              type="number"
              placeholder="-"
              value={infantPassengers}
              onChange={(e): void => handlePassengerChange(e.target.value, 'infant')}
            />
          )}
        </div>
        <div>
          <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-BaggageWeight">
            {formatMessage({ id: 'text.baggageWeight' })}
          </Header>
          {mode === 'view' ? (
            <DisplayText data-testid="PreFlightCustomDataSection-BaggageWeightNumber">
              {baggageWeight || '-'}
            </DisplayText>
          ) : (
            <BaggageWrapper>
              <StyledInput
                type="number"
                placeholder="-"
                value={baggageWeight}
                onChange={(e): void => handlePassengerChange(e.target.value, 'bag')}
              />
              <span> kg</span>
            </BaggageWrapper>
          )}
        </div>
        <div>
          <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-GPUUsage">
            {formatMessage({ id: 'text.GPUUsage' })}
          </Header>
          {mode === 'view' ? (
            <DisplayText data-testid="PreFlightCustomDataSection-GPUFigure">
              <DisplayText>{GPU}</DisplayText>
            </DisplayText>
          ) : (
            <HoursMinsInput value={GPU} onChange={handleTimeChange} onBlur={updateGPU} />
          )}
        </div>
        {dataElements}
      </SectionWrapper>
    </>
  );
};

export default PreFlightCustomDataSection;
