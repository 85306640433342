import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { MELItem } from '../../../models/mels';
import remarksIcon from '../../../assets/icon-remarks.svg';
import placardsIcon from '../../../assets/icon-placards.svg';
import opIcon from '../../../assets/icon-operational-procedure.svg';
import Card from './Card';
import ConfirmationCard from './ConfirmationCard';
import Label from './Label';
import FlexWrapper from './FlexWrapper';
import StyledInput from './StyledInput';

interface ConfirmSectionProps {
  defect: Defect | null;
  melItem: MELItem;
  setErrorMessage?: (input: string) => void;
  rectificationId?: string;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({ defect, melItem, setErrorMessage, rectificationId }) => {
  const [remarks, setRemarks] = useState('');
  const [placards, setPlacards] = useState('');
  const [operationalProcedures, setOperationalProcedures] = useState('');

  const { formatMessage } = useIntl();
  const placardsExtraContent = (
    <FlexWrapper marginTop={30} column>
      <Label fontWeight={600} marginBottom={5}>
        {formatMessage({ id: 'text.placardsNumberOptional' })}
      </Label>
      <StyledInput id="placards_number" type="text" placeholder="Please enter placards number..." />
    </FlexWrapper>
  );

  useEffect(() => {
    if (defect && defect.mel_item) {
      const { mel_item } = defect;
      setRemarks(mel_item?.mel_rectifications?.find((rec) => rec.id === rectificationId)?.remarks);

      setPlacards(mel_item?.mel_rectifications?.find((rec) => rec.id === rectificationId)?.placard_procedure);

      setOperationalProcedures(
        mel_item?.mel_rectifications?.find((rec) => rec.id === rectificationId)?.operational_procedure,
      );
    }
  }, [defect, melItem, rectificationId]);

  return (
    <>
      {((remarks && remarks.length > 0) ||
        (placards && placards.length > 0) ||
        (operationalProcedures && operationalProcedures.length > 0)) && (
        <Card marginTop={30}>
          <Label fontWeight={600} identifier="ConfirmSectionTitle">
            {formatMessage({ id: 'text.confirmReviewedAndActioned' })}
          </Label>
          {remarks && !remarks.includes(null) && (
            <ConfirmationCard
              id="remarksCard"
              title="Remarks"
              text={remarks}
              icon={remarksIcon}
              setErrorMessage={setErrorMessage}
            />
          )}
          {placards && !placards.includes(null) && (
            <ConfirmationCard
              id="placardsCard"
              title="Placards"
              text={placards}
              icon={placardsIcon}
              extraContent={placardsExtraContent}
              setErrorMessage={setErrorMessage}
            />
          )}
          {operationalProcedures && !operationalProcedures.includes(null) && (
            <ConfirmationCard
              id="opsCard"
              title="Operational Procedures"
              text={operationalProcedures}
              icon={opIcon}
              setErrorMessage={setErrorMessage}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default ConfirmSection;
