import { ReactNode } from 'react';
import { DefectsActionTypes } from '../defects';

enum DrawerActionTypes {
  SET_DRAWER_VISIBILITY = 'drawer/setDrawerVisibility',
  SET_DRAWER_CONTENT = 'drawer/setDrawerContent',
  SET_DRAWER_MODE = 'drawer/setDrawerMode',
  HANDLE_BACK_BUTTON = 'drawer/handleBackButton',
  UPDATE_CONTENT = 'drawer/updateContent',
  SET_DRAWER_ID = 'drawer/setDrawerId',
  SET_DRAWER_CHANGES = 'drawer/setDrawerChanges',
  REVERT_DRAWER_CHANGES = 'drawer/revertDrawerChanges',
  SET_MODAL_VISIBILITY = 'drawer/setModalVisibility',
  REVERT_CHANGES = 'drawer/revertChanges',
}

interface SetDrawerVisibilityActionType {
  type: DrawerActionTypes.SET_DRAWER_VISIBILITY;
  payload: boolean;
}

interface SetDrawerContentActionType {
  type: DrawerActionTypes.SET_DRAWER_CONTENT;
  payload: { content: ReactNode; backButton: boolean };
}

interface SetDrawerModeActionType {
  type: DrawerActionTypes.SET_DRAWER_MODE;
  payload: string;
}

interface HandleBackButtonActionType {
  type: DrawerActionTypes.HANDLE_BACK_BUTTON;
}

interface UpdateContentActionType {
  type: DrawerActionTypes.UPDATE_CONTENT;
  payload: boolean;
}

interface SetDrawerIdActionType {
  type: DrawerActionTypes.SET_DRAWER_ID;
  payload: string;
}

interface SetDrawerChangesActionType {
  type: DrawerActionTypes.SET_DRAWER_CHANGES;
  payload: boolean;
}

interface SetModalChangesActionType {
  type: DrawerActionTypes.SET_MODAL_VISIBILITY;
  payload: boolean;
}

interface RevertDrawerChangesActionType {
  type: DrawerActionTypes.REVERT_DRAWER_CHANGES;
}

interface RevertChangesActionType {
  type: DrawerActionTypes.REVERT_CHANGES;
  payload: boolean;
}

type DrawerAction =
  | SetDrawerContentActionType
  | SetDrawerVisibilityActionType
  | SetDrawerModeActionType
  | UpdateContentActionType
  | SetDrawerIdActionType
  | SetDrawerChangesActionType
  | SetModalChangesActionType
  | RevertDrawerChangesActionType
  | RevertChangesActionType
  | HandleBackButtonActionType;

export const initialState: DrawerState = {
  visible: false,
  drawerContent: null,
  mode: 'view',
  drawerHistory: [],
  previousDrawerStates: [],
  updateContent: false,
  drawerId: '',
  drawerChanges: false,
  changeModalVisible: false,
  drawerOriginalContent: null,
  revertChanges: null,
};

export const changeDrawerVisibility = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.SET_DRAWER_VISIBILITY,
    payload,
  });
  dispatch({
    type: DefectsActionTypes.SAVE_DEFECTS_DRAWER,
    payload: [null],
  });
};

export const changeDrawerContent = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.SET_DRAWER_CONTENT,
    payload,
  });
};

export const changeDrawerMode = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.SET_DRAWER_MODE,
    payload,
  });
};

export const handleBackButtonClick = () => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.HANDLE_BACK_BUTTON,
  });
};

export const updateDrawerContent = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.UPDATE_CONTENT,
    payload,
  });
};

export const setDrawerId = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.SET_DRAWER_ID,
    payload,
  });
};

export const setDrawerChanges = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.SET_DRAWER_CHANGES,
    payload,
  });
};

export const changeModalVisibility = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.SET_MODAL_VISIBILITY,
    payload,
  });
};

export const revertDrawerChanges = () => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.REVERT_DRAWER_CHANGES,
  });
};

export const revertChanges = ({ payload }: { payload: any }) => (dispatch: (args: any) => any): void => {
  dispatch({
    type: DrawerActionTypes.REVERT_CHANGES,
    payload,
  });
};

export default function(state: DrawerState = initialState, action: DrawerAction): DrawerState {
  switch (action.type) {
    case DrawerActionTypes.SET_DRAWER_VISIBILITY:
      return {
        ...state,
        visible: action.payload,
        drawerContent: null,
        drawerHistory: [],
        updateContent: false,
        drawerId: '',
        drawerChanges: false,
        revertChanges: null,
      };
    case DrawerActionTypes.SET_DRAWER_CONTENT: {
      const newArray = state.drawerHistory;
      const drawerStateArray = state.previousDrawerStates;
      if (action.payload.backButton) {
        newArray.push(state.drawerContent);
        drawerStateArray.push(state.mode);
      }
      return {
        ...state,
        drawerContent: action.payload.content,
        drawerHistory: newArray,
        drawerChanges: false,
        drawerOriginalContent: action.payload.content,
      };
    }
    case DrawerActionTypes.SET_DRAWER_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case DrawerActionTypes.HANDLE_BACK_BUTTON: {
      const history = state.drawerHistory;
      const newContent = history.pop();
      const drawerState = state.previousDrawerStates;
      const prevState = drawerState.pop();
      return {
        ...state,
        drawerContent: newContent,
        drawerHistory: history,
        mode: prevState,
        drawerChanges: false,
        drawerOriginalContent: newContent,
      };
    }
    case DrawerActionTypes.UPDATE_CONTENT: {
      return {
        ...state,
        updateContent: action.payload,
      };
    }
    case DrawerActionTypes.SET_DRAWER_ID: {
      return {
        ...state,
        drawerId: action.payload,
      };
    }
    case DrawerActionTypes.SET_DRAWER_CHANGES: {
      return {
        ...state,
        drawerChanges: action.payload,
      };
    }
    case DrawerActionTypes.SET_MODAL_VISIBILITY: {
      return {
        ...state,
        changeModalVisible: action.payload,
      };
    }
    case DrawerActionTypes.REVERT_DRAWER_CHANGES: {
      return {
        ...state,
        drawerContent: state.drawerOriginalContent,
        changeModalVisible: false,
        drawerChanges: false,
        revertChanges: true,
      };
    }
    case DrawerActionTypes.REVERT_CHANGES: {
      return {
        ...state,
        revertChanges: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface DrawerState {
  visible: boolean;
  drawerContent: ReactNode;
  mode: string;
  drawerHistory: ReactNode[];
  previousDrawerStates: string[];
  updateContent: boolean;
  drawerId: string;
  drawerChanges: boolean;
  drawerOriginalContent: ReactNode;
  changeModalVisible: boolean;
  revertChanges: boolean;
}
