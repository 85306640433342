import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Select, Row, Col, message } from 'antd';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Modal, Button as TFButton } from '@arcflight/tf-component-library';
import { injectIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import Loading from '../../TFLoading';
import { ButtonSize } from '../../PaginatedDefectsTable/DefectTableHeader';
import disableAutoCorrect from '../../../utils/disableAutoCorrect';
import { addMxItemSkipAPI, updateMxItemSkipAPI } from '../../../models/maintenance/actions';
import { fetchAircraftStatus } from '../../../models/aircraft/actions';
import { addMaintenance, updateMaintenance } from '../../../services/apiNew';
import globalStyles from '../../../utils/globalStyles.module.less';
import MXModalSCHContents from './MXModalSCHContents';
import MXModalOOPContents from './MXModalOOPContents';
import MXModalLLPContents from './MXModalLLPContents';
import styles from './MXModal.module.less';
import bigIcon from './assets/maintenance-item.svg';
import cancelIcon from './assets/cancel.svg';

const { Option } = Select;
const cookies = new Cookies();

class MXModal extends Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    item: PropTypes.object,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    aircraft: PropTypes.object,
    userSettings: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  };

  static defaultProps = {
    item: null,
    aircraft: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      mxType: 'scheduled',
      submitting: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { item } = this.props;
    if (item && this.props.visible !== prevProps.visible) {
      this.getItemDetails(item);
    }
    disableAutoCorrect();
  };

  getItemDetails = (item) => {
    const { mxType } = this.state;
    const newMXType = this.getItemMXType(item);
    if (mxType !== newMXType) {
      this.setState({
        mxType: newMXType,
      });
    }
  };

  getItemMXType = (item) => {
    const { mx_type } = item;
    return mx_type.substring(mx_type.lastIndexOf('_') + 1, mx_type.length);
  };

  getItemArea = (item) => {
    if (item) {
      const { mx_type } = item;
      return mx_type.substring(0, mx_type.lastIndexOf('_'));
    }
    return undefined;
  };

  changeMXType = (value) => {
    this.setState({
      mxType: value,
    });
    this.resetEmptyFields();
  };

  resetEmptyFields = () => {
    const { form } = this.props;
    const requiredFieldsArr = ['area', 'name', 'ata_number', 'part_number', 'part_serial'];
    const unTouchedFieldsArr = [];
    requiredFieldsArr.forEach((field) => {
      if (!form.isFieldTouched(field)) {
        unTouchedFieldsArr.push(field);
      }
      if (form.isFieldTouched(field) && !form.getFieldValue(field)) {
        unTouchedFieldsArr.push(field);
      }
    });
    if (unTouchedFieldsArr.length > 0) {
      form.resetFields(unTouchedFieldsArr);
    }
  };

  hoursAndMinsToSeconds = (input) => {
    const array = input.split(':');
    return parseInt(array[0], 10) * 3600 + parseInt(array[1], 10) * 60;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form,
      aircraft,
      item,
      dispatch,
      intl: { formatMessage },
    } = this.props;
    const { mxType } = this.state;
    const id = item ? item.id : '';

    form.validateFieldsAndScroll(async (err, fieldsValue) => {
      const fieldsVal = fieldsValue;
      if (err) return;

      if (!fieldsVal.flight_seconds_due && !fieldsVal.cycles_due && !fieldsVal.date_due && !fieldsVal.apu_seconds_due) {
        message.error(formatMessage({ id: 'message.fillInDueValue' }));
        return;
      }

      if (fieldsVal.area) {
        // construct mx_type based on state & area
        fieldsVal.mx_type = `${fieldsVal.area}_${mxType}`;
      }

      if (fieldsVal.unit_of_time === undefined) fieldsVal.unit_of_time = 'days';

      if (fieldsVal.unit_of_time === 'days') {
        if (fieldsVal.months_threshold) {
          fieldsVal.days_threshold = fieldsVal.months_threshold;
          delete fieldsVal.months_threshold;
        } else {
          fieldsVal.days_threshold = null;
          delete fieldsVal.months_threshold;
        }
        if (fieldsVal.months_repeat) {
          fieldsVal.days_repeat = fieldsVal.months_repeat;
          delete fieldsVal.months_repeat;
        } else {
          fieldsVal.days_repeat = null;
          delete fieldsVal.months_repeat;
        }
        if (fieldsVal.months_tolerance) {
          fieldsVal.days_tolerance = fieldsVal.months_tolerance;
          delete fieldsVal.months_tolerance;
        } else {
          fieldsVal.days_tolerance = null;
          delete fieldsVal.months_tolerance;
        }
      }

      if (fieldsVal.flight_seconds_due && typeof fieldsVal.flight_seconds_due === 'number') {
        fieldsVal.flight_seconds_due *= 3600;
      } else if (fieldsVal.flight_seconds_due && typeof fieldsVal.flight_seconds_due === 'string') {
        fieldsVal.flight_seconds_due = this.hoursAndMinsToSeconds(fieldsVal.flight_seconds_due);
      }
      if (fieldsVal.flight_seconds_repeat && typeof fieldsVal.flight_seconds_repeat === 'number') {
        fieldsVal.flight_seconds_repeat *= 3600;
      } else if (fieldsVal.flight_seconds_repeat && typeof fieldsVal.flight_seconds_repeat === 'string') {
        fieldsVal.flight_seconds_repeat = this.hoursAndMinsToSeconds(fieldsVal.flight_seconds_repeat);
      }
      if (fieldsVal.flight_seconds_threshold && typeof fieldsVal.flight_seconds_threshold === 'number') {
        fieldsVal.flight_seconds_threshold *= 3600;
      } else if (fieldsVal.flight_seconds_threshold && typeof fieldsVal.flight_seconds_threshold === 'string') {
        fieldsVal.flight_seconds_threshold = this.hoursAndMinsToSeconds(fieldsVal.flight_seconds_threshold);
      }
      if (fieldsVal.flight_seconds_tolerance && typeof fieldsVal.flight_seconds_tolerance === 'number') {
        fieldsVal.flight_seconds_tolerance *= 3600;
      } else if (fieldsVal.flight_seconds_tolerance && typeof fieldsVal.flight_seconds_tolerance === 'string') {
        fieldsVal.flight_seconds_tolerance = this.hoursAndMinsToSeconds(fieldsVal.flight_seconds_tolerance);
      }
      if (fieldsVal.apu_seconds_due && typeof fieldsVal.apu_seconds_due === 'number') {
        fieldsVal.apu_seconds_due *= 3600;
      } else if (fieldsVal.apu_seconds_due && typeof fieldsVal.apu_seconds_due === 'string') {
        fieldsVal.apu_seconds_due = this.hoursAndMinsToSeconds(fieldsVal.apu_seconds_due);
      }
      if (fieldsVal.apu_seconds_repeat && typeof fieldsVal.apu_seconds_repeat === 'number') {
        fieldsVal.apu_seconds_repeat *= 3600;
      } else if (fieldsVal.apu_seconds_repeat && typeof fieldsVal.apu_seconds_repeat === 'string') {
        fieldsVal.apu_seconds_repeat = this.hoursAndMinsToSeconds(fieldsVal.apu_seconds_repeat);
      }
      if (fieldsVal.apu_seconds_threshold && typeof fieldsVal.apu_seconds_threshold === 'number') {
        fieldsVal.apu_seconds_threshold *= 3600;
      } else if (fieldsVal.apu_seconds_threshold && typeof fieldsVal.apu_seconds_threshold === 'string') {
        fieldsVal.apu_seconds_threshold = this.hoursAndMinsToSeconds(fieldsVal.apu_seconds_threshold);
      }
      if (fieldsVal.apu_seconds_tolerance && typeof fieldsVal.apu_seconds_tolerance === 'number') {
        fieldsVal.apu_seconds_tolerance *= 3600;
      } else if (fieldsVal.apu_seconds_tolerance && typeof fieldsVal.apu_seconds_tolerance === 'string') {
        fieldsVal.apu_seconds_tolerance = this.hoursAndMinsToSeconds(fieldsVal.apu_seconds_tolerance);
      }
      if (fieldsVal.date_due) {
        fieldsVal.date_due = moment.utc(moment(fieldsVal.date_due).format('YYYY-MM-DD'));
      }
      if (fieldsVal.months_tolerance || fieldsVal.months_repeat) {
        fieldsVal.days_tolerance = null;
        fieldsVal.days_repeat = null;
      }
      if (fieldsVal.days_tolerance || fieldsVal.days_repeat) {
        fieldsVal.months_tolerance = null;
        fieldsVal.months_repeat = null;
      }
      if (fieldsVal.months_tolerance || fieldsVal.months_repeat) {
        fieldsVal.days_tolerance = null;
        fieldsVal.days_repeat = null;
      }
      const payload = {
        id,
        aircraft: aircraft.id,
        fieldsValue,
        aircraft_id: aircraft.id,
        organisation_id: cookies.get('org'),
      };

      this.setState({ submitting: true }, async () => {
        if (id) {
          // Update current item
          try {
            const response = await updateMaintenance(payload);
            if (response && response.status >= 200 && response.status < 400) {
              message.success(formatMessage({ id: 'message.mxItemUpdated' }, { name: response.data.name }));
              this.props.onSuccess(response.data.estimated_date, response.data);
              this.props.hideModal();
              dispatch(fetchAircraftStatus({ payload: aircraft.id }));
              dispatch(updateMxItemSkipAPI({ payload: response.data }));
              this.setState({ submitting: false });
            }
          } catch (error) {
            message.error(formatMessage({ id: 'message.failedToUpdateMXItem' }));
            this.setState({ submitting: false });
            return null;
          }
        } else {
          // Add new Item
          try {
            const response = await addMaintenance(payload);
            if (response && response.status > 200 && response.status < 400) {
              message.success(formatMessage({ id: 'message.mxItemCreated' }, { name: response.data.name }));
              this.props.onSuccess(response.data.estimated_date, response.data);
              this.props.hideModal();
              dispatch(fetchAircraftStatus({ payload: aircraft.id }));
              dispatch(addMxItemSkipAPI({ payload: response.data }));
              this.setState({ submitting: false });
            }
          } catch (error) {
            message.error(formatMessage({ id: 'message.failedToUpdateMXItem' }));
            this.setState({ submitting: false });
            return null;
          }
        }
        return null;
      });
    });
  };

  generateAreaOptions = () => {
    const { aircraft } = this.props;
    if (aircraft) {
      const optionValues = ['airframe'];
      const engines = aircraft.aircraft_type.engine_count;

      for (let i = 0; i < engines; i += 1) {
        optionValues.push(`engine_${i + 1}`);
      }

      if (aircraft.aircraft_type.engine_type === 'piston' || aircraft.aircraft_type.engine_type === 'turboprop') {
        for (let j = 0; j < engines; j += 1) {
          optionValues.push(`prop_${j + 1}`);
        }
      }

      if (aircraft.apu_installed) {
        optionValues.push('apu');
      }
      return optionValues.map((value) => {
        return (
          <Option key={value} value={value}>
            {value === 'apu' ? 'APU' : <span className={styles.areaOption}>{value.replace('_', ' ')}</span>}
          </Option>
        );
      });
    }
    return null;
  };

  render() {
    const {
      form: { getFieldDecorator },
      form,
      visible,
      aircraft,
      userSettings,
      item,
      intl: { formatMessage },
      hideModal,
    } = this.props;
    const { mxType, submitting } = this.state;
    return (
      <Modal
        handleClose={hideModal}
        isOpen={visible}
        className={styles.modal}
        footer={null}
        width={900}
        closable
        closeIcon={<img src={cancelIcon} alt="cancelIcon" data-test="cancelIcon" className={styles.cancelIcon} />}
        destroyOnClose
        wrapClassName={styles.mxModalWrapper}
      >
        <Loading loading={submitting} contain />
        <Row gutter={16} className={styles.modalParentRow}>
          <Col md={8} className={styles.iconWrapper}>
            <img src={bigIcon} alt="sharingBigIcon" className={styles.bigIcon} data-test="bigIcon" />
          </Col>
          <Col lg={14} md={16}>
            <div className={styles.modalTitle} data-test="title">
              {item ? formatMessage({ id: 'title.updateMXItem' }) : formatMessage({ id: 'title.addMXItem' })}
            </div>
            <div className={styles.modalContent}>
              <Form hideRequiredMark onSubmit={this.handleSubmit} data-test="mxForm">
                <Form.Item label="Item Type" className={globalStyles.formItem} data-test="formItemItemType">
                  <Row gutter={16}>
                    <Col>
                      {item && item.package ? (
                        <Button
                          className={`${globalStyles.buttonRadioGroup}
                            ${globalStyles.buttonRadioGroupActive} ${styles.buttonRadioFull}`}
                          data-test="buttonPackage"
                        >
                          {formatMessage({ id: 'text.package' })}
                        </Button>
                      ) : (
                        <>
                          <Button
                            className={`${globalStyles.buttonRadioGroup} ${mxType === 'scheduled' &&
                              globalStyles.buttonRadioGroupActive} ${styles.buttonRadioFull}`}
                            onClick={() => this.changeMXType('scheduled')}
                            data-test="buttonScheduled"
                          >
                            {formatMessage({ id: 'tab.scheduled' })}
                          </Button>
                          <Button
                            className={`${globalStyles.buttonRadioGroup} ${mxType === 'oop' &&
                              globalStyles.buttonRadioGroupActive} ${styles.buttonRadioFull}`}
                            onClick={() => this.changeMXType('oop')}
                            data-test="buttonOOP"
                          >
                            {formatMessage({ id: 'tab.outOfPhase' })}
                          </Button>
                          <Button
                            className={`${globalStyles.buttonRadioGroup} ${mxType === 'llp' &&
                              globalStyles.buttonRadioGroupActive} ${styles.buttonRadioFull}`}
                            onClick={() => this.changeMXType('llp')}
                            data-test="buttonLLP"
                          >
                            {formatMessage({ id: 'title.lifeLimitedParts' })}
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.area' })}
                  className={globalStyles.formItem}
                  data-test="formItemArea"
                >
                  {getFieldDecorator('area', {
                    rules: [{ required: true, message: formatMessage({ id: 'form.placeholder.selectArea' }) }],
                    initialValue: this.getItemArea(item),
                  })(
                    <Select
                      placeholder={formatMessage({ id: 'form.placeholder.selectArea' })}
                      className={`${globalStyles.formSelect} ${styles.selectFull}`}
                      data-test="formSelectArea"
                    >
                      {this.generateAreaOptions()}
                    </Select>,
                  )}
                </Form.Item>
                {mxType === 'scheduled' && (
                  <MXModalSCHContents form={form} item={item} aircraft={aircraft} userSettings={userSettings} />
                )}
                {mxType === 'oop' && (
                  <MXModalOOPContents form={form} item={item} aircraft={aircraft} userSettings={userSettings} />
                )}
                {mxType === 'llp' && (
                  <MXModalLLPContents form={form} item={item} aircraft={aircraft} userSettings={userSettings} />
                )}
                <Row className={styles.rowSpaceTop}>
                  <Col md={24} className={styles.centerCol} data-test="submitButton">
                    <TFButton
                      size={ButtonSize.MEDIUM}
                      onClick={this.handleSubmit}
                      className={`${globalStyles.standardButton}
                        ${globalStyles.standardButtonWide}
                        ${globalStyles.standardButtonRounded}`}
                      data-test="submitButton"
                      loading={submitting}
                    >
                      {item
                        ? formatMessage({ id: 'form.button.updateItem' })
                        : formatMessage({ id: 'form.button.saveComplete' })}
                    </TFButton>
                    <div className={styles.cancelWrapper}>
                      <TFButton size={ButtonSize.MEDIUM} onClick={hideModal} primary={false}>
                        Cancel
                      </TFButton>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ userSettings }) => ({ userSettings })),
  Form.create(),
)(MXModal);
