import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formatTime } from '../../utils/utils';
import styles from './AircraftTotals.module.less';

const AircraftTotals = ({ aircraft, userSettings, intl: { formatMessage } }) => {
  const { totals } = aircraft;
  const totalsArray = Object.entries(totals).reduce((array, item) => {
    if (item[0] === 'apu' && aircraft.apu_installed) {
      const arrayObject = {
        title: item[0],
        cycles: item[1].cycles,
        hours: item[1].seconds,
        due: item[1].next_due,
      };
      array.push(arrayObject);
    } else if (item[0] !== 'apu') {
      const arrayObject = {
        title: item[0],
        cycles: item[1].cycles,
        hours: item[1].seconds,
        due: item[1].next_due,
      };
      array.push(arrayObject);
    }
    return array;
  }, []);

  const arrayOrder = {
    airframe: 0,
    engine_1: 1,
    engine_2: 2,
    engine_3: 3,
    engine_4: 4,
    apu: 5,
    prop_1: 6,
    prop_2: 7,
    prop_3: 8,
    prop_4: 9,
  };

  const compare = (a, b) => {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  };

  totalsArray.sort((a, b) => {
    const indexResult = compare(arrayOrder[a.title], arrayOrder[b.title]);
    if (indexResult === 0) {
      return compare(a.title, b.title);
    }
    return indexResult;
  });

  const gridRow = (rowObject, index) => {
    const secondsToHours = rowObject.hours || null;

    const formatTitle = (title) => {
      if (title.includes('airframe')) return 'Aircraft';
      if (title.includes('engine')) {
        const number = title.split('_')[1];
        return `Engine ${number}`;
      }
      if (aircraft.apu_installed && title.includes('apu')) return 'APU';
      if (title.includes('prop')) {
        const number = title.split('_')[1];
        return `Propellor ${number}`;
      }
      return null;
    };

    const formatDate = (date) => moment(date).format(userSettings.dateFormat);

    return (
      <>
        <div data-testid={`AircraftTotals--Title${index + 1}`} className={styles.rowTitleWrapper}>
          <div className={styles.rowTitle}>{formatTitle(rowObject.title)}</div>
        </div>
        <div className={styles.rowBackground} style={{ gridRow: `${index + 2}` }} />
        <div
          className={styles.cyclesColumn}
          style={{ gridRow: `${index + 2}` }}
          data-testid={`AircraftTotals--Cycles${index + 1}`}
        >
          {rowObject.cycles || formatMessage({ id: 'text.unknown' })}
        </div>
        <div
          className={styles.hoursColumn}
          style={{ gridRow: `${index + 2}` }}
          data-testid={`AircraftTotals--Hours${index + 1}`}
        >
          {secondsToHours ? formatTime(secondsToHours) : formatMessage({ id: 'text.unknown' })}
        </div>
        <div
          className={styles.dueColumn}
          style={{ gridRow: `${index + 2}` }}
          data-testid={`AircraftTotals--EstDue${index + 1}`}
        >
          {(rowObject.due && formatDate(rowObject.due)) || formatMessage({ id: 'text.unknown' })}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={styles.gridWrapper} style={{ gridTemplateRows: `repeat(${totalsArray.length + 1}, 34px)` }}>
        <div />
        <div className={styles.columnLine} style={{ gridRowEnd: `${totalsArray.length + 3}`, gridColumn: 2 }} />
        <div className={styles.columnLine} style={{ gridRowEnd: `${totalsArray.length + 3}`, gridColumn: 3 }} />
        <div className={styles.columnLine} style={{ gridRowEnd: `${totalsArray.length + 3}`, gridColumn: 4 }} />
        <div className={styles.columnHeaderCycles} data-testid="AircraftTotals--CyclesHeader">
          {formatMessage({ id: 'title.cyclesCaps' })}
        </div>
        <div className={styles.columnHeaderHours} data-testid="AircraftTotals--HoursHeader">
          {formatMessage({ id: 'title.hoursCaps' })}
        </div>
        <div className={styles.columnHeaderDue} data-testid="AircraftTotals--EstDueHeader">
          {formatMessage({ id: 'title.estDueCaps' })}
        </div>
        {totalsArray.map((object, index) => gridRow(object, index))}
      </div>
    </div>
  );
};

AircraftTotals.propTypes = {
  aircraft: PropTypes.object.isRequired,
  userSettings: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  connect(({ userSettings }) => ({ userSettings })),
)(AircraftTotals);
