import moment from 'moment';

const createFlightOptions = (flights): [] => {
  const titleArray = flights.map((flight) => {
    return {
      title: `${flight.departure_airport}${
        flight.time_takeoff !== null
          ? `(${moment(flight.time_takeoff)
              .utc()
              .format('HH:mm')})`
          : ''
      }-${flight.arrival_airport}${
        flight.time_landing !== null
          ? `(${moment(flight.time_landing)
              .utc()
              .format('HH:mm')})`
          : ''
      } ${flight?.trip?.date}`,
      value: flight.id,
    };
  });

  titleArray.unshift({ title: 'None', value: null });

  return titleArray;
};

export default createFlightOptions;
