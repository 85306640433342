import React, { PureComponent } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Form, InputNumber, Popover } from 'antd';
import { Button as TFButton } from '@arcflight/tf-component-library';
import { injectIntl } from 'react-intl';
import { getTripUpdate, updateTripUpdate } from '../../services/api';
import { updateEntry } from '../../models/trips/actions';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import TimeInput from '../Inputs/TimeInput/TimeInput';
import Loading from '../TFLoading';
import FuelSection from './FuelSection';
import aircraftIcon from './assets/aircraft.png';
import apuIcone from './assets/apu.png';
import engineIcon from './assets/engine.png';
import fuelIcon from './assets/fuel.png';
import propellorIcon from './assets/propellor.png';
import styles from './EditTripDrawer.module.less';
import CommentsSection from './CommentsSection';

class EditTripDrawer extends PureComponent {
  static propTypes = {
    trip: PropTypes.object,
    editable: PropTypes.bool,
    override: PropTypes.bool,
    handleEditTripClick: PropTypes.func.isRequired,
    handleOverrideClick: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
    aircraft: PropTypes.object,
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    tripNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tripDate: PropTypes.string.isRequired,
    tripSrpNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  static defaultProps = {
    trip: {},
    editable: false,
    override: false,
    isMobile: false,
    aircraft: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      tripData: {},
      engine1Visible: false,
      engine2Visible: false,
      engine3Visible: false,
      engine4Visible: false,
      APUVisible: false,
      prop1Visible: false,
      prop2Visible: false,
      prop3Visible: false,
      prop4Visible: false,
      loading: false,
      disableFuelUplift: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { trip } = this.props;
    if (trip !== prevProps.trip) {
      this.getData();
    }
  }

  checkAircraftLayout = (data) => {
    const { aircraft } = this.props;
    if (data) {
      const dataKeys = Object.keys(data.utilisation);
      if (dataKeys.includes('engine_1_seconds')) this.setState({ engine1Visible: true });
      if (dataKeys.includes('engine_2_seconds')) this.setState({ engine2Visible: true });
      if (dataKeys.includes('engine_3_seconds')) this.setState({ engine3Visible: true });
      if (dataKeys.includes('engine_4_seconds')) this.setState({ engine4Visible: true });
      if (dataKeys.includes('apu_seconds') && aircraft.apu_installed) this.setState({ APUVisible: true });
      if (dataKeys.includes('prop_1_seconds')) this.setState({ prop1Visible: true });
      if (dataKeys.includes('prop_2_seconds')) this.setState({ prop2Visible: true });
      if (dataKeys.includes('prop_3_seconds')) this.setState({ prop3Visible: true });
      if (dataKeys.includes('prop_4_seconds')) this.setState({ prop4Visible: true });
    }
  };

  getData = async () => {
    const { trip } = this.props;
    await new Promise((resolve) => this.setState({ loading: true }, resolve));
    if (trip) {
      const data = await getTripUpdate(trip.id);
      await new Promise((resolve) => this.setState({ tripData: data }, resolve));
      this.checkAircraftLayout(data);
    }
    await new Promise((resolve) => this.setState({ loading: false }, resolve));
  };

  convertTime = (input) => {
    if (input === 0) return 0;
    const hours = Math.floor(input / 3600);
    const mins = Math.floor(input / 60) % 60;
    return `${hours}:${mins < 10 && mins > -1 ? `0${mins}` : mins}`;
  };

  handleFuelUpliftAdd = async () => {
    const { tripData } = this.state;
    const { form } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        const newTripData = values;
        values.fuel_logs.forEach((log, index) => {
          newTripData.fuel_logs[index].fuel_tank_id = tripData.fuel_logs[index].fuel_tank_id;
          newTripData.fuel_logs[index].value = log.carried_forwards;
          if (values.fuel_logs[index].fuel_uplifts) {
            values.fuel_logs[index].fuel_uplifts.forEach((item, upliftIndex) => {
              newTripData.fuel_logs[index].fuel_uplifts[upliftIndex].aircraft_actual_uplift = parseInt(
                values.fuel_logs[index].fuel_uplifts[upliftIndex].aircraft_actual_uplift,
                10,
              );
            });
          }
        });
        const payload = { body: { newTripData }, tripId: tripData.id };
        await new Promise((resolve) => this.setState({ loading: true }, resolve));
        await updateTripUpdate({ payload });
        this.getData();
      }
    });
  };

  handleOverrideSaveClick = () => {
    const { form, handleOverrideClick } = this.props;
    const { tripData } = this.state;
    form.validateFields(async (err, values) => {
      if (!err) {
        const newTripData = {};
        newTripData.carried_forwards_override = values.carried_forwards;
        const payload = { body: { newTripData }, tripId: tripData.id };
        await new Promise((resolve) => this.setState({ loading: true }, resolve));
        await updateTripUpdate({ payload });
        this.getData();
      }
    });
    handleOverrideClick(false);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, handleEditTripClick, tripNumber, tripDate, tripSrpNumber, aircraft } = this.props;
    const { tripData } = this.state;
    form.validateFields(async (err, values) => {
      if (!err) {
        const newTripData = values;
        if (aircraft && !aircraft?.apu_installed) {
          if (newTripData?.utilisation?.apu_seconds) delete newTripData?.utilisation?.apu_seconds;
          if (newTripData?.utilisation?.apu_cycles) delete newTripData?.utilisation?.apu_cycles;
        }
        if (tripDate) newTripData.date = tripDate;
        if (tripNumber) newTripData.number = tripNumber;
        if (tripSrpNumber) newTripData.srp_number = tripSrpNumber;
        if (values.fuel_logs) {
          values.fuel_logs.forEach((log, index) => {
            newTripData.fuel_logs[index].fuel_tank_id = tripData.fuel_logs[index].fuel_tank_id;
            newTripData.fuel_logs[index].value = log.carried_forwards;
            newTripData.fuel_logs[index].id = tripData.fuel_logs[index].id;
            if (values.fuel_logs[index].fuel_uplifts) {
              values.fuel_logs[index].fuel_uplifts.forEach((item, upliftIndex) => {
                if (
                  newTripData.fuel_logs[index].fuel_uplifts[upliftIndex] &&
                  tripData.fuel_logs[index].fuel_uplifts[upliftIndex]
                )
                  newTripData.fuel_logs[index].fuel_uplifts[upliftIndex].id =
                    tripData.fuel_logs[index].fuel_uplifts[upliftIndex].id;
                newTripData.fuel_logs[index].fuel_uplifts[upliftIndex].aircraft_actual_uplift = parseInt(
                  values.fuel_logs[index].fuel_uplifts[upliftIndex].aircraft_actual_uplift,
                  10,
                );
              });
            }
          });
        }
        if (values.oil_logs) {
          values.oil_logs.forEach((log, index) => {
            newTripData.oil_logs[index].fluid_destination = tripData.oil_logs[index].fluid_destination;
            newTripData.oil_logs[index].fluid_destination_number = tripData.oil_logs[index].fluid_destination_number;
            newTripData.oil_logs[index].id = tripData.oil_logs[index].id;
          });
        }
        if (values.hydraulic_fluid_logs) {
          values.hydraulic_fluid_logs.forEach((log, index) => {
            newTripData.hydraulic_fluid_logs[index].hydraulic_circuit_id =
              tripData.hydraulic_fluid_logs[index].hydraulic_circuit_id;
            newTripData.hydraulic_fluid_logs[index].id = tripData.hydraulic_fluid_logs[index].id;
          });
        }
        await new Promise((resolve) => this.setState({ loading: true }, resolve));
        await this.props.dispatch(
          updateEntry({
            payload: {
              id: tripData.id,
              trip: {
                number: tripNumber,
                srp_number: tripSrpNumber,
                date: moment(tripDate).format('YYYY-MM-DD'),
                ...newTripData,
              },
            },
          }),
        );
        handleEditTripClick(false);
        this.setState({ disableFuelUplift: true });
        this.getData();
        document.getElementById('drawerHeader').scrollIntoView();
      }
    });
  };

  handleCancelClick = () => {
    const { handleEditTripClick } = this.props;
    handleEditTripClick(false);
    this.setState({ disableFuelUplift: true });
  };

  onMainPageCancelClick = () => {
    this.setState({ disableFuelUplift: false });
  };

  warningMessage = (key, initial) => {
    const { form } = this.props;
    let message = null;
    if (form.getFieldValue(key) === '') return <span className={styles.warningMessage}>{message}</span>;
    if (
      ((key.includes('cycles') || key.includes('landings')) &&
        form.getFieldValue(key) !== null &&
        Math.abs(form.getFieldValue(key) - initial) > 12) ||
      (key.includes('seconds') &&
        form.getFieldValue(key) !== null &&
        Math.abs(form.getFieldValue(key) - initial) > 36000)
    ) {
      message = 'Are you sure you want to make this adjustment?';
    }
    return <span className={styles.warningMessage}>{message}</span>;
  };

  warningMessageOilAndHyd = (key, initial, unit) => {
    const { form } = this.props;
    let message = null;
    if (
      (unit === 'qt' && Math.abs(form.getFieldValue(key) - initial) > 10) ||
      (unit === 'ml' && Math.abs(form.getFieldValue(key) - initial) > 10000)
    ) {
      message = 'Are you sure you want to make this adjustment?';
    }
    return <span className={styles.warningMessage}>{message}</span>;
  };

  header = (headerText, icon, longTitle) => (
    <div className={styles.headerWrapper} data-testid="EditTripDrawer -- SectionHeader">
      <img src={icon} alt="icon" />
      <div className={longTitle ? styles.headerLong : styles.header}>{headerText}</div>
      <div className={styles.headerLine} />
    </div>
  );

  dataSection = (title, column, data, unit, key, initialKey, upliftKey) => {
    const {
      intl: { formatMessage },
      form,
      editable,
    } = this.props;
    let calculated = null;
    let initialVal = null;
    let upliftVal = null;
    if (upliftKey && initialKey) {
      initialVal = form.getFieldValue(initialKey);
      upliftVal = form.getFieldValue(upliftKey);
      calculated = form.getFieldValue(initialKey) + form.getFieldValue(upliftKey);
    }
    return (
      <Col xs={12} md={column} className={styles.sectionCol}>
        <Form.Item>
          <div className={styles.dataSection} data-testid={`EditTripDrawer -- ${key}`}>
            <div className={styles.title}>{title}</div>
            {editable ? (
              <div>
                {this.props.form.getFieldDecorator(key, {
                  rules: [
                    { required: false },
                    {
                      pattern: unit === 'qt' ? new RegExp(`^\\d{1,2}(\\.\\d{1,2})?$`) : new RegExp(`^[0-9]{1,5}$`),
                      message:
                        unit === 'qt'
                          ? formatMessage({ id: 'message.twoDecimalPlacesMax99' })
                          : formatMessage({ id: 'message.wholeNumbers' }),
                    },
                  ],
                  initialValue: data || ((initialVal || upliftVal) && initialVal + upliftVal) || 0,
                })(<InputNumber type="number" step={unit === 'qt' ? 0.01 : 1} />)}
                {` ${unit}`}
                {calculated && calculated !== form.getFieldValue(key) ? (
                  <div className={styles.calculatedWrapper}>
                    <span className={styles.calculated}>
                      {formatMessage({ id: 'text.calculated' })}:{' '}
                      <span className={styles.calculatedNumber}>{calculated}</span>
                    </span>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className={styles.data}>{`${data} ${unit}`}</div>
            )}
          </div>
        </Form.Item>
        {editable && form.getFieldError(key) && <div className={styles.invisibleDiv}>{form.getFieldError(key)}</div>}
        {editable &&
        !form.getFieldError(key) &&
        this.warningMessageOilAndHyd(key, data, unit).props.children !== null ? (
          <div className={styles.invisibleWarningDiv}>{this.warningMessageOilAndHyd(key, data, unit)}</div>
        ) : null}
      </Col>
    );
  };

  columnSection = (column, title, data, carriedOverrideData, carriedData, broughtData, key, hours) => {
    const {
      intl: { formatMessage },
      isMobile,
      form,
      editable,
      override,
    } = this.props;
    const dotIndex = key.indexOf('.');
    const cutKey = key.slice(dotIndex);
    const carriedKey = 'carried_forwards'.concat(cutKey);
    const field = form.getFieldValue(key);
    let carriedFigure = carriedData;
    if (field) carriedFigure = carriedData + (field - data);
    const roundedCarriedFigure = Math.round(carriedFigure * 100) / 100;
    let initialCarriedValue = null;
    if (form.getFieldValue(carriedKey) === null) {
      initialCarriedValue = broughtData + data;
    } else if (carriedOverrideData !== null) {
      initialCarriedValue = carriedOverrideData;
    } else {
      initialCarriedValue = roundedCarriedFigure;
    }
    return (
      <>
        <Col xs={12} md={isMobile ? column : column - 1} className={styles.sectionCol}>
          <Form.Item>
            <div className={styles.dataSection} data-testid={`EditTripDrawer -- ${key}`}>
              <div className={styles.title}>
                <span>{title}</span>
              </div>
              {editable ? (
                <>
                  <div>
                    {form.getFieldDecorator(key, {
                      initialValue: data || 0,
                    })(
                      hours ? (
                        <TimeInput input={data || 0} />
                      ) : (
                        <input type="number" step={1} min={0} max={99999} className={styles.formInput} />
                      ),
                    )}
                    {hours ? ' h' : null}
                  </div>
                  {(form.getFieldValue(key) && form.getFieldValue(key) !== data) || form.getFieldValue(key) === 0 ? (
                    <div className={styles.calculatedWrapper}>
                      <span className={styles.calculated}>
                        {formatMessage({ id: 'text.initialSmall' })}:{' '}
                        <span className={styles.calculatedNumber}>{hours ? this.convertTime(data) : data}</span>
                      </span>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className={styles.data}>
                  {`${hours ? this.convertTime(data || 0) : data || 0} ${hours ? ' h' : ''}`}
                </div>
              )}
            </div>
          </Form.Item>
          {editable && form.getFieldError(key) && <div className={styles.invisibleDiv}>{form.getFieldError(key)}</div>}
          {editable && this.warningMessage(key, data).props.children !== null && !form.getFieldError(key) && (
            <div className={styles.invisibleWarningDiv}>{this.warningMessage(key, data)}</div>
          )}
        </Col>
        <Col xs={12} md={column} className={styles.sectionCol}>
          <Form.Item>
            <div className={styles.dataSection} data-testid={`EditTripDrawer -- Carried ${key}`}>
              <div className={styles.title}>{formatMessage({ id: 'text.carriedForward' })}</div>
              {override ? (
                <>
                  <div>
                    {form.getFieldDecorator(carriedKey, {
                      initialValue: initialCarriedValue,
                    })(
                      hours ? (
                        <TimeInput input={initialCarriedValue || 0} />
                      ) : (
                        <input
                          type="number"
                          step={hours ? 0.01 : 1}
                          placeholder={data + broughtData || 0}
                          min={0}
                          max={99999}
                          className={styles.carriedForwardOverride}
                        />
                      ),
                    )}
                    {hours ? ' h' : null}
                    {form.getFieldValue(carriedKey) && form.getFieldValue(carriedKey) !== data + broughtData ? (
                      <Popover
                        placement="bottom"
                        content={
                          <div className={styles.overrideNotice}>
                            {formatMessage({ id: 'message.clearOverrideNotice' })}
                          </div>
                        }
                      >
                        <button
                          type="button"
                          className={styles.clearButton}
                          onClick={() => form.setFieldsValue({ [carriedKey]: null })}
                        >
                          {formatMessage({ id: 'form.button.clear' })}
                        </button>
                      </Popover>
                    ) : null}
                  </div>
                  {(initialCarriedValue === 0 && form.getFieldValue(carriedKey) !== null) ||
                  (form.getFieldValue(carriedKey) && form.getFieldValue(carriedKey) !== data + broughtData) ? (
                    <div className={styles.calculatedWrapper}>
                      <span className={styles.calculated}>
                        {formatMessage({ id: 'text.calculated' })}:{' '}
                        <span className={styles.calculatedNumber}>
                          {hours ? this.convertTime(data + broughtData) : data + broughtData}
                        </span>
                      </span>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className={styles.data}>
                  {`${hours ? this.convertTime(roundedCarriedFigure || 0) : roundedCarriedFigure || 0} ${
                    hours ? ' h' : ''
                  }`}
                </div>
              )}
            </div>
          </Form.Item>
          {override && form.getFieldError(carriedKey) && (
            <div className={styles.invisibleDiv}>{form.getFieldError(carriedKey)}</div>
          )}
          {override &&
            this.warningMessage(carriedKey, data + broughtData).props.children !== null &&
            !form.getFieldError(carriedKey) && (
              <div className={styles.invisibleWarningDiv}>{this.warningMessage(carriedKey, data + broughtData)}</div>
            )}
        </Col>
        {!isMobile && title !== 'NO. OF LANDINGS' ? (
          <Col md={2}>
            <div style={{ height: '40px' }} />
          </Col>
        ) : null}
      </>
    );
  };

  propellorSection = (columnHeading, data, overrideData, carriedHours, broughtHours, key) => {
    const {
      intl: { formatMessage },
      isMobile,
    } = this.props;
    return (
      <>
        <Col>
          <div className={!isMobile ? styles.sectionSpacer : null}>
            <div className={styles.columnHeading}>{columnHeading}</div>
            <Col>
              <Row>
                <div className={styles.dataWrapper}>
                  {this.columnSection(
                    12,
                    formatMessage({ id: 'units.hoursCap' }),
                    data,
                    overrideData,
                    carriedHours,
                    broughtHours,
                    key,
                    'hours',
                  )}
                </div>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  };

  oilSection = () => {
    const { tripData } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    const oilLayout =
      tripData &&
      tripData.oil_logs &&
      tripData.oil_logs.map((log, index) => {
        let carriedForwardNumber = 0;
        if (!log.carried_forwards) {
          if (log.carried_forwards === '0.0') {
            carriedForwardNumber = parseInt(log.carried_forwards, 10);
          } else if (log.uplift && log.initial_value) {
            carriedForwardNumber = parseInt(log.initial_value, 10) + parseInt(log.uplift, 10);
          }
        } else {
          carriedForwardNumber = parseInt(log.carried_forwards, 10);
        }
        return (
          <Row key={log.fluid_destination_number} className={styles.sectionRow}>
            {log.fluid_destination === 'engine' && <div className={styles.columnHeading}>{`Engine ${index + 1}`}</div>}
            {log.fluid_destination === 'apu' && <div className={styles.columnHeading}>APU</div>}
            <div>
              {this.dataSection(
                formatMessage({ id: 'text.initial' }),
                6,
                parseInt(log.initial_value, 10) || 0,
                log.uplift_unit,
                `oil_logs.[${index}]initial_value`,
              )}
              {this.dataSection(
                formatMessage({ id: 'text.uplift' }),
                6,
                parseInt(log.uplift, 10) || 0,
                log.uplift_unit,
                `oil_logs.[${index}]uplift`,
              )}
              {this.dataSection(
                formatMessage({ id: 'text.carriedForward' }),
                10,
                carriedForwardNumber,
                log.uplift_unit,
                `oil_logs.[${index}]carried_forwards`,
                `oil_logs.[${index}]initial_value`,
                `oil_logs.[${index}]uplift`,
              )}
            </div>
          </Row>
        );
      });
    return oilLayout;
  };

  hydraulicSection = () => {
    const { tripData } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    const hydraulicLayout =
      tripData &&
      tripData.hydraulic_fluid_logs &&
      tripData.hydraulic_fluid_logs.map((log, index) => {
        let carriedForwardNumber = 0;
        if (!log.carried_forwards) {
          if (log.carried_forwards === '0.0') {
            carriedForwardNumber = parseInt(log.carried_forwards, 10);
          } else if (log.uplift && log.initial_value) {
            carriedForwardNumber = parseInt(log.initial_value, 10) + parseInt(log.uplift, 10);
          }
        } else {
          carriedForwardNumber = parseInt(log.carried_forwards, 10);
        }
        return (
          <Row key={log.hydraulic_circuit_name} className={styles.sectionRow}>
            <div className={styles.columnHeading}>{log.hydraulic_circuit_name}</div>
            <div data-testid={`EditTripDrawer -- HydraulicRow${index}`}>
              {this.dataSection(
                formatMessage({ id: 'text.initial' }),
                6,
                parseInt(log.initial_value, 10) || 0,
                log.uplift_unit,
                `hydraulic_fluid_logs.[${index}]initial_value`,
              )}
              {this.dataSection(
                formatMessage({ id: 'text.uplift' }),
                6,
                parseInt(log.uplift, 10) || 0,
                log.uplift_unit,
                `hydraulic_fluid_logs.[${index}]uplift`,
              )}
              {this.dataSection(
                formatMessage({ id: 'text.carriedForward' }),
                10,
                carriedForwardNumber,
                log.uplift_unit,
                `hydraulic_fluid_logs.[${index}]carried_forwards`,
                `hydraulic_fluid_logs.[${index}]initial_value`,
                `hydraulic_fluid_logs.[${index}]uplift`,
              )}
            </div>
          </Row>
        );
      });
    return hydraulicLayout;
  };

  render() {
    const {
      loading,
      tripData,
      engine1Visible,
      engine2Visible,
      engine3Visible,
      engine4Visible,
      APUVisible,
      prop1Visible,
      prop2Visible,
      prop3Visible,
      prop4Visible,
      disableFuelUplift,
    } = this.state;
    const {
      editable,
      override,
      form,
      handleEditTripClick,
      handleOverrideClick,
      isMobile,
      aircraft,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <Loading loading={loading} contain />
        <Form
          onSubmit={this.handleSubmit}
          className={(editable || override) && isMobile ? styles.editTripFormMargin : styles.editTripForm}
          data-testid="EditTripDrawer -- Form"
          id="form"
        >
          <div className={styles.drawerWrapper}>
            <div className={styles.sectionWrapper}>
              <div className={styles.section} data-testid="EditTripDrawer -- AirframeSection">
                {this.header(formatMessage({ id: 'text.airframe' }), aircraftIcon)}
                {isMobile ? (
                  <>
                    <Row className={styles.sectionRow}>
                      {this.columnSection(
                        6,
                        formatMessage({ id: 'text.flightHoursCap' }),
                        (tripData && tripData.utilisation && tripData.utilisation.airframe_seconds) || 0,
                        tripData &&
                          tripData.carried_forwards_override &&
                          typeof tripData.carried_forwards_override.airframe_seconds === 'number'
                          ? tripData.carried_forwards_override.airframe_seconds
                          : null,
                        (tripData && tripData.carried_forwards && tripData.carried_forwards.airframe_seconds) || 0,
                        (tripData && tripData.brought_forwards && tripData.brought_forwards.airframe_seconds) || 0,
                        'utilisation.airframe_seconds',
                        'hours',
                      )}
                    </Row>
                    <Row className={styles.sectionRow}>
                      {this.columnSection(
                        6,
                        formatMessage({ id: 'text.noOfLandings' }),
                        (tripData && tripData.utilisation && tripData.utilisation.airframe_landings) || 0,
                        tripData &&
                          tripData.carried_forwards_override &&
                          typeof tripData.carried_forwards_override.airframe_landings === 'number'
                          ? tripData.carried_forwards_override.airframe_landings
                          : null,
                        (tripData && tripData.carried_forwards && tripData.carried_forwards.airframe_landings) || 0,
                        (tripData && tripData.brought_forwards && tripData.brought_forwards.airframe_landings) || 0,
                        'utilisation.airframe_landings',
                      )}
                    </Row>
                  </>
                ) : (
                  <Row className={styles.sectionRow}>
                    {this.columnSection(
                      6,
                      formatMessage({ id: 'text.flightHoursCap' }),
                      (tripData && tripData.utilisation && tripData.utilisation.airframe_seconds) || 0,
                      tripData &&
                        tripData.carried_forwards_override &&
                        typeof tripData.carried_forwards_override.airframe_seconds === 'number'
                        ? tripData.carried_forwards_override.airframe_seconds
                        : null,
                      (tripData && tripData.carried_forwards && tripData.carried_forwards.airframe_seconds) || 0,
                      (tripData && tripData.brought_forwards && tripData.brought_forwards.airframe_seconds) || 0,
                      'utilisation.airframe_seconds',
                      'hours',
                    )}
                    {this.columnSection(
                      6,
                      formatMessage({ id: 'text.noOfLandings' }),
                      (tripData && tripData.utilisation && tripData.utilisation.airframe_landings) || 0,
                      tripData &&
                        tripData.carried_forwards_override &&
                        typeof tripData.carried_forwards_override.airframe_landings === 'number'
                        ? tripData.carried_forwards_override.airframe_landings
                        : null,
                      (tripData && tripData.carried_forwards && tripData.carried_forwards.airframe_landings) || 0,
                      (tripData && tripData.brought_forwards && tripData.brought_forwards.airframe_landings) || 0,
                      'utilisation.airframe_landings',
                    )}
                  </Row>
                )}
                <Row className={styles.sectionRow}>
                  {this.columnSection(
                    6,
                    formatMessage({ id: 'text.flightCyclesCap' }),
                    (tripData && tripData.utilisation && tripData.utilisation.airframe_cycles) || 0,
                    tripData &&
                      tripData.carried_forwards_override &&
                      typeof tripData.carried_forwards_override.airframe_cycles === 'number'
                      ? tripData.carried_forwards_override.airframe_cycles
                      : null,
                    (tripData && tripData.carried_forwards && tripData.carried_forwards.airframe_cycles) || 0,
                    (tripData && tripData.brought_forwards && tripData.brought_forwards.airframe_cycles) || 0,
                    'utilisation.airframe_cycles',
                  )}
                </Row>
              </div>
            </div>
            {engine1Visible ? (
              <div className={styles.sectionWrapper}>
                <div className={styles.section} data-testid="EditTripDrawer -- Engines">
                  {this.header(formatMessage({ id: 'text.engines' }), engineIcon)}
                  <Row className={styles.sectionRow}>
                    <Col md={13} data-testid="EditTripDrawer -- Engine1">
                      <div className={isMobile ? '' : styles.sectionSpacer}>
                        <div className={styles.columnHeading}>{formatMessage({ id: 'text.engine1' })}</div>
                        <Col>
                          <Row>
                            <div className={styles.dataWrapper}>
                              {this.columnSection(
                                10,
                                formatMessage({ id: 'units.hoursCap' }),
                                (tripData && tripData.utilisation && tripData.utilisation.engine_1_seconds) || 0,
                                tripData &&
                                  tripData.carried_forwards_override &&
                                  typeof tripData.carried_forwards_override.engine_1_seconds === 'number'
                                  ? tripData.carried_forwards_override.engine_1_seconds
                                  : null,
                                (tripData && tripData.carried_forwards && tripData.carried_forwards.engine_1_seconds) ||
                                  0,
                                (tripData && tripData.brought_forwards && tripData.brought_forwards.engine_1_seconds) ||
                                  0,
                                'utilisation.engine_1_seconds',
                                'hours',
                              )}
                            </div>
                            {!form.getFieldError('utilisation.engine_1_seconds') &&
                            form.getFieldError('utilisation.engine_2_seconds') ? (
                              <div className={styles.invisibleDiv} />
                            ) : null}
                            {this.warningMessage(
                              'utilisation.engine_2_seconds',
                              (tripData && tripData.utilisation && tripData.utilisation.engine_2_seconds) || 0,
                            ).props.children !== null &&
                            this.warningMessage(
                              'utilisation.engine_1_seconds',
                              (tripData && tripData.utilisation && tripData.utilisation.engine_1_seconds) || 0,
                            ).props.children === null &&
                            form.getFieldError('utilisation.engine_1_seconds') ? (
                              <div className={styles.invisibleWarningDiv} />
                            ) : null}
                          </Row>
                          <Row>
                            <div className={styles.dataWrapper}>
                              {this.columnSection(
                                10,
                                formatMessage({ id: 'units.cyclesCap' }),
                                (tripData && tripData.utilisation && tripData.utilisation.engine_1_cycles) || 0,
                                tripData &&
                                  tripData.carried_forwards_override &&
                                  typeof tripData.carried_forwards_override.engine_1_cycles === 'number'
                                  ? tripData.carried_forwards_override.engine_1_cycles
                                  : null,
                                (tripData && tripData.carried_forwards && tripData.carried_forwards.engine_1_cycles) ||
                                  0,
                                (tripData && tripData.brought_forwards && tripData.brought_forwards.engine_1_cycles) ||
                                  0,
                                'utilisation.engine_1_cycles',
                              )}
                            </div>
                            {!form.getFieldError('utilisation.engine_1_cycles') &&
                            form.getFieldError('utilisation.engine_2_cycles') ? (
                              <div className={styles.invisibleDiv} />
                            ) : null}
                            {this.warningMessage(
                              'utilisation.engine_2_cycles',
                              (tripData && tripData.utilisation && tripData.utilisation.engine_2_cycles) || 0,
                            ).props.children !== null &&
                            this.warningMessage(
                              'utilisation.engine_1_cycles',
                              (tripData && tripData.utilisation && tripData.utilisation.engine_1_cycles) || 0,
                            ).props.children === null &&
                            form.getFieldError('utilisation.engine_1_cycles') ? (
                              <div className={styles.invisibleWarningDiv} />
                            ) : null}
                          </Row>
                        </Col>
                      </div>
                    </Col>
                    {engine2Visible ? (
                      <Col md={11} data-testid="EditTripDrawer -- Engine2">
                        <div className={isMobile ? '' : styles.sectionSpacer}>
                          <div className={styles.columnHeading}>{formatMessage({ id: 'text.engine2' })}</div>
                          <Col>
                            <Row>
                              <div className={styles.dataWrapper}>
                                {this.columnSection(
                                  12,
                                  formatMessage({ id: 'units.hoursCap' }),
                                  (tripData && tripData.utilisation && tripData.utilisation.engine_2_seconds) || 0,
                                  tripData &&
                                    tripData.carried_forwards_override &&
                                    typeof tripData.carried_forwards_override.engine_2_seconds === 'number'
                                    ? tripData.carried_forwards_override.engine_2_seconds
                                    : null,
                                  (tripData &&
                                    tripData.carried_forwards &&
                                    tripData.carried_forwards.engine_2_seconds) ||
                                    0,
                                  (tripData &&
                                    tripData.brought_forwards &&
                                    tripData.brought_forwards.engine_2_seconds) ||
                                    0,
                                  'utilisation.engine_2_seconds',
                                  'hours',
                                )}
                              </div>
                              {form.getFieldError('utilisation.engine_1_seconds') &&
                              !form.getFieldError('utilisation.engine_2_seconds') ? (
                                <div className={styles.invisibleDiv} />
                              ) : null}
                              {this.warningMessage(
                                'utilisation.engine_1_seconds',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_1_seconds) || 0,
                              ).props.children !== null &&
                              this.warningMessage(
                                'utilisation.engine_2_seconds',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_2_seconds) || 0,
                              ).props.children === null &&
                              form.getFieldError('utilisation.engine_2_seconds') ? (
                                <div className={styles.invisibleWarningDiv} />
                              ) : null}
                            </Row>
                            <Row>
                              <div className={styles.dataWrapper}>
                                {this.columnSection(
                                  12,
                                  formatMessage({ id: 'units.cyclesCap' }),
                                  (tripData && tripData.utilisation && tripData.utilisation.engine_2_cycles) || 0,
                                  tripData &&
                                    tripData.carried_forwards_override &&
                                    typeof tripData.carried_forwards_override.engine_2_cycles === 'number'
                                    ? tripData.carried_forwards_override.engine_2_cycles
                                    : null,
                                  (tripData &&
                                    tripData.carried_forwards &&
                                    tripData.carried_forwards.engine_2_cycles) ||
                                    0,
                                  (tripData &&
                                    tripData.brought_forwards &&
                                    tripData.brought_forwards.engine_2_cycles) ||
                                    0,
                                  'utilisation.engine_2_cycles',
                                )}
                              </div>
                              {form.getFieldError('utilisation.engine_1_cycles') &&
                              !form.getFieldError('utilisation.engine_2_cycles') ? (
                                <div className={styles.invisibleDiv} />
                              ) : null}
                              {this.warningMessage(
                                'utilisation.engine_1_cycles',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_1_cycles) || 0,
                              ).props.children !== null &&
                              this.warningMessage(
                                'utilisation.engine_2_cycles',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_2_cycles) || 0,
                              ).props.children === null &&
                              form.getFieldError('utilisation.engine_2_cycles') ? (
                                <div className={styles.invisibleWarningDiv} />
                              ) : null}
                            </Row>
                          </Col>
                        </div>
                      </Col>
                    ) : null}
                    {engine3Visible ? (
                      <Col md={13}>
                        <div className={isMobile ? '' : styles.sectionSpacer}>
                          <div className={styles.columnHeading}>{formatMessage({ id: 'text.engine3' })}</div>
                          <Col>
                            <Row>
                              <div className={styles.dataWrapper}>
                                {this.columnSection(
                                  10,
                                  formatMessage({ id: 'units.hoursCap' }),
                                  (tripData && tripData.utilisation && tripData.utilisation.engine_3_seconds) || 0,
                                  tripData &&
                                    tripData.carried_forwards_override &&
                                    typeof tripData.carried_forwards_override.engine_3_seconds === 'number'
                                    ? tripData.carried_forwards_override.engine_3_seconds
                                    : null,
                                  (tripData &&
                                    tripData.carried_forwards &&
                                    tripData.carried_forwards.engine_3_seconds) ||
                                    0,
                                  (tripData &&
                                    tripData.brought_forwards &&
                                    tripData.brought_forwards.engine_3_seconds) ||
                                    0,
                                  'utilisation.engine_3_seconds',
                                  'hours',
                                )}
                              </div>
                              {!form.getFieldError('utilisation.engine_3_seconds') &&
                              form.getFieldError('utilisation.engine_4_seconds') ? (
                                <div className={styles.invisibleDiv} />
                              ) : null}
                              {this.warningMessage(
                                'utilisation.engine_4_seconds',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_4_seconds) || 0,
                              ).props.children !== null &&
                              this.warningMessage(
                                'utilisation.engine_3_seconds',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_3_seconds) || 0,
                              ).props.children === null &&
                              form.getFieldError('utilisation.engine_3_seconds') ? (
                                <div className={styles.invisibleWarningDiv} />
                              ) : null}
                            </Row>
                            <Row>
                              <div className={styles.dataWrapper}>
                                {this.columnSection(
                                  10,
                                  formatMessage({ id: 'units.cyclesCap' }),
                                  (tripData && tripData.utilisation && tripData.utilisation.engine_3_cycles) || 0,
                                  tripData &&
                                    tripData.carried_forwards_override &&
                                    typeof tripData.carried_forwards_override.engine_3_cycles === 'number'
                                    ? tripData.carried_forwards_override.engine_3_cycles
                                    : null,
                                  (tripData &&
                                    tripData.carried_forwards &&
                                    tripData.carried_forwards.engine_3_cycles) ||
                                    0,
                                  (tripData &&
                                    tripData.brought_forwards &&
                                    tripData.brought_forwards.engine_3_cycles) ||
                                    0,
                                  'utilisation.engine_3_cycles',
                                )}
                              </div>
                            </Row>
                          </Col>
                        </div>
                      </Col>
                    ) : null}
                    {engine4Visible ? (
                      <Col md={11}>
                        <div className={isMobile ? '' : styles.sectionSpacer}>
                          <div className={styles.columnHeading}>{formatMessage({ id: 'text.engine4' })}</div>
                          <Col>
                            <Row>
                              <div className={styles.dataWrapper}>
                                {this.columnSection(
                                  12,
                                  formatMessage({ id: 'units.hoursCap' }),
                                  (tripData && tripData.utilisation && tripData.utilisation.engine_4_seconds) || 0,
                                  tripData &&
                                    tripData.carried_forwards_override &&
                                    typeof tripData.carried_forwards_override.engine_4_seconds === 'number'
                                    ? tripData.carried_forwards_override.engine_4_seconds
                                    : null,
                                  (tripData &&
                                    tripData.carried_forwards &&
                                    tripData.carried_forwards.engine_4_seconds) ||
                                    0,
                                  (tripData &&
                                    tripData.brought_forwards &&
                                    tripData.brought_forwards.engine_4_seconds) ||
                                    0,
                                  'utilisation.engine_4_seconds',
                                  'hours',
                                )}
                              </div>
                              {form.getFieldError('utilisation.engine_3_seconds') &&
                              !form.getFieldError('utilisation.engine_4_seconds') ? (
                                <div className={styles.invisibleDiv} />
                              ) : null}
                              {this.warningMessage(
                                'utilisation.engine_3_seconds',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_3_seconds) || 0,
                              ).props.children !== null &&
                              this.warningMessage(
                                'utilisation.engine_4_seconds',
                                (tripData && tripData.utilisation && tripData.utilisation.engine_4_seconds) || 0,
                              ).props.children === null &&
                              form.getFieldError('utilisation.engine_4_seconds') ? (
                                <div className={styles.invisibleWarningDiv} />
                              ) : null}
                            </Row>
                            <Row>
                              <div className={styles.dataWrapper}>
                                {this.columnSection(
                                  12,
                                  'CYCLES',
                                  (tripData && tripData.utilisation && tripData.utilisation.engine_4_cycles) || 0,
                                  tripData &&
                                    tripData.carried_forwards_override &&
                                    typeof tripData.carried_forwards_override.engine_4_cycles === 'number'
                                    ? tripData.carried_forwards_override.engine_4_cycles
                                    : null,
                                  (tripData &&
                                    tripData.carried_forwards &&
                                    tripData.carried_forwards.engine_4_cycles) ||
                                    0,
                                  (tripData &&
                                    tripData.brought_forwards &&
                                    tripData.brought_forwards.engine_4_cycles) ||
                                    0,
                                  'utilisation.engine_4_cycles',
                                )}
                              </div>
                            </Row>
                          </Col>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </div>
            ) : null}
            {prop1Visible ? (
              <div className={styles.sectionWrapper}>
                <div className={styles.section}>
                  {this.header(formatMessage({ id: 'text.propellors' }), propellorIcon)}
                  <Row className={styles.sectionRow}>
                    <Col md={11}>
                      {this.propellorSection(
                        formatMessage({ id: 'text.propellor1' }),
                        (tripData && tripData.utilisation && tripData.utilisation.prop_1_seconds) || 0,
                        tripData &&
                          tripData.carried_forwards_override &&
                          typeof tripData.carried_forwards_override.prop_1_seconds === 'number'
                          ? tripData.carried_forwards_override.prop_1_seconds
                          : null,
                        (tripData && tripData.carried_forwards && tripData.carried_forwards.prop_1_seconds) || 0,
                        (tripData && tripData.brought_forwards && tripData.brought_forwards.prop_1_seconds) || 0,
                        'utilisation.prop_1_seconds',
                        'hours',
                      )}
                    </Col>
                    {!isMobile ? (
                      <Col md={2}>
                        <div style={{ height: '40px' }} />
                      </Col>
                    ) : null}
                    {prop2Visible ? (
                      <Col md={11}>
                        {this.propellorSection(
                          formatMessage({ id: 'text.propellor2' }),
                          (tripData && tripData.utilisation && tripData.utilisation.prop_2_seconds) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.prop_2_seconds === 'number'
                            ? tripData.carried_forwards_override.prop_2_seconds
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.prop_2_seconds) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.prop_2_seconds) || 0,
                          'utilisation.prop_2_seconds',
                          'hours',
                        )}
                      </Col>
                    ) : null}
                  </Row>
                  <Row className={styles.sectionRow}>
                    {prop3Visible ? (
                      <Col md={11}>
                        {this.propellorSection(
                          formatMessage({ id: 'text.propellor3' }),
                          (tripData && tripData.utilisation && tripData.utilisation.prop_3_seconds) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.prop_3_seconds === 'number'
                            ? tripData.carried_forwards_override.prop_3_seconds
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.prop_3_seconds) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.prop_3_seconds) || 0,
                          'utilisation.prop_3_seconds',
                          'hours',
                        )}
                      </Col>
                    ) : null}
                    {!isMobile ? (
                      <Col md={2}>
                        <div style={{ height: '40px' }} />
                      </Col>
                    ) : null}
                    {prop4Visible ? (
                      <Col md={11}>
                        {this.propellorSection(
                          formatMessage({ id: 'text.propellor4' }),
                          (tripData && tripData.utilisation && tripData.utilisation.prop_4_seconds) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.prop_4_seconds === 'number'
                            ? tripData.carried_forwards_override.prop_4_seconds
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.prop_4_seconds) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.prop_4_seconds) || 0,
                          'utilisation.prop_4_seconds',
                          'hours',
                        )}
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </div>
            ) : null}
            {APUVisible ? (
              <div className={styles.sectionWrapper}>
                <div className={styles.section}>
                  {this.header(formatMessage({ id: 'text.apu' }), apuIcone)}
                  {isMobile ? (
                    <>
                      <Row className={styles.sectionRow}>
                        {this.columnSection(
                          12,
                          formatMessage({ id: 'units.hoursCap' }),
                          (tripData && tripData.utilisation && tripData.utilisation.apu_seconds) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.apu_seconds === 'number'
                            ? tripData.carried_forwards_override.apu_seconds
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.apu_seconds) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.apu_seconds) || 0,
                          'utilisation.apu_seconds',
                          'hours',
                        )}
                      </Row>
                      <Row className={styles.sectionRow}>
                        {this.columnSection(
                          12,
                          formatMessage({ id: 'units.cyclesCap' }),
                          (tripData && tripData.utilisation && tripData.utilisation.apu_cycles) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.apu_cycles === 'number'
                            ? tripData.carried_forwards_override.apu_cycles
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.apu_cycles) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.apu_cycles) || 0,
                          'utilisation.apu_cycles',
                        )}
                      </Row>
                    </>
                  ) : (
                    <Row className={styles.sectionRow}>
                      <Col md={11}>
                        {this.columnSection(
                          12,
                          formatMessage({ id: 'units.hoursCap' }),
                          (tripData && tripData.utilisation && tripData.utilisation.apu_seconds) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.apu_seconds === 'number'
                            ? tripData.carried_forwards_override.apu_seconds
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.apu_seconds) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.apu_seconds) || 0,
                          'utilisation.apu_seconds',
                          'hours',
                        )}
                      </Col>
                      {!isMobile ? (
                        <Col md={2}>
                          <div style={{ height: '40px' }} />
                        </Col>
                      ) : null}
                      <Col md={11}>
                        {this.columnSection(
                          12,
                          formatMessage({ id: 'units.cyclesCap' }),
                          (tripData && tripData.utilisation && tripData.utilisation.apu_cycles) || 0,
                          tripData &&
                            tripData.carried_forwards_override &&
                            typeof tripData.carried_forwards_override.apu_cycles === 'number'
                            ? tripData.carried_forwards_override.apu_cycles
                            : null,
                          (tripData && tripData.carried_forwards && tripData.carried_forwards.apu_cycles) || 0,
                          (tripData && tripData.brought_forwards && tripData.brought_forwards.apu_cycles) || 0,
                          'utilisation.apu_cycles',
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            ) : null}
            {!isMobile && override ? (
              <div className={styles.buttonWrapper}>
                <TFButton size={ButtonSize.MEDIUM} primary={false} onClick={() => handleOverrideClick(false)}>
                  {formatMessage({ id: 'form.button.cancel' })}
                </TFButton>
                <div className={styles.submitButtonWrapper}>
                  <TFButton size={ButtonSize.MEDIUM} onClick={() => this.handleOverrideSaveClick()}>
                    {formatMessage({ id: 'form.button.save' })}
                  </TFButton>
                </div>
              </div>
            ) : null}
            {isMobile && override ? (
              <div className={styles.mobileButtonDiv}>
                <div className={styles.buttonWrapper}>
                  <TFButton size={ButtonSize.MEDIUM} primary={false} onClick={() => handleOverrideClick(false)}>
                    {formatMessage({ id: 'form.button.cancel' })}
                  </TFButton>
                  <div className={styles.submitButtonWrapper}>
                    <TFButton size={ButtonSize.MEDIUM} onClick={() => this.handleOverrideSaveClick()}>
                      {formatMessage({ id: 'form.button.save' })}
                    </TFButton>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.sectionWrapper}>
              <div className={styles.section} data-testid="EditTripDrawer -- FuelSection">
                {this.header(formatMessage({ id: 'text.fuel' }), fuelIcon)}
                <div>
                  <FuelSection
                    fuelLogs={tripData && tripData.fuel_logs}
                    editable={editable}
                    form={form}
                    handleFuelUpliftAdd={this.handleFuelUpliftAdd}
                    disableFuelUplift={disableFuelUplift}
                    onMainPageCancelClick={this.onMainPageCancelClick}
                    aircraft={aircraft}
                  />
                </div>
              </div>
            </div>
            {engine1Visible ? (
              <div className={styles.sectionWrapper}>
                <div className={styles.section} data-testid="EditTripDrawer -- OilSection">
                  {this.header(formatMessage({ id: 'text.oil' }), fuelIcon)}
                  {this.oilSection()}
                </div>
              </div>
            ) : null}
            <div className={styles.sectionWrapperFinal}>
              <div className={styles.section} data-testid="EditTripDrawer -- HydraulicSection">
                {this.header(formatMessage({ id: 'text.hydraulicFluid' }), fuelIcon, true)}
                {this.hydraulicSection()}
              </div>
            </div>
            {tripData && Object.keys(tripData).includes('comments') ? (
              <CommentsSection comments={tripData?.comments} editable={editable} form={form} />
            ) : null}
          </div>
          {!isMobile && editable ? (
            <div>
              <div className={styles.buttonWrapper}>
                <div className={styles.submitButtonWrapper}>
                  <TFButton size={ButtonSize.MEDIUM} onClick={this.handleSubmit}>
                    {formatMessage({ id: 'form.button.save' })}
                  </TFButton>
                </div>
                <TFButton size={ButtonSize.MEDIUM} primary={false} onClick={() => this.handleCancelClick()}>
                  {formatMessage({ id: 'form.button.cancel' })}
                </TFButton>
              </div>
            </div>
          ) : null}
          {isMobile && (editable || override) ? (
            <div className={styles.mobileButtonDiv}>
              <div className={styles.buttonWrapper}>
                <div className={styles.submitButtonWrapper}>
                  <TFButton
                    size={ButtonSize.MEDIUM}
                    onClick={this.handleSubmit}
                    data-testid="EditTripDrawer--SubmitButton"
                  >
                    {formatMessage({ id: 'form.button.save' })}
                  </TFButton>
                </div>
                <TFButton size={ButtonSize.MEDIUM} primary={false} onClick={() => handleEditTripClick(false)}>
                  {formatMessage({ id: 'form.button.cancel' })}
                </TFButton>
              </div>
            </div>
          ) : null}
        </Form>
      </>
    );
  }
}

export default compose(injectIntl, connect(), Form.create())(EditTripDrawer);
