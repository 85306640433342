/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import Exception403 from '../pages/403';
import { DashboardState } from '../models';
import { User, DashboardResource, UserPermission, PeoplePermissions } from '../models/userSettings';
import { AircraftPermission, AircraftResource, Aircraft, BaseAircraft } from '../models/aircraft';
import { updateUserDetails } from '../models/userSettings/actions';
import { saveSingleAircraft } from '../models/aircraft/actions';
import { getMe, queryAircraft } from '../services/apiNew';
import { getOauthUrl } from '../utils/oauth';
import Loading from '../components/TFLoading';
import { hasAircraftPermission } from '../components/_utils/AuthenticationWrapper';

const cookies = new Cookies();

export const useAuthenticationValidation = (
  resourceRequired?: DashboardResource,
  permissionRequired?: UserPermission,
  flag?: string,
): AuthenticationValidation => {
  const { pathname } = useLocation();
  const redirectToLogin = useCallback(() => {
    const url = getOauthUrl(pathname);
    window.location.href = url;
  }, [pathname]);

  useEffect(() => {
    if (window.location.hostname === 'dashboard.trustflight.io') {
      try {
        const trackingId = 'UA-180545911-1';
        ReactGA.initialize(trackingId);
        ReactGA.pageview(`Production ${pathname.replace(/\/*?([a-zA-Z0-9]*-[a-zA-Z0-9]*)/gm, '')}`);
      } catch (e) {
        console.error(e);
      }
    }
  }, [pathname]);

  const [authenticationObject, setAuthenticationObject] = useState<AuthenticationValidation>({
    loading: true,
    authenticated: false,
    lastChecked: Date.now(),
  });
  const { id, people: peoplePermissions, featureFlags } = useSelector<
    DashboardState,
    {
      id: string;
      people: PeoplePermissions[];
      featureFlags: any;
    }
  >(({ userSettings }) => ({
    id: userSettings.details?.id,
    people: userSettings.details?.people,
    featureFlags: userSettings.details?.operators.reduce((flags, op) => {
      if (op.feature_flags) {
        op.feature_flags.forEach((f) => {
          if (!flags.includes(f.feature_name)) {
            flags.push(f.feature_name);
          }
        });
      }
      return flags;
    }, []),
  }));

  const dispatch = useDispatch();
  const [waitingForMe, setWaitingForMe] = useState(false);
  const { auth, userId } = cookies.getAll();
  useEffect(() => {
    if (authenticationObject.loading && id === userId && auth) {
      // Got valid user in the Redux State, let's update the authenticated object and call it a day
      // I have short circuiting global permissions, until we get org level permissions
      // to re-enabled add hasDashboardPermission(peoplePermissions, resourceRequired, permissionRequired)
      // as value on authenticated prop in setAuthenticationObject below.

      if (flag) {
        setAuthenticationObject({
          loading: false,
          authenticated: featureFlags.includes(flag),
          lastChecked: Date.now(),
        });
      } else {
        setAuthenticationObject({
          loading: false,
          authenticated: true,
          lastChecked: Date.now(),
        });
      }
    } else if (authenticationObject.loading && auth && !peoplePermissions && !waitingForMe) {
      setWaitingForMe(true);
      // Got auth token but no user in state, let's go get one and dispatch it to Redux once that's done
      getMe()
        .then(({ data }: { data: User }) => {
          dispatch(updateUserDetails({ details: data }));
          setWaitingForMe(false);
        })
        .catch(() => {
          setWaitingForMe(false);
          redirectToLogin();
        });
    } else if (!auth) {
      // No login data found on the client, let's send them to CoreAPI for authentication
      redirectToLogin();
    }
  }, [
    authenticationObject.loading,
    userId,
    auth,
    dispatch,
    id,
    peoplePermissions,
    resourceRequired,
    permissionRequired,
    redirectToLogin,
  ]);

  return authenticationObject;
};

export const useAircraftAuthenticationValidation = (
  resourceRequired?: AircraftResource,
  permissionRequired?: AircraftPermission,
  flag?: string,
): AuthenticationValidation => {
  const { pathname } = useLocation();
  const redirectToLogin = useCallback(() => {
    const url = getOauthUrl(pathname);
    window.location.href = url;
  }, [pathname]);

  const [authenticationObject, setAuthenticationObject] = useState<AuthenticationValidation>({
    loading: true,
    authenticated: false,
    lastChecked: Date.now(),
  });

  interface ParamTypes {
    id: string;
  }
  const { id: aircraftId } = useParams<ParamTypes>();
  const { auth } = cookies.getAll();
  const { aircraftMap, people: peoplePermissions, userSettings: settings } = useSelector<
    DashboardState,
    { aircraftMap: Map<string, Aircraft | BaseAircraft>; people: PeoplePermissions[]; userSettings: any }
  >(({ aircraft, userSettings }) => ({
    aircraftMap: aircraft.aircraftMap,
    people: userSettings.details?.people,
    userSettings,
  }));
  const requiredAircraft = aircraftMap.get(aircraftId);

  const personWithPermissions =
    peoplePermissions && peoplePermissions.find((person) => person.permission_groups[1].aircraft.includes(aircraftId));

  const aircraftPermissions =
    personWithPermissions && personWithPermissions.permission_groups && personWithPermissions.permission_groups[1];

  let operatorFeatureFlags = [];
  if (flag && settings?.details && requiredAircraft) {
    const currentOp = settings.details?.operators.find((op) => op.id === requiredAircraft.operator_id);
    operatorFeatureFlags = currentOp.feature_flags.map((f) => f.feature_name);
  }

  const dispatch = useDispatch();
  const [waitingForMe, setWaitingForMe] = useState(false);

  useEffect(() => {
    if (authenticationObject.loading && requiredAircraft && auth && aircraftPermissions) {
      const featureFlagsEnabled = flag ? operatorFeatureFlags.includes(flag) : true;
      setAuthenticationObject({
        loading: false,
        authenticated:
          featureFlagsEnabled &&
          hasAircraftPermission(requiredAircraft, resourceRequired, permissionRequired, aircraftPermissions),
        lastChecked: Date.now(),
      });
    } else if (authenticationObject.loading && auth && !peoplePermissions && !waitingForMe) {
      setWaitingForMe(true);
      getMe()
        .then(({ data }: { data: User }) => {
          dispatch(updateUserDetails({ details: data }));
          setWaitingForMe(false);
        })
        .catch(() => {
          redirectToLogin();
          setWaitingForMe(false);
        });
      queryAircraft(aircraftId)
        .then(({ data }: { data: Aircraft }) => {
          dispatch(saveSingleAircraft({ payload: data }));
        })
        .catch((error) => {
          console.error(error.message);
          redirectToLogin();
        });
    } else if (!auth) {
      // No login data found on the client, let's send them to CoreAPI for authentication
      redirectToLogin();
    }
  }, [
    authenticationObject.loading,
    auth,
    aircraftId,
    dispatch,
    requiredAircraft,
    resourceRequired,
    permissionRequired,
    redirectToLogin,
    peoplePermissions,
    aircraftPermissions,
  ]);

  return authenticationObject;
};

export const LoadingScreen: React.FC = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      minWidth: '100vw',
    }}
    data-testid="AuthLoadingScreen"
  >
    <Loading loading theme="transparent" />
  </div>
);

export const AuthProvider: React.FC<{
  resourceRequired?: DashboardResource;
  permissionRequired?: UserPermission;
  featureFlag?: any;
}> = ({ children, resourceRequired, permissionRequired, featureFlag }) => {
  const { authenticated, loading } = useAuthenticationValidation(resourceRequired, permissionRequired, featureFlag);

  if (authenticated) {
    return <>{children}</>;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  //  TODO: redirect to a different authenticated route
  return <Exception403 />;
};

export const AircraftAuthProvider: React.FC<{
  resourceRequired?: AircraftResource;
  permissionRequired?: AircraftPermission;
  featureFlag?: any;
}> = ({ children, resourceRequired, permissionRequired, featureFlag }) => {
  const { authenticated, loading } = useAircraftAuthenticationValidation(
    resourceRequired,
    permissionRequired,
    featureFlag,
  );
  if (authenticated) {
    return <>{children}</>;
  }
  if (loading) {
    return <LoadingScreen />;
  }
  //  TODO: redirect to a different authenticated route
  return <Exception403 />;
};

export interface AuthenticationValidation {
  loading: boolean;
  authenticated: boolean;
  lastChecked: number;
}
