/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { Button, Search } from '@arcflight/tf-component-library';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import Loading from '../../TFLoading/index';
import TFTooltip from '../../TFTooltip/TFTooltip';
import { Defect } from '../../../models/defects';
import { MELItem } from '../../../models/mels';
import { DashboardState } from '../../../models';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import { getMelItemsForAircraft } from '../../../models/mels/actions';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import ViewMelDetails from './ViewMelDetails';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import DefectMelItems from './DefectMelItems';
import DefectNonMelItems from './DefectNonMelItems';
import StyledRadioButton from './StyledRadioButton';
import DeferUntil from './DeferUntil';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';

interface DeferralOptionsProps {
  defect: Defect | null;
  updateDefectData: (changes: any[]) => void;
  editDefect: boolean;
  rectificationCategory: { title: string; colour?: string };
  setRectificationCategory: (option: { title: string; colour?: string }) => void;
  setMelItem?: (input: MELItem) => void;
  setRectificationId?: (input: string) => void;
  aircraftId?: string;
  melItemsLoading?: boolean;
  setMelItemsLoading?: (input: boolean) => void;
  handleMelItemChange?: (melItemId: string, rectId: string) => void;
  defectType: string;
  setDefectType: (input: string) => void;
  setDateDue?: (input) => void;
  displayDefect?: Defect | null;
  setDisplayDefect?: (input) => void;
  apuInstalled: boolean;
  deferredTime: Moment;
  setDeferredTime: (input: Moment) => void;
  defectDeferred: boolean;
  reference: string;
  setReference: (value: string) => void;
  ATAChapter: string;
  setATAChapter: (value: string) => void;
  ATASection: string;
  setATASection: (value: string) => void;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const MelItemsWrapper = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  margin-bottom: 20px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const BoldDiv = styled.div`
  font-weight: 600;
  @media (max-width: 451px) {
    max-width: 165px;
  }
`;

const RegularText = styled.span`
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 4px;
  width: 100%;
  @media (max-width: 450px) {
    margin: 20px 0;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
`;

const DeferralOptions: React.FC<DeferralOptionsProps> = ({
  defect,
  editDefect,
  rectificationCategory,
  setRectificationCategory,
  setMelItem,
  aircraftId,
  melItemsLoading,
  setMelItemsLoading,
  handleMelItemChange,
  defectType,
  setDefectType,
  setDateDue,
  displayDefect,
  setDisplayDefect,
  apuInstalled,
  deferredTime,
  defectDeferred,
  setDeferredTime,
  reference,
  setReference,
  ATAChapter,
  setRectificationId,
  setATAChapter,
  ATASection,
  setATASection,
  updateDefectData,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredItems, setFilteredItems] = useState(null);
  const [melItems, setMelItems] = useState(null);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);
  const [showDeferralInfo, setShowDeferralInfo] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isMobile = window.innerWidth < 451;

  const { aircraftMelsMap, lastFetched, dirty } = useSelector((state: DashboardState) => state.mels);
  let list;

  if (aircraftMelsMap && aircraftMelsMap.get(aircraftId || defect?.aircraft?.id)) {
    list = aircraftMelsMap.get(aircraftId || defect.aircraft.id).list;
  }
  const handleDefectTypeChange = (option): void => {
    setDefectType(option);
    updateDefectData([{ value: option, key: 'defect_type' }]);
  };

  const deferralOptionsArray = ['MEL', 'CDL', 'NEF', 'CAS', 'Other'];
  const deferralOptions = deferralOptionsArray.map((option) => (
    <StyledRadioButton
      identifier={`DeferralOption${option}`}
      id={`DeferralOption${option}`}
      value={`${option}`}
      group="defectTypeGroup"
      checked={option === defect?.defect_type}
      key={option}
      marginRight={option !== 'Other' ? 5 : 0}
      onClick={(): void => handleDefectTypeChange(option)}
    >
      {option}
    </StyledRadioButton>
  ));

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefectType = usePrevious(defectType);

  const usePreviousMelItems = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevMelItems = usePreviousMelItems(list);

  const handleLocalMelItemChange = (melItemId: string, rectId: string): void => {
    handleMelItemChange(melItemId, rectId);
    updateDefectData([
      { value: melItemId, key: 'mel_item_id' },
      { value: rectId, key: 'mel_rectification_id' },
    ]);
    const melItem = melItems.find((item) => item.id === melItemId);
    const newDefect = {
      mel_item: melItem,
      mel_rectification_id: rectId,
      date: defect?.raised_at,
    };
    setDisplayDefect(newDefect);
  };

  const handleDeferralTimeChange = (dateTime: string): void => {
    setDeferredTime(moment.utc(dateTime));
    updateDefectData([{ value: moment.utc(dateTime), key: 'deferred_at' }]);
  };

  const onMouseEnterDeferral = (): void => {
    setShowDeferralInfo(true);
  };

  const onMouseLeaveDeferral = (): void => {
    setShowDeferralInfo(false);
  };

  useEffect(() => {
    if (prevDefectType === 'MEL' && prevDefectType !== defectType) {
      setMelItemsLoading(false);
    }
  }, [defectType, prevDefectType, setMelItemsLoading]);

  useEffect(() => {
    setDisplayDefect(defect);
    if (!defectDeferred) {
      setDefectType('Other');
      updateDefectData([{ value: 'Other', key: 'defect_type' }]);
    } else if (defect?.defect_type) setDefectType(defect?.defect_type);
  }, [defect]);

  useEffect(() => {
    if (melItems) {
      setFilteredItems(melItems);
    }
  }, [melItems]);

  useEffect(() => {
    if (editDefect && defectType === 'MEL') {
      if (aircraftId) {
        if (!aircraftMelsMap.has(aircraftId) && !melItemsLoading) {
          setMelItemsLoading(true);
          dispatch(getMelItemsForAircraft({ payload: { id: aircraftId } }));
        } else if ((Date.now() - lastFetched >= 30000 || dirty) && !aircraftMelsMap.has(aircraftId)) {
          setMelItemsLoading(true);
          dispatch(getMelItemsForAircraft({ payload: { id: aircraftId } }));
        }
      }
    }
  }, [
    aircraftId,
    aircraftMelsMap,
    defect,
    defectType,
    dirty,
    dispatch,
    editDefect,
    lastFetched,
    melItems,
    setMelItemsLoading,
  ]);

  useEffect(() => {
    if (!_.isEqual(list, prevMelItems)) {
      setMelItems(list);
      setMelItemsLoading(false);
    }
  }, [list, prevMelItems, setMelItemsLoading]);

  const filterMelItems = useCallback(
    (input: string): void => {
      const filtered = melItems.filter((item) => {
        let itemReference = `${item.chapter_number}-${item.section_number}`;
        if (item.subsection_number) {
          itemReference = `${itemReference}-${item.subsection_number}`;
        }
        if (
          itemReference
            .toString()
            .toLowerCase()
            .includes(input) ||
          item.title.toLowerCase().includes(input)
        )
          return item;
        return null;
      });
      setFilteredItems(filtered);
    },
    [melItems],
  );

  useEffect(() => {
    if (searchInput) {
      filterMelItems(searchInput);
    } else {
      setFilteredItems(melItems);
    }
  }, [filterMelItems, melItems, searchInput]);

  return (
    <Wrapper id="DeferralOptions" data-testid="StyledCard--DeferralOptions">
      <FlexWrapper column marginBottom={20}>
        <>
          <FlexWrapper marginBottom={defectType ? 30 : 0}>
            <FlexWrapper column marginRight={80}>
              <FlexWrapper>
                <Label marginBottom={20} fontWeight={600} identifier="DeferralOptions" marginRight={4}>
                  {formatMessage({ id: 'text.deferralOptions' })}
                </Label>
                <TFTooltip
                  handleMouseOver={onMouseEnterDeferral}
                  handleMouseLeave={onMouseLeaveDeferral}
                  showToolTip={showDeferralInfo}
                >
                  <BoldDiv>
                    {formatMessage({ id: 'text.mel' })}{' '}
                    <RegularText>{formatMessage({ id: 'text.minimumEquipmentList' })}</RegularText>
                  </BoldDiv>
                  <BoldDiv>
                    {formatMessage({ id: 'text.cdl' })}
                    <RegularText>{formatMessage({ id: 'text.configurationDeviationList' })}</RegularText>
                  </BoldDiv>
                  <BoldDiv>
                    {formatMessage({ id: 'text.nef' })}{' '}
                    <RegularText>{formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' })}</RegularText>
                  </BoldDiv>
                  <BoldDiv>
                    {formatMessage({ id: 'text.cas' })}{' '}
                    <RegularText>{formatMessage({ id: 'text.crewAlertingSystem' })}</RegularText>
                  </BoldDiv>
                </TFTooltip>
              </FlexWrapper>
              <FlexWrapper identifier="DeferralOptionsWrapper">{deferralOptions}</FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
          {defectType === 'MEL' ? (
            <>
              <Label marginBottom={15} fontWeight={500}>
                MEL - Minimum Equipment List
              </Label>
              <MelItemsWrapper>
                <Loading loading={melItemsLoading} contain />
                {melItems && melItems.length > 0 ? (
                  <>
                    <SearchWrapper>
                      <Search
                        onClear={(): void => setSearchInput('')}
                        onChange={(e): void => setSearchInput(e.currentTarget.value.toLowerCase())}
                      />
                    </SearchWrapper>
                    <Card id="MELItemWrapper" maxHeight={500} identifier="MelItemsWrapper">
                      <DefectMelItems
                        melItems={filteredItems}
                        setMelItem={setMelItem}
                        handleMelItemChange={handleLocalMelItemChange}
                        defect={defect}
                        setRectificationCategory={setRectificationCategory}
                        setRectificationId={setRectificationId}
                        searchInput={searchInput}
                        setATAChapter={setATAChapter}
                        setATASection={setATASection}
                      />
                    </Card>
                    <FlexWrapper column={isMobile} marginTop={30} marginBottom={30}>
                      <FlexWrapper marginRight={20}>
                        <DateTimePicker
                          dateTime={defect?.deferred_at}
                          headerDate="Deferral date"
                          headerTime="Deferral time"
                          handleDateTimeChange={handleDeferralTimeChange}
                          disabled={!unlockDeferralTime}
                        />
                      </FlexWrapper>
                      <FlexWrapper marginTop={isMobile ? 20 : 0} width="100%">
                        <DeferUntil
                          defect={displayDefect}
                          rectificationCategory={rectificationCategory}
                          setDateDue={setDateDue}
                        />
                        <ButtonWrapper>
                          {!unlockDeferralTime ? (
                            <Button
                              primary={false}
                              height="24px"
                              padding="0 12px"
                              onClick={(): void => setUnlockDeferralTime(true)}
                            >
                              <StyledIcon src={padlockIcon} alt="padlock icon" />
                              Unlock
                            </Button>
                          ) : null}
                        </ButtonWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                    {displayDefect && displayDefect?.mel_item && !isMobile && (
                      <ViewMelDetails defect={displayDefect} isMelTableVisible edit />
                    )}
                  </>
                ) : (
                  <ManuallyEnterMELDetails
                    defect={defect}
                    rectificationCategory={rectificationCategory}
                    setRectificationCategory={setRectificationCategory}
                    deferred={defectDeferred}
                    setDateDue={setDateDue}
                    noMEL
                    apuInstalled={apuInstalled}
                    reference={reference}
                    setReference={setReference}
                    ATAChapter={ATAChapter}
                    setATAChapter={setATAChapter}
                    ATASection={ATASection}
                    setATASection={setATASection}
                    deferredTime={deferredTime}
                    setDeferredTime={setDeferredTime}
                    updateDefectData={updateDefectData}
                  />
                )}
              </MelItemsWrapper>
            </>
          ) : null}
          {defectType && defectType !== 'MEL' ? (
            <DefectNonMelItems
              defect={defect}
              defectType={defectType}
              rectificationCategory={rectificationCategory}
              setRectificationCategory={setRectificationCategory}
              deferred={defectDeferred}
              setDateDue={setDateDue}
              apuInstalled={apuInstalled}
              deferredTime={deferredTime}
              setDeferredTime={setDeferredTime}
              reference={reference}
              setReference={setReference}
              ATAChapter={ATAChapter}
              setATAChapter={setATAChapter}
              ATASection={ATASection}
              setATASection={setATASection}
              updateDefectData={updateDefectData}
            />
          ) : null}
        </>
      </FlexWrapper>
    </Wrapper>
  );
};

export default DeferralOptions;
